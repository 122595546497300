.sol-cookie-message {
  .sol-clearfix;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 290px;
  border-top-left-radius: 3px;
  background-color: @cookie-bg;
  color: @cookie-text;
  padding: 8px 10px 8px 14px;
  font-size: 13px;
  & a {
    color: @cookie-text;
    text-decoration: underline;

    &:focus {
      outline: 2px solid yellow;
      outline-offset: 2px;
      z-index: 10;
    }
  }
  & .sol-cookie-ok {
    .no-appearance;
    display: inline-block;
    padding: 0 7px;
    border-radius: 2px;
    background-color: @cookie-ok-bg;
    color: @cookie-ok-text;
    font-size: 10px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    float: right;
    margin-top: 3px;

    &:focus {
      outline: 2px solid yellow;
      outline-offset: 2px;
      z-index: 10;
    }
  }
}
.sol-editor-bottom {
  bottom: 60px !important;
}
