.sol-event-info {
  margin-bottom: 25px;
  margin-top: 25px;
  position: relative;
  font-family: @alt-font;
  .sol-simple-box-decoration {
    margin-top: 0;
    padding: 16px 16px 16px 67px;

    @media @md {
      padding: 16px;
    }
  }
  &-field {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 23px;
    font-weight: 400;
    margin-bottom: 5px;

    @media @sm {
      font-size: 19px;
    }

    & > span {
      margin-right: 10px;
      font-size: 23px;
      font-weight: 800;

      @media @sm {
        font-size: 19px;
      }
      &.event-deviding-comma {
        margin-left: -5px;
      }
    }
  }
  & i.fas {
    position: absolute;
    left: 18px;
    top: 18px;
    color: @color-4;
    font-size: 32px;

    @media @md {
      left: unset;
      right: 18px;
    }
  }
  &__ticket-info {
    display: inline-block;
    font-size: 18px !important;
    margin-top: 10px;
  }
  &__ticket-link {
    & i {
      margin-right: 5px;
      margin-left: 10px;
    }
  }
}

.sol-event-info-small {
  .sol-clearfix;
  margin-top: 10px;
  margin-bottom: 10px;
  &-item {
    font-family: @main-font;
    font-size: 15px;
    font-weight: 500;
    color: @black;
    margin: 15px 0 0 0;
    & i {
      font-size: 17px;
      margin-right: 6px;
      color: @black;
    }
  }
}
