.sol-focusevent-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0 0;

  @media @md {
    margin: 20px 0 10px;
  }
  @media @xs {
    margin: 15px 6px 0;
  }

  & .sv-custom-module {
    max-width: 31%;
    margin: 0 1% 20px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-width: 30% !important;
      flex: 1 0 33% !important;

      @media @sm {
        max-width: 100% !important;
        flex: 1 0 100% !important;
      }
    }
    @media (max-width: 1200px) {
      max-width: 360px;
      margin: 0 10px 20px;
    }
    @media @md {
      max-width: 310px;
      margin: 0 4px 20px;
    }
    @media @sm {
      max-width: 94%;
      margin: 20px auto;
    }
    @media @xs {
      max-width: 100%;
      margin: 20px auto;
    }
  }
  & .sol-single-event {
    flex: 1 0 auto;
    max-width: 360px;
    min-height: 460px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
  }
  
  & .sol-focus-event {
    margin: 0 auto;

    @media @sm {
      margin: auto;
    }
  }
}

.sol-startpage-event {
  .sol-focus-event {
    max-width: 480px;
    &-item {
      border-radius: 3px;
      background-color: #fff;
      box-shadow: none;
      margin-bottom: 20px;

      &__text {
        padding: 26px 18px 0;
      }
    }
  }
}

.sol-focus-event {
  max-width: 480px;
  &-item {
    &__link {
      display: block;
      text-decoration: none;

      &:hover {
        & .sol-focus-event-item__header {
          text-decoration: underline;
        }
        & .sol-focus-event-item__preamble {
          text-decoration: underline;
          color: #006579;
        }
      }
    }
    &__image-container {
      text-align: center;
      min-height: 190px;
      overflow: hidden;
      margin-bottom: 0;

      & .sol-focus-event-item__image {
        width: auto;
      }
    }
    &__text {
      padding: 18px 18px 16px 18px;

      & .sol-focus-event-item__header {
        font-family: @main-font;
        color: @color-1;
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 3px;
        margin-top: 0;
      }
      & .sol-focus-event-item__preamble {
        font-family: @main-font;
        color: @black;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 0;
      }
    }
    &__date {
      margin: 8px 0 0;
      & > div {
        display: inline-block;
        margin-right: 12px;
        font-size: 15px;
        font-weight: 500;

        &:nth-of-type(2) {
          margin-right: 0;
        }
      }

      & i {
        margin-right: 2px;
        font-size: 16px;
      }

      & .sol-focus-event-item__location {
        display: inline-block;
        margin-right: 12px;
        width: auto;
        font-size: 15px;
        font-weight: 500;
      }

      & .sol-focus-event-item__free {
        display: inline-block;
        margin-right: 12px;
        width: auto;
        font-size: 15px;
        font-weight: 500;
      }

      & .sol-focus-event-item__categories {
        display: block;
        & i {
          font-size: 15px;
          font-weight: 500;
        }
        & ul {
          padding-left: 0;
          list-style: none;
          display: inline;

          & li {
            font-size: 15px;
            font-weight: 500;
            display: inline-block;
            margin-right: 10px;

            & span {
              margin-left: -3px;
            }
          }
        }
      }
    }
  }
}
.sol-focus-event-page {
  margin: 60px auto;

  & .pagecontent {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);

    & .sv-mfpimage-portlet {
      max-height: 540px;
      overflow: hidden;
      margin-bottom: 0;
      & img {
        max-width: none !important;
        max-height: none !important;
      }
    }
    & h1 {
      font-family: @main-font;
      color: @color-1;
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 3px;
      margin-top: 0;
      padding: 18px 18px 0 18px;
    }
    & p {
      font-family: @main-font;
      color: @black;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 0;
      padding: 0 18px 24px 18px;
    }
  }
}
.sol-widget-fullwidth {
  & .sol-focus-event {
    margin-top: 20px;
  }
}

.sol-tourism-container {
  & .sol-focus-event {
    margin: 20px 0;
  }
}

// Startpage event

.sol-startpage-event {
  & .sol-focus-event {
    &-item {
      &__image-container {
        @media @sm {
          min-height: 0;
        }
      }
    }
  }
}

.sol-single-event {
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

  .sol-focus-event-item__image-container {
    height: 220px;
    background: #efefef;
    text-align: center;

    & img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.sol-startpage-event {
  .sol-single-event {
    background: transparent;
    box-shadow: none;

    .sol-focus-event-item__image-container {
      background: transparent;
    }
  }
}
