@tabs-border-bottom: fadeout(@color-1, 60%);
@tabs-border-bottom-active: @color-1;

.sol-tabs {
  &__buttons {
    display: flex;
    border-bottom: 4px solid @tabs-border-bottom;

    @media @sm {
      display: block;
      border-bottom: none;
    }

    & > button {
      flex: 1;

      @media @sm {
        width: 100%;
      }
    }
  }

  &__content {
    position: relative;

    & > div {
      top: 0;
      width: 100%;
    }
  }
}

.sol-tabs-button {
  display: block;
  font-family: @main-font;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  min-height: 44px;
  line-height: 44px;
  border-bottom: 4px solid transparent;
  margin-bottom: -4px;

  @media @sm {
    border-bottom-color: @tabs-border-bottom;
    padding-top: 5px;
  }

  &--active {
    border-bottom-color: @tabs-border-bottom-active;
  }
}
