.sol-startpage-service-messages{
  border-top-left-radius: 0;
  background-color: #00866d;
  border-left: 4px solid #fff;
  display: flex;
  padding: 6px 34px;
  line-height: 24px;
  // border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  h2{
    color: #fff;
    font-weight: 600 !important;
    font-size: 19px;
    margin: 0 !important;
    line-height: 30px;
  }
  i.fal{
    color: #fff;
    font-weight: lighter;
    font-size: 17px;
    margin-right: 8px;
  }
}

.sol-service-messages {
  ul li{
    min-height: 70px
  }
  .sol-service-message-item {
    display: flex;
    // align-items: center;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
    border-radius: 4px;
    text-decoration: none;
    overflow: hidden;
    margin-bottom: 0px !important;
    height: 100%;

    &:hover {
      .sol-service-message-item__header {
        text-decoration: underline;
      }
    }
    
    &__date, &__header {
      font-family: 'Fira Sans',sans-serif;
      color: #35383b;
      font-weight: 300;
      padding: 12px;
      height: auto; 
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__date {
      background-color: rgba(255, 209, 0, 0.5);
      font-weight: 400;
      font-size: 15px;
      line-height: 1.3;
      min-width: 124px;
      width: 124px;
      text-align: center;
    }
    &__header {
      font-size: 17px;
      line-height: 1.3;
    }
  }
}
