.sol-publish-info {
  background-color: darken(@gray-4, 1%);
  border: none;
  padding-top: 14px;
  padding-bottom: 7px;
  font-size: 16px;
  font-weight: 400;
  & span{
    font-family: 'Fira Sans',sans-serif!important;
    font-weight: 300!important;
  }
  & strong {
    font-family: @main-font;
    margin-right: 5px;
    font-size: 16px;
    font-weight: 600 !important;
    @media @sm {
      font-size: 14px;
    }
    @media @xs {
      display: block;
    }
  }
}
