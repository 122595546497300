.sv-marketplace-sitevision-cookie-consent,
.sv-cookie-consent-banner,
.sv-cookie-consent-modal {
  h1,
  h2 {
    font-weight: 700;
    font-style: normal;
    font-size: 30px;
    color: @black;
    line-height: normal;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  p,
  a,
  .env-text {
    font-weight: 400;
    font-size: 16px !important;
    color: @black;
  }

  p {
    a {
      text-decoration: underline;
    }
  }

  a {
    color: @black;

    &:hover {
      color: @black;
    }
  }

  .env-button {
    &.env-button--primary {
      background-color: @color-1;
      border-color: @color-1;
      color: @white;
      font-weight: 700;
      font-size: 15px;

      &.env-button--ghost {
        background-color: @white;
        color: @color-1;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .env-switch__slider {
    background-color: @color-1 !important;
    border-color: @color-1 !important;
    height: 20px;

    &::before {
      height: 20px;
      width: 20px;
    }

    &::after {
      height: 16px;
      width: 16px;
    }
  }
}

.sv-cookie-consent-banner {
  background-color: #dbede4 !important;
  border-top: 2px solid @color-1;
}
