/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */

.sol-pinboard {
  padding-left: 30px;
  background-color: fadeout(@color-1, 90%);
  border-radius: 8px;

  &-creator {
    padding: 30px 25px 35px;
    border-top: 3px solid @color-1;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);

    &, input::-webkit-input-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input:-moz-placeholder {
      font-size: 15px;
      font-style: normal;
      font-weight: normal;
      font-stretch: normal;
      line-height: normal;
      color: #a7a7a7;
      text-align: left;
      letter-spacing: normal;
    }

    &__registration-form {
      select {
        display: block;
        width: 100%;
        height: 45px;
        padding: 6px 12px;
        font-size: 17px;
        background-color: #f8f8f8;
        border: 1px solid #cecece;
        border-radius: 2px;
        appearance: menulist;

        &:hover {
          cursor: pointer;
        }
      }

      input#sol-notice-title, input#sol-protocol-storage {
        display: block;
        width: 100%;
        padding: 6px 12px;
        margin-bottom: 20px;
        font-size: 18px;
        background-color: white;
        border: 1px solid #cecece;
        border-radius: 2px;
      }
  
      .sol-form-group {
        margin-top: 20px;
        margin-bottom: 30px;

        @media @sm {
          margin-top: 12px;
          margin-bottom: 16px;
        }
      }
  
      &.input-group--text-field.input-group--dirty.input-group--select label, .input-group--text-field.input-group--dirty:not(.input-group--textarea) label {
        left: 40px !important;
        transform: translateY(-40px) translateX(-40px) scale(.85);
      }
      
      .input-group--text-field {
        label {
          top: -4px;
          left: 50px !important;
          font-family: @main-font;
          font-size: 18px;
        }
      }

      .menu__activator {
        .input-group {
          padding: 6px 12px;
          margin-top: 30px;
          border: 1px solid #cecece;

          & input {
            height: 26px;
            font-family: @main-font;
            font-size: 18px;
          }
        }
      }

      .menu__activator--active {
          label {
            top: 18px;
          }
      }

      label {
        display: block;
        margin: 16px 0 4px;
        font-family: @main-font;
        font-size: 1.1rem;
        font-weight: 600;
      }

      .sol-notice__dates {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & label[for='sol-notice-date'] {
          margin-top: 0;
        }

        .application--wrap {
          padding: 0;
          background-color: #fff;
        }
  
        &__error-message {
          font-family: @main-font;
          line-height: 22px;
          color: red;
        }

        .menu__content {
          min-width: initial !important;
        }

        & .notice-date {
          flex: 1 0 50%;
          max-width: 48%;

          @media @sm {
            flex: 1 0 100%;
            max-width: none;
          }
        }

        & .notice-time {
          display: flex;
          flex: 1 0 50%;
          flex-direction: column;
          justify-content: flex-end;
          max-width: 48%;

          @media @sm {
            flex: 1 0 100%;
            max-width: none;            
          }
        }
      }
  
      .sol-authority__error-message {
        color: red;
      }
  
      .sol-title__error-message {
        color: red;
      }
  
      .sol-form-group__error-message {
        color: red;
      }
  
      .sol-files-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        li {
          display: block;
          width: 100%;
          padding: 10px 20px;
          font-size: 18px;
          background-color: fadeout(@color-1, 85%);

          &:nth-of-type(odd) {
            background-color: fadeout(@color-1, 95%);
          }

          & > i {
            margin-right: 8px;
            font-size: 1.12em;
            color: #00866d;
          }

          @media @sm {
            position: relative;
            max-height: 50px;
            padding: 12px;
            padding-right: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .sol-remove-file-btn {
        float: right;
        padding-left: 20px;
        margin-right: 0;

        
        & i {
          font-size: 1.12em;
          font-weight: 600;

          @media @sm {
            position: absolute;
            right: 12px;
          }
        }
      }

      .sol-file-uploader {
        position: relative;
        padding: 0;
        margin: 0;

        & .sol-file-uploader__file-name {
          display: none;
        }
      }

      & .fileupload-wrapper {
        background: #fff;
      }
  
      .sol-file-uploader__upload-btn {
        display: inline-block;
        margin: 4px 0 16px;
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline;

        &:hover {
          color: #006579;
          cursor: pointer;
        }

        & i {
          margin-right: 10px;
          font-size: 23px;
          color: #00866D;
        }
      }
  
      button[type='submit'] {
        display: inline-block;
        height: 42px;
        padding: 0 30px;
        margin: 10px 0 15px;
        overflow: hidden;
        font-size: 16px;
        font-weight: 400;
        line-height: 42px;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: rgba(255, 209, 0, 0.6);
        border-radius: 100px;
      }
  
      .sol-file-uploader input {
        position: absolute;
        z-index: -1;
        height: 90px;
        outline: none;

        &:focus {
          + label {
            z-index: 10;
            outline: 2px solid #257cc3;
            outline-offset: 1px;
          }
        }
      }  
  
      .input-group {
        height: 44px;
        padding: 0;
        margin: 23px 0 18px;
  
        &__details {
          display: none;
        }
      }
  
      .sol-pinboard-datePicker {
        max-height: 100px;
      }
  
      .application--wrap {
        min-height: auto;
      }
    }

    ul {
      padding: 0;
      margin-top: 0;
      list-style-type: none;
    }

    & .sol-notice__file-folders {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
      & > div.sol-form-group {
        display: flex;
        flex: 1 0 50%;
        flex-direction: column;
        justify-content: flex-end;
        max-width: 48%;
        margin-top: 0;

        @media @sm {
          flex: 1 0 100%;
          max-width: none;
          margin: 0;
        }
      }
    }
  }

  .sol-pin {
    padding-top: 16px;
    padding-bottom: 16px;

    p {
      margin-bottom: 0;
    }
  }
}

.sol-collapsible-pinboard {
  margin-bottom: 20px;
}

.sol-form-top-level-error-list{
  margin-bottom: 1rem;
  border-radius: 3px;
}

.sol-form-top-level-error-item {
  padding: 15px;
  margin-bottom: 1rem;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  color: #333;
  letter-spacing: 0.4px;
  background-color: rgba(200, 73, 107, 0.2);
}

.sol-error-message {
  display: none;
}

.sol-thankyou-message {
  display: none;
}

.sol-pinboard-datePicker {
  & .material-icons {
    color: #00866D !important;
  }
}

.sol-pinboard-creator {
  & label[for='noticeMeeting'], label[for='noticeProtocol'] {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
    font-family: @main-font;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.3em;
    color: @form-input-text;
    cursor: pointer;
  
    & input {
      position: relative;
      top: 4px;
      display: inline-block;
      width: 21px;
      height: 21px;
      margin-right: 6px;
      background-color: white;
      border: 1px solid #1B927C;
      border-radius: 50%;
      box-shadow: none;
      appearance: none;
      
      &:checked {
        background: #1b927c url('https://www.strangnas.se/images/18.38e877f416b3ac0044142498/1560260286947/circle-128.png') no-repeat;
        background-position: center;
        background-size: 11px;
        border: 2px solid #1b927c;
        box-shadow: none;
      }
    }
  }

  input {
    &#sol-notice-location, &#sol-notice-storage-location, &#sol-notice-secretary {
      width: 100%;
      height: 45px;
      padding-left: 10px;
      font-size: 15px;
      background-color: #f8f8f8;
      border: 1px solid #cecece;
    }

    &#sol-notice-date, &#sol-notice-time {
      width: 100%;
      height: 45px;
      padding-left: 10px;
      font-size: 15px;
      background-color: #f8f8f8;
      border: 1px solid #cecece;
    }
  }
}
