.sol-news-container {
  margin: 30px 0;
      & ul li > a.font-normal {
          display: inline-block;
          text-decoration: none;
          margin-bottom: 3px;
          & h2, & h3 {
            margin: 5px 0 2px;
            font-family: @main-font;
            font-size: 19px;
            font-weight: 600;
            color: darken(@color-1, 1%);
          }
          & span {
            font-family: @main-font;
            color: #28282a;
            text-decoration: none;
            font-size: 18px;
            line-height: 1.6;
            font-weight: 300;
            margin: 8px 0 0;
          }
          &:hover {
            & h2, & h3 {
              text-decoration: underline !important;
            }
            & span {
              text-decoration: underline !important;
            }
          }
      }
      & ul li > h2, & ul li > h3 {
        margin: 0 !important;

        & a {
          text-decoration: none;

          & span {
            font-family: @main-font;
            font-size: 19px;
            font-weight: 600;
            color: darken(@color-1, 1%);
          }
        }
      }
  & ul li > span {
      font-family: @main-font;
      color: #35383b;
      font-size: 14px;

      &.font-heading-2 {
        display: none;
      }
  }
  & a {
      &:hover{
          text-decoration: underline;
      }
      font-family: @main-font;
      text-decoration: none;
      font-size: 18px;
      font-weight: 300;
  }
  & li {
      border-bottom: 1px solid @gray-2;
      padding-bottom: 12px;
  }
  & .sol-links-with-icon {
    & a {
      display: inline-block;
      position: relative;
      text-decoration: none;
      padding-left: 24px;
  
      &:before {
        font-family: 'Font Awesome 5 Pro';
        content: "\f105";
        font-size: 23px;
        font-weight: 400;
        color: @color-1;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 5px;
        top: -2px;
      }
    }
  }
}
.sol-news-menu__mobile {
    position: relative;
    & .sol-sub-menu-container {
        display: none;
    }
    & i {
        position: absolute;
        right: 20px;
        font-size: 32px;
        top: 5px;
    }
}
@media @md {
    .sol-menu-container__desktop {
        display: none;
    }
    .sol-news-menu__mobile {
        display: block;
    }
}
