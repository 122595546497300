.sol-collapsible {
  margin-top: 10px;
  margin-bottom: 10px;
  &__header {
    .no-appearance;
    .focus-outline;
    position: relative;
    display: block;
    width: 100%;
    padding: 14px 50px 14px 22px;
    background-color: @box-decoration-header-bg;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(255, 209, 0, 0.2);
    border: solid 1px rgba(255, 209, 0, 0.3);
    border-left-width: 3px;
    border-left-color: @color-4;
    color: @box-decoration-header-text;
    text-align: left;
    text-decoration: none;
    line-height: 1.1;
    font-weight: 600;
    font-size: 15px;
    margin: 0;
    cursor: pointer;
    &__icon {
      display: block;
      width: 38px;
      height: 38px;
      position: absolute;
      right: 6px;
      top: 50%;
      margin-top: -19px;
      &::after,
      &::before {
        content: "";
        display: block;
        background-color: @white;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 4px;
        margin-left: -10px;
        margin-top: -3px;
        .transition(all);
      }
      &::after {
        .rotate(90deg);
      }
    }
    .sol-collapsible-icon {
      font-size:30px;
    }
    &--active {
      background-color: @button-bg;
      & .sol-collapsible-icon {
        transition: transform .2s ease-in-out;
        .rotate(180deg);
      }
    }
    &:hover {
      box-shadow: 0 0 6px fadeout(@color-4, 40%);

      & span {
        text-decoration: underline;
      }
    }
    &__text {
      font-size: 18px;
      font-weight: 600;
      //font-size: 20px;
      //font-weight: normal;

      @media @sm {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  & .sol-collapsible__content,
  & .sv-decoration-content {
    display: none;
    background-color: @box-decoration-bg;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
    border-radius: 3px;
    // padding: 20px;
    padding: 20px 20px 60px;
    & > *:last-child {
      margin-bottom: 0;
    }
    & p:last-of-type {
      margin: 0;
    }
    .sv-editing-mode & {
     // display: block;
    }
  }

  & .sol-collapsible__close {
    display: none;
    position: relative;
    bottom: 50px;
    right: 0;
    left: calc(100% - 150px);
    padding: 0 20px;
    width: 150px;
    
    &-text {
      text-decoration: underline;
      font-size: 15px;
    }
    & i {
      font-size: 17px;
    }
  }
  &.active {
    & .sol-collapsible__close {
      display: block;
    }
  }
}
& .sol-collapsible-icon {
  font-size: 24px;
  transition: transform .2s ease-in-out; 
  .rotate(0deg);
  font-weight: 300;
  position: absolute;
  right: 15px;
  font-size: 28px;
  top: 8px;
}
