.sol-contact,
.sol-related {
  background-color: @related-box-bg;
  border: 1px solid fadeout(@color-1, 60%);
  font-size: 18px;
  margin-top: 5px;
  padding-bottom: 8px;
  padding-top: 10px;
  margin-bottom: 10px;
  @media @md {
    font-size: 17px;
  }
}
.sol-related {
  &-item {
    margin-bottom: 5px;
    text-decoration: none;
    line-height: 23px;
    display: inline-block;
    &-name {
      text-decoration: underline;
      color: @related-box-link-text;
      .transition(color);
    }
    &-size {
      display: none;
      color: @related-box-link-text;
      font-size: 12px;
      margin-left: 10px;
    }
    & i.fa {
      width: 17px;
      height: 17px;
      margin-right: 5px;
      border-radius: 50%;
      color: @related-box-icon;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      position: relative;
      top: 1px;
      &.sol-link-icon {
        font-size: 19px;
      }
    }
    &:hover &-name {
      color: @related-box-link-hover;
    }
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  div &-item &-item-name {
    text-decoration: none;
    color: @related-box-text;
  }
}
.sol-contact {
  &-item {
    h3 {
      font-family: @main-font;
      margin-top: 15px;
      font-size: 18px;
      font-weight: 700;
    }
    h4 {
      font-family: @main-font;
      font-size: 14px;
      margin-top: 8px;
      display: block;
      margin-bottom: 1px;
    }
    h5 {
      font-family: @main-font;
      font-size: 14px;
      margin-top: 8px;
      display: block;
      margin-bottom: 1px;
      font-weight: 700;
    }
    p {
      font-family: @main-font;
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 18px;
      line-height: 1.4;
    }
    .postal-address{

      p{

        @media @md {

        }
        @media @sm {

        }

        &:nth-child(2) {
          float: right;
        }
      }
        span{
          .font-normal{
            margin-right: -60px;
            margin-left: 2px;
            margin-top: -21px;
            padding-left: 71px;
            @media @md {
              padding-left: 71px;
              margin-top: -21px;
            }
            @media @sm {
  
            }
          }
        }
      }
      .visit-address{
        display: inline-block;
        p{

          @media @md {

          }
          @media @sm {

          }
        }
      
        span{
          .font-normal{
            float: right;
            margin-right: -60px;
            margin-left: 2px;
            margin-top: -21px;
            padding-left: 90px;
            @media @md {
              padding-left: 87px;
              margin-top: -21px;
            }
            @media @sm {
            }
          }
        }
      }
    

    p.font-normal{
      @media @md {
        font-size: 15px !important;
      }
      @media @sm {
        font-size: 15px !important;
      }
    }
  }
}

.sol-contact-heading, .sol-related-heading {
  @media @md {
    font-size: 15px;
  }
}

.sol-contact-item {
  @media @md {
    p, p.font-normal {
      font-size: 17px !important;
    }
  }
}

.sv-text-portlet
.sv-text-portlet-content {

  ul.font-normal,
  ul.font-description{
    li{
      margin-left: 20px;
    }
  }
  ol{
   margin-left: 20px;
  }
  ul.sol-bullet-list{
    li{
      margin-left:0px !important;
    }
  }
  
}
 