
.sol-popular-services {
  min-height: 310px;
  .fa-external-link{
    display: none;
  }
  & a {
    background-color: #FFF6CC;
    display: block;
    position: relative;
    text-decoration: none;
    padding: 12px 38px 12px 30px;
    border-bottom: 2px solid @services-border;
    color: @services-text;
    font-size: 16px;
    line-height: 20px;

    @media @md {
      padding: 16px 38px 16px 30px;
      line-height: 22px;
    }
    .transition(color);
    &:hover {
      color: @services-text-hover;
      text-decoration: underline;
    }
    & i.fal {

      &:first-child {
        position: absolute;
        left: 15px;
        font-size: 17px;
        color: @services-icon;
        text-align: center;
        margin-top: 0;

        @media @md {
          font-size: 23px;
          left: 10px;
        }
      }
      &:last-child {
        position: absolute;
        right: 12px;
        top: 35%;
        font-size: 14px;
        color: @services-icon;
        text-align: center;
        margin-top: 0;

        @media @md {
          right: 16px;
          font-size: 17px;
        }
      }
    }
  }
  &__see-all {
    background-color: #FFF6CC;
    width: 100%;
    padding: 10px;

    & a {
      background-color: #fff;
      margin: .4em 0;
      padding: 16px 10px 10px 16px;
      height: 50px;
      line-height: 18px;
      border-radius: 300px;
      width: 100%;
      font-size: 15px;
      font-weight: 400;
      color: #35383b;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media @md {
        font-size: 14px;
        font-weight: 500;
        width: fit-content;
        margin: 0;
      }
    }
  }
  @media @md {
    background-color: #FFF6CC;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
  }
}
