.lp-panic-button {
  position: fixed;
  right: 0;
  left: calc(50% - 252px);
  bottom: 0;
  z-index: 999;

  @media (min-width: 1381px) {
    left: calc(50% - 630px);
  }
  @media @sm {
    left: 0;
  }

  & a {
    padding: 15px 35px;

    @media @sm {
      width: 100%;
      padding: 16px;
      font-size: 19px;
    }
  }
}