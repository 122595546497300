.sol-blurb {
  max-width: 480px;
  &-item {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
    margin-bottom: 20px;

    &__link {
      text-decoration: none;

      &:hover {
        & .sol-blurb-item__header {
          text-decoration: underline;
        }
        & .sol-blurb-item__preamble {
          color: #006579;
          text-decoration: underline;
        }
      }
    }
    &__image-container {
      max-height: 540px;
      overflow: hidden;
      margin-bottom: 0;

      & .sol-blurb-item__image {
        width: 100%;
        height: 100%;
      }
    }
    &__text {
      padding: 18px 18px 24px 18px;

      & .sol-blurb-item__header {
        font-family: @main-font;
        color: @color-1;
        // text-decoration: underline;
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 3px;
        margin-top: 0;
      }
      & .sol-blurb-item__preamble {
        font-family: @main-font;
        color: @black;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 0;
      }
    }
  }
}
.sol-blurb-page {
  margin: 60px auto;

  & .pagecontent {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);

    & .sv-mfpimage-portlet {
      max-height: 540px;
      overflow: hidden;
      margin-bottom: 0;
      & img {
        max-width: none !important;
        max-height: none !important;
      }
    }
    & h1 {
      font-family: @main-font;
      color: @color-1;
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 3px;
      margin-top: 0;
      padding: 18px 18px 0 18px;
    }
    & p {
      font-family: @main-font;
      color: @black;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 0;
      padding: 0 18px 24px 18px;
    }
  }
}
.sol-widget-fullwidth {
  & .sol-blurb {
    margin-top: 20px;
  }
}

.sol-tourism-container {
  width: 100%;
  position: relative;
  & .sol-blurb {
    margin: 20px 0;
  }
}

.sol-startpage-available-positions {
  & .sol-blurb {
    &-item {
      box-shadow: none;
    }
  }
}
