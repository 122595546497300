/* stylelint-disable media-feature-range-notation */
/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
.sol-tourism-events {
  width: 100%;
  max-width: 1260px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    list-style: none;
  }

  &__item {
    flex: 0 0 32%;
    max-width: 32%;
    margin: 20px auto;

    @media (max-width: 1170px) {
      flex: 0 0 49%;
      max-width: 49%;
      margin: 20px auto;
    }

    @media @sm {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &:last-of-type {
      margin: 20px auto;
      margin-left: 0;

      @media @sm {
        margin-right: auto;
        margin-left: auto;
      }
    }

    &-content {
      max-width: 406px;
      min-height: 450px;
      margin: auto;
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

      @media @sm {
        height: auto;
      }
    }

    &--text {
      padding: 18px 18px 24px;
    }

    & a {
      text-decoration: none;

      &:hover {
        & .sol-tourism-events__item--header {
          text-decoration: underline;
        }

        & .sol-tourism-events__item--preamble {
          color: #006579;
          text-decoration: underline;
        }
      }
    }

    &--image-container {
      text-align: center;
      background: #E4E4E4;
    }

    &--image {
      max-width: 100%;
      height: 230px;
    }

    &--no-image {
      width: auto;
      height: 230px;
      background-color: #f8f8f8;
    }

    &--header {
      display: block;
      margin-top: 0;
      margin-bottom: 3px;
      font-family: @main-font;
      font-size: 18px;
      font-weight: 600;
      color: @color-1;
    }

    &--preamble {
      display: block;
      margin-bottom: 6px;
      font-family: @main-font;
      font-size: 19px;
      font-weight: 300;
      line-height: 1.4em;
    }

    &--info {
      & span {
        display: inline-block;
        width: auto;
        margin-right: 15px;
        font-family: @main-font;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.4em;

        @media @sm {
          max-width: none;
        }

        &:first-of-type {
          @media @sm {
            max-width: none;
          }
        }

        &:last-of-type {
          max-width: 100%;
        }

        &.sol-tourism-event-categories {
          width: 100%;
        }

        & ul {
          display: inline-block;
          list-style: none;

          & li {
            display: inline-block;
  
            & span {
              margin-right: 5px;
            }
          }
        }
      }

      & i {
        margin-right: 5px;
        font-size: 17px;
        font-weight: 300;
        color: @color-1;
      }
    }
  }
}

.sol-tourism-center-btn {
  margin: 20px auto 0;

  & .sol-link-tablet {
    margin: auto;
  }
}
