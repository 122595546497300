footer {
  .sol-bottom {
    padding: 230px 0 50px;
    background: url("@{sol-graphics-path}/Skyline_sidfot.png");
    background-size: cover;
    background-color: @gray-4;

    @media (min-width: 2700px) {
      padding-top: 330px;
    }

    @media (min-width: 3300px) {
      padding-top: 380px;
    }

    @media @md {
      padding: 190px 20px 30px;
    }
    @media @sm {
      padding: 110px 35px 30px;
      background: url("@{sol-graphics-path}/Skyline_sidfot2.png");
      background-size: cover;
      background-color: @gray-4;
    }

    & .sv-grid-main .sv-row > .sv-column-4 {
      padding-right: 30px;
    }

    .sol-footer-contact,
    .sol-footer-links {
      &-item {
        font-size: 16px;
        line-height: 38px;
        display: block;
        position: relative;
        margin-bottom: 11px;
        padding-left: 0px;
        text-decoration: none;
        color: @footer-text;

        @media @sm {
          font-size: 14px;
        }

        &--icon-links {
          & a::before {
            .icon(@fa-var-angle-right, 14px, block);
            width: 18px;
            height: 18px;
            margin-right: 10px;
            padding-left: 2px;
            border: 1px solid rgba(255, 255, 255, 0.7);
            border-radius: 50%;
            opacity: 0.5;
            line-height: 16px;
            text-align: center;
            font-size: 14px;
            position: absolute;
            left: 0;
            top: 1px;
            box-sizing: border-box;
          }
        }

        & a,
        & p {
          font-family: @main-font;
          display: block;
          text-decoration: none;
          color: @footer-text;
          font-size: 16px;
          line-height: 20px;
          font-weight: 300;
          margin: 0;

          @media @sm {
            font-size: 14px;
          }
        }

        .transition(opacity);

        & i.fa:first-child {
          position: absolute;
          left: 0;
          top: 1px;
          font-size: 17px;
        }

        &__icon {
          width: 38px;
          height: 38px;
          margin-right: 7px;
          padding-left: 1px;
          float: left;
          border: transparent;
          background-color: fadeout(@white, 80%);
          border-radius: 50%;
          color: @footer-text;
          font-size: 19px;
          text-align: center;
          line-height: 40px;

          .transition(opacity);

          &:hover {
            opacity: 0.5;
          }
        }

        & img {
          @media @sm {
            height: 40px;
          }
        }
      }

      &-list {
        list-style: none;
        width: 100%;
        padding: 47px 0 0 0;

        @media @md {
          padding: 0;
          margin: 0;
        }

        & li {
          padding-left: 0;
          float: left;
          height: 60px;
          width: 50%;

          @media @md {
            width: 100%;
            float: none;
            padding-left: 0;
          }

          &:nth-child(even) {
            padding-left: 0;
            float: right;

            @media @md {
              width: 100%;
              float: none;
              padding-left: 0;
            }
          }

          & a {
            text-decoration: none;
          }
        }
      }

      & a:hover {
        opacity: 0.7;
        text-decoration: underline;
      }

      & a:focus {
        & .sol-footer-logo {
          outline: 2px solid yellow;
          outline-offset: 2px;
          z-index: 10;
        }
      }
    }

    .sol-social-media {
      padding-left: 0px;

      .sol-clearfix;
      .sol-social-media-item > a {
        width: 28px;
        height: 28px;
        margin-right: 7px;
        padding-left: 1px;
        float: left;
        border: transparent;
        background-color: fadeout(@white, 80%);
        border-radius: 50%;
        color: @footer-text;
        font-size: 14px;
        text-align: center;
        line-height: 29px;

        .transition(opacity);

        &:hover {
          opacity: 0.5;
        }

        &:focus {
          outline: 2px solid yellow;
          outline-offset: 2px;
          z-index: 10;
        }

        @media @md {
          margin-right: 12px;
        }

        @media @sm {
          width: 38px;
          height: 38px;
          margin-right: 12px;
          padding-top: 2px;
          padding-left: 0px;
          font-size: 19px;
          line-height: 34px;
        }
      }
    }

    .sol-footer-third-col {
      position: relative !important;
      text-align: center;
    }
  }

  h1,
  p {
    &.font-footer-header {
      margin-bottom: 20px;
      margin-top: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid fadeout(@footer-text, 80%);
      font-size: 14px;
      font-family: @main-font;
      color: @footer-text;
      line-height: 22px;
      font-weight: 700;
    }
  }

  p.font-footer-text {
    margin-bottom: 11px;
    font-size: 14px;
    font-family: @main-font;
    color: @footer-text;
    line-height: 22px;
    font-weight: 700;
    a:hover {
      color: fadeout(@footer-text, 30%);
      text-decoration: underline;
    }
  }

  .sol-footer-third-col {
    margin-top: 50px;
  }

  .sol-footer-second-logo {
    & img {
      height: 45px;
      width: 200px;
      display: block;
    }
  }

  .sol-footer-logo {
    & img {
      height: 45.1px;
      width: auto;
    }
  }
  .sol-footer-wave {

    &--svg {
      width: 101%;
      margin-bottom: -54px;
      display: block;
      margin-left: -1%;
      min-height: 100px;
      background-color: #f8f8f8;

      @media @sm {
        display: none;
      }

      @media (min-width:768px) and (max-width:1367px) {
        background-color: transparent;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: none;
      }
    }

    &--img {
      width: 100%;
      display: none;

      @media @sm {
        display: none;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;

        @media @sm {
          display: none;
        }
      }
    }
  }

  .sol-footer-wave-mobile {
    width: 100%;
    margin-bottom: -29px;
    display: none;
    @media @sm {
      display: block;
    }
  }

  .sr-only {
    color: @general-text;
  }
}

a > .sol-footer-contact-item {
  &:hover {
    text-decoration: underline;
  }
}

.sol-footer-contact-list {
  & a:focus {
    & .sol-footer-contact-item {
      outline: 2px solid yellow;
      outline-offset: 2px;
      z-index: 10;
    }
  }
}
