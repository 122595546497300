.troint-wrapper {
  position: relative;
  border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  padding-bottom: 0;
  margin: 25px 0 5px;

  & #troman {
    padding: 0;
    overflow: auto;

    header {
      display: none;
    }
    .breadcrumb {
      display: none;
      & .divider {
        display: inline;
      }
      & > .active {
        color: #000;
      }
    }
    & h1:first-of-type {
      display:none;
    }
    & h2:first-of-type {
      display:none;
    }
    &.preformatted:first-of-type {
      display: none;
    }
    & > table {
      font-family: 'Fira Sans',sans-serif;

      & thead th {
        font-size: 18px;
        padding: 18px 12px;
        font-weight: 600;
      }
      & tbody td {
        font-size: 18px;
        padding: 18px 12px;
      }
    }

    & #organization-engagements {
      & #engagementTable {
        font-family: 'Fira Sans',sans-serif;
        & thead {
          & th {
            font-size: 18px;
            padding: 18px 8px;
            font-weight: 600;
          }
        }
        & tbody {
          & td {
            font-size: 18px;
            padding: 18px 12px;
          }
        }
      }
    }
  }

  & br {
    display: none;
  }

  & > table {
    width: 100%;
    
    &:first-of-type {
      display: none;
      
      & h2 {
        color: #fff;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 25px;
        letter-spacing: 0.4px;
      }
    }
    &:nth-of-type(2) {
      padding: 0 0 30px;

      & h3 {
        margin: 0;
      }
      & table {
        width: 100%;
        border-spacing: 0;

        & tr[bgcolor="#b0c4de"] {
          background-color: #fff;
          
          & > td {
            padding: 16px 14px;
            padding-left: 0;
          }
        }
        & tr:not([bgcolor="#b0c4de"]) {
          & > td {
            & form {
              display: flex;
              flex-wrap: wrap;
              padding: 20px;

              & strong {
                flex: 1 0 100%;
                margin: 6px 0 0;
              }
              & input {
                &[type="Text"] {
                  flex: 1 0 100%;
                  border: 1px solid #333;
                  padding: 5px 10px;
                  margin: 0 0 10px;
                }
                &[type="Submit"] {
                  margin: 20px 0 30px;
                  background-color: rgba(255,209,0,.6);
                  display: flex;
                  padding: 0 30px;
                  height: 45px;
                  line-height: 45px;
                  width: -webkit-fit-content;
                  width: -moz-fit-content;
                  width: fit-content;
                  max-width: 670px;
                  text-decoration: none;
                  border-radius: 100px;
                  overflow: hidden;
                }
              }
              & select {
                flex: 1 0 100%;
                border: 1px solid #333;
                padding: 5px 10px;
                margin: 0 0 10px;
              }
            }
            & hr {
              display: none;
            }
            & > strong {
              margin: 0 10px;
            }
          }
        }
        & td {

          & div {
            & a {
              display: block;
              margin: 10px 0 0;
              padding: 0;

              &[shape="rect"] {
                text-align: left;
                margin: 20px 15px;
              }
            }
          }

          & a {
            font-size: 21px;
            display: block;
            padding: 10px 16px;
            margin: 0;

            &:nth-of-type(even) {
              background-color: #f8f8f8;
            }
          }
        }
      }
      & .MenuPane {
        width: inherit;
        position: absolute;
        padding-top: 20px;
        bottom: 20px;
        left: 0;
        right: 0;

        & center {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;

          & .MenuItem {
            margin: 0 10px;

            & a {
              background-color: rgba(255,209,0,.6);
              display: flex;
              padding: 0 30px;
              height: 45px;
              line-height: 45px;
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content;
              max-width: 670px;
              text-decoration: none;
              border-radius: 100px;
              overflow: hidden;
            }
          }
        }
      }
    }
    &:nth-of-type(3) {
      display: none;
    }
  }
  & > img {
    width: 100%;
    display: none;
  }
  & font {
    display: block;
    padding: 10px 20px 0;
  }
  & tr[bgcolor="#dcdcdc"] {
    background-color: #f8f8f8;
  }
  & tr {
    & td { 
      & div[align="Left"] {
        padding: 0 20px;
      }
      & p {
        border-top: 1px solid #f8f8f8;
        & img {
          display: none;
        }
        & a {
          width: 100%;
        }
      }
      & b {
        width: 48%;
        display: inline-flex;
        flex-wrap: wrap;
        padding: 10px 16px;

        & a {
          flex: 0 0 80%;
          padding: 0 !important;

          &:nth-of-type(even) {
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
  form[action="/sitevision/proxy/4.4cd5bc2316db45f598b20762.html/svid12_36841ec916e356d2d753af7b/-2079936182/troint/historik_allanamnder_tidpunkt.asp"] {
    display: none !important;
  }
}
