/**
 * Soleil AB
 * Author: Per Lundström
 * Date: Tue May 07 2024 17:46:30 GMT+0200 (GMT+02:00)
 * Build script version: 2.2.4
 */
/*!
 * Font Awesome Pro 5.0.9 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'vendor/vuetify.min.css';
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border-radius: 0.1em;
  border: solid 0.08em #eee;
  padding: 0.2em 0.25em 0.15em;
}
/* .@{fa-css-prefix}-pull-left { float: left; }
.@{fa-css-prefix}-pull-right { float: right; }

.@{fa-css-prefix}, .fas, .far, .fal, .fab {
  &.@{fa-css-prefix}-pull-left { margin-right: .3em; }
  &.@{fa-css-prefix}-pull-right { margin-left: .3em; }
}
 */
.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-alarm-clock:before {
  content: "\f34e";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-archive:before {
  content: "\f187";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-alt-down:before {
  content: "\f354";
}
.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}
.fa-arrow-alt-from-left:before {
  content: "\f347";
}
.fa-arrow-alt-from-right:before {
  content: "\f348";
}
.fa-arrow-alt-from-top:before {
  content: "\f349";
}
.fa-arrow-alt-left:before {
  content: "\f355";
}
.fa-arrow-alt-right:before {
  content: "\f356";
}
.fa-arrow-alt-square-down:before {
  content: "\f350";
}
.fa-arrow-alt-square-left:before {
  content: "\f351";
}
.fa-arrow-alt-square-right:before {
  content: "\f352";
}
.fa-arrow-alt-square-up:before {
  content: "\f353";
}
.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}
.fa-arrow-alt-to-left:before {
  content: "\f34b";
}
.fa-arrow-alt-to-right:before {
  content: "\f34c";
}
.fa-arrow-alt-to-top:before {
  content: "\f34d";
}
.fa-arrow-alt-up:before {
  content: "\f357";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-from-bottom:before {
  content: "\f342";
}
.fa-arrow-from-left:before {
  content: "\f343";
}
.fa-arrow-from-right:before {
  content: "\f344";
}
.fa-arrow-from-top:before {
  content: "\f345";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-square-down:before {
  content: "\f339";
}
.fa-arrow-square-left:before {
  content: "\f33a";
}
.fa-arrow-square-right:before {
  content: "\f33b";
}
.fa-arrow-square-up:before {
  content: "\f33c";
}
.fa-arrow-to-bottom:before {
  content: "\f33d";
}
.fa-arrow-to-left:before {
  content: "\f33e";
}
.fa-arrow-to-right:before {
  content: "\f340";
}
.fa-arrow-to-top:before {
  content: "\f341";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-audible:before {
  content: "\f373";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-aws:before {
  content: "\f375";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-badge:before {
  content: "\f335";
}
.fa-badge-check:before {
  content: "\f336";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-barcode-alt:before {
  content: "\f463";
}
.fa-barcode-read:before {
  content: "\f464";
}
.fa-barcode-scan:before {
  content: "\f465";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-baseball:before {
  content: "\f432";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-basketball-hoop:before {
  content: "\f435";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-battery-bolt:before {
  content: "\f376";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-slash:before {
  content: "\f377";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-bed:before {
  content: "\f236";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blanket:before {
  content: "\f498";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-heart:before {
  content: "\f499";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-bowling-pins:before {
  content: "\f437";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-alt:before {
  content: "\f49a";
}
.fa-box-check:before {
  content: "\f467";
}
.fa-box-fragile:before {
  content: "\f49b";
}
.fa-box-full:before {
  content: "\f49c";
}
.fa-box-heart:before {
  content: "\f49d";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-box-up:before {
  content: "\f49f";
}
.fa-box-usd:before {
  content: "\f4a0";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-boxes-alt:before {
  content: "\f4a1";
}
.fa-boxing-glove:before {
  content: "\f438";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-browser:before {
  content: "\f37e";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-bus:before {
  content: "\f207";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-edit:before {
  content: "\f333";
}
.fa-calendar-exclamation:before {
  content: "\f334";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-alt:before {
  content: "\f332";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-caret-circle-down:before {
  content: "\f32d";
}
.fa-caret-circle-left:before {
  content: "\f32e";
}
.fa-caret-circle-right:before {
  content: "\f330";
}
.fa-caret-circle-up:before {
  content: "\f331";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-bishop-alt:before {
  content: "\f43b";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-clock:before {
  content: "\f43d";
}
.fa-chess-clock-alt:before {
  content: "\f43e";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-king-alt:before {
  content: "\f440";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-knight-alt:before {
  content: "\f442";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-pawn-alt:before {
  content: "\f444";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-queen-alt:before {
  content: "\f446";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chess-rook-alt:before {
  content: "\f448";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-double-down:before {
  content: "\f322";
}
.fa-chevron-double-left:before {
  content: "\f323";
}
.fa-chevron-double-right:before {
  content: "\f324";
}
.fa-chevron-double-up:before {
  content: "\f325";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-square-down:before {
  content: "\f329";
}
.fa-chevron-square-left:before {
  content: "\f32a";
}
.fa-chevron-square-right:before {
  content: "\f32b";
}
.fa-chevron-square-up:before {
  content: "\f32c";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-download-alt:before {
  content: "\f381";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-cloud-upload-alt:before {
  content: "\f382";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-club:before {
  content: "\f327";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-code-commit:before {
  content: "\f386";
}
.fa-code-merge:before {
  content: "\f387";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cog:before {
  content: "\f013";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-comment-alt-check:before {
  content: "\f4a2";
}
.fa-comment-alt-dots:before {
  content: "\f4a3";
}
.fa-comment-alt-edit:before {
  content: "\f4a4";
}
.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}
.fa-comment-alt-lines:before {
  content: "\f4a6";
}
.fa-comment-alt-minus:before {
  content: "\f4a7";
}
.fa-comment-alt-plus:before {
  content: "\f4a8";
}
.fa-comment-alt-slash:before {
  content: "\f4a9";
}
.fa-comment-alt-smile:before {
  content: "\f4aa";
}
.fa-comment-alt-times:before {
  content: "\f4ab";
}
.fa-comment-check:before {
  content: "\f4ac";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-comment-edit:before {
  content: "\f4ae";
}
.fa-comment-exclamation:before {
  content: "\f4af";
}
.fa-comment-lines:before {
  content: "\f4b0";
}
.fa-comment-minus:before {
  content: "\f4b1";
}
.fa-comment-plus:before {
  content: "\f4b2";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comment-smile:before {
  content: "\f4b4";
}
.fa-comment-times:before {
  content: "\f4b5";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-alt:before {
  content: "\f4b6";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-alt:before {
  content: "\f422";
}
.fa-compress-wide:before {
  content: "\f326";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-container-storage:before {
  content: "\f4b7";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-conveyor-belt:before {
  content: "\f46e";
}
.fa-conveyor-belt-alt:before {
  content: "\f46f";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-credit-card-blank:before {
  content: "\f389";
}
.fa-credit-card-front:before {
  content: "\f38a";
}
.fa-cricket:before {
  content: "\f449";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-curling:before {
  content: "\f44a";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-desktop-alt:before {
  content: "\f390";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-dna:before {
  content: "\f471";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-empty:before {
  content: "\f473";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-dolly-flatbed-alt:before {
  content: "\f475";
}
.fa-dolly-flatbed-empty:before {
  content: "\f476";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edit:before {
  content: "\f044";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-h-alt:before {
  content: "\f39b";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ellipsis-v-alt:before {
  content: "\f39c";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-envira:before {
  content: "\f299";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-exclamation-square:before {
  content: "\f321";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-alt:before {
  content: "\f424";
}
.fa-expand-arrows:before {
  content: "\f31d";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-expand-wide:before {
  content: "\f320";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-female:before {
  content: "\f182";
}
.fa-field-hockey:before {
  content: "\f44c";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-check:before {
  content: "\f316";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-edit:before {
  content: "\f31c";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-exclamation:before {
  content: "\f31a";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-minus:before {
  content: "\f318";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-plus:before {
  content: "\f319";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-times:before {
  content: "\f317";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-film:before {
  content: "\f008";
}
.fa-film-alt:before {
  content: "\f3a0";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-fly:before {
  content: "\f417";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-football-helmet:before {
  content: "\f44f";
}
.fa-forklift:before {
  content: "\f47a";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-fragile:before {
  content: "\f4bb";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-frown:before {
  content: "\f119";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-golf-club:before {
  content: "\f451";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-h1:before {
  content: "\f313";
}
.fa-h2:before {
  content: "\f314";
}
.fa-h3:before {
  content: "\f315";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hand-heart:before {
  content: "\f4bc";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-box:before {
  content: "\f47b";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-seedling:before {
  content: "\f4bf";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-holding-water:before {
  content: "\f4c1";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-receiving:before {
  content: "\f47c";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f4c2";
}
.fa-hands-heart:before {
  content: "\f4c3";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-hands-usd:before {
  content: "\f4c5";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-handshake-alt:before {
  content: "\f4c6";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-circle:before {
  content: "\f4c7";
}
.fa-heart-square:before {
  content: "\f4c8";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-hexagon:before {
  content: "\f312";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-hockey-sticks:before {
  content: "\f454";
}
.fa-home:before {
  content: "\f015";
}
.fa-home-heart:before {
  content: "\f4c9";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-image:before {
  content: "\f03e";
}
.fa-images:before {
  content: "\f302";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-inbox-in:before {
  content: "\f310";
}
.fa-inbox-out:before {
  content: "\f311";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-industry:before {
  content: "\f275";
}
.fa-industry-alt:before {
  content: "\f3b3";
}
.fa-info:before {
  content: "\f129";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-info-square:before {
  content: "\f30f";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-inventory:before {
  content: "\f480";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-jack-o-lantern:before {
  content: "\f30e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-key:before {
  content: "\f084";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-lamp:before {
  content: "\f4ca";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leaf-heart:before {
  content: "\f4cb";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-alt:before {
  content: "\f30d";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-lock-open-alt:before {
  content: "\f3c2";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-loveseat:before {
  content: "\f4cc";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luchador:before {
  content: "\f455";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-male:before {
  content: "\f183";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-minus:before {
  content: "\f068";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-minus-hexagon:before {
  content: "\f307";
}
.fa-minus-octagon:before {
  content: "\f308";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f10b";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-mobile-android:before {
  content: "\f3ce";
}
.fa-mobile-android-alt:before {
  content: "\f3cf";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-moon:before {
  content: "\f186";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-music:before {
  content: "\f001";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nintendo-switch:before {
  content: "\f418";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-octagon:before {
  content: "\f306";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-pallet-alt:before {
  content: "\f483";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-pencil-alt:before {
  content: "\f303";
}
.fa-pennant:before {
  content: "\f456";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-percent:before {
  content: "\f295";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person-carry:before {
  content: "\f4cf";
}
.fa-person-dolly:before {
  content: "\f4d0";
}
.fa-person-dolly-empty:before {
  content: "\f4d1";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-plus:before {
  content: "\f4d2";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-php:before {
  content: "\f457";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-alt:before {
  content: "\f3de";
}
.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-hexagon:before {
  content: "\f300";
}
.fa-plus-octagon:before {
  content: "\f301";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-alt:before {
  content: "\f486";
}
.fa-print:before {
  content: "\f02f";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-question-square:before {
  content: "\f2fd";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-racquet:before {
  content: "\f45a";
}
.fa-ramp-loading:before {
  content: "\f4d4";
}
.fa-random:before {
  content: "\f074";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-react:before {
  content: "\f41b";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-rectangle-landscape:before {
  content: "\f2fa";
}
.fa-rectangle-portrait:before {
  content: "\f2fb";
}
.fa-rectangle-wide:before {
  content: "\f2fc";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-rendact:before {
  content: "\f3e4";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-repeat:before {
  content: "\f363";
}
.fa-repeat-1:before {
  content: "\f365";
}
.fa-repeat-1-alt:before {
  content: "\f366";
}
.fa-repeat-alt:before {
  content: "\f364";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-retweet-alt:before {
  content: "\f361";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-road:before {
  content: "\f018";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-safari:before {
  content: "\f267";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-scanner:before {
  content: "\f488";
}
.fa-scanner-keyboard:before {
  content: "\f489";
}
.fa-scanner-touchscreen:before {
  content: "\f48a";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scrubber:before {
  content: "\f2f8";
}
.fa-search:before {
  content: "\f002";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-share:before {
  content: "\f064";
}
.fa-share-all:before {
  content: "\f367";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-shield:before {
  content: "\f132";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-shield-check:before {
  content: "\f2f7";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-shipping-timed:before {
  content: "\f48c";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shuttlecock:before {
  content: "\f45b";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-signal:before {
  content: "\f012";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-sliders-h-square:before {
  content: "\f3f0";
}
.fa-sliders-v:before {
  content: "\f3f1";
}
.fa-sliders-v-square:before {
  content: "\f3f2";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smile:before {
  content: "\f118";
}
.fa-smile-plus:before {
  content: "\f4da";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-spade:before {
  content: "\f2f4";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-spinner-third:before {
  content: "\f3f4";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-exclamation:before {
  content: "\f2f3";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-strava:before {
  content: "\f428";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-subway:before {
  content: "\f239";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-sun:before {
  content: "\f185";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-sync:before {
  content: "\f021";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablet-android:before {
  content: "\f3fb";
}
.fa-tablet-android-alt:before {
  content: "\f3fc";
}
.fa-tablet-rugged:before {
  content: "\f48f";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-tachometer-alt:before {
  content: "\f3fd";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tennis-ball:before {
  content: "\f45e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-times-hexagon:before {
  content: "\f2ee";
}
.fa-times-octagon:before {
  content: "\f2f0";
}
.fa-times-square:before {
  content: "\f2d3";
}
.fa-tint:before {
  content: "\f043";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-train:before {
  content: "\f238";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-tree-alt:before {
  content: "\f400";
}
.fa-trello:before {
  content: "\f181";
}
.fa-triangle:before {
  content: "\f2ec";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-trophy-alt:before {
  content: "\f2eb";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-container:before {
  content: "\f4dc";
}
.fa-truck-couch:before {
  content: "\f4dd";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-ramp:before {
  content: "\f4e0";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-tv-retro:before {
  content: "\f401";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-uber:before {
  content: "\f402";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-university:before {
  content: "\f19c";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-upload:before {
  content: "\f093";
}
.fa-usb:before {
  content: "\f287";
}
.fa-usd-circle:before {
  content: "\f2e8";
}
.fa-usd-square:before {
  content: "\f2e9";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensil-fork:before {
  content: "\f2e3";
}
.fa-utensil-knife:before {
  content: "\f2e4";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-utensils-alt:before {
  content: "\f2e6";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-plus:before {
  content: "\f4e1";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-mute:before {
  content: "\f2e2";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-warehouse-alt:before {
  content: "\f495";
}
.fa-watch:before {
  content: "\f2e1";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-whistle:before {
  content: "\f460";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-window:before {
  content: "\f40e";
}
.fa-window-alt:before {
  content: "\f40f";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*!
 * Font Awesome Pro 5.0.9 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('/webdav/files/System/resources/fonts/font-awesome-5/fa-brands-400.eot');
  src: url('/webdav/files/System/resources/fonts/font-awesome-5/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-brands-400.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-brands-400.woff') format('woff'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-brands-400.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-brands-400.svg#fontawesome') format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
}
/*!
 * Font Awesome Pro 5.0.9 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url('/webdav/files/System/resources/fonts/font-awesome-5/fa-regular-400.eot');
  src: url('/webdav/files/System/resources/fonts/font-awesome-5/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-regular-400.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-regular-400.woff') format('woff'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-regular-400.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-regular-400.svg#fontawesome') format('svg');
}
.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 5.0.9 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url('/webdav/files/System/resources/fonts/font-awesome-5/fa-solid-900.eot');
  src: url('/webdav/files/System/resources/fonts/font-awesome-5/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-solid-900.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-solid-900.woff') format('woff'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-solid-900.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-solid-900.svg#fontawesome') format('svg');
}
.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
/*!
 * Font Awesome Pro 5.0.9 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url('/webdav/files/System/resources/fonts/font-awesome-5/fa-light-300.eot');
  src: url('/webdav/files/System/resources/fonts/font-awesome-5/fa-light-300.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-light-300.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-light-300.woff') format('woff'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-light-300.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/font-awesome-5/fa-light-300.svg#fontawesome') format('svg');
}
.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sv-row::before,
.sv-row::after {
  display: none;
}
@media (min-width: 1200px) {
  .sv-grid-main.sv-fluid-grid {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (max-width: 1199px) {
  .sv-grid-main.sv-fluid-grid {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (min-width: 991px) and (max-width: 1380px) {
  .sv-grid-main.sv-fluid-grid {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .sv-grid-main.sv-fluid-grid {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 479px) {
  .sv-grid-main.sv-fluid-grid {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 1200px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xl-push-12 {
    position: relative;
    left: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 1200px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-lg-push-12 {
    position: relative;
    left: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sv-column-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-md-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-sm-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 480px) and (max-width: 767px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media (max-width: 479px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -10px;
    justify-content: flex-start;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sol-row.sol-row-center,
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-1,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-2,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-3,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-4,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-5,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-6,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-7,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-8,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-9,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-10,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-11,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-12,
  .sv-grid-main.sv-fluid-grid .sv-row *.sol-col-xs-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (max-width: 479px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row,
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
.sv-grid-main.sv-fluid-grid *[class*=sol-col-],
.sv-grid-main.sv-fluid-grid *[class*=sv-column-] {
  flex: 1 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid *[class*=sol-col-],
  .sv-grid-main.sv-fluid-grid *[class*=sv-column-] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.sol-show-lg,
.sol-show-md,
.sol-show-sm,
.sol-show-xl,
.sol-show-xs {
  display: none;
}
.sol-hide-sm {
  display: inherit;
}
@media (min-width: 1200px) {
  .sol-hide-xl {
    display: none;
  }
  .sol-show-xl {
    display: inherit;
  }
}
@media (max-width: 1199px) {
  .sol-hide-lg {
    display: none;
  }
  .sol-show-lg {
    display: inherit;
  }
}
@media (max-width: 991px) {
  .sol-hide-md {
    display: none;
  }
  .sol-show-md {
    display: inherit;
  }
}
@media (max-width: 767px) {
  .sol-hide-sm {
    display: none;
  }
  .sol-show-sm {
    display: inherit;
  }
}
@media (max-width: 479px) {
  .sol-hide-xs {
    display: none;
  }
  .sol-show-xs {
    display: inherit;
  }
}
.sol-action-link {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 44px;
}
.sol-action-link a {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.1;
  text-decoration: none;
  color: #fff;
  padding: 13px 50px 14px 15px;
  border-radius: 6px;
  background-color: #00866D;
  position: absolute;
  right: 0;
  left: 0;
}
.sol-action-link a:hover {
  text-decoration: underline;
}
.sol-action-link a:after {
  content: '\f054';
  font-size: 20px;
  font-family: FontAwesome;
  position: absolute;
  right: 16px;
  margin-top: -1px;
}
.sol-action-link a i.fa-external-link:before {
  position: relative;
  margin-left: 6px;
  top: 1px;
}
.sol-selfservice-result {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0.7em;
  font-family: 'Fira Sans', sans-serif;
}
.sol-selfservice-searchhit {
  margin-left: 0;
  padding-left: 0.5em;
  padding-top: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
  border-right: 1px solid rgba(0, 134, 109, 0.15);
  /* background-color: rgba(0, 134, 109, 0.15); */
  border-left: 1px solid rgba(0, 134, 109, 0.15);
}
.sol-selfservice-searchhit-odd {
  border-right: 1px solid rgba(0, 134, 109, 0.15);
  background-color: rgba(0, 134, 109, 0.15);
  border-left: 1px solid rgba(0, 134, 109, 0.15);
}
/*.sol-service-category:before {
    content: "\f054";
    font-family: FontAwesome;
    display: inline-block;
    font-size: 11px;
  }*/
.sol-selfservice-href {
  display: block;
}
.sol-selfservice-href .fa-file::before {
  padding-right: 5px;
}
.sol-selfservice-href .fa-desktop::before {
  padding-right: 5px;
}
.sol-selfservice-toggler a {
  text-decoration: none;
}
.sol-selfservice-toggler a .fa-service-collapsible-icon {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-weight: 300;
  font-size: 28px;
}
.sol-selfservice-searchfield {
  padding-left: 32px;
  display: inline;
  width: 80%;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 55px;
  border: solid 1px rgba(158, 158, 158, 0.35);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #35383b;
  background-color: #fff;
  margin-left: 34px;
}
a[aria-expanded=true]:before {
  content: "\f068";
}
.sv-vamiddle.sol-service-button {
  width: 70px;
  height: 70px;
  top: -6px;
  right: -17px;
  border-radius: 50%;
  background-color: #00866d;
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  margin-left: -20px;
}
@media (max-width: 767px) {
  .sv-vamiddle.sol-service-button {
    margin-left: 290px;
    margin-top: -90px;
  }
}
span.sol-event-checkbox-service {
  position: absolute;
  top: 14px;
  left: 34px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border: 1px solid #00866D;
  border-radius: 50%;
  background-color: #fff;
}
span.sol-event-checkbox-service:hover {
  background-color: rgba(0, 134, 109, 0.2);
}
span.sol-event-checkbox-service:after {
  left: -1px;
  top: -1px;
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  background: url('/webdav/files/System//resources/graphics/icons/circle_128x128.png');
  background-size: cover;
  background-position: center;
  visibility: hidden;
}
.sol-service-category-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .sol-service-category-wrapper {
    margin-left: 10px;
  }
}
.sol-service-category-wrapper .sol-service-category {
  flex: 0 0 48%;
  height: 50px;
  line-height: 45px;
  list-style: none;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
}
@media (max-width: 767px) {
  .sol-service-category-wrapper .sol-service-category {
    flex: 0 0 75%;
  }
}
.sol-service-category-wrapper .sol-service-category .sol-event-radio-btn {
  opacity: 0;
  cursor: pointer;
  top: 15px;
  left: 20px;
  z-index: 2;
}
.font-heading-service-1 {
  padding-top: 32px;
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 23px;
  line-height: 1.32em;
  font-weight: 600;
  margin-left: 34px;
}
@media (max-width: 991px) {
  .font-heading-service-1 {
    font-size: 23px;
    line-height: 25px;
  }
}
@media (max-width: 767px) {
  .font-heading-service-1 {
    font-size: 23px;
  }
}
@media (max-width: 479px) {
  .font-heading-service-1 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 26px;
  }
}
.sv-search-facet-item-selected .sol-event-checkbox-service:after {
  visibility: visible;
}
.sol-service-cat {
  font-size: 19px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 34px;
}
@media (max-width: 767px) {
  .sol-service-cat {
    padding-left: 45px;
  }
}
.sol-cat-title {
  font-size: 15px;
  padding-left: 48px;
}
.subheading3 {
  font-size: 19px;
  font-weight: 600;
  line-height: 1.26;
}
.sol-mer-info-but {
  height: 45px;
  width: 121px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: normal;
  background-color: #00866d;
  border-radius: 30px;
  font-size: 16px;
  color: white;
  text-align: center;
  line-height: 45px;
}
.sol-service-border {
  border: solid 1px rgba(53, 56, 59, 0.15);
  margin-top: 40px !important;
  margin-bottom: 15px !important;
}
.sol-download-file {
  padding-left: 2px;
  text-decoration: none !important;
  font-size: 18px;
}
.sol-download-file:hover {
  text-decoration: underline !important;
}
.sol-to-service {
  padding-left: 2px;
  flex: 0 0 50%;
  text-decoration: none !important;
  font-size: 18px;
}
.sol-to-service:hover {
  text-decoration: underline !important;
}
.sol-category-container {
  background-color: #F8F8F8;
  padding-bottom: 35px;
}
.sol-wrapper-download-service {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 15px;
}
.input-label {
  display: block;
  font-size: 19px;
  font-weight: 600 !important;
  margin-bottom: 5px;
  font-family: 'Fira Sans', sans-serif;
}
.input-required-asterisk:after {
  content: '*';
  color: #d53636;
  font-size: 14px;
  margin-left: 2px;
}
.input-text {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 8px 15px;
  font-size: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  color: #35383b;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
}
.input-text::-webkit-input-placeholder {
  color: #727272;
}
.input-text:-moz-placeholder {
  color: #727272;
}
.input-text::-moz-placeholder {
  color: #727272;
}
.input-text:-ms-input-placeholder {
  color: #727272;
}
.input-text:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-text:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-text:focus {
  background-color: #fff;
  border-color: #00866D;
}
.input-text-small {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 8px 15px;
  font-size: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  color: #35383b;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  height: 35px;
  padding: 9px 16px 8px;
  font-size: 12px;
}
.input-text-small::-webkit-input-placeholder {
  color: #727272;
}
.input-text-small:-moz-placeholder {
  color: #727272;
}
.input-text-small::-moz-placeholder {
  color: #727272;
}
.input-text-small:-ms-input-placeholder {
  color: #727272;
}
.input-text-small:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-text-small:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-text-small:focus {
  background-color: #fff;
  border-color: #00866D;
}
.input-select {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 8px 15px;
  font-size: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  color: #35383b;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  cursor: pointer;
  background-image: url('/webdav/files/System/resources/graphics/icons/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
}
.input-select::-webkit-input-placeholder {
  color: #727272;
}
.input-select:-moz-placeholder {
  color: #727272;
}
.input-select::-moz-placeholder {
  color: #727272;
}
.input-select:-ms-input-placeholder {
  color: #727272;
}
.input-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-select:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-select:focus {
  background-color: #fff;
  border-color: #00866D;
}
.input-select-small {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 8px 15px;
  font-size: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  color: #35383b;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  cursor: pointer;
  background-image: url('/webdav/files/System/resources/graphics/icons/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
  padding: 9px 16px 8px;
  font-size: 12px;
}
.input-select-small::-webkit-input-placeholder {
  color: #727272;
}
.input-select-small:-moz-placeholder {
  color: #727272;
}
.input-select-small::-moz-placeholder {
  color: #727272;
}
.input-select-small:-ms-input-placeholder {
  color: #727272;
}
.input-select-small:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-select-small:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-select-small:focus {
  background-color: #fff;
  border-color: #00866D;
}
.input-text-invalid {
  border-color: #eec3c3;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/times_invalid.svg');
}
.input-text-valid {
  border-color: #c7e6b3;
  background-color: #eef7e8;
  background-image: url('/webdav/files/System/resources/graphics/icons/check_valid.svg');
}
.input-textarea {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 8px 15px;
  font-size: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  color: #35383b;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  min-height: 120px;
  height: auto;
  max-width: 100%;
}
.input-textarea::-webkit-input-placeholder {
  color: #727272;
}
.input-textarea:-moz-placeholder {
  color: #727272;
}
.input-textarea::-moz-placeholder {
  color: #727272;
}
.input-textarea:-ms-input-placeholder {
  color: #727272;
}
.input-textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.input-textarea:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.input-textarea:focus {
  background-color: #fff;
  border-color: #00866D;
}
.input-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}
.input-checkbox + label,
.input-radio + label {
  color: #35383b;
  font-family: 'Fira Sans', sans-serif;
  display: block;
  padding-left: 35px;
  font-size: 17px;
  line-height: 1.3em;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
}
.input-checkbox {
  position: relative;
  top: 27px;
  left: 0;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 3px;
  display: inline-block;
}
.input-radio {
  position: relative;
  top: 27px;
  left: 0;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 50%;
  display: inline-block;
}
.input-file {
  display: block;
  height: 0;
  opacity: 0;
}
.input-file + label {
  margin-bottom: 20px;
}
.input-file + label:after,
.input-file + label:before {
  content: "";
  display: table;
}
.input-file + label:after {
  clear: both;
}
.input-file + label .sol-form-file-label {
  display: block;
  font-size: 19px;
  font-weight: 600 !important;
  margin-bottom: 5px;
  font-family: 'Fira Sans', sans-serif;
  margin-bottom: 8px;
}
.input-file + label .sol-form-file-btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 12px 25px;
  color: #35383b;
  border-radius: 20px;
  background-color: rgba(255, 209, 0, 0.6);
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
  border: 0;
  padding: 5px 20px;
  margin-left: 10px;
  margin-top: 2px;
  font-size: 13px;
}
.input-file + label .sol-form-file-btn:focus,
[data-whatinput="keyboard"] .input-file + label .sol-form-file-btn:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .input-file + label .sol-form-file-btn:focus,
[data-whatinput="mouse"] .input-file + label .sol-form-file-btn:focus,
[data-whatinput="touch"] .input-file + label .sol-form-file-btn:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.input-file + label .sol-form-file-btn:hover {
  background-color: #FFD100;
  color: black;
  text-decoration: underline;
}
.input-file + label .sol-form-file-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #006579;
}
.input-file + label .sol-form-file-btn i.fa {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.input-file + label .sol-form-file-text {
  float: left;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 300;
  line-height: 34px;
}
.input-file + label .sol-form-file-remove i {
  margin-left: 13px;
  margin-top: 7px;
  font-size: 18px;
  color: #00866D;
}
.input-btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 12px 25px;
  color: #35383b;
  border-radius: 20px;
  background-color: rgba(255, 209, 0, 0.6);
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
  border: 0;
}
.input-btn:focus,
[data-whatinput="keyboard"] .input-btn:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .input-btn:focus,
[data-whatinput="mouse"] .input-btn:focus,
[data-whatinput="touch"] .input-btn:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.input-btn:hover {
  background-color: #FFD100;
  color: black;
  text-decoration: underline;
}
.input-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #006579;
}
.input-btn i.fa {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.input-btn-small {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 12px 25px;
  color: #35383b;
  border-radius: 20px;
  background-color: rgba(255, 209, 0, 0.6);
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
  border: 0;
  font-size: 14px;
  padding: 5px 15px 6px;
}
.input-btn-small:focus,
[data-whatinput="keyboard"] .input-btn-small:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .input-btn-small:focus,
[data-whatinput="mouse"] .input-btn-small:focus,
[data-whatinput="touch"] .input-btn-small:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.input-btn-small:hover {
  background-color: #FFD100;
  color: black;
  text-decoration: underline;
}
.input-btn-small:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #006579;
}
.input-btn-small i.fa {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.sol_matsedel_RSS h3 {
  margin-bottom: 0 !important;
}
.sol_matsedel_RSS time {
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 0.9em;
  color: #333;
}
.sv-marketplace-sitevision-cookie-consent h1,
.sv-cookie-consent-banner h1,
.sv-cookie-consent-modal h1,
.sv-marketplace-sitevision-cookie-consent h2,
.sv-cookie-consent-banner h2,
.sv-cookie-consent-modal h2 {
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  color: #35383b;
  line-height: normal;
}
.sv-marketplace-sitevision-cookie-consent h3,
.sv-cookie-consent-banner h3,
.sv-cookie-consent-modal h3 {
  font-size: 22px;
}
.sv-marketplace-sitevision-cookie-consent h4,
.sv-cookie-consent-banner h4,
.sv-cookie-consent-modal h4 {
  font-size: 20px;
}
.sv-marketplace-sitevision-cookie-consent p,
.sv-cookie-consent-banner p,
.sv-cookie-consent-modal p,
.sv-marketplace-sitevision-cookie-consent a,
.sv-cookie-consent-banner a,
.sv-cookie-consent-modal a,
.sv-marketplace-sitevision-cookie-consent .env-text,
.sv-cookie-consent-banner .env-text,
.sv-cookie-consent-modal .env-text {
  font-weight: 400;
  font-size: 16px !important;
  color: #35383b;
}
.sv-marketplace-sitevision-cookie-consent p a,
.sv-cookie-consent-banner p a,
.sv-cookie-consent-modal p a {
  text-decoration: underline;
}
.sv-marketplace-sitevision-cookie-consent a,
.sv-cookie-consent-banner a,
.sv-cookie-consent-modal a {
  color: #35383b;
}
.sv-marketplace-sitevision-cookie-consent a:hover,
.sv-cookie-consent-banner a:hover,
.sv-cookie-consent-modal a:hover {
  color: #35383b;
}
.sv-marketplace-sitevision-cookie-consent .env-button.env-button--primary,
.sv-cookie-consent-banner .env-button.env-button--primary,
.sv-cookie-consent-modal .env-button.env-button--primary {
  background-color: #00866D;
  border-color: #00866D;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
}
.sv-marketplace-sitevision-cookie-consent .env-button.env-button--primary.env-button--ghost,
.sv-cookie-consent-banner .env-button.env-button--primary.env-button--ghost,
.sv-cookie-consent-modal .env-button.env-button--primary.env-button--ghost {
  background-color: #fff;
  color: #00866D;
}
.sv-marketplace-sitevision-cookie-consent .env-button.env-button--primary:hover,
.sv-cookie-consent-banner .env-button.env-button--primary:hover,
.sv-cookie-consent-modal .env-button.env-button--primary:hover {
  text-decoration: underline;
}
.sv-marketplace-sitevision-cookie-consent .env-switch__slider,
.sv-cookie-consent-banner .env-switch__slider,
.sv-cookie-consent-modal .env-switch__slider {
  background-color: #00866D !important;
  border-color: #00866D !important;
  height: 20px;
}
.sv-marketplace-sitevision-cookie-consent .env-switch__slider::before,
.sv-cookie-consent-banner .env-switch__slider::before,
.sv-cookie-consent-modal .env-switch__slider::before {
  height: 20px;
  width: 20px;
}
.sv-marketplace-sitevision-cookie-consent .env-switch__slider::after,
.sv-cookie-consent-banner .env-switch__slider::after,
.sv-cookie-consent-modal .env-switch__slider::after {
  height: 16px;
  width: 16px;
}
.sv-cookie-consent-banner {
  background-color: #dbede4 !important;
  border-top: 2px solid #00866D;
}
.lp-panic-button {
  position: fixed;
  right: 0;
  left: calc(50% - 252px);
  bottom: 0;
  z-index: 999;
}
@media (min-width: 1381px) {
  .lp-panic-button {
    left: calc(50% - 630px);
  }
}
@media (max-width: 767px) {
  .lp-panic-button {
    left: 0;
  }
}
.lp-panic-button a {
  padding: 15px 35px;
}
@media (max-width: 767px) {
  .lp-panic-button a {
    width: 100%;
    padding: 16px;
    font-size: 19px;
  }
}
.sv-mfpimage-portlet {
  margin-bottom: 20px;
}
body {
  font-family: 'Fira Sans', sans-serif;
  color: #28282A;
  font-size: 23px;
  line-height: 1.32em;
  font-weight: 400;
  background-color: #fff;
  overflow-x: hidden;
  /*     -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; */
}
p,
p.font-normal,
.font-normal,
.sol-plusbox-anchor {
  margin-bottom: 20px;
  font-family: 'Crimson text', serif;
  color: #28282A;
  font-size: 23px;
  line-height: 1.32em;
  font-weight: 400;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  /*     text-rendering: optimizeLegibility !important;
    -moz-font-smoothing: unset;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: auto;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; */
}
@media (max-width: 767px) {
  p,
  p.font-normal,
  .font-normal,
  .sol-plusbox-anchor {
    font-size: 19px !important;
  }
}
ap,
ap.font-normal,
a.font-normal,
a.sol-plusbox-anchor,
p a,
p.font-normal a,
.font-normal a,
.sol-plusbox-anchor a {
  color: #28282A;
  text-decoration: underline;
}
ap:hover,
ap.font-normal:hover,
a.font-normal:hover,
a.sol-plusbox-anchor:hover,
p a:hover,
p.font-normal a:hover,
.font-normal a:hover,
.sol-plusbox-anchor a:hover {
  color: #006579;
}
p.font-description {
  font-family: 'Fira Sans', sans-serif;
  color: #28282A;
  font-weight: 300;
  font-size: 24px;
}
ap.font-description,
p.font-description a {
  color: #28282A;
}
ap.font-description:hover,
p.font-description a:hover {
  color: #28282A;
}
h1,
.font-heading-1,
h1.font-heading-1 {
  font-family: 'Fira Sans', sans-serif;
  color: #00866D;
  margin-top: 0px;
  margin-bottom: 12px;
  font-size: 39px;
  line-height: 1.32em;
  font-weight: 300;
}
@media (max-width: 991px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-size: 31px;
    line-height: 25px;
  }
}
@media (max-width: 767px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-size: 26px !important;
  }
}
@media (max-width: 479px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 26px;
  }
}
h2,
h2.font-heading-2,
.font-heading-2 {
  font-family: 'Fira Sans', sans-serif;
  color: #28282A;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 23px;
  line-height: 1.32em;
  font-weight: 800;
}
@media (max-width: 479px) {
  h2,
  h2.font-heading-2,
  .font-heading-2 {
    font-size: 18px;
    line-height: 29px;
  }
}
h3,
h3.font-heading-3,
.font-heading-3 {
  font-family: 'Fira Sans', sans-serif;
  color: #28282A;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 19px;
  line-height: 1.32em;
  font-weight: 600;
}
@media (max-width: 479px) {
  h3,
  h3.font-heading-3,
  .font-heading-3 {
    font-size: 14px;
  }
}
h4,
h4.font-heading-4,
.font-heading-4 {
  font-family: 'Fira Sans', sans-serif;
  color: #28282A;
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 17px;
  line-height: 1.32em;
  font-weight: 600;
}
@media (max-width: 479px) {
  h4,
  h4.font-heading-4,
  .font-heading-4 {
    font-size: 12px;
  }
}
p.font-quote,
.font-quote,
blockquote.sv-blockquote p,
blockquote.sv-blockquote-edit p {
  font-size: 29px;
  font-family: 'Crimson text', serif;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #006579;
  padding-left: 55px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  p.font-quote,
  .font-quote,
  blockquote.sv-blockquote p,
  blockquote.sv-blockquote-edit p {
    padding-left: 35px;
    font-size: 24px;
    line-height: 27px;
  }
}
@media (max-width: 767px) {
  p.font-quote,
  .font-quote,
  blockquote.sv-blockquote p,
  blockquote.sv-blockquote-edit p {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 479px) {
  p.font-quote,
  .font-quote,
  blockquote.sv-blockquote p,
  blockquote.sv-blockquote-edit p {
    padding-left: 30px;
  }
}
p.font-quote::before,
.font-quote::before,
blockquote.sv-blockquote p::before,
blockquote.sv-blockquote-edit p::before {
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 20px;
  content: "\f10e";
  position: absolute;
  margin-left: -35px;
  color: #FFD100;
  line-height: 1.21;
}
@media (max-width: 991px) {
  p.font-quote::before,
  .font-quote::before,
  blockquote.sv-blockquote p::before,
  blockquote.sv-blockquote-edit p::before {
    line-height: 16px;
  }
}
@media (max-width: 479px) {
  p.font-quote::before,
  .font-quote::before,
  blockquote.sv-blockquote p::before,
  blockquote.sv-blockquote-edit p::before {
    left: 42px;
    line-height: 16px;
  }
}
p.font-quote::after,
.font-quote::after,
blockquote.sv-blockquote p::after,
blockquote.sv-blockquote-edit p::after {
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 20px;
  content: "\f10d";
  color: #FFD100;
  line-height: 35px;
  margin-left: 15px;
}
@media (max-width: 991px) {
  p.font-quote::after,
  .font-quote::after,
  blockquote.sv-blockquote p::after,
  blockquote.sv-blockquote-edit p::after {
    left: 5px;
    line-height: 33px;
  }
}
@media (max-width: 479px) {
  p.font-quote::after,
  .font-quote::after,
  blockquote.sv-blockquote p::after,
  blockquote.sv-blockquote-edit p::after {
    left: 5px;
    line-height: 31px;
  }
}
blockquote.sv-blockquote,
blockquote.sv-blockquote-edit {
  padding-left: 0;
  margin: 0;
}
blockquote.sv-blockquote::before,
blockquote.sv-blockquote-edit::before {
  content: unset !important;
}
@media (max-width: 479px) {
  blockquote.sv-blockquote p::before,
  blockquote.sv-blockquote-edit p::before {
    margin-left: -40px;
  }
}
strong {
  font-weight: bold !important;
}
ol.sol-bullet-list,
ul.sol-bullet-list,
ol.sol-numbered-list,
ul.sol-numbered-list {
  margin-top: 5px;
  margin-bottom: 20px;
  padding-left: 20px;
  list-style: none !important;
}
ol.sol-bullet-list li,
ul.sol-bullet-list li,
ol.sol-numbered-list li,
ul.sol-numbered-list li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 15px !important;
}
ol.sol-bullet-list li::before,
ul.sol-bullet-list li::before,
ol.sol-numbered-list li::before,
ul.sol-numbered-list li::before {
  position: absolute;
  left: -19px;
  margin-top: 2px;
  font-size: 23px;
  content: "•";
  color: #FFD100;
}
.sol-bullet-list li::before {
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: inline-block;
  font-size: 16px;
  content: "\f105";
  color: #006579;
}
ol.sol-numbered-list li,
ul.sol-numbered-list li {
  counter-increment: step-counter;
  padding-left: 20px;
}
ol.sol-numbered-list li::before,
ul.sol-numbered-list li::before {
  content: counter(step-counter) '.';
  color: #006579;
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
  top: 0;
}
ol.sol-numbered-list {
  padding-left: 0px !important;
}
ul.sol-bullet-list ::before {
  margin-top: 4px !important;
}
* {
  box-sizing: border-box;
}
a:focus,
button:focus,
[data-whatinput="keyboard"] a:focus,
[data-whatinput="keyboard"] button:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] a:focus,
[data-whatinput="initial"] button:focus,
[data-whatinput="mouse"] a:focus,
[data-whatinput="mouse"] button:focus,
[data-whatinput="touch"] a:focus,
[data-whatinput="touch"] button:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.sol-ul,
.sol-ul-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sol-float-right {
  float: right;
}
.sol-float-left {
  float: left;
}
.sol-hidden {
  display: none;
}
.sol-visibility-hidden {
  visibility: hidden;
}
.sol-clearfix:after,
.sol-clearfix:before {
  content: "";
  display: table;
}
.sol-clearfix:after {
  clear: both;
}
.sol-relative {
  position: relative;
}
.sol-no-anim * {
  transition: none !important;
}
.sol-external-link-icon {
  margin-left: 6px;
  font-size: 10px;
  display: none;
}
.sol-lightgray-background {
  background-color: #F8F8F8;
}
.sv-portlet-image-caption {
  text-align: left !important;
}
.sv-youtube-portlet .sv-video-container {
  padding-bottom: 0;
  margin-bottom: 10px;
  min-height: 315px;
}
.sol-action__link {
  display: inline-flex;
  width: fit-content;
  max-width: 670px;
  height: auto;
  padding: 10px 30px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 25px;
  text-decoration: none;
  background-color: rgba(255, 209, 0, 0.6);
  border-radius: 100px;
}
@media (max-width: 767px) {
  .sol-action__link {
    max-width: 480px;
  }
}
@media (max-width: 479px) {
  .sol-action__link {
    max-width: 320px;
  }
}
.sol-action__link .sol-action__icon {
  padding-top: 3px;
}
.sol-action__link .sol-action__icon i {
  position: relative;
  width: 23px;
  height: 100%;
  margin-right: 10px;
  font-size: 23px;
}
.sol-action__link .sol-action__icon i.fa-angle-left {
  margin-right: 0;
}
.sol-action__link .sol-action__icon i::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.sol-action__link:hover {
  text-decoration: underline;
  background-color: #FFD100;
}
.sol-collapsible .sol-action__link {
  margin: 20px 0;
}
.sol-btn {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 12px 25px;
  color: #35383b;
  border-radius: 20px;
  background-color: rgba(255, 209, 0, 0.6);
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
  border: 0;
}
.sol-btn:focus,
[data-whatinput="keyboard"] .sol-btn:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-btn:focus,
[data-whatinput="mouse"] .sol-btn:focus,
[data-whatinput="touch"] .sol-btn:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-btn:hover {
  background-color: #FFD100;
  color: black;
  text-decoration: underline;
}
.sol-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #006579;
}
.sol-btn i.fa {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;
}
.font-btn > a {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 12px 25px;
  color: #35383b;
  border-radius: 20px;
  background-color: rgba(255, 209, 0, 0.6);
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
  border: 0;
  display: inline;
  padding-bottom: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.font-btn > a:focus,
[data-whatinput="keyboard"] .font-btn > a:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .font-btn > a:focus,
[data-whatinput="mouse"] .font-btn > a:focus,
[data-whatinput="touch"] .font-btn > a:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.font-btn > a:hover {
  background-color: #FFD100;
  color: black;
  text-decoration: underline;
}
.font-btn > a:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #006579;
}
.font-btn > a i.fa {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.sol-btn-small {
  padding: 7px 25px 8px;
  color: #fff;
  background-color: #595959;
  box-shadow: none;
  font-size: 11px;
  line-height: 10px;
  font-weight: 600;
  text-transform: none;
}
.sol-btn-small:hover {
  background-color: #00866D;
  color: #fff;
}
.sol-btn-small:active {
  top: 0;
  box-shadow: none;
}
.sv-text-portlet.sol-font-btn-small .font-btn {
  padding-top: 4px;
  padding-bottom: 5px;
  margin-bottom: 0;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a {
  padding: 7px 25px 8px;
  color: #fff;
  background-color: #595959;
  box-shadow: none;
  font-size: 11px;
  line-height: 10px;
  font-weight: 600;
  text-transform: none;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a:hover {
  background-color: #00866D;
  color: #fff;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a:active {
  top: 0;
  box-shadow: none;
}
.sol-box-decoration {
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: 5px;
}
.sol-box-decoration:after,
.sol-box-decoration:before {
  content: "";
  display: table;
}
.sol-box-decoration:after {
  clear: both;
}
.sol-box-decoration-header {
  display: block;
  padding: 13px 15px;
  height: 45px;
  background-color: rgba(255, 209, 0, 0.6);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #35383b;
  text-align: left;
  text-decoration: none;
  line-height: 25px;
  font-weight: 500;
  font-size: 17px;
  margin: 0;
}
@media (max-width: 991px) {
  .sol-box-decoration-header {
    font-size: 17px;
  }
}
a .sol-box-decoration-header:focus,
[data-whatinput="keyboard"] a .sol-box-decoration-header:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] a .sol-box-decoration-header:focus,
[data-whatinput="mouse"] a .sol-box-decoration-header:focus,
[data-whatinput="touch"] a .sol-box-decoration-header:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-box-decoration .sv-text-portlet.sol-btn-small .font-btn {
  margin-bottom: 5px;
}
.sol-box-decoration .sv-decoration-content {
  background-color: #FFF6CC;
}
.sol-divider-decoration {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid #e7e7e7;
}
.sol-simple-box-decoration {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 8px 20px 2px 15px;
  border: 1px solid #FFD100;
  border-radius: 3px;
  background-color: #fff;
}
.sol-simple-box-decoration.sol-simple-box-decoration-alt {
  background-color: rgba(255, 209, 0, 0.1);
}
.sol-simple-box-decoration > div {
  display: block;
  margin-bottom: 5px;
}
.sv-editing-mode .sol-error-box,
.sv-editing-mode .sol-warning-box {
  display: block;
}
.sol-error-box,
.sol-warning-box {
  display: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.sol-warning-box {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.sol-error-box {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.sol-listingpage-container {
  padding-top: 0;
  margin-top: -10px;
}
@media (max-width: 767px) {
  .sol-listingpage-container {
    padding-top: 0px;
    margin-top: 0px;
  }
}
@media (max-width: 991px) {
  .sol-listingpage-container {
    margin-top: 0px;
  }
}
@media (min-width: 1200px) {
  .sol-listingpage-container {
    padding-top: 10px;
    margin-top: -30px;
  }
}
.sol-listingpage-container .pagecontent {
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .sol-listingpage-container .pagecontent {
    margin-top: -10px;
  }
}
.sol-listingpage-container .pagecontent .sol-pagelist-box {
  z-index: 2;
  flex: 0 0 31%;
  margin: 15px 1%;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: solid 1px rgba(53, 56, 59, 0.1);
}
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-clickable-element:hover p,
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-clickable-element:hover h2 {
  color: #006579;
  text-decoration: underline;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-clickable-element:hover p a,
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-clickable-element:hover h2 a {
  color: #006579;
}
@media (max-width: 991px) {
  .sol-listingpage-container .pagecontent .sol-pagelist-box {
    flex: 0 0 48%;
  }
}
@media (max-width: 767px) {
  .sol-listingpage-container .pagecontent .sol-pagelist-box {
    flex: 0 0 100%;
  }
}
.sol-listingpage-container .pagecontent .sol-pagelist-box p,
.sol-listingpage-container .pagecontent .sol-pagelist-box h2 {
  font-family: 'Fira Sans', sans-serif !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  background-color: rgba(255, 209, 0, 0.6);
  border-left: 4px solid #FFD100;
  padding: 2px 10px;
  height: auto;
  padding-bottom: 5px;
  line-height: 40px;
  margin-bottom: 5px;
  margin-top: 0;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box p a,
.sol-listingpage-container .pagecontent .sol-pagelist-box h2 a {
  padding-left: 25px;
  text-decoration: none;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #35383b;
  background: url(/webdav/files/System//resources/graphics/icons/angle-right-light.svg) 5px 0px no-repeat;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box p a:hover,
.sol-listingpage-container .pagecontent .sol-pagelist-box h2 a:hover {
  color: #006579;
  text-decoration: underline;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box__content {
  padding: 10px;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box__content p {
  background-color: #fff;
  border-left: none;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px !important;
  font-weight: 300 !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #35383b;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box__content p a {
  border: none;
  background: transparent;
  padding: 5px 0px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px !important;
  font-weight: 300 !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #35383b;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast {
  background-color: rgba(255, 209, 0, 0.3);
}
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast p,
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast h2 {
  background-color: #FFD100;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast p:before,
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast h2:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 100;
  content: '\f05a';
  display: block;
  float: left;
  margin-right: 10px;
  font-size: 19px;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast .sol-pagelist-box__content {
  padding: 10px;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast .sol-pagelist-box__content p {
  margin-bottom: 0;
  background-color: transparent;
}
.sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast .sol-pagelist-box__content p:before {
  content: '\f105';
}
@media (max-width: 991px) {
  .sol-listingpage-container .pagecontent .sol-pagelist-box.sol-pagelist-findfast {
    flex: 0 0 100%;
  }
}
.sol-collapsible {
  margin-top: 10px;
  margin-bottom: 10px;
}
.sol-collapsible__header {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: relative;
  display: block;
  width: 100%;
  padding: 14px 50px 14px 22px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(255, 209, 0, 0.2);
  border: solid 1px rgba(255, 209, 0, 0.3);
  border-left-width: 3px;
  border-left-color: #FFD100;
  color: #35383b;
  text-align: left;
  text-decoration: none;
  line-height: 1.1;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
}
.sol-collapsible__header:focus,
[data-whatinput="keyboard"] .sol-collapsible__header:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-collapsible__header:focus,
[data-whatinput="mouse"] .sol-collapsible__header:focus,
[data-whatinput="touch"] .sol-collapsible__header:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-collapsible__header__icon {
  display: block;
  width: 38px;
  height: 38px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -19px;
}
.sol-collapsible__header__icon::after,
.sol-collapsible__header__icon::before {
  content: "";
  display: block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 4px;
  margin-left: -10px;
  margin-top: -3px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-collapsible__header__icon::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sol-collapsible__header .sol-collapsible-icon {
  font-size: 30px;
}
.sol-collapsible__header--active {
  background-color: rgba(255, 209, 0, 0.6);
}
.sol-collapsible__header--active .sol-collapsible-icon {
  transition: transform 0.2s ease-in-out;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sol-collapsible__header:hover {
  box-shadow: 0 0 6px rgba(255, 209, 0, 0.6);
}
.sol-collapsible__header:hover span {
  text-decoration: underline;
}
.sol-collapsible__header__text {
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .sol-collapsible__header__text {
    font-size: 16px;
    line-height: 20px;
  }
}
.sol-collapsible .sol-collapsible__content,
.sol-collapsible .sv-decoration-content {
  display: none;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 20px 20px 60px;
}
.sol-collapsible .sol-collapsible__content > *:last-child,
.sol-collapsible .sv-decoration-content > *:last-child {
  margin-bottom: 0;
}
.sol-collapsible .sol-collapsible__content p:last-of-type,
.sol-collapsible .sv-decoration-content p:last-of-type {
  margin: 0;
}
.sol-collapsible .sol-collapsible__close {
  display: none;
  position: relative;
  bottom: 50px;
  right: 0;
  left: calc(100% - 150px);
  padding: 0 20px;
  width: 150px;
}
.sol-collapsible .sol-collapsible__close-text {
  text-decoration: underline;
  font-size: 15px;
}
.sol-collapsible .sol-collapsible__close i {
  font-size: 17px;
}
.sol-collapsible.active .sol-collapsible__close {
  display: block;
}
.sol-collapsible-icon {
  font-size: 24px;
  transition: transform 0.2s ease-in-out;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  font-weight: 300;
  position: absolute;
  right: 15px;
  font-size: 28px;
  top: 8px;
}
.sol-form,
.sol-form-decoration {
  padding: 20px 20px 40px;
  border-radius: 3px;
  background-color: #F8F8F8 !important;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .sol-form,
  .sol-form-decoration {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .sol-form,
  .sol-form-decoration {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.sol-form h2,
.sol-form-decoration h2 {
  margin-top: 0;
}
.sol-form form input[type='email'],
.sol-form-decoration form input[type='email'],
.sol-form form input[type='number'],
.sol-form-decoration form input[type='number'],
.sol-form form input[type='password'],
.sol-form-decoration form input[type='password'],
.sol-form form input[type='tel'],
.sol-form-decoration form input[type='tel'],
.sol-form form input[type='text'],
.sol-form-decoration form input[type='text'],
.sol-form form input[type='url'],
.sol-form-decoration form input[type='url'],
.sol-form form select,
.sol-form-decoration form select,
.sol-form form textarea,
.sol-form-decoration form textarea {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 8px 15px;
  font-size: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  color: #35383b;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
}
.sol-form form input[type='email']::-webkit-input-placeholder,
.sol-form-decoration form input[type='email']::-webkit-input-placeholder,
.sol-form form input[type='number']::-webkit-input-placeholder,
.sol-form-decoration form input[type='number']::-webkit-input-placeholder,
.sol-form form input[type='password']::-webkit-input-placeholder,
.sol-form-decoration form input[type='password']::-webkit-input-placeholder,
.sol-form form input[type='tel']::-webkit-input-placeholder,
.sol-form-decoration form input[type='tel']::-webkit-input-placeholder,
.sol-form form input[type='text']::-webkit-input-placeholder,
.sol-form-decoration form input[type='text']::-webkit-input-placeholder,
.sol-form form input[type='url']::-webkit-input-placeholder,
.sol-form-decoration form input[type='url']::-webkit-input-placeholder,
.sol-form form select::-webkit-input-placeholder,
.sol-form-decoration form select::-webkit-input-placeholder,
.sol-form form textarea::-webkit-input-placeholder,
.sol-form-decoration form textarea::-webkit-input-placeholder {
  color: #727272;
}
.sol-form form input[type='email']:-moz-placeholder,
.sol-form-decoration form input[type='email']:-moz-placeholder,
.sol-form form input[type='number']:-moz-placeholder,
.sol-form-decoration form input[type='number']:-moz-placeholder,
.sol-form form input[type='password']:-moz-placeholder,
.sol-form-decoration form input[type='password']:-moz-placeholder,
.sol-form form input[type='tel']:-moz-placeholder,
.sol-form-decoration form input[type='tel']:-moz-placeholder,
.sol-form form input[type='text']:-moz-placeholder,
.sol-form-decoration form input[type='text']:-moz-placeholder,
.sol-form form input[type='url']:-moz-placeholder,
.sol-form-decoration form input[type='url']:-moz-placeholder,
.sol-form form select:-moz-placeholder,
.sol-form-decoration form select:-moz-placeholder,
.sol-form form textarea:-moz-placeholder,
.sol-form-decoration form textarea:-moz-placeholder {
  color: #727272;
}
.sol-form form input[type='email']::-moz-placeholder,
.sol-form-decoration form input[type='email']::-moz-placeholder,
.sol-form form input[type='number']::-moz-placeholder,
.sol-form-decoration form input[type='number']::-moz-placeholder,
.sol-form form input[type='password']::-moz-placeholder,
.sol-form-decoration form input[type='password']::-moz-placeholder,
.sol-form form input[type='tel']::-moz-placeholder,
.sol-form-decoration form input[type='tel']::-moz-placeholder,
.sol-form form input[type='text']::-moz-placeholder,
.sol-form-decoration form input[type='text']::-moz-placeholder,
.sol-form form input[type='url']::-moz-placeholder,
.sol-form-decoration form input[type='url']::-moz-placeholder,
.sol-form form select::-moz-placeholder,
.sol-form-decoration form select::-moz-placeholder,
.sol-form form textarea::-moz-placeholder,
.sol-form-decoration form textarea::-moz-placeholder {
  color: #727272;
}
.sol-form form input[type='email']:-ms-input-placeholder,
.sol-form-decoration form input[type='email']:-ms-input-placeholder,
.sol-form form input[type='number']:-ms-input-placeholder,
.sol-form-decoration form input[type='number']:-ms-input-placeholder,
.sol-form form input[type='password']:-ms-input-placeholder,
.sol-form-decoration form input[type='password']:-ms-input-placeholder,
.sol-form form input[type='tel']:-ms-input-placeholder,
.sol-form-decoration form input[type='tel']:-ms-input-placeholder,
.sol-form form input[type='text']:-ms-input-placeholder,
.sol-form-decoration form input[type='text']:-ms-input-placeholder,
.sol-form form input[type='url']:-ms-input-placeholder,
.sol-form-decoration form input[type='url']:-ms-input-placeholder,
.sol-form form select:-ms-input-placeholder,
.sol-form-decoration form select:-ms-input-placeholder,
.sol-form form textarea:-ms-input-placeholder,
.sol-form-decoration form textarea:-ms-input-placeholder {
  color: #727272;
}
.sol-form form input[type='email']:focus,
.sol-form-decoration form input[type='email']:focus,
.sol-form form input[type='number']:focus,
.sol-form-decoration form input[type='number']:focus,
.sol-form form input[type='password']:focus,
.sol-form-decoration form input[type='password']:focus,
.sol-form form input[type='tel']:focus,
.sol-form-decoration form input[type='tel']:focus,
.sol-form form input[type='text']:focus,
.sol-form-decoration form input[type='text']:focus,
.sol-form form input[type='url']:focus,
.sol-form-decoration form input[type='url']:focus,
.sol-form form select:focus,
.sol-form-decoration form select:focus,
.sol-form form textarea:focus,
.sol-form-decoration form textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-form form input[type='email']:hover,
.sol-form-decoration form input[type='email']:hover,
.sol-form form input[type='number']:hover,
.sol-form-decoration form input[type='number']:hover,
.sol-form form input[type='password']:hover,
.sol-form-decoration form input[type='password']:hover,
.sol-form form input[type='tel']:hover,
.sol-form-decoration form input[type='tel']:hover,
.sol-form form input[type='text']:hover,
.sol-form-decoration form input[type='text']:hover,
.sol-form form input[type='url']:hover,
.sol-form-decoration form input[type='url']:hover,
.sol-form form select:hover,
.sol-form-decoration form select:hover,
.sol-form form textarea:hover,
.sol-form-decoration form textarea:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-form form input[type='email']:focus,
.sol-form-decoration form input[type='email']:focus,
.sol-form form input[type='number']:focus,
.sol-form-decoration form input[type='number']:focus,
.sol-form form input[type='password']:focus,
.sol-form-decoration form input[type='password']:focus,
.sol-form form input[type='tel']:focus,
.sol-form-decoration form input[type='tel']:focus,
.sol-form form input[type='text']:focus,
.sol-form-decoration form input[type='text']:focus,
.sol-form form input[type='url']:focus,
.sol-form-decoration form input[type='url']:focus,
.sol-form form select:focus,
.sol-form-decoration form select:focus,
.sol-form form textarea:focus,
.sol-form-decoration form textarea:focus {
  background-color: #fff;
  border-color: #00866D;
}
.sol-form form input[type='email']:invalid,
.sol-form-decoration form input[type='email']:invalid,
.sol-form form input[type='number']:invalid,
.sol-form-decoration form input[type='number']:invalid,
.sol-form form input[type='password']:invalid,
.sol-form-decoration form input[type='password']:invalid,
.sol-form form input[type='tel']:invalid,
.sol-form-decoration form input[type='tel']:invalid,
.sol-form form input[type='text']:invalid,
.sol-form-decoration form input[type='text']:invalid,
.sol-form form input[type='url']:invalid,
.sol-form-decoration form input[type='url']:invalid,
.sol-form form select:invalid,
.sol-form-decoration form select:invalid,
.sol-form form textarea:invalid,
.sol-form-decoration form textarea:invalid {
  border-color: #eec3c3;
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/times_invalid.svg');
}
.sol-form form input[type='email']:required:valid,
.sol-form-decoration form input[type='email']:required:valid,
.sol-form form input[type='number']:required:valid,
.sol-form-decoration form input[type='number']:required:valid,
.sol-form form input[type='password']:required:valid,
.sol-form-decoration form input[type='password']:required:valid,
.sol-form form input[type='tel']:required:valid,
.sol-form-decoration form input[type='tel']:required:valid,
.sol-form form input[type='text']:required:valid,
.sol-form-decoration form input[type='text']:required:valid,
.sol-form form input[type='url']:required:valid,
.sol-form-decoration form input[type='url']:required:valid,
.sol-form form select:required:valid,
.sol-form-decoration form select:required:valid,
.sol-form form textarea:required:valid,
.sol-form-decoration form textarea:required:valid {
  border-color: #c7e6b3;
  background-color: #eef7e8;
  background-image: url('/webdav/files/System/resources/graphics/icons/check_valid.svg');
}
.sol-form form input[type='email'] ~ span.font-normal,
.sol-form-decoration form input[type='email'] ~ span.font-normal,
.sol-form form input[type='number'] ~ span.font-normal,
.sol-form-decoration form input[type='number'] ~ span.font-normal,
.sol-form form input[type='password'] ~ span.font-normal,
.sol-form-decoration form input[type='password'] ~ span.font-normal,
.sol-form form input[type='tel'] ~ span.font-normal,
.sol-form-decoration form input[type='tel'] ~ span.font-normal,
.sol-form form input[type='text'] ~ span.font-normal,
.sol-form-decoration form input[type='text'] ~ span.font-normal,
.sol-form form input[type='url'] ~ span.font-normal,
.sol-form-decoration form input[type='url'] ~ span.font-normal,
.sol-form form select ~ span.font-normal,
.sol-form-decoration form select ~ span.font-normal,
.sol-form form textarea ~ span.font-normal,
.sol-form-decoration form textarea ~ span.font-normal {
  margin-top: 10px;
}
.sol-form form textarea,
.sol-form-decoration form textarea {
  height: auto;
}
.sol-form form select,
.sol-form-decoration form select {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 8px 15px;
  font-size: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  color: #35383b;
  vertical-align: middle;
  height: 40px;
  border: 1px solid #E8E8E8;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition-property: border, background-color, box-shadow;
  transition-property: border, background-color, box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  box-shadow: none;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  cursor: pointer;
  background-image: url('/webdav/files/System/resources/graphics/icons/chevron-down.svg');
  background-size: 13px;
  background-position: right 15px top 12px;
}
.sol-form form select::-webkit-input-placeholder,
.sol-form-decoration form select::-webkit-input-placeholder {
  color: #727272;
}
.sol-form form select:-moz-placeholder,
.sol-form-decoration form select:-moz-placeholder {
  color: #727272;
}
.sol-form form select::-moz-placeholder,
.sol-form-decoration form select::-moz-placeholder {
  color: #727272;
}
.sol-form form select:-ms-input-placeholder,
.sol-form-decoration form select:-ms-input-placeholder {
  color: #727272;
}
.sol-form form select:focus,
.sol-form-decoration form select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
.sol-form form select:hover,
.sol-form-decoration form select:hover {
  border-color: #d6d6d6;
  background-color: #fff;
}
.sol-form form select:focus,
.sol-form-decoration form select:focus {
  background-color: #fff;
  border-color: #00866D;
}
.sol-form form .sol-form-field,
.sol-form-decoration form .sol-form-field {
  margin-bottom: 10px;
}
.sol-form form .sol-label-container > label,
.sol-form-decoration form .sol-label-container > label,
.sol-form form .sv-field-title-container > label,
.sol-form-decoration form .sv-field-title-container > label,
.sol-form form .sv-login-input-group > label,
.sol-form-decoration form .sv-login-input-group > label {
  display: block;
  font-size: 19px;
  font-weight: 600 !important;
  margin-bottom: 5px;
  font-family: 'Fira Sans', sans-serif;
}
.sol-form form .sol-label-container > span,
.sol-form-decoration form .sol-label-container > span,
.sol-form form .sv-field-title-container > span,
.sol-form-decoration form .sv-field-title-container > span,
.sol-form form .sv-login-input-group > span,
.sol-form-decoration form .sv-login-input-group > span {
  display: block;
  margin-bottom: 5px;
  font-size: 23px;
  font-weight: 600;
  font-family: 'Fira Sans', sans-serif;
}
.sol-form form .sv-form-datefield > div,
.sol-form-decoration form .sv-form-datefield > div {
  width: 50%;
  margin: 0 !important;
}
.sol-form form .sv-form-datefield > div:nth-of-type(1),
.sol-form-decoration form .sv-form-datefield > div:nth-of-type(1) {
  padding-right: 5px;
}
.sol-form form .sv-form-datefield > div:nth-of-type(2),
.sol-form-decoration form .sv-form-datefield > div:nth-of-type(2) {
  padding-left: 5px;
}
.sol-form form .sv-form-datefield > div select,
.sol-form-decoration form .sv-form-datefield > div select {
  min-width: 100%;
  margin-bottom: 10px;
}
.sol-form form br,
.sol-form-decoration form br {
  display: none;
}
.sol-form form .font-normal,
.sol-form-decoration form .font-normal {
  display: block;
  font-size: 19px;
  font-weight: 600;
}
.sol-form form .sv-formField,
.sol-form-decoration form .sv-formField {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .sol-form form .sv-formField,
  .sol-form-decoration form .sv-formField {
    width: 100%;
    padding: 0;
  }
}
.sol-form form input[type='checkbox'],
.sol-form-decoration form input[type='checkbox'] {
  position: relative;
  top: 27px;
  left: 0;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 3px;
  display: inline-block;
}
.sol-form form input[type='checkbox'] + label,
.sol-form-decoration form input[type='checkbox'] + label {
  color: #35383b;
  font-family: 'Fira Sans', sans-serif;
  display: block;
  padding-left: 35px;
  font-size: 17px;
  line-height: 1.3em;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
}
.sol-form form input[type='radio'],
.sol-form-decoration form input[type='radio'] {
  position: relative;
  top: 27px;
  left: 0;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 50%;
  display: inline-block;
}
.sol-form form input[type='radio'] + label,
.sol-form-decoration form input[type='radio'] + label {
  color: #35383b;
  font-family: 'Fira Sans', sans-serif;
  display: block;
  padding-left: 35px;
  font-size: 17px;
  line-height: 1.3em;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
}
.sol-form form button[type='submit'],
.sol-form-decoration form button[type='submit'],
.sol-form form input[type='submit'],
.sol-form-decoration form input[type='submit'] {
  position: relative;
  display: inline-block;
  line-height: 22px;
  padding: 12px 25px;
  color: #35383b;
  border-radius: 20px;
  background-color: rgba(255, 209, 0, 0.6);
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
  border: 0;
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}
.sol-form form button[type='submit']:focus,
.sol-form-decoration form button[type='submit']:focus,
.sol-form form input[type='submit']:focus,
.sol-form-decoration form input[type='submit']:focus,
[data-whatinput="keyboard"] .sol-form form button[type='submit']:focus,
[data-whatinput="keyboard"] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput="keyboard"] .sol-form form input[type='submit']:focus,
[data-whatinput="keyboard"] .sol-form-decoration form input[type='submit']:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-form form button[type='submit']:focus,
[data-whatinput="initial"] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput="initial"] .sol-form form input[type='submit']:focus,
[data-whatinput="initial"] .sol-form-decoration form input[type='submit']:focus,
[data-whatinput="mouse"] .sol-form form button[type='submit']:focus,
[data-whatinput="mouse"] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput="mouse"] .sol-form form input[type='submit']:focus,
[data-whatinput="mouse"] .sol-form-decoration form input[type='submit']:focus,
[data-whatinput="touch"] .sol-form form button[type='submit']:focus,
[data-whatinput="touch"] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput="touch"] .sol-form form input[type='submit']:focus,
[data-whatinput="touch"] .sol-form-decoration form input[type='submit']:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-form form button[type='submit']:hover,
.sol-form-decoration form button[type='submit']:hover,
.sol-form form input[type='submit']:hover,
.sol-form-decoration form input[type='submit']:hover {
  background-color: #FFD100;
  color: black;
  text-decoration: underline;
}
.sol-form form button[type='submit']:active,
.sol-form-decoration form button[type='submit']:active,
.sol-form form input[type='submit']:active,
.sol-form-decoration form input[type='submit']:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #006579;
}
.sol-form form button[type='submit'] i.fa,
.sol-form-decoration form button[type='submit'] i.fa,
.sol-form form input[type='submit'] i.fa,
.sol-form-decoration form input[type='submit'] i.fa {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.sol-form form button[type='submit'] > i.fa,
.sol-form-decoration form button[type='submit'] > i.fa,
.sol-form form input[type='submit'] > i.fa,
.sol-form-decoration form input[type='submit'] > i.fa {
  margin-right: 11px;
  margin-left: -15px;
}
.sol-form form .sol-form-divider,
.sol-form-decoration form .sol-form-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}
.sol-form input[type="tel"] ~ span.font-normal,
.sol-form-decoration input[type="tel"] ~ span.font-normal {
  font-size: 11px !important;
  background-color: #ac1a2f !important;
  border-radius: 2px !important;
  color: #fff !important;
  height: 21px;
  width: auto;
  line-height: 20px;
  padding-left: 5px;
}
.sv-dbform-portlet form {
  margin-top: 20px;
}
.sv-dbform-portlet form .sv-form-consent {
  position: relative;
  padding-bottom: 30px;
  border-bottom: 2px solid rgba(211, 211, 211, 0.8);
}
.sv-dbform-portlet form .sv-form-consent input {
  top: 27px;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 3px;
  display: block;
}
.sv-dbform-portlet form .sv-form-consent input:active,
.sv-dbform-portlet form .sv-form-consent input:checked:active {
  box-shadow: none;
}
.sv-dbform-portlet form .sv-form-consent input:checked {
  background: #fff url(https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg) no-repeat;
  background-size: 18px;
  background-position: center;
  border: 2px solid #1B927C;
  box-shadow: none;
}
.sv-dbform-portlet form .sv-form-consent input:focus {
  outline: 2px solid #257cc3;
  z-index: 10;
  outline-offset: 0px;
}
.sv-dbform-portlet form .sv-form-consent input:focus + label {
  text-decoration: underline;
}
.sv-dbform-portlet form .sv-form-consent input:hover {
  background-color: #B9DBD4;
}
.sv-dbform-portlet form .sv-form-consent input + label {
  padding-left: 36px;
  display: inline;
}
.sv-formField input[type="submit"] {
  border: 0;
  background-color: rgba(255, 209, 0, 0.6);
  border-radius: 40px;
  padding: 8px 20px;
  margin-top: 45px;
}
.sv-formField input[type="submit"]:focus {
  background-color: #FFD100;
  border-color: transparent;
}
.sv-formField input[type="text"]:focus,
.sv-formField input[type="email"]:focus,
.sv-formField input[type="tel"]:focus {
  outline: 2px solid #257cc3 !important;
  z-index: 10 !important;
  outline-offset: -1px !important;
}
.sv-dbform-portlet form input[type="radio"] {
  position: relative;
  top: 27px;
  width: 22px;
  height: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 50%;
  display: block;
}
.sv-dbform-portlet form input[type="radio"]:active,
.sv-dbform-portlet form input[type="radio"]:checked:active {
  box-shadow: none;
}
.sv-dbform-portlet form input[type="radio"]:checked {
  background: #1b927c url('https://www.strangnas.se/images/18.38e877f416b3ac0044142498/1560260286947/circle-128.png') no-repeat;
  border: 2px solid #1b927c;
  box-shadow: none;
  background-size: 8px;
  background-position: center;
}
.sv-dbform-portlet form input[type="radio"]:focus {
  outline: 2px solid #257cc3;
  z-index: 10;
  outline-offset: 0px;
}
.sv-dbform-portlet form input[type="radio"]:focus + label {
  text-decoration: underline;
}
.sv-dbform-portlet form input[type="radio"]:hover {
  background-color: #B9DBD4;
}
.sv-dbform-portlet form input[type="radio"] + label {
  padding-left: 30px;
  display: inline-block;
}
input[type=radio]::-ms-check {
  border: 2px solid #1b927c;
  box-shadow: none;
  background-size: 8px;
  background-position: center;
}
input[type=checkbox]::-ms-check {
  background-size: 18px;
  background-position: center;
  border: 2px solid #1B927C;
  box-shadow: none;
  border-radius: 3px;
}
.sv-defaultFormTheme input[type="checkbox"] {
  position: relative;
  top: 27px;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 3px;
  display: block;
}
.sv-defaultFormTheme input[type="checkbox"]:active,
.sv-defaultFormTheme input[type="checkbox"]:checked:active {
  box-shadow: none;
}
.sv-defaultFormTheme input[type="checkbox"]:checked {
  background: #fff url(https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg) no-repeat;
  background-size: 18px;
  background-position: center;
  border: 2px solid #1B927C;
  box-shadow: none;
}
.sv-defaultFormTheme input[type="checkbox"]:focus {
  outline: 2px solid #257cc3;
  z-index: 10;
  outline-offset: 0px;
}
.sv-defaultFormTheme input[type="checkbox"]:focus + label {
  text-decoration: underline;
}
.sv-defaultFormTheme input[type="checkbox"]:hover {
  background-color: #B9DBD4;
}
.sv-defaultFormTheme input[type="checkbox"] + label {
  padding-left: 30px;
  display: inline-block;
}
.sv-defaultFormTheme input[type="radio"] {
  position: relative;
  top: 27px;
  width: 22px;
  height: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 50%;
  display: block;
}
.sv-defaultFormTheme input[type="radio"]:active,
.sv-defaultFormTheme input[type="radio"]:checked:active {
  box-shadow: none;
}
.sv-defaultFormTheme input[type="radio"]:checked {
  background: #1b927c url('https://www.strangnas.se/images/18.38e877f416b3ac0044142498/1560260286947/circle-128.png') no-repeat;
  border: 2px solid #1b927c;
  box-shadow: none;
  background-size: 8px;
  background-position: center;
}
.sv-defaultFormTheme input[type="radio"]:focus {
  outline: 2px solid #257cc3;
  z-index: 10;
  outline-offset: 0px;
}
.sv-defaultFormTheme input[type="radio"]:focus + label {
  text-decoration: underline;
}
.sv-defaultFormTheme input[type="radio"]:hover {
  background-color: #B9DBD4;
}
.sv-defaultFormTheme input[type="radio"] + label {
  color: #35383b;
  font-family: 'Fira Sans', sans-serif;
  padding-left: 30px;
  font-size: 17px;
  line-height: 1.3em;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
}
.sv-form-singleselectionfield {
  margin-bottom: 20px !important;
}
.sv-formField .sv-form-consent {
  padding-left: 0 !important;
}
.sv-formField .sv-form-consent input {
  position: relative !important;
}
.sol-info-box,
.sol-simple-box-decoration.sol-info-box {
  position: relative;
  padding: 10px 16px 16px 64px !important;
}
.sol-info-box--alt,
.sol-simple-box-decoration.sol-info-box--alt {
  padding: 16px !important;
  background-color: rgba(255, 209, 0, 0.05);
}
@media (max-width: 479px) {
  .sol-info-box--alt,
  .sol-simple-box-decoration.sol-info-box--alt {
    padding: 12px;
  }
}
.sol-info-box .fa-exclamation-circle,
.sol-simple-box-decoration.sol-info-box .fa-exclamation-circle {
  position: absolute;
  top: 18px;
  left: 18px;
  font-size: 32px;
  color: #FFD100;
}
@media (max-width: 479px) {
  .sol-info-box,
  .sol-simple-box-decoration.sol-info-box {
    padding: 56px 16px 16px 22px;
  }
}
/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
table.sol-table-decoration,
table.sv-table-type-stylad-tabell {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 3px;
  box-shadow: 0 0 0 1px #e8f3f8;
}
table.sol-table-decoration caption,
table.sv-table-type-stylad-tabell caption {
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 800;
  line-height: 1.2em;
}
table.sol-table-decoration thead th,
table.sv-table-type-stylad-tabell thead th {
  padding: 11px 18px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 19px;
  font-weight: 600;
  border-right: 1px solid rgba(232, 243, 248, 0.3);
}
@media (max-width: 767px) {
  table.sol-table-decoration thead th,
  table.sv-table-type-stylad-tabell thead th {
    font-size: 13px;
    font-weight: 700;
  }
}
table.sol-table-decoration thead th p,
table.sv-table-type-stylad-tabell thead th p {
  font-family: 'Fira Sans', sans-serif !important;
  font-size: 19px !important;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  table.sol-table-decoration thead th p,
  table.sv-table-type-stylad-tabell thead th p {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
}
table.sol-table-decoration thead th:last-child,
table.sv-table-type-stylad-tabell thead th:last-child {
  border: none;
  border-top-right-radius: 3px;
}
table.sol-table-decoration thead th:first-child,
table.sv-table-type-stylad-tabell thead th:first-child {
  border-top-left-radius: 3px;
}
table.sol-table-decoration tbody,
table.sv-table-type-stylad-tabell tbody {
  background-color: #fff;
}
table.sol-table-decoration tbody th,
table.sv-table-type-stylad-tabell tbody th {
  padding: 8px 12px;
  background-color: rgba(255, 209, 0, 0.3) !important;
  border-right: 1px solid rgba(232, 243, 248, 0.3);
}
@media (max-width: 767px) {
  table.sol-table-decoration tbody th,
  table.sv-table-type-stylad-tabell tbody th {
    font-size: 13px;
    font-weight: 700;
  }
}
table.sol-table-decoration tbody th p,
table.sv-table-type-stylad-tabell tbody th p {
  font-family: 'Fira Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  table.sol-table-decoration tbody th p,
  table.sv-table-type-stylad-tabell tbody th p {
    font-size: 13px !important;
    font-weight: 700 !important;
  }
}
table.sol-table-decoration tbody th:last-child,
table.sv-table-type-stylad-tabell tbody th:last-child {
  border: none;
  border-top-right-radius: 3px;
}
table.sol-table-decoration tbody th:first-child,
table.sv-table-type-stylad-tabell tbody th:first-child {
  border-top-left-radius: 3px;
}
table.sol-table-decoration tbody tr td,
table.sv-table-type-stylad-tabell tbody tr td {
  padding: 11px 18px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 300;
  border-right: 1px solid rgba(0, 134, 109, 0.15);
}
@media (max-width: 767px) {
  table.sol-table-decoration tbody tr td,
  table.sv-table-type-stylad-tabell tbody tr td {
    font-size: 13px;
    font-weight: 300;
  }
}
table.sol-table-decoration tbody tr td p,
table.sv-table-type-stylad-tabell tbody tr td p {
  font-family: 'Fira Sans', sans-serif !important;
  font-size: 17px !important;
  font-weight: 300 !important;
}
@media (max-width: 767px) {
  table.sol-table-decoration tbody tr td p,
  table.sv-table-type-stylad-tabell tbody tr td p {
    font-size: 13px ! important;
    font-weight: 300 !important;
  }
}
table.sol-table-decoration tbody tr td:last-child,
table.sv-table-type-stylad-tabell tbody tr td:last-child {
  border: none;
}
table.sol-table-decoration tbody tr:nth-child(odd),
table.sv-table-type-stylad-tabell tbody tr:nth-child(odd) {
  background-color: rgba(0, 134, 109, 0.15);
}
table.sv-table-type-stylad-tabell thead {
  background-color: #00866D !important;
}
table.sv-table-type-stylad-tabell thead p {
  color: #fff !important;
}
table caption {
  display: none;
}
.sv-editable-layout table caption {
  display: table-caption;
}
.sol-link-tablet {
  display: table;
  margin-bottom: 20px;
}
.sol-link-tablet__link {
  display: flex;
  width: fit-content;
  max-width: 670px;
  height: auto;
  padding: 10px 30px;
  line-height: 25px;
  text-decoration: none;
  background-color: rgba(255, 209, 0, 0.6);
  border-radius: 100px;
}
@media (max-width: 767px) {
  .sol-link-tablet__link {
    max-width: 480px;
  }
}
@media (max-width: 479px) {
  .sol-link-tablet__link {
    max-width: 320px;
  }
}
.sol-link-tablet__link .sol-link-tablet__icon i {
  position: relative;
  width: 23px;
  height: 100%;
  margin-right: 10px;
  font-size: 23px;
}
.sol-link-tablet__link .sol-link-tablet__icon i.fa-angle-left {
  margin-right: 0;
}
.sol-link-tablet__link .sol-link-tablet__icon i::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.sol-link-tablet__link .sol-link-tablet__text {
  font-size: 16px;
  font-weight: normal;
  text-overflow: ellipsis;
}
.sol-link-tablet__link:hover {
  text-decoration: underline;
  background-color: #FFD100;
}
.sol-collapsible .sol-link-tablet {
  margin: 20px 0;
}
.sol-tabs__buttons {
  display: flex;
  border-bottom: 4px solid rgba(0, 134, 109, 0.4);
}
@media (max-width: 767px) {
  .sol-tabs__buttons {
    display: block;
    border-bottom: none;
  }
}
.sol-tabs__buttons > button {
  flex: 1;
}
@media (max-width: 767px) {
  .sol-tabs__buttons > button {
    width: 100%;
  }
}
.sol-tabs__content {
  position: relative;
}
.sol-tabs__content > div {
  top: 0;
  width: 100%;
}
.sol-tabs-button {
  display: block;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  min-height: 44px;
  line-height: 44px;
  border-bottom: 4px solid transparent;
  margin-bottom: -4px;
}
@media (max-width: 767px) {
  .sol-tabs-button {
    border-bottom-color: rgba(0, 134, 109, 0.4);
    padding-top: 5px;
  }
}
.sol-tabs-button--active {
  border-bottom-color: #00866D;
}
.sol-widget-decoration-wrapper {
  margin-top: 20px;
}
.sol-widget-decoration {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  max-width: 300px;
  margin-bottom: 20px;
}
.sol-widget-decoration .sol-widget-decoration-img {
  position: relative;
  background-position: 50% 50%;
  background-size: contain;
  height: 175px;
  max-width: 300px;
}
@media (max-width: 991px) {
  .sol-widget-decoration .sol-widget-decoration-img {
    height: 124px;
  }
}
@media (max-width: 767px) {
  .sol-widget-decoration .sol-widget-decoration-img {
    height: 250px;
  }
}
.sol-widget-decoration .sol-widget-decoration-img:before {
  content: '';
  background-color: #FFD100;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-widget-decoration .sv-text-portlet {
  position: relative;
  padding: 5px 20px 30px 20px;
  font-size: 13px;
  line-height: 20px;
}
.sol-widget-decoration .sv-text-portlet h2 {
  font-family: 'Fira Sans', sans-serif;
  color: #00866d;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 0;
}
.sol-widget-decoration .sv-text-portlet h2 a {
  font-family: 'Fira Sans', sans-serif;
  color: #00866d !important;
  font-size: 19px;
}
.sol-widget-decoration .sv-text-portlet a:hover {
  text-decoration: none;
}
.sol-widget-decoration .sv-text-portlet p a {
  text-decoration: none;
}
.sol-widget-decoration .sv-text-portlet p strong a {
  color: #00866d !important;
}
.sol-widget-decoration.sol-clickable-element:hover a,
.sol-widget-decoration.sol-clickable-element:hover p {
  text-decoration: underline;
  color: #006579;
}
.sol-widget-decoration .font-widget-heading {
  color: #00866D;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
}
.sol-widget-decoration .font-widget-heading:hover a {
  color: #00866D;
  text-decoration: underline;
}
.sol-widget-decoration .font-widget-heading a {
  color: #00866D;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.sol-widget-decoration .font-widget-heading a .sv-linkicon {
  display: none;
}
.sol-widget-decoration .font-normal {
  height: 100%;
  max-height: none;
  font-family: 'Fira Sans', sans-serif;
  margin-bottom: 0;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.4em;
  overflow: hidden;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-widget-decoration .font-normal {
    height: auto;
    max-height: none;
    overflow: visible;
  }
}
@media (max-width: 991px) {
  .sol-widget-decoration {
    margin: auto;
  }
}
.sol-widget-decoration:hover .font-widget-heading a {
  color: #00866D;
  text-decoration: underline;
}
.sol-widget-fullwidth .sv-column-3 {
  /*     &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    } */
}
@media (max-width: 991px) {
  .sol-widget-fullwidth .sv-column-3 {
    flex: 1 0 50% !important;
  }
}
@media (max-width: 767px) {
  .sol-widget-fullwidth .sv-column-3 {
    flex: 1 0 100% !important;
    padding: 0 !important;
  }
}
body {
  padding: 0 !important;
}
.sol-cookie-message {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 290px;
  border-top-left-radius: 3px;
  background-color: #3d3d3d;
  color: #fff;
  padding: 8px 10px 8px 14px;
  font-size: 13px;
}
.sol-cookie-message:after,
.sol-cookie-message:before {
  content: "";
  display: table;
}
.sol-cookie-message:after {
  clear: both;
}
.sol-cookie-message a {
  color: #fff;
  text-decoration: underline;
}
.sol-cookie-message a:focus {
  outline: 2px solid yellow;
  outline-offset: 2px;
  z-index: 10;
}
.sol-cookie-message .sol-cookie-ok {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: inline-block;
  padding: 0 7px;
  border-radius: 2px;
  background-color: #fff;
  color: #00866D;
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  float: right;
  margin-top: 3px;
}
.sol-cookie-message .sol-cookie-ok:focus {
  outline: 2px solid yellow;
  outline-offset: 2px;
  z-index: 10;
}
.sol-editor-bottom {
  bottom: 60px !important;
}
footer .sol-bottom {
  padding: 230px 0 50px;
  background: url("/webdav/files/System/resources/graphics/Skyline_sidfot.png");
  background-size: cover;
  background-color: #F8F8F8;
}
@media (min-width: 2700px) {
  footer .sol-bottom {
    padding-top: 330px;
  }
}
@media (min-width: 3300px) {
  footer .sol-bottom {
    padding-top: 380px;
  }
}
@media (max-width: 991px) {
  footer .sol-bottom {
    padding: 190px 20px 30px;
  }
}
@media (max-width: 767px) {
  footer .sol-bottom {
    padding: 110px 35px 30px;
    background: url("/webdav/files/System/resources/graphics/Skyline_sidfot2.png");
    background-size: cover;
    background-color: #F8F8F8;
  }
}
footer .sol-bottom .sv-grid-main .sv-row > .sv-column-4 {
  padding-right: 30px;
}
footer .sol-bottom .sol-footer-contact-item,
footer .sol-bottom .sol-footer-links-item {
  font-size: 16px;
  line-height: 38px;
  display: block;
  position: relative;
  margin-bottom: 11px;
  padding-left: 0px;
  text-decoration: none;
  color: #fff;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
@media (max-width: 767px) {
  footer .sol-bottom .sol-footer-contact-item,
  footer .sol-bottom .sol-footer-links-item {
    font-size: 14px;
  }
}
footer .sol-bottom .sol-footer-contact-item--icon-links a::before,
footer .sol-bottom .sol-footer-links-item--icon-links a::before {
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  display: block;
  content: "\f105";
  width: 18px;
  height: 18px;
  margin-right: 10px;
  padding-left: 2px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  opacity: 0.5;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 1px;
  box-sizing: border-box;
}
footer .sol-bottom .sol-footer-contact-item a,
footer .sol-bottom .sol-footer-links-item a,
footer .sol-bottom .sol-footer-contact-item p,
footer .sol-bottom .sol-footer-links-item p {
  font-family: 'Fira Sans', sans-serif;
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  margin: 0;
}
@media (max-width: 767px) {
  footer .sol-bottom .sol-footer-contact-item a,
  footer .sol-bottom .sol-footer-links-item a,
  footer .sol-bottom .sol-footer-contact-item p,
  footer .sol-bottom .sol-footer-links-item p {
    font-size: 14px;
  }
}
footer .sol-bottom .sol-footer-contact-item i.fa:first-child,
footer .sol-bottom .sol-footer-links-item i.fa:first-child {
  position: absolute;
  left: 0;
  top: 1px;
  font-size: 17px;
}
footer .sol-bottom .sol-footer-contact-item__icon,
footer .sol-bottom .sol-footer-links-item__icon {
  width: 38px;
  height: 38px;
  margin-right: 7px;
  padding-left: 1px;
  float: left;
  border: transparent;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  color: #fff;
  font-size: 19px;
  text-align: center;
  line-height: 40px;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
footer .sol-bottom .sol-footer-contact-item__icon:hover,
footer .sol-bottom .sol-footer-links-item__icon:hover {
  opacity: 0.5;
}
@media (max-width: 767px) {
  footer .sol-bottom .sol-footer-contact-item img,
  footer .sol-bottom .sol-footer-links-item img {
    height: 40px;
  }
}
footer .sol-bottom .sol-footer-contact-list,
footer .sol-bottom .sol-footer-links-list {
  list-style: none;
  width: 100%;
  padding: 47px 0 0 0;
}
@media (max-width: 991px) {
  footer .sol-bottom .sol-footer-contact-list,
  footer .sol-bottom .sol-footer-links-list {
    padding: 0;
    margin: 0;
  }
}
footer .sol-bottom .sol-footer-contact-list li,
footer .sol-bottom .sol-footer-links-list li {
  padding-left: 0;
  float: left;
  height: 60px;
  width: 50%;
}
@media (max-width: 991px) {
  footer .sol-bottom .sol-footer-contact-list li,
  footer .sol-bottom .sol-footer-links-list li {
    width: 100%;
    float: none;
    padding-left: 0;
  }
}
footer .sol-bottom .sol-footer-contact-list li:nth-child(even),
footer .sol-bottom .sol-footer-links-list li:nth-child(even) {
  padding-left: 0;
  float: right;
}
@media (max-width: 991px) {
  footer .sol-bottom .sol-footer-contact-list li:nth-child(even),
  footer .sol-bottom .sol-footer-links-list li:nth-child(even) {
    width: 100%;
    float: none;
    padding-left: 0;
  }
}
footer .sol-bottom .sol-footer-contact-list li a,
footer .sol-bottom .sol-footer-links-list li a {
  text-decoration: none;
}
footer .sol-bottom .sol-footer-contact a:hover,
footer .sol-bottom .sol-footer-links a:hover {
  opacity: 0.7;
  text-decoration: underline;
}
footer .sol-bottom .sol-footer-contact a:focus .sol-footer-logo,
footer .sol-bottom .sol-footer-links a:focus .sol-footer-logo {
  outline: 2px solid yellow;
  outline-offset: 2px;
  z-index: 10;
}
footer .sol-bottom .sol-social-media {
  padding-left: 0px;
}
footer .sol-bottom .sol-social-media:after,
footer .sol-bottom .sol-social-media:before {
  content: "";
  display: table;
}
footer .sol-bottom .sol-social-media:after {
  clear: both;
}
footer .sol-bottom .sol-social-media .sol-social-media-item > a {
  width: 28px;
  height: 28px;
  margin-right: 7px;
  padding-left: 1px;
  float: left;
  border: transparent;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 29px;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
footer .sol-bottom .sol-social-media .sol-social-media-item > a:hover {
  opacity: 0.5;
}
footer .sol-bottom .sol-social-media .sol-social-media-item > a:focus {
  outline: 2px solid yellow;
  outline-offset: 2px;
  z-index: 10;
}
@media (max-width: 991px) {
  footer .sol-bottom .sol-social-media .sol-social-media-item > a {
    margin-right: 12px;
  }
}
@media (max-width: 767px) {
  footer .sol-bottom .sol-social-media .sol-social-media-item > a {
    width: 38px;
    height: 38px;
    margin-right: 12px;
    padding-top: 2px;
    padding-left: 0px;
    font-size: 19px;
    line-height: 34px;
  }
}
footer .sol-bottom .sol-footer-third-col {
  position: relative !important;
  text-align: center;
}
footer h1.font-footer-header,
footer p.font-footer-header {
  margin-bottom: 20px;
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  font-family: 'Fira Sans', sans-serif;
  color: #fff;
  line-height: 22px;
  font-weight: 700;
}
footer p.font-footer-text {
  margin-bottom: 11px;
  font-size: 14px;
  font-family: 'Fira Sans', sans-serif;
  color: #fff;
  line-height: 22px;
  font-weight: 700;
}
footer p.font-footer-text a:hover {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline;
}
footer .sol-footer-third-col {
  margin-top: 50px;
}
footer .sol-footer-second-logo img {
  height: 45px;
  width: 200px;
  display: block;
}
footer .sol-footer-logo img {
  height: 45.1px;
  width: auto;
}
footer .sol-footer-wave--svg {
  width: 101%;
  margin-bottom: -54px;
  display: block;
  margin-left: -1%;
  min-height: 100px;
  background-color: #f8f8f8;
}
@media (max-width: 767px) {
  footer .sol-footer-wave--svg {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1367px) {
  footer .sol-footer-wave--svg {
    background-color: transparent;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  footer .sol-footer-wave--svg {
    display: none;
  }
}
footer .sol-footer-wave--img {
  width: 100%;
  display: none;
}
@media (max-width: 767px) {
  footer .sol-footer-wave--img {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  footer .sol-footer-wave--img {
    display: block;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 767px), (-ms-high-contrast: active) and (max-width: 767px) {
  footer .sol-footer-wave--img {
    display: none;
  }
}
footer .sol-footer-wave-mobile {
  width: 100%;
  margin-bottom: -29px;
  display: none;
}
@media (max-width: 767px) {
  footer .sol-footer-wave-mobile {
    display: block;
  }
}
footer .sr-only {
  color: #28282A;
}
a > .sol-footer-contact-item:hover {
  text-decoration: underline;
}
.sol-footer-contact-list a:focus .sol-footer-contact-item {
  outline: 2px solid yellow;
  outline-offset: 2px;
  z-index: 10;
}
.sol-shortcut-container {
  background-color: #F8F8F8;
  padding: 40px 0;
  margin-bottom: -2px;
}
@media (min-width: 768px) and (max-width: 1367px) {
  .sol-shortcut-container {
    margin-bottom: -50px;
  }
}
.sol-shortcut {
  margin-left: -10px;
  margin-right: -10px;
}
.sol-shortcut:after,
.sol-shortcut:before {
  content: "";
  display: table;
}
.sol-shortcut:after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-shortcut {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.sol-shortcut a {
  text-decoration: none;
  max-width: 150px;
  display: inline-block;
}
.sol-shortcut-item {
  border-right: 1px solid #e7e7e7;
  display: inline-block;
  float: left;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.sol-shortcut-item:last-child {
  border: none;
}
@media (max-width: 991px) {
  .sol-shortcut-item {
    width: 33%!important;
    margin-bottom: 40px;
  }
  .sol-shortcut-item:nth-child(3) {
    border: none;
  }
}
@media (max-width: 479px) {
  .sol-shortcut-item {
    width: 16.6% !important;
    margin-bottom: 0;
    border-right: 1px solid #e7e7e7;
    padding: 0;
  }
  .sol-shortcut-item span {
    display: none;
  }
  .sol-shortcut-item:last-child {
    border-right: none;
  }
  .sol-shortcut-item:nth-child(3) {
    border-right: 1px solid #e7e7e7;
  }
}
.sol-shortcut-item i.fal {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto 0;
  background-color: transparent;
  color: #008268;
  font-size: 34px;
  text-align: center;
  line-height: 54px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
@media (max-width: 479px) {
  .sol-shortcut-item i.fal {
    width: 40px;
    height: 40px;
  }
}
.sol-shortcut-item a > span {
  text-decoration: none;
  color: #008268;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-shortcut-item a:focus i.fal,
.sol-shortcut-item a:hover i.fal {
  color: #FFD100;
}
.sol-shortcut-item a:focus > span,
.sol-shortcut-item a:hover > span {
  text-decoration: underline;
}
.sol-crisis-container {
  position: relative;
  background-color: #ac1a2f;
  color: #fff;
  -webkit-transition-property: margin-top;
  transition-property: margin-top;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}
.sol-crisis-container.interference {
  background-color: rgba(255, 209, 0, 0.2);
  border: 2px solid #FFD100;
}
.sol-crisis-container.interference .sv-row {
  padding: 5px 10px 0;
}
.sol-crisis-container.interference .sol-crisis-message-title,
.sol-crisis-container.interference .sol-crisis-message-desc,
.sol-crisis-container.interference .sol-close-crisis {
  color: #35383b;
}
.sol-crisis-container.interference .sol-crisis-message .fa-info-circle {
  color: #FFD100;
}
.sol-crisis-container.interference .sol-crisis-message-title {
  padding-left: 75px;
}
@media (max-width: 991px) {
  .sol-crisis-container.interference .sol-crisis-message-title {
    padding-left: 45px;
  }
}
.sol-crisis-container.interference .sol-crisis-message-desc {
  padding-left: 35px;
  max-width: 880px;
}
@media (max-width: 991px) {
  .sol-crisis-container.interference .sol-crisis-message-desc {
    padding-left: 20px;
  }
}
@media (max-width: 991px) {
  .sol-crisis-container.interference .sol-close-crisis {
    top: 10px;
  }
}
.sol-crisis-container .sv-row {
  position: relative;
  padding: 20px;
  display: inline-block;
  font-family: 'Fira Sans', sans-serif;
}
@media (max-width: 991px) {
  .sol-crisis-container .sv-row {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sv-row {
    padding: 10px;
  }
}
.sol-crisis-container i.fa-warning {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -55px;
  font-size: 100px;
  line-height: 110px;
}
@media (max-width: 991px) {
  .sol-crisis-container i.fa-warning {
    left: 30px;
    top: 20px;
    margin-top: 0;
    font-size: 45px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container i.fa-warning {
    left: 20px;
    font-size: 25px;
    line-height: 30px;
  }
}
.sol-crisis-container .sol-crisis-message {
  margin-top: 30px;
  margin-left: -30px;
  padding-bottom: 10px;
}
.sol-crisis-container .sol-crisis-message:after,
.sol-crisis-container .sol-crisis-message:before {
  content: "";
  display: table;
}
.sol-crisis-container .sol-crisis-message:after {
  clear: both;
}
.sol-crisis-container .sol-crisis-message .fa-exclamation,
.sol-crisis-container .sol-crisis-message .fa-info-circle {
  font-size: 51px;
  display: flex;
  margin-bottom: -50px;
  color: white;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-crisis-message .fa-exclamation,
  .sol-crisis-container .sol-crisis-message .fa-info-circle {
    height: 51px;
    font-size: 40px;
    margin-left: -15px;
    line-height: 1.2;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message .fa-exclamation,
  .sol-crisis-container .sol-crisis-message .fa-info-circle {
    margin-left: 0px;
    height: 27px;
    font-size: 27px;
    line-height: 0px;
    width: 11px;
  }
}
.sol-crisis-container .sol-crisis-message-title {
  font-size: 23px;
  font-weight: 600;
  font-family: 'Fira Sans', sans-serif;
  line-height: 1.35;
  display: block;
  padding-left: 40px;
  color: #fff;
  text-decoration: underline;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-crisis-message-title {
    padding-left: 26px;
    font-weight: 600;
    line-height: 1.11;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message-title {
    font-weight: 600;
    line-height: 1.11;
    font-size: 16px;
  }
}
.sol-crisis-container .sol-crisis-message-desc {
  color: #fff;
  line-height: 1.44;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 300;
  margin-left: 40px;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-crisis-message-desc {
    font-size: 15px !important;
    margin-bottom: 10px;
    line-height: 1.24;
    margin-left: 26px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message-desc {
    font-size: 13px !important;
  }
}
.sol-crisis-container .sol-crisis-message-more {
  display: block;
  text-align: right;
  font-size: 15px;
}
.sol-crisis-container .sol-crisis-message-more a {
  border-radius: 2px;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message-more a {
    display: block;
    text-align: right;
    bottom: 6px;
    right: 20px;
    position: absolute;
    font-size: 15px;
  }
}
.sol-crisis-container .sol-crisis-message-more a:hover {
  background-color: transparent;
  text-decoration: underline;
}
.sol-crisis-container .sol-close-crisis {
  position: absolute;
  top: 30px;
  right: -40px;
  width: 160px;
  font-size: 13px;
  font-weight: 600;
  margin-right: 50px;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-close-crisis {
    top: 18px;
    right: -60px;
  }
}
.sol-crisis-container .sol-close-crisis i.fal,
.sol-crisis-container .sol-close-crisis i.fas {
  margin-left: 10px;
  margin-top: -5px;
  font-size: 19px;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-close-crisis i.fal,
  .sol-crisis-container .sol-close-crisis i.fas {
    font-size: 16px;
  }
}
.sol-crisis-container a {
  padding: 10px 25px;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
@media (max-width: 767px) {
  .sol-crisis-container a {
    padding: 35px 25px 10px;
  }
}
.sol-no-script i.fa-warning {
  font-size: 50px;
  margin-top: -57px;
}
.sol-no-script .sv-row {
  padding: 15px 20px 0 90px;
}
.sol-no-script .sol-crisis-message-title {
  margin-bottom: 5px;
}
head header {
  position: relative;
  z-index: 50;
  background-color: #00866D;
  margin: 0 !important;
}
@media (max-width: 991px) {
  header .sol-top-search-desktop {
    display: none;
  }
}
header .sol-top-search-desktop form div input:focus {
  outline: 2px solid #257cc3 !important;
  outline-offset: -6px !important;
}
header.sol-mobile-search-active {
  width: 100%;
}
header .sol-top-search-mobile,
header .sol-top-search-desktop {
  position: relative;
  background-color: #00866D;
  height: auto;
  overflow: hidden;
}
header .sol-top-search-mobile.sol-active,
header .sol-top-search-desktop.sol-active {
  height: 115px;
  background-color: rgba(0, 134, 109, 0.9);
}
@media (max-width: 991px) {
  header .sol-top-search-mobile.sol-active,
  header .sol-top-search-desktop.sol-active {
    height: auto;
  }
}
@media (max-width: 767px) {
  header .sol-top-search-mobile.sol-active,
  header .sol-top-search-desktop.sol-active {
    height: auto;
  }
}
header .sol-top-search-mobile form,
header .sol-top-search-desktop form {
  position: relative;
  display: block;
  width: 280px;
}
@media (max-width: 991px) {
  header .sol-top-search-mobile form,
  header .sol-top-search-desktop form {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  header .sol-top-search-mobile form,
  header .sol-top-search-desktop form {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 479px) {
  header .sol-top-search-mobile form,
  header .sol-top-search-desktop form {
    width: 90%;
  }
}
header .sol-top-search-mobile form div input,
header .sol-top-search-desktop form div input {
  color: #35383b;
  padding: 0 20px;
  border: none;
  border-radius: 3px;
  width: 100%;
  height: 40px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}
header .sol-top-search-mobile form div input:focus,
header .sol-top-search-desktop form div input:focus {
  outline: 2px solid #ff0 !important;
  outline-offset: 2px!important;
  z-index: 10!important;
}
@media (max-width: 991px) {
  header .sol-top-search-mobile form div input,
  header .sol-top-search-desktop form div input {
    height: 66.6px;
    border-radius: 0;
  }
}
header .sol-top-search-mobile form div input::placeholder,
header .sol-top-search-desktop form div input::placeholder {
  color: #35383b;
  opacity: 1;
}
header .sol-top-search-mobile form div input:-ms-input-placeholder,
header .sol-top-search-desktop form div input:-ms-input-placeholder {
  color: #35383b;
  opacity: 1;
}
header .sol-top-search-mobile form div input::-ms-input-placeholder,
header .sol-top-search-desktop form div input::-ms-input-placeholder {
  color: #35383b;
  opacity: 1;
}
header .sol-top-search-mobile form div button,
header .sol-top-search-desktop form div button {
  color: #00866D;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 5px;
  height: 40px;
  width: 40px;
  border: none;
}
header .sol-top-search-mobile form div button i,
header .sol-top-search-desktop form div button i {
  font-size: 19px;
}
header .sol-top-search-mobile-close,
header .sol-top-search-desktop-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  position: absolute;
  right: -74px;
  top: 9px;
  color: #00866D;
  text-decoration: underline;
  cursor: pointer;
}
header .sol-top-search-mobile-close:focus,
header .sol-top-search-desktop-close:focus,
[data-whatinput="keyboard"] header .sol-top-search-mobile-close:focus,
[data-whatinput="keyboard"] header .sol-top-search-desktop-close:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] header .sol-top-search-mobile-close:focus,
[data-whatinput="initial"] header .sol-top-search-desktop-close:focus,
[data-whatinput="mouse"] header .sol-top-search-mobile-close:focus,
[data-whatinput="mouse"] header .sol-top-search-desktop-close:focus,
[data-whatinput="touch"] header .sol-top-search-mobile-close:focus,
[data-whatinput="touch"] header .sol-top-search-desktop-close:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
@media (max-width: 991px) {
  header .sol-top-search-mobile-close,
  header .sol-top-search-desktop-close {
    display: none;
  }
}
header .sol-top-search-mobile-close:hover,
header .sol-top-search-desktop-close:hover {
  color: #35383b;
}
header .sol-top-search-desktop {
  -webkit-transition-property: background-color, height;
  transition-property: background-color, height;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
header .sol-top {
  padding: 15px 45px;
  background-color: #00866D;
}
@media (min-width: 991px) and (max-width: 1260px) {
  header .sol-top {
    padding: 15px 20px;
  }
}
@media (max-width: 991px) {
  header .sol-top {
    padding: 15px 15px;
  }
}
@media (max-width: 767px) {
  header .sol-top {
    max-height: 45px;
    text-align: center;
    background-color: #00866D;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media (max-width: 767px) {
  header .sol-top {
    padding-left: 7px;
    padding-right: 7px;
  }
}
@media (max-width: 479px) {
  header .sol-top {
    padding-left: 2px;
    padding-right: 2px;
  }
}
header .sol-top .sol-header-flex {
  flex-wrap: wrap;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  justify-content: flex-end;
}
@media (max-width: 1199px) {
  header .sol-top .sol-header-flex {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 991px) {
  header .sol-top .sol-header-flex {
    justify-content: space-evenly;
  }
}
@media (max-width: 767px) {
  header .sol-top .sol-header-flex {
    width: 100%;
    padding: 0;
    padding-right: 2%;
  }
}
@media (max-width: 479px) {
  header .sol-top .sol-header-flex {
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  header .sol-top .sol-header-flex .sol-header-tools {
    flex: 1 0 auto;
  }
}
header .sol-top .sol-header-top-item {
  line-height: 45px;
  margin: 0 16px;
  font-size: 14px;
  margin: 0 30px;
}
@media (min-width: 991px) and (max-width: 1260px) {
  header .sol-top .sol-header-top-item {
    margin: 0 18px;
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  header .sol-top .sol-header-top-item {
    line-height: 45px;
    margin: 0 12px;
    flex: 1 0 10%;
    margin-right: 20px !important;
  }
}
@media (max-width: 767px) {
  header .sol-top .sol-header-top-item {
    font-size: 11px;
    line-height: unset;
    margin: 0;
    margin-right: 12px !important;
  }
}
@media (max-width: 479px) {
  header .sol-top .sol-header-top-item {
    margin-right: 8px !important;
  }
}
header .sol-top .sol-header-top-item .sol-header-link {
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  line-height: 45px;
}
header .sol-top .sol-header-top-item .sol-header-link .fa-desktop::before {
  display: none;
}
@media (max-width: 767px) {
  header .sol-top .sol-header-top-item .sol-header-link {
    vertical-align: middle;
    display: inline-flex;
  }
}
header .sol-top .sol-header-top-item .sol-header-link i {
  transform: translateY(2px);
  font-size: 19px;
  margin-right: 10px;
  color: #fff;
}
@media (min-width: 991px) and (max-width: 1260px) {
  header .sol-top .sol-header-top-item .sol-header-link i {
    margin-right: 5px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  header .sol-top .sol-header-top-item .sol-header-link i {
    line-height: 39px;
    margin-right: 3px;
    font-size: 11px;
  }
}
header .sol-top .sol-header-top-item .sol-header-link i:nth-child(2) {
  font-size: 14px;
  margin-left: 8px;
}
@media (max-width: 767px) {
  header .sol-top .sol-header-top-item .sol-header-link i:nth-child(2) {
    line-height: 39px;
    font-size: 11px;
    margin-left: 6px;
  }
}
header .sol-top .sol-header-top-item .sol-header-link:focus {
  outline: 2px solid yellow;
  outline-offset: 2px;
  padding: 2px;
  z-index: 10;
}
header .sol-top .sol-header-top-item .sol-header-link:hover {
  text-decoration: underline;
}
header .sol-top .sol-header-top-item .sol-header-all-sites {
  display: none;
  position: absolute;
  background: #fff;
  border: 2px solid #00866D;
  width: auto;
  padding: 15px;
  z-index: 2;
}
@media (max-width: 479px) {
  header .sol-top .sol-header-top-item .sol-header-all-sites {
    right: 0;
  }
}
header .sol-top .sol-header-top-item .sol-header-all-sites a {
  white-space: nowrap;
  display: block;
  color: #35383b;
}
@media (min-width: 991px) and (max-width: 1260px) {
  header .sol-top .sol-header-top-item:last-of-type {
    margin-left: 22px;
  }
  header .sol-top .sol-header-top-item:first-of-type {
    margin-right: 6px;
  }
}
@media (max-width: 340px) {
  header .sol-top .sol-header-top-item.sol-header-websites .sol-header-link i {
    display: none;
  }
}
header .sol-top .sol-top-logo {
  display: flex;
}
header .sol-top .sol-top-logo > img {
  height: 48px;
  width: auto;
}
header .sol-top .sol-top-logo > span {
  font-size: 22px;
  margin-top: -5px;
  margin-left: 12px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-color: #00866D;
}
@media (max-width: 991px) {
  header .sol-top .sol-top-logo {
    display: none;
  }
}
header .sol-top .sol-top-logo:focus {
  outline: 2px solid yellow;
  outline-offset: 2px;
  z-index: 10;
}
header .sol-bottom {
  background-color: #fff;
  text-align: left;
  -webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
}
@media (max-width: 991px) {
  header .sol-bottom {
    display: none;
  }
}
header .sol-bottom > div > .sv-script-portlet {
  text-align: center;
}
.sol-to-content {
  width: 1px;
  height: 35px;
  display: block;
  opacity: 0;
  position: relative;
  left: 45px;
  top: 23px;
  padding: 10px;
  line-height: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}
@media (max-width: 1400px) {
  .sol-to-content {
    position: absolute;
    margin-left: 205px;
    top: 40px;
    padding: 1px 10px;
  }
}
@media (max-width: 991px) {
  .sol-to-content {
    position: absolute;
    right: 200px;
    margin-left: 0;
    top: 105px;
  }
}
@media (max-width: 767px) {
  .sol-to-content {
    top: 92px;
  }
}
.sol-to-content:focus {
  opacity: 1;
  width: 200px;
  color: #35383b;
  background-color: #ffec99e1;
}
.sol-header-display-menu {
  display: flex;
  flex-direction: column;
  width: auto;
}
@media (min-width: 991px) and (max-width: 1260px) {
  .sol-header-logo {
    position: absolute;
    left: 5%;
    margin-left: -20px;
  }
}
@media (min-width: 991px) and (max-width: 1260px) {
  .sol-header-flex:first-of-type {
    max-width: 100%;
    padding-left: 200px !important;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) and (max-width: 991px), (-ms-high-contrast: active) and (min-width: 768px) and (max-width: 991px) {
  .sol-header-flex {
    display: block !important;
    width: 100%  !important;
  }
  .sol-header-flex > div {
    display: inline-block;
    margin: 0 20px;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 767px), (-ms-high-contrast: active) and (max-width: 767px) {
  .sol-header-flex {
    margin-left: -145px;
  }
  .sol-header-flex .sol-header-tools {
    flex: 1 0 auto;
  }
  .sol-header-top-item {
    flex: 1 0 auto;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 479px), (-ms-high-contrast: active) and (max-width: 479px) {
  .sol-header-flex {
    margin-left: -205px;
  }
}
.sol-mobile-header {
  display: none;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
}
.sol-mobile-header > div {
  position: relative;
  max-width: 930px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}
@media (max-width: 767px) {
  .sol-mobile-header > div {
    padding: unset;
    max-width: 728px;
    height: 55px;
  }
}
@media (max-width: 991px) {
  .sol-mobile-header {
    display: block;
    padding: 16px 31px;
  }
}
@media (max-width: 767px) {
  .sol-mobile-header {
    display: block;
    padding: 12px 5px;
  }
}
@media (max-width: 1199px) {
  .sol-mobile-header {
    background-color: #fff;
  }
}
.sol-mobile-header .sol-mobile-logo {
  height: 44px;
  width: auto;
  float: left;
}
@media (max-width: 767px) {
  .sol-mobile-header .sol-mobile-logo {
    margin-left: 17px;
    margin-top: 10px;
    height: 36px;
  }
}
@media (max-width: 767px) and (min-width: 350px) {
  .sol-mobile-header .sol-mobile-logo {
    margin-top: 7px;
    height: 40px;
  }
}
@media (max-width: 350px) {
  .sol-mobile-header .sol-mobile-logo {
    margin-left: 8px;
  }
}
.sol-mobile-header .sol-hamburger-wrapper {
  width: 55px;
  height: 55px;
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 0;
  text-align: center;
  padding-top: 32px;
}
.sol-mobile-header .sol-hamburger-wrapper:focus,
[data-whatinput="keyboard"] .sol-mobile-header .sol-hamburger-wrapper:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-mobile-header .sol-hamburger-wrapper:focus,
[data-whatinput="mouse"] .sol-mobile-header .sol-hamburger-wrapper:focus,
[data-whatinput="touch"] .sol-mobile-header .sol-hamburger-wrapper:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
@media (max-width: 767px) and (min-width: 350px) {
  .sol-mobile-header .sol-hamburger-wrapper {
    right: 10px;
  }
}
@media (max-width: 479px) {
  .sol-mobile-header .sol-hamburger-wrapper {
    width: 45px;
  }
}
.sol-mobile-header .sol-hamburger-wrapper .sol-hamburger {
  width: 35px;
  position: absolute;
  top: 8px;
  left: 50%;
  margin-left: -18px;
}
@media (max-width: 479px) {
  .sol-mobile-header .sol-hamburger-wrapper .sol-hamburger {
    margin-left: -15px;
  }
}
.sol-mobile-header .sol-hamburger-wrapper .sol-hamburger > span {
  display: block;
  width: 35px;
  height: 3px;
  border-radius: 2px;
  background-color: #35383b;
  transition: transform 500ms;
}
@media (max-width: 479px) {
  .sol-mobile-header .sol-hamburger-wrapper .sol-hamburger > span {
    width: 30px;
  }
}
.sol-mobile-header .sol-hamburger-wrapper .sol-hamburger .sol-hamburger-middle {
  margin-top: 6px;
  margin-bottom: 6px;
  transition: width 500ms;
}
.sol-mobile-header .sol-hamburger-wrapper .sol-hamburger.sol-active .sol-hamburger-top {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
  width: 37px;
}
.sol-mobile-header .sol-hamburger-wrapper .sol-hamburger.sol-active .sol-hamburger-middle {
  width: 0;
}
.sol-mobile-header .sol-hamburger-wrapper .sol-hamburger.sol-active .sol-hamburger-bottom {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
  width: 37px;
}
@media (max-width: 767px) {
  .sol-mobile-header .sol-hamburger-wrapper .sol-hamburger {
    right: 10px;
  }
}
.sol-mobile-header .sol-hamburger-wrapper .sol-hamburger-text {
  color: #35383b;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Fira Sans', sans-serif;
}
@media (max-width: 767px) {
  .sol-mobile-header .sol-hamburger-wrapper .sol-hamburger-text {
    font-size: 11px;
  }
}
.sol-mobile-header .sol-header-mobile-search button {
  font-family: 'Fira Sans', sans-serif;
  height: 65px;
  width: 55px;
  overflow: visible;
  background: none;
  border: none;
  font-size: 14px;
  padding-top: 7px;
  position: absolute;
  right: 60px;
}
@media (max-width: 767px) {
  .sol-mobile-header .sol-header-mobile-search button {
    font-size: 11px;
  }
}
@media (max-width: 479px) {
  .sol-mobile-header .sol-header-mobile-search button {
    width: 45px;
    right: 52px;
  }
}
.sol-mobile-header .sol-header-mobile-search button i {
  margin-bottom: 6px;
  display: block;
  color: #35383b;
  font-size: 24px;
}
.sol-top-search-mobile {
  display: none;
}
.sol-top-search-mobile.sol-active {
  position: absolute;
  z-index: 1000;
  width: 100%;
}
@media (max-width: 991px) {
  .sol-top-search-mobile.sol-active {
    top: 165px;
  }
}
@media (max-width: 767px) {
  .sol-top-search-mobile.sol-active {
    top: 125px;
  }
}
.sol-top-search-mobile .sol-top-search-field .sol-search-up {
  width: 0;
  height: 0;
  right: 65px;
  top: -19px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid white;
}
.sol-top-search-mobile .sol-top-search-field input[type=search] {
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #006579;
  height: 52px;
}
.sol-top-search-mobile .sol-top-search-field button[type=submit] {
  background-color: transparent;
  color: #00866D;
  margin-right: 30px;
  margin-top: 8px;
}
.sol-top-search-mobile .sol-top-search-field button[type=submit] i {
  font-size: 21px;
  font-weight: 500;
}
.sol-mobile-menu-wrapper {
  position: relative;
  display: none;
}
@media (max-width: 991px) {
  .sol-mobile-menu-wrapper {
    display: block;
  }
}
.sol-mobile-menu-container {
  display: none;
  background-color: #F8F8F8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 49;
  height: 0;
  min-height: 0;
  -webkit-transition-property: height, min-height;
  transition-property: height, min-height;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.43);
}
@media (max-width: 991px) {
  .sol-mobile-menu-container.sol-active {
    display: block;
  }
}
.sol-mobile-menu-container > ul {
  border-top: 1px solid #e7e7e7;
}
.sol-mobile-menu-container .sol-menu-list {
  width: 100%;
}
.sol-mobile-menu-container .sol-menu-list-expanded-init {
  display: block;
}
.sol-mobile-menu-container .sol-menu-item {
  position: relative;
}
.sol-mobile-menu-container .sol-menu-item-link {
  display: block;
  position: relative;
  padding: 10px 49px 10px 19px;
  border-bottom: 1px solid #e7e7e7;
  text-decoration: none;
  margin-left: 1px;
  margin-right: 1px;
  color: #35383b;
}
.sol-mobile-menu-container .sol-menu-item-link:focus,
[data-whatinput="keyboard"] .sol-mobile-menu-container .sol-menu-item-link:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-mobile-menu-container .sol-menu-item-link:focus,
[data-whatinput="mouse"] .sol-mobile-menu-container .sol-menu-item-link:focus,
[data-whatinput="touch"] .sol-mobile-menu-container .sol-menu-item-link:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-mobile-menu-container .sol-menu-item-link .sol-menu-item-display-name {
  font-size: 17px;
}
.sol-mobile-menu-container .sol-menu-item .sol-menu-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  top: 0;
  height: 43px;
  right: 0;
  width: 50px;
}
.sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="keyboard"] .sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="mouse"] .sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="touch"] .sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-mobile-menu-container .sol-menu-item .sol-menu-button i {
  position: absolute;
  right: 16px;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  text-align: center;
  padding-top: 3px;
  padding-left: 3px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='1'] .sol-menu-item-link {
  padding-left: 30px;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='2'] .sol-menu-item-link {
  padding-left: 40px;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='3'] .sol-menu-item-link {
  padding-left: 50px;
  border: none;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='4'] .sol-menu-item-link {
  padding-left: 60px;
  background-color: #fff;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='5'] .sol-menu-item-link {
  padding-left: 70px;
  background-color: #fff;
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'] > .sol-menu-button i.fal {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='false'][data-menu-level='2'] > .sol-menu-item-link,
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'][data-menu-level='2'] > .sol-menu-item-link {
  background-color: #fff;
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='true'] > .sol-menu-item-link,
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'] > .sol-menu-item-link {
  background-color: #F8F8F8;
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='false'][data-menu-level='3'] > .sol-menu-item-link,
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'][data-menu-level='3'] > .sol-menu-item-link {
  background-color: #fff;
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='true'][data-menu-level='2'] > .sol-menu-list ul,
.sol-mobile-menu-container .sol-menu-item[data-is-current='false'][data-menu-level='2'] > .sol-menu-list ul {
  border-bottom: 1px solid rgba(255, 209, 0, 0.4);
  border-left: 2px solid rgba(255, 209, 0, 0.4);
  border-top: 1px solid rgba(255, 209, 0, 0.4);
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='false'][data-is-current='true'] a {
  background-color: rgba(255, 209, 0, 0.2) !important;
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='false'][data-is-current='true'] > .sol-menu-item-link span {
  font-size: 17px;
  font-weight: normal !important;
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='true'] > .sol-menu-item-link span,
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'] > .sol-menu-item-link span {
  font-size: 17px;
  font-weight: 600;
}
.sol-mobile-menu-container .sol-menu-item[data-node-type='sv:folder'] > .sol-menu-button {
  width: 100%;
  border-left: none;
}
.sol-tool-nav {
  max-width: 700px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  position: relative;
}
@media (max-width: 767px) {
  .sol-tool-nav {
    display: flex;
    vertical-align: top;
    margin-top: 20px;
    text-align: center;
  }
}
.sol-tool-nav-item {
  display: inline-block;
}
.sol-tool-nav-item a:hover {
  text-decoration: underline;
}
.sol-tool-nav-item a:focus {
  outline: 2px solid #ff0 !important;
  outline-offset: 2px !important;
  z-index: 10 !important;
}
.sol-tool-nav-item > a,
.sol-tool-nav-item > button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  display: block;
  height: 40px;
  margin-left: 12px;
  margin-right: 5px;
  font-weight: 400;
  color: #35383b;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Fira Sans', sans-serif;
}
.sol-tool-nav-item > a:focus,
.sol-tool-nav-item > button:focus,
[data-whatinput="keyboard"] .sol-tool-nav-item > a:focus,
[data-whatinput="keyboard"] .sol-tool-nav-item > button:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-tool-nav-item > a:focus,
[data-whatinput="initial"] .sol-tool-nav-item > button:focus,
[data-whatinput="mouse"] .sol-tool-nav-item > a:focus,
[data-whatinput="mouse"] .sol-tool-nav-item > button:focus,
[data-whatinput="touch"] .sol-tool-nav-item > a:focus,
[data-whatinput="touch"] .sol-tool-nav-item > button:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
@media (max-width: 991px) {
  .sol-tool-nav-item > a,
  .sol-tool-nav-item > button {
    margin: 0px 9px 0px;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-item > a,
  .sol-tool-nav-item > button {
    margin: 0 9px 9px;
    height: 100%;
  }
}
.sol-tool-nav-item > a i,
.sol-tool-nav-item > button i {
  color: #00866D;
  margin-right: 8px;
  font-size: 18px;
}
.sol-tool-nav-item > a:hover,
.sol-tool-nav-item > button:hover {
  text-decoration: underline;
}
.sol-tool-nav-item > a:hover i,
.sol-tool-nav-item > button:hover i {
  color: #16a88d;
}
.sol-tool-nav-item > a.tool-translate,
.sol-tool-nav-item > button.tool-translate {
  width: 85px;
  color: #fff;
  font-weight: 300;
}
@media (max-width: 1260px) and (min-width: 991px) {
  .sol-tool-nav-item > a.tool-translate,
  .sol-tool-nav-item > button.tool-translate {
    margin-right: 6px;
    margin-left: 3px;
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-item > a.tool-translate,
  .sol-tool-nav-item > button.tool-translate {
    margin-right: -3px;
    font-size: 11px;
    line-height: 45px;
  }
}
.sol-tool-nav-item > a.tool-translate i,
.sol-tool-nav-item > button.tool-translate i {
  color: #fff;
}
@media (max-width: 1260px) and (min-width: 991px) {
  .sol-tool-nav-item > a.tool-translate i,
  .sol-tool-nav-item > button.tool-translate i {
    margin-right: 4px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-item > a.tool-translate i,
  .sol-tool-nav-item > button.tool-translate i {
    transform: translateY(0);
    margin-right: 4px;
    font-size: 11px;
  }
}
.sol-tool-nav-item .sol-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  padding: 0;
}
.sol-tool-nav-item .sol-close:focus,
[data-whatinput="keyboard"] .sol-tool-nav-item .sol-close:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-tool-nav-item .sol-close:focus,
[data-whatinput="mouse"] .sol-tool-nav-item .sol-close:focus,
[data-whatinput="touch"] .sol-tool-nav-item .sol-close:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-tool-nav-item .sol-close i.fa {
  font-size: 11px;
}
@media (max-width: 767px) {
  .sol-tool-nav-item {
    flex: 1 0 auto;
  }
}
.sol-tool-nav-box {
  position: absolute;
  width: 189px;
  margin-top: 10px;
  margin-left: -60px;
  padding: 17px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1;
  cursor: default;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-tool-nav-box {
    margin-left: 0;
    right: 45px;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-box {
    top: 31px;
    left: 50%;
    margin-left: -78px;
    width: 215px;
    padding: 12px;
  }
}
.sol-tool-nav-box__item {
  text-decoration: none;
  color: #00866D;
  margin: 0 5px;
}
.sol-tool-nav-box a.sol-btn-round {
  -webkit-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  color: #006579;
  border: 2px solid #006579;
  border-radius: 50%;
  font-size: 15px;
  padding-top: 1px;
  text-align: center;
}
@media (max-width: 767px) {
  .sol-tool-nav-box a.sol-btn-round {
    width: 40px;
    height: 40px;
    font-size: 21px;
    padding-top: 0;
    line-height: 37px;
  }
}
.sol-tool-nav-box a.sol-btn-round:first-child {
  margin-left: 0;
}
.sol-tool-nav-box a.sol-btn-round:last-child {
  margin-right: 0;
}
.sol-tool-nav-box a.sol-btn-round:hover {
  border-color: #595959;
}
.sol-tool-nav-box-translate {
  padding: 12px 17px 9px;
}
@media (max-width: 991px) {
  .sol-tool-nav {
    width: 100%;
  }
}
.sol-wide-content-wrapper .sol-tool-nav {
  width: 100%;
  max-width: none;
}
.sol-article-top-wrapper .sol-tool-nav-item {
  flex: 0 0 31%;
}
.sol-article-top-wrapper .sol-tool-nav-item:nth-child(2) {
  flex: 1 0 31%;
}
@media (max-width: 767px) {
  .sol-article-wrapper .sol-tool-nav-item {
    flex: 0 0 37%;
  }
  .sol-article-wrapper .sol-tool-nav-item button {
    margin: 0 12px 10px 12px;
  }
}
.sol-article-wrapper button.sol-js-toggle-readspeaker.sol-listen {
  display: block !important;
}
.sv-readspeaker_expandingplayer-portlet {
  position: relative;
}
.sv-readspeaker_expandingplayer-portlet > a {
  display: none;
}
.sv-readspeaker_expandingplayer-portlet div[id^=readspeaker_player].rsexpanded {
  position: absolute;
  left: 0;
  height: auto;
  width: auto !important;
  padding: 17px 17px 22px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0;
  text-align: center;
}
@media (max-width: 991px) {
  .sol-header-top-item > .sv-script-portlet {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .sol-header-top-item {
    flex: 0 0 20%;
  }
}
.sol-header-top-item .sol-tool-nav {
  width: 100%;
  border: none;
  margin: 0;
}
@media (max-width: 991px) {
  .sol-header-top-item .sol-tool-nav {
    display: flex;
  }
}
@media (max-width: 767px) {
  .sol-header-top-item .sol-tool-nav {
    width: 100%;
  }
}
.sol-header-top-item .sol-tool-nav button {
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 14px;
  line-height: 45px;
  font-weight: 300;
  height: 45px;
}
.sol-header-top-item .sol-tool-nav button.sol-listen {
  margin-right: 16px;
}
@media (min-width: 991px) and (max-width: 1260px) {
  .sol-header-top-item .sol-tool-nav button.sol-listen {
    margin-right: 6px;
    margin-left: 3px;
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  .sol-header-top-item .sol-tool-nav button.sol-listen {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sol-header-top-item .sol-tool-nav button.sol-listen {
    margin-right: -3px;
    font-size: 11px;
  }
}
.sol-header-top-item .sol-tool-nav button.sol-listen:focus {
  outline: 2px solid yellow;
  outline-offset: 2px;
  z-index: 10;
}
.sol-header-top-item .sol-tool-nav button.sol-translate {
  margin-left: 16px;
}
@media (min-width: 991px) and (max-width: 1260px) {
  .sol-header-top-item .sol-tool-nav button.sol-translate {
    margin-left: 6px;
    margin-right: 8px;
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  .sol-header-top-item .sol-tool-nav button.sol-translate {
    width: 100%;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .sol-header-top-item .sol-tool-nav button.sol-translate {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 11px;
  }
}
.sol-header-top-item .sol-tool-nav button.sol-translate:focus {
  outline: 2px solid yellow;
  outline-offset: 2px;
  z-index: 10;
}
.sol-header-top-item .sol-tool-nav button i {
  transform: translateY(2px);
  font-size: 19px;
  margin-right: 8px;
  color: #fff;
}
@media (min-width: 991px) and (max-width: 1260px) {
  .sol-header-top-item .sol-tool-nav button i {
    margin-right: 4px;
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .sol-header-top-item .sol-tool-nav button i {
    transform: translateY(0px);
    margin-right: 4px;
    font-size: 11px;
  }
}
.sol-header-top-item .sol-tool-nav-item {
  margin-right: -30px;
  margin-left: 2px;
}
@media (max-width: 991px) {
  .sol-header-top-item .sol-tool-nav-item {
    flex: 1 0 auto;
    margin-right: 0px;
    margin-left: 0px;
  }
}
.sol-header-top-item .sol-tool-nav-item:first-of-type {
  margin-left: -14px;
  margin-right: 2px;
}
@media (max-width: 991px) {
  .sol-header-top-item .sol-tool-nav-item:first-of-type {
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media (max-width: 479px) {
  .sol-header-top-item .sol-tool-nav-item:first-of-type {
    margin-left: -5px;
  }
}
.sol-tools-fullwidth .sol-tool-nav {
  width: 100%;
}
.sol-top-nav {
  display: inline-block;
  vertical-align: top;
}
.sol-top-nav:after,
.sol-top-nav:before {
  content: "";
  display: table;
}
.sol-top-nav:after {
  clear: both;
}
.sol-top-nav-item {
  white-space: nowrap;
  float: left;
  padding: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  color: #35383b;
  font-size: 16px;
  line-height: 1.2em;
  font-weight: 400;
  cursor: pointer;
  margin-left: auto;
  margin-right: 25px;
  vertical-align: top;
  text-decoration: none;
  max-width: 215px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-top-nav-item:focus,
[data-whatinput="keyboard"] .sol-top-nav-item:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-top-nav-item:focus,
[data-whatinput="mouse"] .sol-top-nav-item:focus,
[data-whatinput="touch"] .sol-top-nav-item:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-top-nav-item:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 0;
}
.sol-top-nav-item:hover {
  border-bottom: 3px solid #00866D;
  height: 54px !important;
  top: 2px;
  position: relative;
}
.sol-top-nav-item.sol-active {
  border-bottom: 3px solid #00866D;
}
.sol-top-search-field {
  position: relative;
}
.sol-top-search-field input[type=search],
.sol-top-search-field input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  -webkit-transition-property: border-color;
  transition-property: border-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  width: 100%;
  height: 45px;
  padding: 0 11px;
  border: 2px solid rgba(0, 134, 109, 0.4);
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 300;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.sol-top-search-field input[type=search]:focus,
.sol-top-search-field input[type=text]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
}
@media (max-width: 767px) {
  .sol-top-search-field input[type=search],
  .sol-top-search-field input[type=text] {
    height: 35px;
  }
}
.sol-top-search-field input[type=search]:focus,
.sol-top-search-field input[type=text]:focus {
  border-color: #e7e7e7;
}
.sol-top-search-field input[type=search]::-ms-clear,
.sol-top-search-field input[type=text]::-ms-clear,
.sol-top-search-field input[type=search]::-ms-reveal,
.sol-top-search-field input[type=text]::-ms-reveal {
  display: none;
}
.sol-top-search-field input[type=search]::placeholder,
.sol-top-search-field input[type=text]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #35383b;
}
.sol-top-search-field input[type=search]:-ms-input-placeholder,
.sol-top-search-field input[type=text]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #35383b;
}
.sol-top-search-field input[type=search]::-ms-input-placeholder,
.sol-top-search-field input[type=text]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #35383b;
}
.sol-top-search-field button[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  position: absolute;
  top: -9px;
  right: -10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #00866D;
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
}
.sol-top-search-field button[type=submit]:focus,
[data-whatinput="keyboard"] .sol-top-search-field button[type=submit]:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-top-search-field button[type=submit]:focus,
[data-whatinput="mouse"] .sol-top-search-field button[type=submit]:focus,
[data-whatinput="touch"] .sol-top-search-field button[type=submit]:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
@media (max-width: 767px) {
  .sol-top-search-field button[type=submit] {
    width: 50px;
    height: 50px;
    top: -8px;
  }
}
.sol-top-search-field button[type=submit] > i.fa {
  position: relative;
  top: -2px;
}
.sol-top-search-field > div:hover input[type=search],
.sol-top-search-field > div:hover input[type=text] {
  border-color: #e7e7e7;
}
.sol-top-search-field > div:hover button {
  background-color: #595959;
}
.sol-translated-pages {
  line-height: 50px;
}
.sol-translated-pages a {
  font-size: 22px;
  font-weight: 300;
  line-height: 40px;
  color: #fff;
  text-decoration: none;
}
@media (max-width: 767px) {
  .sol-translated-pages a {
    font-size: 18px;
  }
}
.sol-translated-pages a:hover {
  text-decoration: underline;
}
.sol-translated-pages a:focus {
  z-index: 10 !important;
  outline: 2px solid #ff0 !important;
  outline-offset: 2px !important;
}
.sol-translated-pages span.divider {
  display: inline-block;
  width: 1px;
  height: 25px;
  margin: 0 4px;
  vertical-align: text-bottom;
  background-color: #fff;
}
@media (max-width: 767px) {
  .sol-translated-pages span.divider {
    height: 20px;
  }
}
.sol-article-item {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px 20px 20px 230px;
  border-top: 2px solid #00866D;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sol-article-item {
    padding-left: 160px;
  }
}
@media (max-width: 767px) {
  .sol-article-item {
    padding-left: 20px;
    padding-top: 210px;
  }
}
.sol-article-item.sol-no-img {
  padding-left: 60px;
}
@media (max-width: 767px) {
  .sol-article-item.sol-no-img {
    padding-top: 20px;
  }
}
.sol-article-item .sol-article-item-img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 200px;
  background-position: 50% 50%;
  background-size: cover;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sol-article-item .sol-article-item-img {
    width: 130px;
  }
}
@media (max-width: 767px) {
  .sol-article-item .sol-article-item-img {
    width: 100%;
    height: 200px;
  }
}
.sol-article-item .sol-article-item-img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-article-item .sol-article-item-date {
  position: absolute;
  left: -45px;
  top: -45px;
  width: 100px;
  height: 100px;
  padding-top: 47px;
  padding-left: 31px;
  border-radius: 100%;
  background-color: rgba(0, 134, 109, 0.8);
  color: #fff;
  text-align: center;
  z-index: 2;
}
@media (max-width: 767px) {
  .sol-article-item .sol-article-item-date {
    left: -40px;
    top: -40px;
    width: 90px;
    height: 90px;
    padding-top: 40px;
  }
}
.sol-article-item .sol-article-item-date-day {
  display: block;
  font-size: 16px;
  line-height: 11px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .sol-article-item .sol-article-item-date-day {
    font-size: 14px;
    line-height: 7px;
  }
}
.sol-article-item .sol-article-item-date-month {
  font-size: 12px;
  line-height: 22px;
  display: block;
}
.sol-article-item.sol-no-img .sol-article-item-date {
  background-color: #00866D;
}
.sol-article-item .sol-article-item-heading {
  display: block;
  margin-bottom: 3px;
  font-size: 15px;
  font-weight: 800;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-article-item .sol-article-item-event-info {
  margin-top: 10px;
}
.sol-article-item .sol-article-item-event-info:after,
.sol-article-item .sol-article-item-event-info:before {
  content: "";
  display: table;
}
.sol-article-item .sol-article-item-event-info:after {
  clear: both;
}
.sol-article-item .sol-article-item-event-info-item {
  margin-right: 13px;
  font-size: 11px;
  float: left;
}
.sol-article-item .sol-article-item-event-info-item i {
  margin-right: 6px;
  color: #00866D;
}
.sol-article-item:hover .sol-article-item-img:before {
  background-color: rgba(0, 134, 109, 0.4);
}
.sol-article-item:hover .sol-article-item-heading {
  color: #00866D;
  text-decoration: underline;
}
.sol-event-info {
  margin-bottom: 25px;
  margin-top: 25px;
  position: relative;
  font-family: 'Crimson text', serif;
}
.sol-event-info .sol-simple-box-decoration {
  margin-top: 0;
  padding: 16px 16px 16px 67px;
}
@media (max-width: 991px) {
  .sol-event-info .sol-simple-box-decoration {
    padding: 16px;
  }
}
.sol-event-info-field {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 23px;
  font-weight: 400;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .sol-event-info-field {
    font-size: 19px;
  }
}
.sol-event-info-field > span {
  margin-right: 10px;
  font-size: 23px;
  font-weight: 800;
}
@media (max-width: 767px) {
  .sol-event-info-field > span {
    font-size: 19px;
  }
}
.sol-event-info-field > span.event-deviding-comma {
  margin-left: -5px;
}
.sol-event-info i.fas {
  position: absolute;
  left: 18px;
  top: 18px;
  color: #FFD100;
  font-size: 32px;
}
@media (max-width: 991px) {
  .sol-event-info i.fas {
    left: unset;
    right: 18px;
  }
}
.sol-event-info__ticket-info {
  display: inline-block;
  font-size: 18px !important;
  margin-top: 10px;
}
.sol-event-info__ticket-link i {
  margin-right: 5px;
  margin-left: 10px;
}
.sol-event-info-small {
  margin-top: 10px;
  margin-bottom: 10px;
}
.sol-event-info-small:after,
.sol-event-info-small:before {
  content: "";
  display: table;
}
.sol-event-info-small:after {
  clear: both;
}
.sol-event-info-small-item {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #35383b;
  margin: 15px 0 0 0;
}
.sol-event-info-small-item i {
  font-size: 17px;
  margin-right: 6px;
  color: #35383b;
}
.sol-event-item {
  display: block;
  margin: 12px;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-event-item:hover {
  opacity: 0.9;
}
.sol-event-item-content {
  padding: 4px 10px;
  background-color: #00866D;
  color: #fff;
  text-align: left;
}
.sol-event-item-heading {
  display: block;
  font-size: 13px;
  font-weight: 700;
}
.sol-event-item-desc {
  display: block;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 1.4em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sol-event-item-info {
  font-size: 11px;
  background-color: rgba(0, 134, 109, 0.15);
  color: #35383b;
  line-height: 19px;
  padding: 4px 10px;
}
.sol-event-item-info:after,
.sol-event-item-info:before {
  content: "";
  display: table;
}
.sol-event-item-info:after {
  clear: both;
}
.sol-event-item-info-item {
  display: inline-block;
  margin-right: 13px;
  float: left;
}
.sol-event-item-info-item i.fa {
  margin-right: 6px;
  color: #00866D;
}
.sol-event-container h1 {
  margin-bottom: 20px;
}
.sol-article-wrapper {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.sol-article-wrapper .sv-image-portlet {
  position: relative;
  overflow: hidden;
}
.sol-article-wrapper .pagecontent:after,
.sol-article-wrapper .pagecontent:before {
  content: "";
  display: table;
}
.sol-article-wrapper .pagecontent:after {
  clear: both;
}
.sol-article-wrapper.sol-section {
  padding-top: 10px;
}
.sol-article-top-wrapper {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.sol-news-menu .sol-menu-item[data-expanded="false"] .sol-menu-list {
  display: none;
}
.sol-article-date {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #35383b;
  margin: 15px 0 0 0;
}
.sol-blurbs-wrapper .pagecontent {
  display: flex;
  flex-wrap: wrap;
}
.sol-blurbs-wrapper .pagecontent .sv-custom-module {
  flex: 1 0 20%;
  margin: 1%;
}
.sol-breadcrumbs-wrapper {
  display: inherit;
  padding-top: 6px;
  padding-bottom: 6px;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs {
  margin-bottom: 0px;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs:after,
.sol-breadcrumbs-wrapper .sol-breadcrumbs:before {
  content: "";
  display: table;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs:after {
  clear: both;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li {
  float: left;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a,
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > span {
  font-size: 14px;
  font-weight: 500;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a {
  text-decoration: underline;
  color: #35383b;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > a:hover {
  color: #00866D;
  text-decoration: underline;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > span {
  color: #35383b;
}
.sol-breadcrumbs-wrapper .sol-breadcrumbs li > span.sol-divider {
  margin-right: 0;
  margin-left: 7px;
}
@media (max-width: 767px) {
  .sol-breadcrumbs-wrapper .sol-breadcrumbs {
    display: none;
  }
}
/* subpage/page-listing.less */
.sol-page-listing-old {
  background-color: #F8F8F8;
  padding: 20px 20px 15px 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
}
.sol-page-listing-old ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sol-page-listing-old-item {
  margin-bottom: 20px;
}
.sol-page-listing-old-item__link {
  text-decoration: none;
}
.sol-page-listing-old-item__link:hover {
  text-decoration: underline;
}
.sol-page-listing-old-item__name {
  color: #008068;
  margin-bottom: 12px;
  margin-top: 0;
  font-weight: 700;
}
.sol-page-listing-old-item__icon {
  font-size: 17px;
  float: left;
  margin-right: 7px;
  height: 20px;
  width: 20px;
  color: #fff;
  background-color: #00866D;
  border-radius: 50%;
  text-align: center;
  position: relative;
  top: 2px;
  padding-left: 1px;
  padding-top: 0;
  margin-left: -12px;
}
.sol-page-listing-old-child__link {
  display: block;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 400;
  color: #35383b;
  text-decoration: none;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-page-listing-old-child__link:hover {
  text-decoration: underline;
}
.sol-page-listing-old-child__link i.fa-external-link {
  font-size: 11px;
  position: relative;
  top: -1px;
  margin-left: 5px;
}
.sol-page-listing-old-child__icon {
  font-size: 23px;
  float: left;
  margin-right: 8px;
  color: #35383b;
  position: relative;
  top: 3px;
  padding-left: 2px;
}
.sol-page-listing-old i.fa-external-link {
  position: relative;
  top: -5px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 2px;
}
/* subpage/page-listing.less */
.sol-page-listing {
  margin: 30px 0;
  position: relative;
}
.sol-page-listing ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sol-page-listing-item {
  padding: 15px 25px !important;
  background-color: #F8F8F8;
  margin-bottom: 10px;
}
.sol-page-listing-item__link {
  text-decoration: none;
  display: block;
  /*       .fa-external-link{
        display: none;
      } */
}
.sol-page-listing-item__link:focus h2:focus,
[data-whatinput="keyboard"] .sol-page-listing-item__link:focus h2:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-page-listing-item__link:focus h2:focus,
[data-whatinput="mouse"] .sol-page-listing-item__link:focus h2:focus,
[data-whatinput="touch"] .sol-page-listing-item__link:focus h2:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-page-listing-item__link:hover {
  text-decoration: underline;
  text-decoration-color: #006579;
}
.sol-page-listing-item__name {
  color: #008068;
  margin-bottom: 8px;
  margin-top: 0;
  font-size: 19px;
  font-weight: 600;
  position: relative;
}
.sol-page-listing-item__name:last-of-type {
  margin-bottom: 0;
}
.sol-page-listing-item__icon {
  font-size: 23px;
  font-weight: 400;
  margin-right: 8px;
  color: #00866D;
  position: relative;
}
.sol-page-listing-item__external-icon {
  font-size: 19px;
  color: #35383b;
  margin-left: 12px;
}
.sol-page-listing-child__link {
  display: block;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 400;
  color: #35383b;
  text-decoration: none;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-page-listing-child__link:hover {
  text-decoration: underline;
}
.sol-page-listing-child__link:first-of-type {
  margin-top: 8px;
}
.sol-page-listing-child__link:last-of-type {
  margin-bottom: 0;
}
.sol-page-listing-child__icon {
  font-size: 21px;
  margin-right: 6px;
  color: #35383b;
  position: relative;
}
.sol-page-listing-child__external-icon {
  font-size: 16px;
  color: #35383b;
  margin-left: 12px;
}
.sol-page-listing-child__name {
  font-size: 17px;
  position: relative;
}
.sol-page-listing-child__name:last-of-type {
  margin-bottom: 0;
}
.sol-print {
  font-family: 'Fira Sans', sans-serif;
  text-decoration: underline;
  color: #35383b;
  font-size: 16px;
  font-weight: 400;
  float: right;
}
.sol-print > span {
  cursor: pointer;
}
.sol-print > span:hover {
  text-decoration: underline;
}
.sol-print i.fal {
  margin-right: 7px;
}
.sol-publish-info {
  background-color: #f5f5f5;
  border: none;
  padding-top: 14px;
  padding-bottom: 7px;
  font-size: 16px;
  font-weight: 400;
}
.sol-publish-info span {
  font-family: 'Fira Sans', sans-serif !important;
  font-weight: 300!important;
}
.sol-publish-info strong {
  font-family: 'Fira Sans', sans-serif;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  .sol-publish-info strong {
    font-size: 14px;
  }
}
@media (max-width: 479px) {
  .sol-publish-info strong {
    display: block;
  }
}
.sol-contact,
.sol-related {
  background-color: rgba(0, 134, 109, 0.25);
  border: 1px solid rgba(0, 134, 109, 0.4);
  font-size: 18px;
  margin-top: 5px;
  padding-bottom: 8px;
  padding-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .sol-contact,
  .sol-related {
    font-size: 17px;
  }
}
.sol-related-item {
  margin-bottom: 5px;
  text-decoration: none;
  line-height: 23px;
  display: inline-block;
}
.sol-related-item-name {
  text-decoration: underline;
  color: #006579;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-related-item-size {
  display: none;
  color: #006579;
  font-size: 12px;
  margin-left: 10px;
}
.sol-related-item i.fa {
  width: 17px;
  height: 17px;
  margin-right: 5px;
  border-radius: 50%;
  color: #35383b;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  position: relative;
  top: 1px;
}
.sol-related-item i.fa.sol-link-icon {
  font-size: 19px;
}
.sol-related-item:hover .sol-related-item-name {
  color: #35383b;
}
.sol-related a:hover {
  text-decoration: underline;
}
div .sol-related-item .sol-related-item-name {
  text-decoration: none;
  color: #35383b;
}
.sol-contact-item h3 {
  font-family: 'Fira Sans', sans-serif;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 700;
}
.sol-contact-item h4 {
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  margin-top: 8px;
  display: block;
  margin-bottom: 1px;
}
.sol-contact-item h5 {
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  margin-top: 8px;
  display: block;
  margin-bottom: 1px;
  font-weight: 700;
}
.sol-contact-item p {
  font-family: 'Fira Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 1.4;
}
.sol-contact-item .postal-address p:nth-child(2) {
  float: right;
}
.sol-contact-item .postal-address span .font-normal {
  margin-right: -60px;
  margin-left: 2px;
  margin-top: -21px;
  padding-left: 71px;
}
@media (max-width: 991px) {
  .sol-contact-item .postal-address span .font-normal {
    padding-left: 71px;
    margin-top: -21px;
  }
}
.sol-contact-item .visit-address {
  display: inline-block;
}
.sol-contact-item .visit-address span .font-normal {
  float: right;
  margin-right: -60px;
  margin-left: 2px;
  margin-top: -21px;
  padding-left: 90px;
}
@media (max-width: 991px) {
  .sol-contact-item .visit-address span .font-normal {
    padding-left: 87px;
    margin-top: -21px;
  }
}
@media (max-width: 991px) {
  .sol-contact-item p.font-normal {
    font-size: 15px !important;
  }
}
@media (max-width: 767px) {
  .sol-contact-item p.font-normal {
    font-size: 15px !important;
  }
}
@media (max-width: 991px) {
  .sol-contact-heading,
  .sol-related-heading {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  .sol-contact-item p,
  .sol-contact-item p.font-normal {
    font-size: 17px !important;
  }
}
.sv-text-portlet .sv-text-portlet-content ul.font-normal li,
.sv-text-portlet .sv-text-portlet-content ul.font-description li {
  margin-left: 20px;
}
.sv-text-portlet .sv-text-portlet-content ol {
  margin-left: 20px;
}
.sv-text-portlet .sv-text-portlet-content ul.sol-bullet-list li {
  margin-left: 0px !important;
}
.sol-menu-container {
  font-size: 15px;
  overflow: visible;
  border: 1px solid #E1E1E2;
  border-top: 0;
}
.sol-menu-container .sol-menu-list {
  display: none;
  width: 100%;
}
.sol-menu-container .sol-menu-list-expanded-init {
  display: block;
}
.sol-menu-container .sol-menu-item {
  position: relative;
}
.sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link {
  position: relative;
  display: block;
  padding: 8px 34px 8px 20px;
  border-top: 1px solid #e7e7e7;
  color: #35383b;
  text-decoration: none;
  -webkit-transition-property: color, background-color, border;
  transition-property: color, background-color, border;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput="keyboard"] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput="mouse"] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput="touch"] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-menu-container .sol-menu-item[data-menu-level="2"] .sol-menu-item-link {
  padding-left: 30px;
}
.sol-menu-container .sol-menu-item[data-menu-level="3"] .sol-menu-item-link {
  padding-left: 40px;
  border-top-color: rgba(255, 209, 0, 0.4) !important;
  border-bottom-color: rgba(255, 209, 0, 0.4) !important;
}
.sol-menu-container .sol-menu-item[data-menu-level="3"] .sol-menu-button {
  height: 30px;
}
.sol-menu-container .sol-menu-item[data-menu-level="4"] .sol-menu-item-link {
  padding-left: 45px;
}
.sol-menu-container .sol-menu-item[data-menu-level="5"] .sol-menu-item-link {
  padding-left: 55px;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-item-link:hover {
  color: #35383b;
  background-color: #F8F8F8;
  border-top-color: #FFD100;
  font-weight: 400;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] i.fal {
  color: #35383b;
  transform: rotate(90deg);
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="2"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="2"] > .sol-menu-item-link:hover {
  color: #35383b;
  background-color: #F8F8F8;
  border-top-color: #FFD100;
  font-weight: 400;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="2"] i.fal {
  color: #35383b;
  transform: rotate(90deg);
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="2"] > .sol-menu-list > ul {
  border-left: 2px solid #FFD100;
}
.sol-menu-container .sol-menu-item[data-expanded="true"] > .sol-menu-button i.fal {
  transform: rotate(90deg);
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-is-current="true"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-is-current="true"] > .sol-menu-item-link:hover {
  color: #35383b;
  background-color: rgba(255, 209, 0, 0.2) !important;
  font-weight: 600 !important;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link i.fal {
  color: #35383b;
  transform: rotate(90deg);
}
.sol-menu-container .sol-menu-item[data-expanded="false"][data-menu-level="2"] i.fal,
.sol-menu-container .sol-menu-item[data-expanded="false"][data-menu-level="3"] i.fal {
  transform: unset;
}
.sol-menu-container .sol-menu-item[data-has-children="false"] > .sol-menu-item-link {
  padding-right: 5px;
}
.sol-menu-container .sol-menu-item > .sol-menu-item-link:hover {
  color: #006579;
  text-decoration: underline;
  background-color: unset;
}
.sol-menu-container .sol-menu-item .sol-menu-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  right: 0;
  width: 34px;
  text-align: right;
  font-size: 24px;
  top: 0;
  height: 48px;
  padding-right: 20px;
  cursor: pointer;
}
.sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="keyboard"] .sol-menu-container .sol-menu-item .sol-menu-button:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
[data-whatinput="initial"] .sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="mouse"] .sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput="touch"] .sol-menu-container .sol-menu-item .sol-menu-button:focus {
  outline: 2px solid #257cc3;
  outline-offset: 1px;
  z-index: 10;
}
.sol-menu-container .sol-menu-item .sol-menu-button > i.fal {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-menu-container .sol-menu-item[data-node-type="sv:folder"] > .sol-menu-button {
  width: 100%;
  left: 0;
}
.sv-file-portlet .sv-file-portlet-fileicon {
  width: 26px;
}
.sv-file-portlet .sv-file-portlet-fileicon img {
  width: 100%;
}
.sv-file-portlet .sv-file-portlet-file {
  width: auto;
}
.sv-linklist-portlet > div > div:nth-child(3) a,
.sv-linklist-portlet > div > div:nth-child(3) span {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 0;
  margin-right: 2px;
  margin-left: 2px;
  padding: 4px 0 0;
  border-radius: 100%;
  background-color: transparent;
  box-shadow: none;
  color: #35383b;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  border: none;
  text-decoration: none;
  line-height: 22px;
}
.sv-linklist-portlet > div > div:nth-child(3) a:hover,
.sv-linklist-portlet > div > div:nth-child(3) span:hover {
  background-color: #e8f3f8;
}
.sv-linklist-portlet > div > div:nth-child(3) span,
.sv-linklist-portlet > div > div:nth-child(3) span {
  background-color: #00866D;
  color: #fff;
}
.sv-linklist-portlet > div > div:nth-child(3) span strong,
.sv-linklist-portlet > div > div:nth-child(3) span strong {
  font-weight: 300;
}
.sol-subpage-wrapper:after,
.sol-subpage-wrapper:before {
  content: "";
  display: table;
}
.sol-subpage-wrapper:after {
  clear: both;
}
.sol-subpage-wrapper > .sol-left {
  width: 65%;
  max-width: 700px;
  float: left;
}
@media (max-width: 991px) {
  .sol-subpage-wrapper > .sol-left {
    float: none;
    width: 100%;
  }
}
.sol-subpage-wrapper > .sol-right {
  width: 30%;
  float: right;
}
@media (max-width: 991px) {
  .sol-subpage-wrapper > .sol-right {
    float: none;
    width: 100%;
  }
}
.sol-subpage-wrapper .pagecontent:after,
.sol-subpage-wrapper .pagecontent:before {
  content: "";
  display: table;
}
.sol-subpage-wrapper .pagecontent:after {
  clear: both;
}
.sol-subpage-wrapper h1 {
  margin-top: 5px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .sol-subpage-wrapper h1 {
    margin-top: 5px;
    margin-bottom: 0px;
  }
}
.sol-section {
  padding-top: 0px;
  padding-bottom: 50px;
}
@media (max-width: 991px) {
  .sol-section {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .sol-section {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 479px) {
  .sol-section {
    padding-top: 0px;
    padding-bottom: 20px;
  }
}
.sol-wide-content-wrapper {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1200px) {
  .sol-listingpage-container {
    margin-top: -90px;
  }
}
@media (max-width: 991px) {
  .sol-listingpage-container {
    margin-top: -20px;
  }
}
@media (max-width: 767px) {
  .sol-listingpage-container.sol-section {
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 25px;
  }
}
.sol-article-wrapper .sv-image-portlet,
.sol-subpage-wrapper .sv-image-portlet,
.sol-article-wrapper .sv-mfpimage-portlet,
.sol-subpage-wrapper .sv-mfpimage-portlet {
  margin-bottom: 20px;
  position: relative;
}
.sol-article-wrapper .sv-image-portlet .sv-portlet-image-caption,
.sol-subpage-wrapper .sv-image-portlet .sv-portlet-image-caption,
.sol-article-wrapper .sv-mfpimage-portlet .sv-portlet-image-caption,
.sol-subpage-wrapper .sv-mfpimage-portlet .sv-portlet-image-caption {
  background-color: #35383b;
  bottom: 0;
  color: #fff;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px !important;
  max-width: 100%;
  padding: 3px 5px;
  position: absolute;
  right: 0;
}
.sol-article-wrapper .sv-linkicon,
.sol-subpage-wrapper .sv-linkicon {
  display: none;
}
.sol-subpage-wrapper p.font-description {
  font-size: 24px;
  font-weight: 300;
}
@media (max-width: 991px) {
  .sol-subpage-wrapper p.font-description {
    font-size: 23px;
  }
}
@media (max-width: 767px) {
  .sol-subpage-wrapper p.font-description {
    font-size: 19px;
  }
}
@media (max-width: 991px) {
  .sol-subpage-wrapper p.font-normal {
    font-size: 19px;
  }
}
.sol-popular-services {
  min-height: 310px;
}
.sol-popular-services .fa-external-link {
  display: none;
}
.sol-popular-services a {
  background-color: #FFF6CC;
  display: block;
  position: relative;
  text-decoration: none;
  padding: 12px 38px 12px 30px;
  border-bottom: 2px solid rgba(89, 89, 89, 0.1);
  color: #35383b;
  font-size: 16px;
  line-height: 20px;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
@media (max-width: 991px) {
  .sol-popular-services a {
    padding: 16px 38px 16px 30px;
    line-height: 22px;
  }
}
.sol-popular-services a:hover {
  color: #00866D;
  text-decoration: underline;
}
.sol-popular-services a i.fal:first-child {
  position: absolute;
  left: 15px;
  font-size: 17px;
  color: #35383b;
  text-align: center;
  margin-top: 0;
}
@media (max-width: 991px) {
  .sol-popular-services a i.fal:first-child {
    font-size: 23px;
    left: 10px;
  }
}
.sol-popular-services a i.fal:last-child {
  position: absolute;
  right: 12px;
  top: 35%;
  font-size: 14px;
  color: #35383b;
  text-align: center;
  margin-top: 0;
}
@media (max-width: 991px) {
  .sol-popular-services a i.fal:last-child {
    right: 16px;
    font-size: 17px;
  }
}
.sol-popular-services__see-all {
  background-color: #FFF6CC;
  width: 100%;
  padding: 10px;
}
.sol-popular-services__see-all a {
  background-color: #fff;
  margin: 0.4em 0;
  padding: 16px 10px 10px 16px;
  height: 50px;
  line-height: 18px;
  border-radius: 300px;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #35383b;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 991px) {
  .sol-popular-services__see-all a {
    font-size: 14px;
    font-weight: 500;
    width: fit-content;
    margin: 0;
  }
}
@media (max-width: 991px) {
  .sol-popular-services {
    background-color: #FFF6CC;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
  }
}
.sol-popular-pages {
  background-color: rgba(255, 255, 255, 0.96);
  padding: 10px 35px 5px;
  max-height: 50px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sol-popular-pages:after,
.sol-popular-pages:before {
  content: "";
  display: table;
}
.sol-popular-pages:after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-popular-pages {
    background-color: #006579;
  }
}
.sol-popular-pages > a,
.sol-popular-pages > div {
  color: #35383b;
  float: left;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .sol-popular-pages > a,
  .sol-popular-pages > div {
    font-size: 13px;
  }
}
.sol-popular-pages > div {
  display: inline-block;
  margin-right: 10px;
  font-weight: 600;
  font-size: 17px;
}
.sol-popular-pages > a {
  font-size: 16px;
  opacity: 0.8;
  margin-right: 15px;
  text-decoration: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-popular-pages > a:hover {
  opacity: 1;
  text-decoration: underline;
}
.sol-popular-pages > a i.fal {
  opacity: 1;
  font-weight: 500;
  line-height: 15px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  text-align: center;
}
@media (max-width: 991px) {
  .sol-popular-pages {
    display: none;
  }
}
.sol-startpage-service-messages {
  border-top-left-radius: 0;
  background-color: #00866d;
  border-left: 4px solid #fff;
  display: flex;
  padding: 6px 34px;
  line-height: 24px;
  border-top-right-radius: 4px;
}
.sol-startpage-service-messages h2 {
  color: #fff;
  font-weight: 600 !important;
  font-size: 19px;
  margin: 0 !important;
  line-height: 30px;
}
.sol-startpage-service-messages i.fal {
  color: #fff;
  font-weight: lighter;
  font-size: 17px;
  margin-right: 8px;
}
.sol-service-messages ul li {
  min-height: 70px;
}
.sol-service-messages .sol-service-message-item {
  display: flex;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-decoration: none;
  overflow: hidden;
  margin-bottom: 0px !important;
  height: 100%;
}
.sol-service-messages .sol-service-message-item:hover .sol-service-message-item__header {
  text-decoration: underline;
}
.sol-service-messages .sol-service-message-item__date,
.sol-service-messages .sol-service-message-item__header {
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  font-weight: 300;
  padding: 12px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sol-service-messages .sol-service-message-item__date {
  background-color: rgba(255, 209, 0, 0.5);
  font-weight: 400;
  font-size: 15px;
  line-height: 1.3;
  min-width: 124px;
  width: 124px;
  text-align: center;
}
.sol-service-messages .sol-service-message-item__header {
  font-size: 17px;
  line-height: 1.3;
}
.sol-startpage-content-bottom .sol-left,
.sol-startpage-content-bottom .sol-right {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sol-startpage-content-bottom .sol-left .sol-startpage-articles-header,
.sol-startpage-content-bottom .sol-right .sol-startpage-articles-header {
  display: flex;
  background-color: #00866D;
  padding: 6px 34px;
  line-height: 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.sol-startpage-content-bottom .sol-left .sol-startpage-articles-header i.fal,
.sol-startpage-content-bottom .sol-right .sol-startpage-articles-header i.fal {
  color: #fff;
  font-weight: lighter;
  font-size: 17px;
  margin-right: 8px;
}
.sol-startpage-content-bottom .sol-left .sol-startpage-articles-header h2,
.sol-startpage-content-bottom .sol-right .sol-startpage-articles-header h2 {
  color: #fff;
  font-weight: 600 !important;
  font-size: 19px;
  margin: 0 !important;
  line-height: 30px;
}
.sol-startpage-content-bottom .sol-left .sol-news-container,
.sol-startpage-content-bottom .sol-right .sol-news-container {
  margin-top: 0;
  margin-bottom: 0;
  background-color: #F8F8F8;
  padding: 10px 34px;
}
.sol-startpage-content-bottom .sol-left .sol-news-container li:last-child,
.sol-startpage-content-bottom .sol-right .sol-news-container li:last-child {
  border: none;
}
.sol-startpage-content-bottom .sol-left .sol-lightgray-background,
.sol-startpage-content-bottom .sol-right .sol-lightgray-background {
  padding: 30px 30px;
  margin-bottom: 30px;
  border-top: 2px solid rgba(89, 89, 89, 0.1);
}
.sol-startpage-content-bottom .sol-left .sol-startpage-articles-header {
  border-top-right-radius: 0;
}
.sol-startpage-content-bottom .sol-right .sol-startpage-articles-header {
  border-top-left-radius: 0;
  background-color: #00866D;
  border-left: 4px solid #fff;
}
.sol-startpage-content-bottom .sol-startpage-event {
  padding: 26px 35px;
}
.sol-startpage-content-bottom .sol-startpage-event .sv-image-portlet {
  margin-bottom: 20px;
}
.sol-startpage-content-bottom .sol-startpage-event h2 {
  margin-top: 0;
}
.sol-startpage-content-bottom .sol-startpage-event h2 a {
  color: #00866D;
}
.sol-startpage-content-bottom .sol-startpage-event:hover h2 a {
  color: #00866D;
  text-decoration: underline;
}
.sol-startpage-content-bottom .sol-startpage-event__info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.sol-startpage-content-bottom .sol-startpage-event__info p {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px !important;
  font-weight: 500;
  margin: 0 auto 0 6px;
  white-space: nowrap;
  margin-bottom: 20px;
}
.sol-startpage-content-bottom .sol-startpage-event__info p:last-child {
  white-space: normal;
  margin-bottom: 0;
}
.sol-startpage-content-bottom .sol-startpage-event__info p i.fal {
  color: #00866D;
  font-size: 15px;
  font-weight: 300;
  vertical-align: top;
  margin-top: 2px;
  margin-left: 25px;
  margin-right: 5px;
}
.sol-startpage-content-bottom .sol-startpage-event__info p i.fal:first-child {
  margin-left: 0;
}
.sol-startpage-content-bottom .sol-startpage-event h2 {
  font-family: 'Fira Sans', sans-serif;
  color: #00866D;
  font-size: 19px;
  font-weight: 600;
}
.sol-startpage-content-bottom .sol-startpage-event p {
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  font-size: 18px;
  font-weight: 300;
}
.sol-startpage-content-bottom .sol-startpage-available-positions {
  margin-bottom: 0;
  padding: 26px 35px;
}
.sol-startpage-content-bottom .sol-startpage-available-positions h2 {
  font-family: 'Fira Sans', sans-serif;
  color: #00866D;
  font-size: 19px;
  font-weight: 600;
  margin-top: 0;
}
.sol-startpage-content-bottom .sol-startpage-available-positions h2 a {
  color: #00866D;
}
.sol-startpage-content-bottom .sol-startpage-available-positions:hover h2 a {
  color: #00866D;
  text-decoration: underline;
}
.sol-startpage-content-bottom .sol-startpage-available-positions:hover p {
  text-decoration: underline;
  color: #006579;
}
.sol-startpage-content-bottom .sol-startpage-available-positions p {
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  font-size: 18px;
  font-weight: 300;
}
/* stylelint-disable media-feature-range-notation */
.sol-startpage-events {
  width: 100%;
  max-width: 1260px;
}
.sol-startpage-events__item {
  width: 100%;
}
@media (max-width: 1170px) {
  .sol-startpage-events__item {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sol-startpage-events__item {
    width: 100%;
  }
}
.sol-startpage-events__item-content {
  width: auto;
  max-width: 454px;
  height: auto;
  margin: auto;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
}
@media (max-width: 991px) {
  .sol-startpage-events__item-content {
    height: auto;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .sol-startpage-events__item-content {
    height: auto;
    margin: 0;
  }
}
.sol-startpage-events__item-content a:focus h2 {
  z-index: 10;
  outline: 2px solid #257cc3;
}
.sol-startpage-events__item--text {
  padding: 18px 18px 24px;
}
.sol-startpage-events__item a {
  text-decoration: none;
}
.sol-startpage-events__item--image-container {
  text-align: center;
  background: #fff;
}
.sol-startpage-events__item--image {
  max-width: 100%;
  height: auto;
  max-height: 290px;
}
.sol-startpage-events__item--no-image {
  width: auto;
  height: 250px;
  background-color: #f8f8f8;
}
.sol-startpage-events__item--header {
  display: block;
  margin-top: 0;
  margin-bottom: 3px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #00866D;
}
.sol-startpage-events__item--preamble {
  margin-bottom: 6px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.4em;
}
.sol-startpage-events__item--info span {
  display: inline-block;
  width: auto;
  margin-right: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4em;
}
@media (max-width: 767px) {
  .sol-startpage-events__item--info span {
    max-width: none;
  }
}
@media (max-width: 767px) {
  .sol-startpage-events__item--info span:first-of-type {
    max-width: none;
  }
}
.sol-startpage-events__item--info span:last-of-type {
  max-width: 100%;
}
.sol-startpage-events__item--info i {
  margin-right: 5px;
  font-size: 17px;
  font-weight: 300;
  color: #00866D;
  /*         &.fal.fa-th-list {
          font-weight: 600;
        } */
}
.sol-startpage-events__item--info ul {
  display: inline-block;
  max-width: 90%;
  vertical-align: top;
  list-style: none;
}
.sol-startpage-events__item--info ul li {
  display: inline-block;
}
.sol-startpage-events__item--info ul li span {
  margin-right: 5px;
}
.sol-top-search-start {
  padding: 30px 37px 30px 37px !important;
}
@media (max-width: 767px) {
  .sol-top-search-start {
    padding: 15px 20px !important;
  }
}
.sol-top-search-start:hover button[type="submit"] {
  background-color: #f9eaa4;
}
.sol-top-search-start button[type="submit"] {
  top: -7px;
  background-color: #FFE366;
  color: #35383b;
}
.sol-top-search-start button[type="submit"] i {
  font-size: 25px;
}
.sol-top-search-start input[type="search"] {
  border: none;
}
/* stylelint-disable media-feature-range-notation */
.sol-top-contact {
  display: flex;
  padding: 62px 15px;
  background-color: #00866D;
  border-radius: 4px;
}
@media (min-width: 1150px) and (max-width: 1200px) {
  .sol-top-contact {
    padding: 54px 15px;
  }
}
@media (max-width: 991px) {
  .sol-top-contact {
    margin-bottom: 10px;
  }
}
@media (max-width: 820px) {
  .sol-top-contact {
    padding: 15px 10px;
  }
  .sol-top-contact__inner-left,
  .sol-top-contact__inner-right {
    width: 50%;
    padding: 0 10px;
  }
}
@media (max-width: 767px) {
  .sol-top-contact {
    flex-wrap: wrap;
  }
  .sol-top-contact__inner-left,
  .sol-top-contact__inner-right {
    flex: 0 0 100%;
  }
  .sol-top-contact__inner-right {
    padding: 0 !important;
  }
  .sol-top-contact__inner-right a {
    font-size: 14px;
  }
}
.sol-top-contact__inner-left {
  max-width: 49%;
  padding: 0 1em;
  border-right: 1px dotted rgba(255, 255, 255, 0.25);
}
@media (max-width: 991px) {
  .sol-top-contact__inner-left {
    width: 100%;
    max-width: none;
    margin-bottom: 40px;
    border: none;
  }
}
@media (max-width: 767px) {
  .sol-top-contact__inner-left {
    margin-bottom: 10px;
  }
}
.sol-top-contact__inner-left--header {
  margin-bottom: 0.5em;
  font-family: 'Fira Sans', sans-serif;
  font-size: 23px;
  font-weight: 600 !important;
  color: #fff;
}
@media (max-width: 767px) {
  .sol-top-contact__inner-left--header {
    font-size: 18px !important;
  }
}
.sol-top-contact__inner-left--info {
  display: flex;
  flex-direction: column;
}
.sol-top-contact__inner-left--info a {
  color: #fff;
  text-decoration: none;
}
.sol-top-contact__inner-left--info a:hover {
  text-decoration: underline;
}
.sol-top-contact__inner-left--info p {
  margin: 0.5em 0;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 200;
  color: #fff;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  .sol-top-contact__inner-left--info p {
    font-size: 17px !important;
  }
}
.sol-top-contact__inner-left--info p i {
  margin-right: 0.8em;
  font-size: 18px;
  color: #fff;
}
.sol-top-contact__inner-left--info p a {
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 200;
  color: #fff;
  text-decoration: none;
}
.sol-top-contact__inner-left--info p a:hover {
  text-decoration: underline;
}
.sol-top-contact__inner-left--info p a:focus {
  z-index: 10;
  outline: 2px solid yellow;
  outline-offset: 2px;
}
@media (max-width: 767px) {
  .sol-top-contact__inner-left--info p a {
    font-size: 17px !important;
  }
}
.sol-top-contact__inner-right {
  max-width: 49%;
  padding: 0 1em;
}
@media (max-width: 1199px) {
  .sol-top-contact__inner-right {
    max-width: none;
  }
}
@media (max-width: 991px) {
  .sol-top-contact__inner-right {
    width: 100%;
    max-width: none;
  }
}
.sol-top-contact__inner-right--wrapper {
  display: flex;
  flex-direction: column;
}
.sol-top-contact__inner-right--item {
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0 15px 0 40px;
  margin: 0.8em;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 50px;
  color: #35383b;
  text-decoration: none;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 300px;
}
@media (max-width: 991px) {
  .sol-top-contact__inner-right--item {
    margin-left: 0;
  }
}
.sol-top-contact__inner-right--item:hover {
  text-decoration: underline;
}
.sol-top-contact__inner-right--item:focus {
  z-index: 10;
  outline: 2px solid yellow !important;
  outline-offset: 2px;
}
.sol-top-contact__inner-right--item i {
  position: absolute;
  top: 48%;
  left: 17px;
  font-size: 17px;
  line-height: 50px;
  transform: translateY(-50%);
}
.sol-top-contact__inner-right--item i:last-child {
  right: 17px;
  left: unset;
  font-size: 27px;
}
.sol-startpage-top-images {
  position: relative;
}
.sol-startpage-top-images-item {
  height: 450px;
  background-position: 50% 50%;
  background-size: cover;
}
@media (max-width: 767px) {
  .sol-startpage-top-images-item {
    height: 200px;
  }
}
.sol-startpage-top-images .slick-dots {
  list-style: none;
  margin: 0;
  padding: 10px 0 0 0;
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  text-align: center;
}
.sol-startpage-top-images .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  margin: 0 3px 5px;
  -webkit-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.sol-startpage-top-images .slick-dots li button {
  display: none;
}
.sol-startpage-top-images .slick-dots li[aria-hidden=false] {
  background-color: white;
}
.sol-startpage-top-images-content {
  position: absolute;
  width: 855px;
  left: 0px;
  bottom: 45px;
  right: 0px;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .sol-startpage-top-images-content {
    position: absolute;
    bottom: 15px;
    width: auto;
    border-radius: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 991px) {
  .sol-startpage-top-images-content {
    width: auto;
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (min-width: 1600px) {
  .sol-startpage-top-images-content {
    bottom: 85px;
  }
}
@media (min-width: 2100px) {
  .sol-startpage-top-images-content {
    bottom: 120px;
  }
}
.sol-startpage-top-images-content .sol-top-search {
  padding: 30px 35px 30px 15px;
  background-color: rgba(255, 255, 255, 0.9);
}
@media (max-width: 767px) {
  .sol-startpage-top-images-content .sol-top-search {
    background-color: rgba(255, 255, 255, 0.9);
  }
}
.sol-startpage-links {
  display: none;
  background-color: #00866D;
  border-radius: 4px;
}
.sol-startpage-links::before {
  display: none;
}
.sol-startpage-links a {
  flex: 0 0 50%;
  color: #fff;
  font-size: 17px;
  text-decoration: none;
  padding: 20px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  white-space: nowrap;
  overflow: hidden;
}
.sol-startpage-links a:last-child {
  display: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-startpage-links a {
    flex: 0 0 100%;
  }
}
@media (max-width: 767px) {
  .sol-startpage-links a {
    padding: 10px 25px;
    font-size: 15px;
  }
}
@media (max-width: 479px) {
  .sol-startpage-links a {
    flex: 0 0 100%;
    font-size: 17px;
    padding: 15px 25px;
  }
}
.sol-startpage-links a:nth-child(odd) {
  border-right: 1px solid rgba(255, 255, 255, 0.6);
}
@media (max-width: 479px) {
  .sol-startpage-links a:nth-child(odd) {
    border-right: unset;
  }
}
.sol-startpage-links a i.fal {
  font-size: 29px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 15px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .sol-startpage-links a i.fal {
    margin-right: 8px;
    font-size: 17px;
  }
}
@media (max-width: 479px) {
  .sol-startpage-links a i.fal {
    font-size: 27px;
    margin-right: 15px;
  }
}
@media (max-width: 991px) {
  .sol-startpage-links {
    display: flex;
    flex-wrap: wrap;
  }
}
.sol-startpage-wrapper .sol-top {
  position: relative;
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-top {
    margin-bottom: 20px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom > div:first-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:first-child {
  padding-right: 15px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom > div:first-child,
  .sol-startpage-wrapper .sol-startpage-content-top > div:first-child {
    padding-right: 10px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom > div:last-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:last-child {
  padding-left: 15px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom > div:last-child,
  .sol-startpage-wrapper .sol-startpage-content-top > div:last-child {
    padding-left: 10px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom {
  margin-top: 35px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom {
    margin-top: 0;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom .sol-left h2.font-heading-2 {
  margin-top: 0;
  margin-bottom: 10px;
}
.sol-startpage-wrapper .sol-startpage-content-bottom .sol-right {
  padding-top: 33px;
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom .sol-right {
    padding-top: 0;
  }
}
@media (min-width: 1600px) {
  .sol-startpage-wrapper .sol-startpage-main {
    position: relative;
    top: -40px;
  }
}
.sol-searchpage-wrapper {
  position: relative;
}
.sol-searchpage-wrapper .sol-top-searchpage {
  max-width: 700px;
}
.sol-searchpage-wrapper .sol-top-searchpage .sol-top-search-field input[type='search'] {
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 400;
  height: 55px;
  border: solid 1px rgba(89, 89, 89, 0.35);
  box-shadow: none;
  color: #35383b;
}
.sol-searchpage-wrapper .sol-top-searchpage .sol-top-search-field input[type='search']:-moz-placeholder {
  color: #35383b;
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
}
.sol-searchpage-wrapper .sol-top-searchpage .sol-top-search-field input[type='search']::placeholder {
  color: #35383b;
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
}
.sol-searchpage-wrapper .sol-top-searchpage .sol-top-search-field button[type="submit"] {
  width: 70px;
  height: 70px;
  top: -6px;
  right: -17px;
}
.sol-searchpage-wrapper .sol-top-searchpage .sol-top-search-field:hover button[type="submit"] {
  background-color: #09b594;
}
.sol-searchpage-wrapper ol.sv-search-result {
  max-width: 700px;
}
.sol-searchpage-wrapper ol.sv-search-result strong {
  font-weight: 600;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit {
  box-shadow: none;
  border-bottom: 2px solid #f3f3f3;
  padding-left: 0;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit:hover .font-heading-3 {
  text-decoration: underline;
  color: #00866D;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit a {
  color: #00866D;
  font-family: 'Fira Sans', sans-serif;
  font-size: 19px;
  display: block;
  margin-top: 10px;
  margin-bottom: 6px;
  font-weight: 600;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit a:hover {
  text-decoration: underline;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit p {
  color: #35383b;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-top: 5px;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit p a {
  font-size: 13px;
  color: #35383b;
  font-weight: normal;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit p a:hover {
  color: #006579 !important;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit img {
  display: none;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit .sol-search-hit-date {
  display: inline;
  font-size: 14px;
  font-weight: normal;
}
.sol-searchpage-wrapper ol.sv-search-result li.sv-search-hit .sol-search-hit-type {
  display: block;
  font-size: 13px;
  font-weight: normal;
}
.sol-searchpage-wrapper .sv-search-facets {
  min-width: 300px;
  border: 1px solid rgba(89, 89, 89, 0.4);
  border-bottom: none;
  padding: 0 !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.sol-searchpage-wrapper .sv-search-facets > div:nth-child(2) ul,
.sol-searchpage-wrapper .sv-search-facets > div:nth-child(3) ul,
.sol-searchpage-wrapper .sv-search-facets > div:nth-child(4) ul {
  border-top: none;
}
.sol-searchpage-wrapper .sv-search-facets > div:nth-child(2) p,
.sol-searchpage-wrapper .sv-search-facets > div:nth-child(3) p,
.sol-searchpage-wrapper .sv-search-facets > div:nth-child(4) p {
  display: none;
}
@media (max-width: 1200px) {
  .sol-searchpage-wrapper .sv-search-facets {
    border: none;
  }
}
.sol-searchpage-wrapper .sv-search-facets .sv-search-facet-name {
  background-color: rgba(255, 209, 0, 0.5);
  padding: 12px 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
}
@media (max-width: 1200px) {
  .sol-searchpage-wrapper .sv-search-facets .sv-search-facet-name {
    border-left: 4px solid #FFD100;
  }
  .sol-searchpage-wrapper .sv-search-facets .sv-search-facet-name.active::after {
    float: right;
    font-family: 'Font Awesome 5 Pro';
    content: "\f077";
  }
  .sol-searchpage-wrapper .sv-search-facets .sv-search-facet-name::after {
    float: right;
    font-family: 'Font Awesome 5 Pro';
    content: "\f078";
  }
}
@media (max-width: 1200px) {
  .sol-searchpage-wrapper .sv-search-facets ul.sv-search-facet-items {
    display: none;
    border: 1px solid rgba(89, 89, 89, 0.4);
  }
}
.sol-searchpage-wrapper .sv-search-facets ul.sv-search-facet-items li {
  padding: 4px 4px 9px 9px !important;
  margin-bottom: 0 !important;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid rgba(89, 89, 89, 0.4);
}
.sol-searchpage-wrapper .sv-search-facets ul.sv-search-facet-items li.sv-search-facet-item-selected {
  min-height: 45px;
  line-height: 30px;
  padding-top: 5px !important;
}
.sol-searchpage-wrapper .sv-search-facets ul.sv-search-facet-items li.sv-search-facet-item-selected a {
  width: 247px;
  margin-left: -30px;
  padding-left: 30px;
  position: relative;
  z-index: 2;
  text-decoration: underline;
}
.sol-searchpage-wrapper .sv-search-facets ul.sv-search-facet-items li.sv-search-facet-item-selected:before {
  font-family: 'Font Awesome 5 Pro';
  content: '\f00d';
  font-size: 18px;
  position: relative;
  left: 3px;
  top: 3px;
}
.sol-searchpage-wrapper .sv-search-facets ul.sv-search-facet-items li a {
  display: inline-block;
  width: 210px;
  margin-right: 6px;
  text-decoration: none;
}
.sol-searchpage-wrapper .sv-search-facets .sv-search-facet {
  margin: 0 !important;
}
.sol-searchpage-wrapper .sv-search-facets .sv-search-facet-items {
  margin: 0 !important;
}
@media (max-width: 1200px) {
  .sol-searchpage-wrapper .sv-search-facets {
    margin-top: 20px;
    float: none !important;
    width: 300px;
  }
}
.sol-searchpage-wrapper .sv-search-facets p {
  display: none;
}
.sol-searchpage-wrapper .sv-search-facets p:first-child {
  display: block;
}
.sol-searchpage-wrapper #search12_1e4f37c616489dbb2245160eresult > p {
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
}
@media (max-width: 767px) {
  .sol-searchpage-wrapper #search12_1e4f37c616489dbb2245160eresult > p {
    font-size: 19px;
  }
}
.sol-searchpage-wrapper #search12_1e4f37c616489dbb2245160eresult > p a {
  color: #006d58;
}
.sol-searchpage-wrapper #search12_1e4f37c616489dbb2245160eresult > p strong {
  font-weight: 700;
  margin: 0 3px;
}
.sol-searchpage-wrapper .pagination {
  line-height: 15px;
}
.sol-searchpage-wrapper .pagination span {
  border: none !important;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  text-decoration: none;
  padding: 6px 10px !important;
}
.sol-searchpage-wrapper .pagination span.current {
  background-color: rgba(255, 209, 0, 0.6);
  border-radius: 50%;
}
.sol-searchpage-wrapper .pagination span.current.prev,
.sol-searchpage-wrapper .pagination span.current.next {
  background-color: transparent;
  font-size: 16px;
  max-height: unset;
  max-width: unset;
}
.sol-searchpage-wrapper .pagination a {
  border: none !important;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 0 !important;
}
.sol-searchpage-wrapper .pagination a:hover {
  background-color: transparent;
}
.sol-searchpage-wrapper .sol-search-facet-btn {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300 !important;
  position: absolute;
  top: 135px;
  width: 300px;
  height: 48px;
  background: transparent;
  border: none;
  z-index: 1;
}
@media (min-width: 991px) and (max-width: 1200px) {
  .sol-searchpage-wrapper .sol-search-facet-btn {
    top: 158px;
  }
}
@media (min-width: 1200px) {
  .sol-searchpage-wrapper .sol-search-facet-btn {
    display: none;
  }
}
.sol-searchpage-wrapper .sv-facetedsearch-portlet ul {
  margin-top: 5px;
  margin-bottom: 20px;
  padding-left: 0px;
  list-style: none !important;
}
.sol-searchpage-wrapper .sv-facetedsearch-portlet ul li {
  color: #35383b;
  font-family: 'Crimson text', serif;
  position: relative;
  margin-bottom: 7px;
  padding-left: 15px;
  font-size: 23px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
}
@media (max-width: 767px) {
  .sol-searchpage-wrapper .sv-facetedsearch-portlet ul li {
    font-size: 19px;
  }
}
.sol-searchpage-wrapper .sv-facetedsearch-portlet ul li::before {
  content: "•";
  height: 100%;
  color: #FFD100;
  margin-right: 10px;
  font-size: 26px;
}
.sol-searchpage-wrapper .sv-facetedsearch-portlet .sv-search-facet-items {
  list-style: none;
}
.sol-searchpage-wrapper .sv-facetedsearch-portlet .sv-search-facet-items li::before {
  content: '';
}
.sol-searchpage-wrapper p {
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
}
.sol-searchpage-wrapper .sol-hit-path-text {
  font-size: 13px !important;
  color: #35383b !important;
  font-weight: normal !important;
  text-decoration: underline !important;
}
/* stylelint-disable media-feature-range-notation */
/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
.sol-tourism-events {
  width: 100%;
  max-width: 1260px;
}
.sol-tourism-events__list {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  list-style: none;
}
.sol-tourism-events__item {
  flex: 0 0 32%;
  max-width: 32%;
  margin: 20px auto;
}
@media (max-width: 1170px) {
  .sol-tourism-events__item {
    flex: 0 0 49%;
    max-width: 49%;
    margin: 20px auto;
  }
}
@media (max-width: 767px) {
  .sol-tourism-events__item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.sol-tourism-events__item:last-of-type {
  margin: 20px auto;
  margin-left: 0;
}
@media (max-width: 767px) {
  .sol-tourism-events__item:last-of-type {
    margin-right: auto;
    margin-left: auto;
  }
}
.sol-tourism-events__item-content {
  max-width: 406px;
  min-height: 450px;
  margin: auto;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .sol-tourism-events__item-content {
    height: auto;
  }
}
.sol-tourism-events__item--text {
  padding: 18px 18px 24px;
}
.sol-tourism-events__item a {
  text-decoration: none;
}
.sol-tourism-events__item a:hover .sol-tourism-events__item--header {
  text-decoration: underline;
}
.sol-tourism-events__item a:hover .sol-tourism-events__item--preamble {
  color: #006579;
  text-decoration: underline;
}
.sol-tourism-events__item--image-container {
  text-align: center;
  background: #E4E4E4;
}
.sol-tourism-events__item--image {
  max-width: 100%;
  height: 230px;
}
.sol-tourism-events__item--no-image {
  width: auto;
  height: 230px;
  background-color: #f8f8f8;
}
.sol-tourism-events__item--header {
  display: block;
  margin-top: 0;
  margin-bottom: 3px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #00866D;
}
.sol-tourism-events__item--preamble {
  display: block;
  margin-bottom: 6px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.4em;
}
.sol-tourism-events__item--info span {
  display: inline-block;
  width: auto;
  margin-right: 15px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4em;
}
@media (max-width: 767px) {
  .sol-tourism-events__item--info span {
    max-width: none;
  }
}
@media (max-width: 767px) {
  .sol-tourism-events__item--info span:first-of-type {
    max-width: none;
  }
}
.sol-tourism-events__item--info span:last-of-type {
  max-width: 100%;
}
.sol-tourism-events__item--info span.sol-tourism-event-categories {
  width: 100%;
}
.sol-tourism-events__item--info span ul {
  display: inline-block;
  list-style: none;
}
.sol-tourism-events__item--info span ul li {
  display: inline-block;
}
.sol-tourism-events__item--info span ul li span {
  margin-right: 5px;
}
.sol-tourism-events__item--info i {
  margin-right: 5px;
  font-size: 17px;
  font-weight: 300;
  color: #00866D;
}
.sol-tourism-center-btn {
  margin: 20px auto 0;
}
.sol-tourism-center-btn .sol-link-tablet {
  margin: auto;
}
.sol-tourism-content .sol-widget-decoration {
  min-height: 400px;
}
@media (max-width: 1170px) {
  .sol-tourism-content .sol-widget-decoration {
    margin: auto;
  }
}
@media (max-width: 767px) {
  .sol-tourism-content .sol-widget-decoration {
    min-height: 0;
  }
}
.sol-tourism-content .sol-widget-decoration .sv-text-portlet-content p {
  overflow: hidden;
  display: block;
}
.sol-tourism-content .sol-widget-decoration .sol-widget-decoration-img {
  background-size: cover;
}
.sol-tourism-content > div.sv-layout {
  flex: 0 0 32%;
}
@media (max-width: 1170px) {
  .sol-tourism-content > div.sv-layout {
    flex: 0 0 49%;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .sol-tourism-content > div.sv-layout {
    flex: 0 0 100%;
    margin: 0;
  }
}
.sol-tourism-content > div.sv-layout:last-of-type {
  margin-left: 0;
  margin-right: auto;
}
.sol-tourism-content > .sv-custom-module {
  width: 100%;
}
.sol-tourism-content .sv-mfpimage-portlet {
  margin-bottom: 0;
}
.sol-tourism-container {
  padding: 30px 20px;
}
.sol-tourism-container .sol-tourism-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1260px;
  margin: auto;
}
.sol-tourism-container .sol-tourism-content > div.sv-layout {
  margin: 0 auto;
}
@media (max-width: 1170px) {
  .sol-tourism-container .sol-tourism-content > div.sv-layout {
    margin-left: 0;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .sol-tourism-container .sol-tourism-content > div.sv-layout {
    margin: 0 auto;
  }
}
.sol-tourism-container .sol-tourism-content .sol-widget-decoration-img {
  max-width: 406px;
  height: 230px;
}
.sol-tourism-container .sol-widget-decoration {
  max-width: 406px;
}
.sol-tourism-container .sol-widget-decoration .sv-text-portlet {
  padding: 18px 18px 40px 18px;
}
@media (max-width: 767px) {
  .sol-tourism-container .sol-widget-decoration .sv-text-portlet {
    padding: 18px 18px 24px 18px;
  }
}
.sol-tourism-links {
  padding: 40px 0 0 0;
}
.sol-tourism-links .sol-shortcut-container {
  position: relative;
  width: 100%;
  margin-top: 55px;
  padding: 50px 0 40px 0;
}
.sol-tourism-links .sol-shortcut-container > div.sv-grid-main {
  max-width: 950px;
  margin: auto;
}
@media (max-width: 991px) {
  .sol-tourism-links .sol-shortcut-container {
    margin-top: 15px;
  }
}
@media (max-width: 1367px) and (min-width: 768px) {
  .sol-tourism-links .sol-shortcut-container {
    margin-bottom: 0px;
  }
}
@media (max-width: 479px) {
  .sol-tourism-links .sol-shortcut-container {
    padding: 20px 0 25px;
  }
}
@media (min-width: 992px) {
  .sol-tourism-links {
    margin-top: -75px;
  }
}
@media (max-width: 991px) {
  .sol-tourism-links {
    margin-top: -45px;
  }
}
.sol-tourism-template .sol-page__top-text-container {
  margin-top: -80px !important;
  padding-bottom: 0px !important;
}
@media (max-width: 1200px) and (min-width: 991px) {
  .sol-tourism-template .sol-page__top-text-container {
    margin-top: -35px !important;
    margin-bottom: 80px !important;
  }
}
@media (max-width: 991px) {
  .sol-tourism-template .sol-page__top-text-container {
    margin-top: -20px !important;
    margin-bottom: 25px !important;
  }
}
@media (max-width: 767px) {
  .sol-tourism-template .sol-page__top-text-container {
    margin-top: 15px !important;
    margin-bottom: 30px !important;
  }
}
@media (max-width: 991px) {
  .sol-tourism-template .sol-top-image__header--text {
    height: 110px;
    margin-top: -30px;
  }
}
@media (max-width: 767px) {
  .sol-tourism-template .sol-top-image__header--text {
    height: 110px;
    margin-top: -45px;
  }
}
.sol-tourism-boxes {
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  padding: 16px;
  margin: auto;
  margin-bottom: 45px;
}
.sol-pagelist-box {
  z-index: 2;
  flex: 0 0 32.5%;
  margin: 10px auto;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: solid 1px rgba(53, 56, 59, 0.1);
}
@media (max-width: 991px) {
  .sol-pagelist-box {
    flex: 0 0 48%;
    margin-left: 0;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .sol-pagelist-box {
    flex: 0 0 100%;
    margin: 10px auto;
  }
}
.sol-pagelist-box p,
.sol-pagelist-box h2 {
  font-family: 'Fira Sans', sans-serif !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  background-color: rgba(255, 209, 0, 0.6);
  border-left: 4px solid #FFD100;
  padding: 2px 10px;
  height: auto;
  padding-bottom: 5px;
  line-height: 40px;
  margin-bottom: 5px;
  margin-top: 0px !important;
}
.sol-pagelist-box p a,
.sol-pagelist-box h2 a {
  padding-left: 25px;
  text-decoration: none;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #35383b;
  background: url(/webdav/files/System//resources/graphics/icons/angle-right-light.svg) 5px 0px no-repeat;
}
.sol-pagelist-box p a:hover,
.sol-pagelist-box h2 a:hover {
  text-decoration: underline;
}
.sol-pagelist-box__content {
  padding: 10px;
}
.sol-pagelist-box__content p {
  background-color: #fff;
  border-left: none;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px !important;
  font-weight: 300 !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #35383b;
}
.sol-pagelist-box__content p a {
  border: none;
  background: transparent;
  padding: 5px 0px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px !important;
  font-weight: 300 !important;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #35383b;
}
.sol-pagelist-box.sol-pagelist-findfast {
  background-color: rgba(255, 209, 0, 0.3);
}
.sol-pagelist-box.sol-pagelist-findfast p {
  background-color: #FFD100;
}
.sol-pagelist-box.sol-pagelist-findfast p:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 100;
  content: '\f05a';
  display: block;
  float: left;
  margin-right: 10px;
  font-size: 19px;
}
.sol-pagelist-box.sol-pagelist-findfast .sol-pagelist-box__content {
  padding: 10px;
}
.sol-pagelist-box.sol-pagelist-findfast .sol-pagelist-box__content p {
  margin-bottom: 0;
  background-color: transparent;
}
.sol-pagelist-box.sol-pagelist-findfast .sol-pagelist-box__content p:before {
  content: '\f105';
}
@media (max-width: 991px) {
  .sol-pagelist-box.sol-pagelist-findfast {
    flex: 0 0 100%;
  }
}
.sol-a-z-icon {
  font-size: 15px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.sol-letters {
  max-width: 450px;
}
.sol-letters:after,
.sol-letters:before {
  content: "";
  display: table;
}
.sol-letters:after {
  clear: both;
}
.sol-letters a {
  padding: 6px 0;
  text-align: center;
  height: 25px;
  width: 25px;
  color: #fff;
  background-color: #595959;
  box-shadow: none;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
  text-transform: none;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 10px;
  float: left;
}
.sol-letters a.sol-active,
.sol-letters a:hover {
  background-color: #00866D;
  color: #fff;
}
@media (max-width: 991px) {
  .sv-layout.sv-column-6 {
    margin-bottom: 5px;
  }
  .sv-layout.sv-column-6 p.font-normal {
    margin-bottom: 10px;
  }
  .sv-layout.sv-column-6 .sv-mfpimage-portlet {
    margin-bottom: 5px;
  }
  .sv-layout .sv-text-portlet-content .font-heading-2 {
    margin-top: 20px;
    margin-bottom: 8px;
  }
}
.sol-blurb {
  max-width: 480px;
}
.sol-blurb-item {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.sol-blurb-item__link {
  text-decoration: none;
}
.sol-blurb-item__link:hover .sol-blurb-item__header {
  text-decoration: underline;
}
.sol-blurb-item__link:hover .sol-blurb-item__preamble {
  color: #006579;
  text-decoration: underline;
}
.sol-blurb-item__image-container {
  max-height: 540px;
  overflow: hidden;
  margin-bottom: 0;
}
.sol-blurb-item__image-container .sol-blurb-item__image {
  width: 100%;
  height: 100%;
}
.sol-blurb-item__text {
  padding: 18px 18px 24px 18px;
}
.sol-blurb-item__text .sol-blurb-item__header {
  font-family: 'Fira Sans', sans-serif;
  color: #00866D;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 0;
}
.sol-blurb-item__text .sol-blurb-item__preamble {
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
}
.sol-blurb-page {
  margin: 60px auto;
}
.sol-blurb-page .pagecontent {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.sol-blurb-page .pagecontent .sv-mfpimage-portlet {
  max-height: 540px;
  overflow: hidden;
  margin-bottom: 0;
}
.sol-blurb-page .pagecontent .sv-mfpimage-portlet img {
  max-width: none !important;
  max-height: none !important;
}
.sol-blurb-page .pagecontent h1 {
  font-family: 'Fira Sans', sans-serif;
  color: #00866D;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 0;
  padding: 18px 18px 0 18px;
}
.sol-blurb-page .pagecontent p {
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
  padding: 0 18px 24px 18px;
}
.sol-widget-fullwidth .sol-blurb {
  margin-top: 20px;
}
.sol-tourism-container {
  width: 100%;
  position: relative;
}
.sol-tourism-container .sol-blurb {
  margin: 20px 0;
}
.sol-startpage-available-positions .sol-blurb-item {
  box-shadow: none;
}
.eventItem {
  position: relative;
}
.eventDateWrp {
  top: 24px;
  padding: 0 !important;
  background-color: #00866D !important;
}
.eventName h3 {
  margin: 16px 0 0 0;
}
.eventLocation {
  margin-bottom: 16px;
}
.eventForm .btn {
  background-color: #00866D;
}
.eventDetails a.calDownload {
  color: #00866D !important;
}
/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
.sol-error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 764px;
  text-align: center;
  background-color: transparent;
}
@media (max-width: 1199px) {
  .sol-error-page {
    height: 764px;
  }
}
@media (max-width: 991px) {
  .sol-error-page {
    height: 420px;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
@media (max-width: 767px) {
  .sol-error-page {
    height: 330px;
  }
}
@media (max-width: 479px) {
  .sol-error-page {
    height: 270px;
  }
}
.sol-error-page-message {
  max-width: 50%;
  padding: 3em;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
  .sol-error-page-message {
    max-width: 80%;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
}
.sol-error-page-message h1 {
  font-family: 'Fira Sans', sans-serif;
  font-size: 42px;
  font-weight: 600;
  line-height: 40px;
}
@media (max-width: 991px) {
  .sol-error-page-message h1 {
    font-weight: bold;
    color: white;
  }
}
@media (max-width: 767px) {
  .sol-error-page-message h1 {
    font-size: 30px;
    font-weight: bold;
  }
}
@media (max-width: 479px) {
  .sol-error-page-message h1 {
    font-size: 24px;
  }
}
.sol-error-page-text-big {
  font-family: 'Fira Sans', sans-serif;
  font-size: 255px;
  font-weight: 700;
  line-height: 220px;
  color: #fff;
}
@media (max-width: 991px) {
  .sol-error-page-text-small span {
    font-weight: 500;
    color: white;
  }
}
@media (max-width: 767px) {
  .sol-error-page-text-small span {
    display: none;
  }
}
.sol-error-page-btns {
  margin-top: 30px;
}
.sol-error-page-btns .sol-btn {
  min-width: 180px;
  font-weight: 400;
  background-color: #FFE366;
}
.sol-error-page-btns .sol-btn:last-of-type {
  margin-left: 25px;
}
@media (max-width: 991px) {
  .sol-error-page-btns .sol-btn:last-of-type {
    margin-left: 18px;
  }
}
@media (max-width: 767px) {
  .sol-error-page-btns .sol-btn:last-of-type {
    margin-top: 10px;
    margin-left: 0;
  }
}
.sol-contact-card {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding: 20px 20px 10px;
}
.sol-contact-card .sv-text-portlet {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 10px;
  min-height: 51px;
}
.sol-contact-card .sv-text-portlet .sv-portlet-toolbar {
  font-weight: 600;
}
.sol-contact-card h1.font-heading-1 {
  margin-bottom: 20px;
  font-family: 'Crimson text', serif;
  color: #28282A;
  font-size: 23px;
  line-height: 1.32em;
  font-weight: 400;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  /*     text-rendering: optimizeLegibility !important;
    -moz-font-smoothing: unset;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: auto;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden; */
  font-weight: normal;
}
@media (max-width: 767px) {
  .sol-contact-card h1.font-heading-1 {
    font-size: 19px !important;
  }
}
a.sol-contact-card h1.font-heading-1,
.sol-contact-card h1.font-heading-1 a {
  color: #28282A;
  text-decoration: underline;
}
a.sol-contact-card h1.font-heading-1:hover,
.sol-contact-card h1.font-heading-1 a:hover {
  color: #006579;
}
.sol-contact-card h2.font-heading-2 {
  font-family: 'Fira Sans', sans-serif;
  color: #28282A;
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 17px;
  line-height: 1.32em;
  font-weight: 600;
}
@media (max-width: 479px) {
  .sol-contact-card h2.font-heading-2 {
    font-size: 12px;
  }
}
.sol-contact-card h1.font-heading-1,
.sol-contact-card h2.font-heading-2,
.sol-contact-card h3.font-heading-3,
.sol-contact-card h4.font-heading-4,
.sol-contact-card p.font-normal {
  margin: 0;
}
.sol-contact-card .sv-html-portlet {
  display: none;
}
.sol-contact-card .sv-html-portlet p b {
  font-weight: 700;
}
.sv-editing-mode .sol-contact-card .sv-html-portlet {
  display: block;
}
/* stylelint-disable color-function-notation */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable alpha-value-notation */
.sol-eventlist__wrapper {
  position: relative;
  display: flex;
  padding-bottom: 60px;
  margin: 20px 0;
}
@media (max-width: 991px) {
  .sol-eventlist__wrapper {
    display: block;
  }
}
.sol-eventlist__wrapper .sol-filter-mobile {
  position: relative;
  display: none;
  padding-bottom: 90px;
}
@media (max-width: 991px) {
  .sol-eventlist__wrapper .sol-filter-mobile {
    display: block;
  }
}
.sol-eventlist__wrapper .sol-filter-desktop {
  display: block;
}
@media (max-width: 991px) {
  .sol-eventlist__wrapper .sol-filter-desktop {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-eventlist__wrapper {
    display: block;
    height: 100%;
  }
  .sol-eventlist__wrapper .sol-eventlist__list {
    float: left;
    width: 700px;
    height: 100%;
    padding-bottom: 60px;
    margin-right: 20px;
    /* stylelint-disable-next-line media-feature-range-notation */
  }
  .sol-eventlist__wrapper .sol-eventlist__list .clearfix {
    overflow: auto;
  }
  .sol-eventlist__wrapper .sol-eventlist-filters {
    float: left;
    width: 320px;
    height: 100%;
  }
  .sol-eventlist__wrapper .sol-eventlist-filters .clearfix {
    overflow: auto;
  }
  .sol-eventlist__wrapper .sol-eventlist-filters__search--text input {
    flex: 0 0 90% !important;
  }
  .sol-eventlist__wrapper .sol-eventlist__show-more,
  .sol-eventlist__wrapper .sol-eventlist__show-less {
    top: 20px;
    left: 0;
    text-decoration: none;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 991px), (-ms-high-contrast: active) and (max-width: 991px) {
  .sol-eventlist__wrapper .sol-eventlist__list {
    width: 100%;
    height: auto;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 991px) and (max-width: 1200px), (-ms-high-contrast: active) and (min-width: 991px) and (max-width: 1200px) {
  .sol-eventlist__wrapper .sol-eventlist__list {
    width: 520px;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 991px), (-ms-high-contrast: active) and (max-width: 991px) {
  .sol-eventlist__wrapper .sol-eventlist-filters {
    width: 100%;
    height: auto;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 991px), (-ms-high-contrast: active) and (max-width: 991px) {
  .sol-eventlist__wrapper .sol-eventlist__show-more,
  .sol-eventlist__wrapper .sol-eventlist__show-less {
    left: 0;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 991px), (-ms-high-contrast: active) and (max-width: 991px) {
  .sol-eventlist__wrapper .sol-eventlist__item a {
    display: block;
  }
}
.sol-eventlist__list {
  flex: 0 0 65%;
  max-width: 700px;
  padding-left: 0;
  list-style: none;
}
.sol-eventlist__item {
  border-bottom: 2px solid #F8F8F8;
}
.sol-eventlist__item > a {
  display: flex;
}
.sol-eventlist__item > a > div:first-child {
  flex: 0 0 30%;
  padding: 18px 18px 18px 0;
}
@media (max-width: 767px) {
  .sol-eventlist__item > a > div:first-child {
    padding: 0;
  }
}
.sol-eventlist__item > a > div:last-child {
  flex: 1 0 70%;
  padding: 16px 0;
}
@media (max-width: 767px) {
  .sol-eventlist__item > a {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .sol-eventlist__item--image {
    width: 100%;
    height: auto;
    max-height: unset;
  }
}
.sol-eventlist__item--header {
  margin-top: 5px !important;
  font-family: 'Fira Sans', sans-serif;
  font-size: 19px;
  font-weight: 600;
  color: #00866D;
}
.sol-eventlist__item--preamble {
  margin-top: 0 !important;
  margin-bottom: 8px !important;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
}
.sol-eventlist__item--info span {
  display: inline-block;
  margin-right: 14px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .sol-eventlist__item--info span {
    display: block;
  }
}
.sol-eventlist__item--info span i {
  margin-right: 7px;
  font-size: 15px;
  white-space: nowrap;
}
.sol-eventlist__item--info span ul {
  display: inline-block;
  list-style: none;
}
@media (max-width: 767px) {
  .sol-eventlist__item--info span ul {
    display: inline-flex;
  }
}
.sol-eventlist__item--info span ul li {
  display: inline-block;
}
@media (max-width: 767px) {
  .sol-eventlist__item--info span ul li {
    display: inline-flex;
  }
}
.sol-eventlist__item--info-categories {
  width: 100%;
}
.sol-eventlist__item--info-categories span {
  margin-right: 6px;
}
.sol-eventlist__item a {
  text-decoration: none;
}
@media (max-width: 767px) {
  .sol-eventlist__item {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 2px solid #E1E1E2;
  }
}
.sol-eventlist__show-more,
.sol-eventlist__show-less {
  position: relative;
  top: 20px;
  left: 0;
  padding: 12px 30px;
  margin-top: 40px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  text-decoration: none;
  background-color: rgba(255, 209, 0, 0.6);
  border: none;
  border-radius: 100px;
}
.sol-eventlist__show-more:hover,
.sol-eventlist__show-less:hover {
  text-decoration: underline;
  cursor: pointer;
  background-color: #FFD100;
}
.sol-eventlist-filters {
  flex: 0 0 35%;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;
}
.sol-eventlist-filters__create {
  position: relative;
  padding: 7px 36px 7px 24px;
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: 64px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  text-decoration: none;
  background-color: rgba(255, 209, 0, 0.6);
  border: none;
  border-radius: 100px;
}
.sol-eventlist-filters__create:hover {
  text-decoration: underline;
  cursor: pointer;
  background-color: #FFD100;
}
.sol-eventlist-filters__create i {
  position: absolute;
  top: 14px;
  right: 14px;
  margin-left: 8px;
}
@media (max-width: 991px) {
  .sol-eventlist-filters__create {
    position: relative;
    left: 50%;
    margin-left: -123px;
  }
}
.sol-eventlist-filters__search {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}
.sol-eventlist-filters__search--header {
  padding: 5px 20px;
  background-color: rgba(255, 209, 0, 0.6);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.sol-eventlist-filters__search--header i {
  margin-right: 6px;
  font-size: 15px;
  font-weight: 500;
}
.sol-eventlist-filters__search--header span {
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #35383b;
}
@media (max-width: 991px) {
  .sol-eventlist-filters__search--header span::after {
    float: right;
    font-family: 'Font Awesome 5 Pro';
    content: '\f078';
  }
}
@media (max-width: 991px) {
  .sol-eventlist-filters__search--body {
    display: none;
  }
}
.sol-eventlist-filters__search--text {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.sol-eventlist-filters__search--text span {
  display: block;
  flex: 0 0 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}
.sol-eventlist-filters__search--text input {
  flex: 0 0 100%;
  width: 100%;
  padding: 10px 14px;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #35383b;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}
.sol-eventlist-filters__search--text .vdatetime input {
  width: 100%;
  padding: 10px 14px;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #35383b;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}
.sol-eventlist-filters__search--text label {
  font-size: 15px;
  font-weight: 600;
}
.sol-eventlist-filters__search--text .dateContainer {
  flex: 0 0 46%;
}
.sol-eventlist-filters__search--text .dateContainer:first-of-type {
  margin-right: auto;
}
.sol-eventlist-filters__search--text .dateContainer:last-of-type {
  margin-left: auto;
}
.sol-eventlist-filters__search button {
  padding: 12px 30px;
  margin: 0 0 20px 20px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  background-color: rgba(255, 209, 0, 0.6);
  border: none;
  border-radius: 100px;
}
.sol-eventlist-filters__search button:hover {
  text-decoration: underline;
  background-color: #FFD100;
}
.sol-eventlist-filters__categories,
.sol-eventlist-filters__areas {
  margin-top: 30px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}
.sol-eventlist-filters__categories--header,
.sol-eventlist-filters__areas--header {
  padding: 5px 20px;
  background-color: rgba(255, 209, 0, 0.6);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.sol-eventlist-filters__categories--header i,
.sol-eventlist-filters__areas--header i {
  margin-right: 6px;
  font-size: 15px;
  font-weight: 500;
}
.sol-eventlist-filters__categories--header span,
.sol-eventlist-filters__areas--header span {
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #35383b;
}
@media (max-width: 991px) {
  .sol-eventlist-filters__categories--header span::after,
  .sol-eventlist-filters__areas--header span::after {
    float: right;
    font-family: 'Font Awesome 5 Pro';
    content: '\f078';
  }
}
.sol-eventlist-filters__categories--title,
.sol-eventlist-filters__areas--title {
  padding-left: 50px;
  font-size: 16px;
  font-weight: 400;
}
.sol-eventlist-filters__categories--categorylist li,
.sol-eventlist-filters__areas--categorylist li,
.sol-eventlist-filters__categories--arealist li,
.sol-eventlist-filters__areas--arealist li {
  position: relative;
  height: 50px;
  line-height: 47px;
  list-style: none;
  border-bottom: 1px solid #e7e7e7;
}
.sol-eventlist-filters__categories--categorylist li input,
.sol-eventlist-filters__areas--categorylist li input,
.sol-eventlist-filters__categories--arealist li input,
.sol-eventlist-filters__areas--arealist li input {
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 2;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0;
}
.sol-eventlist-filters__categories--categorylist li input:hover ~ .sol-checkbox,
.sol-eventlist-filters__areas--categorylist li input:hover ~ .sol-checkbox,
.sol-eventlist-filters__categories--arealist li input:hover ~ .sol-checkbox,
.sol-eventlist-filters__areas--arealist li input:hover ~ .sol-checkbox {
  background-color: rgba(0, 134, 109, 0.7);
}
.sol-eventlist-filters__categories--categorylist li input:hover ~ .sol-checkbox::after,
.sol-eventlist-filters__areas--categorylist li input:hover ~ .sol-checkbox::after,
.sol-eventlist-filters__categories--arealist li input:hover ~ .sol-checkbox::after,
.sol-eventlist-filters__areas--arealist li input:hover ~ .sol-checkbox::after {
  display: none;
}
.sol-eventlist-filters__categories--categorylist li input:checked ~ .sol-checkbox,
.sol-eventlist-filters__areas--categorylist li input:checked ~ .sol-checkbox,
.sol-eventlist-filters__categories--arealist li input:checked ~ .sol-checkbox,
.sol-eventlist-filters__areas--arealist li input:checked ~ .sol-checkbox {
  background-color: #00866D;
}
.sol-eventlist-filters__categories--categorylist li input:checked ~ .sol-checkbox::after,
.sol-eventlist-filters__areas--categorylist li input:checked ~ .sol-checkbox::after,
.sol-eventlist-filters__categories--arealist li input:checked ~ .sol-checkbox::after,
.sol-eventlist-filters__areas--arealist li input:checked ~ .sol-checkbox::after {
  display: block;
}
.sol-eventlist-filters__categories--categorylist li input::after,
.sol-eventlist-filters__areas--categorylist li input::after,
.sol-eventlist-filters__categories--arealist li input::after,
.sol-eventlist-filters__areas--arealist li input::after {
  position: absolute;
  display: none;
  content: '';
}
.sol-eventlist-filters__categories--categorylist li span.sol-checkbox,
.sol-eventlist-filters__areas--categorylist li span.sol-checkbox,
.sol-eventlist-filters__categories--arealist li span.sol-checkbox,
.sol-eventlist-filters__areas--arealist li span.sol-checkbox {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #00866D;
  border-radius: 50%;
}
.sol-eventlist-filters__categories--categorylist li span.sol-checkbox::after,
.sol-eventlist-filters__areas--categorylist li span.sol-checkbox::after,
.sol-eventlist-filters__categories--arealist li span.sol-checkbox::after,
.sol-eventlist-filters__areas--arealist li span.sol-checkbox::after {
  position: absolute;
  top: 3px;
  left: 6px;
  width: 5px;
  height: 10px;
  content: '';
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
@media (max-width: 991px) {
  .sol-eventlist-filters__categories--categorylist,
  .sol-eventlist-filters__areas--categorylist,
  .sol-eventlist-filters__categories--arealist,
  .sol-eventlist-filters__areas--arealist {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-top-image__header--text {
    display: table;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 1200px), (-ms-high-contrast: active) and (min-width: 1200px) {
  .sol-top-image__header--text {
    margin-top: -15px;
  }
}
@media (max-width: 767px) {
  #lediga-jobb-item .gray-background > div {
    float: none !important;
    width: auto !important;
  }
}
.sol-footer-wave--svg {
  position: inherit;
  right: 0;
  transition: all 0.2s ease;
  transform: none;
}
.sol-event-registration-success {
  position: relative;
  padding: 12px;
  background-color: #cfc;
  border: 2px solid #009900;
  border-radius: 10px;
}
.sol-event-registration-success p {
  font-size: 28px;
}
.sol-event-registration-success::after {
  position: absolute;
  top: 20px;
  right: 25px;
  font-family: FontAwesome;
  font-size: 40px;
  color: #009900;
  content: '\f00c';
}
.sol-event-registration-success a {
  background-color: #ffe366;
}
.sol-event-registration-success a:hover {
  background-color: #ffd100;
}
.sol-event-registration-failure {
  position: relative;
  padding: 12px;
  background-color: #fcc;
  border: 2px solid #990000;
  border-radius: 10px;
}
.sol-event-registration-failure p {
  font-size: 28px;
}
.sol-event-registration-failure::after {
  position: absolute;
  top: 20px;
  right: 25px;
  font-family: 'Font Awesome 5 Pro';
  font-size: 40px;
  font-weight: 900;
  color: #990000;
  content: '\f321';
}
/* NEW UPDATED APP 2020 */
.sol-event-listing {
  margin: 0;
}
.sol-event-listing-result {
  padding: 15px 20px 10px;
  background-color: #f8f8f8;
}
@media (max-width: 767px) {
  .sol-event-listing-result {
    padding: 20px 4px;
  }
}
.sol-event-listing .sol-event-item {
  display: block;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.05);
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item {
    padding: 10px 12px 16px;
    margin: 12px 0;
  }
}
.sol-event-listing .sol-event-item:hover h3,
.sol-event-listing .sol-event-item:hover .sol-event-item__desc {
  text-decoration: underline;
}
.sol-event-listing .sol-event-item__content {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item__content {
    flex-direction: column;
  }
}
.sol-event-listing .sol-event-item__content--left {
  display: flex;
  justify-content: center;
  max-width: 36%;
  max-height: none;
  margin-right: 4%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item__content--left {
    max-width: none;
    margin-right: 0;
  }
}
.sol-event-listing .sol-event-item__content--left .sol-event-item__img {
  display: block;
  min-width: 190px;
  max-width: 300px;
  max-height: none;
  margin: auto;
}
.sol-event-listing .sol-event-item__content--right {
  max-width: 60%;
  max-height: 240px;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item__content--right {
    max-width: none;
    max-height: none;
  }
}
.sol-event-listing .sol-event-item__heading {
  margin-top: 12px;
  margin-bottom: 6px;
}
.sol-event-listing .sol-event-item__heading a {
  font-family: 'Fira Sans', sans-serif;
  font-size: 19px;
  font-weight: 600;
  color: #00866d;
  text-decoration: none;
}
.sol-event-listing .sol-event-item__desc {
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item__desc {
    margin-bottom: 12px;
  }
}
.sol-event-listing .sol-event-item__date > div {
  display: inline-block;
  margin-right: 12px;
  font-size: 15px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item__date > div {
    line-height: 2;
  }
}
.sol-event-listing .sol-event-item__date i {
  margin-right: 2px;
  font-size: 16px;
}
.sol-event-listing .sol-event-item__date .sol-event-item__location {
  display: inline-block;
  width: auto;
  margin-right: 12px;
  font-size: 15px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item__date .sol-event-item__location {
    line-height: 2;
  }
}
.sol-event-listing .sol-event-item__date .sol-event-item__free {
  display: block;
  width: auto;
  margin-top: 5px;
  margin-right: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item__date .sol-event-item__free {
    margin-bottom: 10px;
  }
}
.sol-event-listing .sol-event-item__date .sol-event-item__categories {
  display: flex;
  margin-top: 4px;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-item__date .sol-event-item__categories {
    line-height: 2;
  }
}
.sol-event-listing .sol-event-item__date .sol-event-item__categories i {
  padding-top: 7px;
  font-size: 15px;
  font-weight: 500;
}
.sol-event-listing .sol-event-item__date .sol-event-item__categories ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
}
.sol-event-listing .sol-event-item__date .sol-event-item__categories ul li {
  display: inline-block;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
}
.sol-event-listing .sol-event-item__date .sol-event-item__categories ul li span {
  margin-left: -3px;
}
.sol-event-listing .sol-show-more-btn {
  padding: 8px 25px;
  margin-left: 10px;
  font-size: 18px;
  background-color: rgba(255, 209, 0, 0.6);
  border-radius: 20px;
}
.sol-event-listing .sol-show-more-btn:hover {
  background-color: rgba(255, 209, 0, 0.8);
}
.sol-event-listing .sol-event-filter {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 30px 18px;
  margin: 0 !important;
  background-color: #f8f8f8;
  border: none;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-filter {
    padding: 6px 16px 30px;
  }
}
.sol-event-listing .sol-event-filter__query,
.sol-event-listing .sol-event-filter__date {
  display: flex;
  flex: 1 0 46%;
  flex-wrap: wrap;
  margin: 20px 2%;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-filter__query,
  .sol-event-listing .sol-event-filter__date {
    margin: 10px 0 5px;
  }
}
.sol-event-listing .sol-event-filter__query label,
.sol-event-listing .sol-event-filter__date label {
  flex: 1 0 100%;
  font-size: 18px;
  font-weight: 500;
}
.sol-event-listing .sol-event-filter__query input,
.sol-event-listing .sol-event-filter__date input {
  flex: 1 0 100%;
  max-width: 100%;
  height: 44px;
  padding: 0 12px;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #c3c3c3;
}
.sol-event-listing .sol-event-filter__categories fieldset,
.sol-event-listing .sol-event-filter__areas fieldset {
  display: block;
  margin: 0 !important;
}
.sol-event-listing .sol-event-filter__categories fieldset > div,
.sol-event-listing .sol-event-filter__areas fieldset > div {
  display: inline-block;
  width: 50%;
  padding: 4px 2px 4px 4px !important;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-filter__categories fieldset > div,
  .sol-event-listing .sol-event-filter__areas fieldset > div {
    width: 100%;
  }
}
.sol-event-listing .sol-event-filter__categories fieldset > div label,
.sol-event-listing .sol-event-filter__areas fieldset > div label {
  font-size: 18px;
}
.sol-event-listing .sol-event-filter__categories fieldset > div input,
.sol-event-listing .sol-event-filter__areas fieldset > div input {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #1b927c;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
}
.sol-event-listing .sol-event-filter__categories fieldset > div input:hover,
.sol-event-listing .sol-event-filter__areas fieldset > div input:hover {
  background-color: #b9dbd4;
}
.sol-event-listing .sol-event-filter__categories fieldset > div input:checked,
.sol-event-listing .sol-event-filter__areas fieldset > div input:checked {
  background: #fff url('https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg') no-repeat;
  background-position: center;
  background-size: 16px;
  border: 2px solid #1b927c;
  box-shadow: none;
}
.sol-event-listing .sol-event-filter__categories fieldset > legend,
.sol-event-listing .sol-event-filter__areas fieldset > legend {
  display: none;
}
.sol-event-listing .sol-event-filter fieldset {
  margin: 4% 2%;
  border: none;
}
.sol-event-listing .sol-event-filter .number-of-hits {
  position: relative;
  left: calc(100% - 282px);
  display: block;
  margin-top: 20px;
  margin-left: 16px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-filter .number-of-hits {
    left: 0;
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    margin-left: 0;
  }
}
.sol-event-listing .sol-event-filter .sol-clear-filter {
  position: relative;
  top: 40px;
  left: calc(100% - 430px);
  padding: 8px 24px;
  margin-top: 12px;
  margin-left: 16px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 500;
  text-decoration: underline;
  border: 0;
  border-radius: 100px;
}
@media (max-width: 767px) {
  .sol-event-listing .sol-event-filter .sol-clear-filter {
    top: 0;
    left: 0;
    display: inline-block;
    padding: 0;
    margin-left: 0;
  }
}
.sol-event-listing .sol-event-filter .sol-clear-filter:hover {
  color: #006579;
}
.sol-event-listing .sol-event-filter .sol-clear-filter i {
  margin-left: 4px;
}
.sol-event-listing .sol-event-filter .sol-clear-filter button[type='submit'] {
  visibility: hidden;
}
.sol-event-listing .free-event-container {
  margin: 18px 20px 0;
}
@media (max-width: 767px) {
  .sol-event-listing .free-event-container {
    margin: 15px 0 0;
  }
}
.sol-event-listing .free-event-container label {
  font-size: 18px;
  font-weight: 500;
}
.sol-event-listing #freeEventCheckbox {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #1b927c;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
}
.sol-event-listing #freeEventCheckbox:hover {
  background-color: #b9dbd4;
}
.sol-event-listing #freeEventCheckbox:checked {
  background: #fff url('https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg') no-repeat;
  background-position: center;
  background-size: 16px;
  border: 2px solid #1b927c;
  box-shadow: none;
}
.sol-event-listing .accordion {
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .sol-event-listing .accordion {
    margin-top: 15px;
  }
}
.sol-event-listing .accordion .card {
  display: block;
  float: left;
  width: inherit;
  max-width: 46%;
  margin: 6px 2%;
  background-color: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 0;
  box-shadow: none;
}
.sol-event-listing .accordion .card i::before {
  content: '\f107';
}
.sol-event-listing .accordion .card:hover {
  cursor: pointer;
}
@media (max-width: 767px) {
  .sol-event-listing .accordion .card {
    max-width: 100%;
    margin: 10px 0 5px;
  }
}
.sol-event-listing .accordion .card:nth-of-type(2n) {
  float: right;
}
.sol-event-listing .accordion .card-header {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 12px;
}
.sol-event-listing .accordion .card-header-title {
  flex: 1 0;
}
.sol-event-listing .accordion .card-header-icon {
  flex: 0 1 auto;
}
.sol-event-listing .accordion .card-content {
  padding: 12px 20px 20px;
  margin-top: 0;
  border-top: 1px solid #ccc;
}
.sol-event-listing .accordion .card.card-active i::before {
  content: '\f106';
}
.sol-event-listing .accordion .card.card-active .sol-event-filter__categories,
.sol-event-listing .accordion .card.card-active .sol-event-filter__areas {
  visibility: visible;
  opacity: 1;
  transition-duration: 0s, 0.3s;
  transition-property: visibility, opacity;
}
.sol-event-listing .accordion .card .sol-event-filter__categories,
.sol-event-listing .accordion .card .sol-event-filter__areas {
  visibility: hidden;
  opacity: 0;
  transition-delay: 0s, 0.1s;
  transition-duration: 0.3s, 0s;
  transition-property: opacity, visibility;
}
.sol-event-listing .accordion p {
  display: flex;
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
[data-whatinput='keyboard'] .sol-event-item__heading a:focus {
  z-index: 10;
  display: block;
  outline: 2px solid #257cc3;
  outline-offset: -2px;
}
.sol-eventguide-top {
  width: 100%;
  margin-top: 30px;
}
.sol-eventguide-top .sv-text-portlet-content h2 {
  margin-top: 0;
}
@media (max-width: 767px) {
  .sol-eventguide-top {
    margin-right: auto;
    margin-left: auto;
  }
}
.sol-eventguide-bottom {
  width: 100%;
  margin-top: 0;
}
.sol-eventguide-bottom .sv-text-portlet-content {
  margin: 0 10px;
}
.sol-eventguide-bottom .sv-text-portlet-content h2 {
  margin-top: 0;
}
@media (max-width: 767px) {
  .sol-eventguide-bottom {
    max-width: 100%;
    margin-top: 12px;
  }
}
.sol-eventguide-bottom .sol-tabs {
  margin-top: 15px;
}
.sol-eventguide-bottom .sol-tabs__buttons {
  display: inline-flex;
  width: auto;
  margin-bottom: 4px;
  border: none;
}
.sol-eventguide-bottom .sol-tabs-button {
  display: flex;
  flex: 0 1;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  border: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-eventguide-bottom .sol-tabs-button {
    flex: 0 1 200px;
    max-width: 200px;
    line-height: 1;
  }
}
.sol-eventguide-bottom .sol-tabs-button > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: 60px;
  padding: 0 36px;
  margin: 0 10px 0 0;
  font-weight: 300;
  line-height: initial;
  color: black;
  background-color: #fff6cc;
  border: 1px solid #ccc;
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
@media (max-width: 767px) {
  .sol-eventguide-bottom .sol-tabs-button > span {
    padding: 2px 24px;
  }
}
@media (max-width: 479px) {
  .sol-eventguide-bottom .sol-tabs-button > span {
    height: 55px;
    padding: 0 6px;
    font-size: 0.85em;
  }
}
.sol-eventguide-bottom .sol-tabs-button--active > span {
  color: black;
  background-color: rgba(255, 209, 0, 0.6);
  border-color: rgba(255, 209, 0, 0.6);
}
.sol-eventguide-bottom .sol-tabs__tab {
  padding: 5px 0 15px;
  background-color: #f8f8f8;
  border-top: 3px solid rgba(255, 209, 0, 0.6);
}
.sol-eventguide-bottom .sol-tabs__tab > .sv-custom-module .strex-webapp-form {
  max-width: 800px;
  padding: 30px 40px;
  margin: 30px auto 20px;
  background: #fff;
  border-top: none;
  border-right: 8px solid #f8f8f8;
  border-left: 8px solid #f8f8f8;
  box-shadow: none;
}
@media (max-width: 767px) {
  .sol-eventguide-bottom .sol-tabs__tab > .sv-custom-module .strex-webapp-form {
    padding: 10px 12px;
    margin: 30px auto 20px;
  }
}
.sol-eventguide-bottom .sol-tabs__tab > .sv-custom-module .strex-webapp-form button[type='submit'] {
  margin-top: 15px;
  margin-left: 0;
}
.sol-eventguide-bottom .sol-tabs__tab > .sv-text-portlet {
  padding: 25px 20px 15px;
  margin: 25px 20px 0;
  background-color: #fff;
}
@media (max-width: 767px) {
  .sol-eventguide-bottom .sol-tabs__tab > .sv-text-portlet {
    margin: 16px 10px 0;
    border-left: none;
  }
}
@media (max-width: 767px) {
  .sol-eventguide-bottom .sol-tabs__tab {
    padding: 5px 0 10px;
  }
}
@media (min-width: 1200px) {
  .sol-eventguide .sol-page-with-top-image .sol-page__top-text-container {
    padding-bottom: 15px;
    margin-bottom: 120px;
  }
}
@media (max-width: 1200px) and (min-width: 991px) {
  .sol-eventguide .sol-page-with-top-image .sol-page__top-text-container {
    margin-bottom: 15px;
  }
}
@media (max-width: 991px) {
  .sol-eventguide .sol-page-with-top-image .sol-page__top-text-container {
    padding-bottom: 5px;
  }
}
.sol-eventguide .sv-mfpimage-portlet img,
.sol-eventguide .sv-image-portlet img {
  max-width: none !important;
  max-height: 420px !important;
  object-fit: cover;
}
@media (max-width: 767px) {
  .sol-eventguide .sv-mfpimage-portlet img,
  .sol-eventguide .sv-image-portlet img {
    max-width: none !important;
    max-height: 420px !important;
  }
}
.sol-eventguide .sol-bottom-wave {
  position: relative;
  top: -165px;
}
@media (max-width: 767px) {
  .sol-eventguide .sol-bottom-wave {
    top: -140px;
  }
}
.sol-eventguide .sol-bottom-wave img {
  width: 101%;
  max-width: none;
}
@media (max-width: 767px) {
  .sol-eventguide .sol-bottom-wave img {
    position: relative;
    top: 10px;
  }
}
.sol-eventguide .sol-bottom-wave .sol-top-image__header {
  position: relative;
  top: 70px;
  text-align: center;
}
@media (max-width: 991px) {
  .sol-eventguide .sol-bottom-wave .sol-top-image__header {
    top: 50px;
  }
}
@media (max-width: 767px) {
  .sol-eventguide .sol-bottom-wave .sol-top-image__header {
    top: 45px;
  }
}
.sol-eventguide .sol-bottom-wave .sol-top-image__header--text {
  display: inline-block;
  padding: 16px 40px 24px;
  background-color: #fff;
  border-radius: 6px;
}
.sol-eventguide .sol-page__top-text-container {
  position: relative;
  bottom: 135px;
  margin-top: -160px;
}
@media (max-width: 2100px) {
  .sol-eventguide .sol-page__top-text-container {
    bottom: 120px;
    margin-top: -120px;
  }
}
@media (max-width: 1500px) {
  .sol-eventguide .sol-page__top-text-container {
    bottom: 125px;
    margin-top: -65px;
  }
}
@media (max-width: 1200px) {
  .sol-eventguide .sol-page__top-text-container {
    bottom: 75px;
    margin-top: -105px;
  }
}
@media (max-width: 991px) {
  .sol-eventguide .sol-page__top-text-container {
    bottom: 45px;
    margin-top: -115px;
  }
}
@media (max-width: 767px) {
  .sol-eventguide .sol-page__top-text-container {
    bottom: 0;
    margin-top: -110px;
    margin-bottom: 20px;
  }
}
.sol-eventguide .sol-page__top-text-container p.sol-page__top-text {
  max-width: 910px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .sol-eventguide .sol-page__top-text-container p.sol-page__top-text {
    max-width: 90%;
  }
}
@media (max-width: 767px) {
  .sol-eventguide .sol-page__top-text-container p.sol-page__top-text {
    max-width: 92%;
  }
}
@media (min-width: 1200px) {
  .sol-eventguide .sol-listingpage-container {
    margin-top: -75px;
  }
}
@media (max-width: 991px) {
  .sol-eventguide .sol-eventguide-image img {
    width: auto;
    height: 230px;
  }
}
@media (max-width: 767px) {
  .sol-eventguide .sol-eventguide-image img {
    width: auto;
    height: 180px;
  }
}
@media (max-width: 767px) {
  .sol-eventguide .sol-breadcrumbs-wrapper {
    left: 10px !important;
    display: inline-block !important;
  }
  .sol-eventguide .sol-breadcrumbs-wrapper .sol-breadcrumbs {
    display: block;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__title label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__title input.sol-form-control {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 65%;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__title input.sol-form-control {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__title input.sol-form-control::placeholder {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(89, 89, 89, 0.9);
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__description label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__description #eventDescription {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 65%;
  height: 160px;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__description #eventDescription {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__description #eventDescription::placeholder {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(89, 89, 89, 0.9);
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__preamble label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__preamble #eventPreamble {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 65%;
  height: 100px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__preamble #eventPreamble {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__preamble #eventPreamble::placeholder {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(89, 89, 89, 0.9);
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__place label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__place input.sol-form-control {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 65%;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__place input.sol-form-control {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__place input.sol-form-control::placeholder {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(89, 89, 89, 0.9);
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__info label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__info #ticketInfo {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 65%;
  height: 160px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__info #ticketInfo {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__info #ticketInfo::placeholder {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(89, 89, 89, 0.9);
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__link label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__link input.sol-form-control {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 65%;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__link input.sol-form-control {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__link input.sol-form-control::placeholder {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(89, 89, 89, 0.9);
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader {
  margin-bottom: 50px;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader input {
  opacity: 0;
  height: 0;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader__label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader__upload-btn {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-size: 17px;
  background-color: rgba(255, 209, 0, 0.6);
  padding: 10px 20px;
  border-radius: 100px;
  border: none;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader__upload-btn:hover {
  background-color: #FFD100;
  text-decoration: underline;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset {
  padding: 20px;
  width: 65%;
  border-color: rgba(0, 134, 109, 0.5);
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-fieldset {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sol-create-new-event-page .sol-new-event-form .sol-fieldset {
    padding: 8px;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset legend {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-weight: 700;
  padding: 0 10px;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset .sol-form-group label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset .sol-form-group input.sol-form-control {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 100%;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset .sol-form-group input.sol-form-control::placeholder {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(89, 89, 89, 0.9);
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend {
  display: block;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend {
    display: none;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend--mobile {
  display: none;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend--mobile {
    display: block;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend--mobile.details {
  font-size: 12px;
  white-space: nowrap;
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -10px;
}
.sol-create-new-event-page .sol-new-event-form button[type='submit'] {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-size: 17px;
  background-color: rgba(255, 209, 0, 0.6);
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 100px;
  border: none;
}
.sol-create-new-event-page .sol-new-event-form button[type='submit']:hover {
  background-color: #FFD100;
  text-decoration: underline;
}
.sol-create-new-event-page .sol-eventlist-filters__areas,
.sol-create-new-event-page .sol-eventlist-filters__categories {
  width: 65%;
  margin-bottom: 30px;
  margin-top: 0;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-eventlist-filters__areas,
  .sol-create-new-event-page .sol-eventlist-filters__categories {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-eventlist-filters__areas fieldset,
.sol-create-new-event-page .sol-eventlist-filters__categories fieldset {
  border: none;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--header,
.sol-create-new-event-page .sol-eventlist-filters__categories--header {
  background-color: rgba(0, 134, 109, 0.2);
  padding: 6px 20px;
  position: relative;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--header legend,
.sol-create-new-event-page .sol-eventlist-filters__categories--header legend {
  position: absolute;
  top: 8px;
  left: 40px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #35383b;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist {
  display: flex;
  flex-wrap: wrap;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li {
  flex: 1 0 50%;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li:nth-child(even),
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li:nth-child(even) {
  border-left: 1px solid #e7e7e7;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li .sol-event-radio-btn,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li .sol-event-radio-btn {
  opacity: 1;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--arealist,
.sol-create-new-event-page .sol-eventlist-filters__categories--arealist {
  display: flex;
  flex-wrap: wrap;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--arealist li,
.sol-create-new-event-page .sol-eventlist-filters__categories--arealist li {
  flex: 1 0 50%;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--arealist li:nth-child(even),
.sol-create-new-event-page .sol-eventlist-filters__categories--arealist li:nth-child(even) {
  border-left: 1px solid #e7e7e7;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--arealist li .sol-event-radio-btn,
.sol-create-new-event-page .sol-eventlist-filters__categories--arealist li .sol-event-radio-btn {
  opacity: 1;
}
.sol-create-new-event-page .dates-checkbox label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #00866D;
}
.sol-create-new-event-page .sol-form-group__error-msg,
.sol-create-new-event-page .sol-form-group__error-message {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  width: 100%;
  color: #AC1A2F;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-form-group__error-msg,
  .sol-create-new-event-page .sol-form-group__error-message {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-add-new-date-time {
  margin-top: 10px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-size: 17px;
  background-color: rgba(255, 209, 0, 0.6);
  padding: 10px 20px;
  border-radius: 100px;
  border: 0;
}
.sol-create-new-event-page .sol-remove-date-time-btn {
  width: 36px;
  height: 36px;
  background-color: rgba(0, 134, 109, 0.5);
  margin-top: 10px;
}
.sol-create-new-event-page .sol-remove-date-time-btn i {
  font-size: 21px;
  padding-top: 5px;
}
#recommended-image-size {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #00866d;
  letter-spacing: 0.1px;
  margin-bottom: 15px;
}
.sol-file-uploader__label {
  margin-bottom: 0 !important;
}
.sol-new-event__multiple-dates,
.sol-new-event__multiple-days {
  max-width: 65%;
  display: block;
  padding: 5px 25px;
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
}
.sol-new-event__multiple-dates fieldset,
.sol-new-event__multiple-days fieldset {
  border: none;
}
.sol-new-event__multiple-dates fieldset legend,
.sol-new-event__multiple-days fieldset legend {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-weight: 600;
}
.sol-new-event__multiple-days {
  margin-bottom: 10px !important;
}
.sol-new-event__consent {
  max-width: 73%;
}
.sol-new-event__consent fieldset {
  border: none;
}
.sol-new-event__consent fieldset legend {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-weight: 600;
}
.sol-new-event__multiple-dates,
.sol-new-event__consent,
.sol-new-event__multiple-days {
  margin: 10px 10px 20px 0;
}
@media (max-width: 767px) {
  .sol-new-event__multiple-dates,
  .sol-new-event__consent,
  .sol-new-event__multiple-days {
    max-width: none;
  }
}
.sol-new-event__multiple-dates input,
.sol-new-event__consent input,
.sol-new-event__multiple-days input {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  position: relative;
  top: 4px;
}
.sol-new-event__multiple-dates label,
.sol-new-event__consent label,
.sol-new-event__multiple-days label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  display: inline;
  font-weight: 500;
}
.sol-new-event-form > div label[required="required"]::after,
.sol-new-event-form > div legend[required="required"]::after,
.sol-new-event-form .sol-form-group label[required="required"]::after {
  display: inline-block;
  content: "\002a";
  color: red;
  font-size: 14px;
  margin-left: 3px;
}
.sol-new-event__date-time__startDate,
.sol-new-event__date-time__endDate {
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  padding: 10px 14px;
  margin-bottom: 20px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: black;
  margin-top: 0px;
  margin-bottom: 25px;
}
.sol-new-event__date-time__startDate::placeholder,
.sol-new-event__date-time__endDate::placeholder {
  color: rgba(89, 89, 89, 0.9);
}
.sol-new-event__date-time label {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
}
.dateLabel {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
}
.vdatetime-fade-enter-active,
.vdatetime-fade-leave-active {
  transition: opacity 0.4s;
}
.vdatetime-fade-enter,
.vdatetime-fade-leave-to {
  opacity: 0;
}
.vdatetime-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
}
.vdatetime-popup {
  box-sizing: border-box;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 340px;
  max-width: calc(100% - 30px);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background: #fff;
  -webkit-tap-highlight-color: transparent;
}
.vdatetime-popup * {
  box-sizing: border-box;
}
.vdatetime-popup__header {
  padding: 15px 30px;
  background: #3f51b5;
  color: #fff;
  font-size: 32px;
}
.vdatetime-popup__year {
  display: block;
  font-weight: 300;
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.3s;
}
.vdatetime-popup__year:hover {
  opacity: 1;
}
.vdatetime-popup__actions {
  padding: 0 20px 10px 30px;
  text-align: right;
}
.vdatetime-popup__actions__button {
  display: inline-block;
  border: none;
  padding: 10px 20px;
  background: 0 0;
  font-size: 16px;
  color: #3f51b5;
  cursor: pointer;
  transition: color 0.3s;
}
.vdatetime-popup__actions__button:hover {
  color: #444;
}
.vdatetime-calendar__navigation--next:hover svg path,
.vdatetime-calendar__navigation--previous:hover svg path {
  stroke: #888;
}
.vdatetime-calendar__navigation,
.vdatetime-calendar__navigation * {
  box-sizing: border-box;
}
.vdatetime-calendar__navigation {
  position: relative;
  margin: 15px 0;
  padding: 0 30px;
  width: 100%;
}
.vdatetime-calendar__navigation--next,
.vdatetime-calendar__navigation--previous {
  position: absolute;
  top: 0;
  padding: 0 5px;
  width: 18px;
  cursor: pointer;
}
.vdatetime-calendar__navigation--next svg,
.vdatetime-calendar__navigation--previous svg {
  width: 8px;
}
.vdatetime-calendar__navigation--next svg path,
.vdatetime-calendar__navigation--previous svg path {
  transition: stroke 0.3s;
}
.vdatetime-calendar__navigation--previous {
  left: 25px;
}
.vdatetime-calendar__navigation--next {
  right: 25px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.vdatetime-calendar__current--month {
  text-align: center;
  text-transform: capitalize;
}
.vdatetime-calendar__month {
  padding: 0 20px;
  transition: height 0.2s;
}
.vdatetime-calendar__month__day,
.vdatetime-calendar__month__weekday {
  display: inline-block;
  width: 14.28571%;
  line-height: 36px;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer;
}
.vdatetime-calendar__month__day > span,
.vdatetime-calendar__month__weekday > span {
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 0 0 100%;
  overflow: hidden;
}
.vdatetime-calendar__month__day > span > span,
.vdatetime-calendar__month__weekday > span > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0;
  border-radius: 50%;
  transition: background-color 0.3s, color 0.3s;
}
.vdatetime-calendar__month__weekday {
  font-weight: 700;
}
.vdatetime-calendar__month__day:hover > span > span {
  background: #eee;
}
.vdatetime-calendar__month__day--selected:hover > span > span,
.vdatetime-calendar__month__day--selected > span > span {
  color: #fff;
  background: #3f51b5;
}
.vdatetime-calendar__month__day--disabled {
  opacity: 0.4;
  cursor: default;
}
.vdatetime-calendar__month__day--disabled:hover > span > span {
  color: inherit;
  background: 0 0;
}
.vdatetime-time-picker__list::-webkit-scrollbar-thumb {
  background: #ccc;
}
.vdatetime-time-picker__list::-webkit-scrollbar-track {
  background: #efefef;
}
.vdatetime-time-picker * {
  box-sizing: border-box;
}
.vdatetime-time-picker {
  box-sizing: border-box;
}
.vdatetime-time-picker::after {
  content: '';
  display: table;
  clear: both;
}
.vdatetime-time-picker__list {
  float: left;
  width: 50%;
  height: 305px;
  overflow-y: scroll;
}
.vdatetime-time-picker__list::-webkit-scrollbar {
  width: 3px;
}
.vdatetime-time-picker__with-suffix .vdatetime-time-picker__list {
  width: 33.3%;
}
.vdatetime-time-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size 0.3s;
}
.vdatetime-time-picker__item:hover {
  font-size: 32px;
}
.vdatetime-time-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}
.vdatetime-time-picker__item--disabled {
  opacity: 0.4;
  cursor: default;
  font-size: 20px !important;
}
.vdatetime-year-picker__list::-webkit-scrollbar-thumb {
  background: #ccc;
}
.vdatetime-year-picker__list::-webkit-scrollbar-track {
  background: #efefef;
}
.vdatetime-year-picker * {
  box-sizing: border-box;
}
.vdatetime-year-picker {
  box-sizing: border-box;
}
.vdatetime-year-picker::after {
  content: '';
  display: table;
  clear: both;
}
.vdatetime-year-picker__list {
  float: left;
  width: 100%;
  height: 305px;
  overflow-y: scroll;
}
.vdatetime-year-picker__list::-webkit-scrollbar {
  width: 3px;
}
.vdatetime-year-picker__item {
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  transition: font-size 0.3s;
}
.vdatetime-year-picker__item:hover {
  font-size: 32px;
}
.vdatetime-year-picker__item--selected {
  color: #3f51b5;
  font-size: 32px;
}
.sol-new-event__date-time {
  padding: 10px 14px;
  margin-bottom: 20px;
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 10px;
  width: 65%;
}
@media (max-width: 991px) {
  .sol-new-event__date-time {
    width: 100%;
  }
}
.sol-new-event__date-time .application--wrap {
  min-height: 100px;
}
.sol-new-event__date-time .application--wrap .layout.row.wrap {
  margin-left: 20px !important;
}
.sol-new-event__date-time .spacer {
  max-width: 50px;
}
.sol-new-event__date-time .input-group--text-field.input-group--prepend-icon .input-group__details {
  max-width: none !important;
}
.sol-new-event__date-time button.btn {
  border-radius: 0;
  line-height: 15px;
  background-color: rgba(0, 134, 109, 0.2);
}
.sol-new-event__date-time button.btn--icon {
  background-color: transparent;
}
.sol-new-event__date-time button.btn--icon:hover {
  background-color: transparent;
}
.sol-new-event__date-time button.btn:hover {
  text-decoration: none;
  background-color: rgba(0, 134, 109, 0.4);
  border-radius: 0;
}
.sol-new-event__date-time button.btn span:before,
.sol-new-event__date-time button.btn div:before {
  border-radius: 0;
}
.sol-new-event__date-time .picker--date__header-selector-date {
  font-size: 18px;
  margin-bottom: 15px;
}
.sol-new-event__date-time .picker--time__clock > span {
  top: calc(44%);
  left: calc(44%);
}
.sol-new-event__date-time label:after {
  font-size: 17px;
  color: red;
  margin-left: 5px;
}
.sol-file-uploader__file-name {
  color: rgba(0, 134, 109, 0.5);
  font-size: 15px;
  font-weight: 600;
}
.sol-file-uploader__file-name:before {
  content: "\f03e";
  font-family: "Font Awesome 5 Pro";
  margin-right: 5px;
  font-size: 19px;
  color: black;
}
.sol-file-uploader__remove-btn {
  font-weight: 300;
  font-size: 17px;
  padding: 6px 16px;
  border-radius: 100px;
  margin-left: 10px;
  background-color: rgba(172, 26, 47, 0.5) !important;
  color: white;
}
.sol-file-uploader__error-message {
  font-size: 15px;
  display: block;
  width: 65%;
  color: #AC1A2F;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: -20px;
}
.event-one-line {
  font-weight: 400 !important;
  display: inline-flex;
  margin: 0 !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .event-one-line {
    display: inline;
  }
}
.default-font {
  font-family: 'Fira Sans', sans-serif;
  font-size: 15px;
  font-weight: 300;
}
.default-border-and-background {
  border: 1px solid #e7e7e7;
  background-color: #F8F8F8;
}
.default-spacing {
  padding: 10px 14px;
  margin-bottom: 20px;
}
.strex-webapp-form input,
.strex-webapp-form textarea,
.strex-webapp-form select {
  border-radius: 4px;
}
.strex-webapp-form input::-webkit-input-placeholder,
.strex-webapp-form textarea::-webkit-input-placeholder,
.strex-webapp-form select::-webkit-input-placeholder {
  /* Edge */
  color: #222 !important;
  opacity: 1;
}
.strex-webapp-form input:-ms-input-placeholder,
.strex-webapp-form textarea:-ms-input-placeholder,
.strex-webapp-form select:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #222 !important;
  opacity: 1;
}
.strex-webapp-form input::placeholder,
.strex-webapp-form textarea::placeholder,
.strex-webapp-form select::placeholder {
  color: #222 !important;
  opacity: 1;
}
.sol-article-listing .sol-article-filter__categories fieldset > div,
.sol-article-listing .sol-article-filter__areas fieldset > div {
  width: 50%;
  display: inline-block;
  padding: 10px;
}
#lediga-jobb-item .gray-background > div:first-of-type {
  float: none !important;
  width: 100% !important;
}
.sv-edit-mode .sol-display-in-edit {
  display: block !important;
}
.sol-focusevent-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0 0;
}
@media (max-width: 991px) {
  .sol-focusevent-container {
    margin: 20px 0 10px;
  }
}
@media (max-width: 479px) {
  .sol-focusevent-container {
    margin: 15px 6px 0;
  }
}
.sol-focusevent-container .sv-custom-module {
  max-width: 31%;
  margin: 0 1% 20px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-focusevent-container .sv-custom-module {
    max-width: 30% !important;
    flex: 1 0 33% !important;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 767px), (-ms-high-contrast: active) and (max-width: 767px) {
  .sol-focusevent-container .sv-custom-module {
    max-width: 100% !important;
    flex: 1 0 100% !important;
  }
}
@media (max-width: 1200px) {
  .sol-focusevent-container .sv-custom-module {
    max-width: 360px;
    margin: 0 10px 20px;
  }
}
@media (max-width: 991px) {
  .sol-focusevent-container .sv-custom-module {
    max-width: 310px;
    margin: 0 4px 20px;
  }
}
@media (max-width: 767px) {
  .sol-focusevent-container .sv-custom-module {
    max-width: 94%;
    margin: 20px auto;
  }
}
@media (max-width: 479px) {
  .sol-focusevent-container .sv-custom-module {
    max-width: 100%;
    margin: 20px auto;
  }
}
.sol-focusevent-container .sol-single-event {
  flex: 1 0 auto;
  max-width: 360px;
  min-height: 460px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.sol-focusevent-container .sol-focus-event {
  margin: 0 auto;
}
@media (max-width: 767px) {
  .sol-focusevent-container .sol-focus-event {
    margin: auto;
  }
}
.sol-startpage-event .sol-focus-event {
  max-width: 480px;
}
.sol-startpage-event .sol-focus-event-item {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: none;
  margin-bottom: 20px;
}
.sol-startpage-event .sol-focus-event-item__text {
  padding: 26px 18px 0;
}
.sol-focus-event {
  max-width: 480px;
}
.sol-focus-event-item__link {
  display: block;
  text-decoration: none;
}
.sol-focus-event-item__link:hover .sol-focus-event-item__header {
  text-decoration: underline;
}
.sol-focus-event-item__link:hover .sol-focus-event-item__preamble {
  text-decoration: underline;
  color: #006579;
}
.sol-focus-event-item__image-container {
  text-align: center;
  min-height: 190px;
  overflow: hidden;
  margin-bottom: 0;
}
.sol-focus-event-item__image-container .sol-focus-event-item__image {
  width: auto;
}
.sol-focus-event-item__text {
  padding: 18px 18px 16px 18px;
}
.sol-focus-event-item__text .sol-focus-event-item__header {
  font-family: 'Fira Sans', sans-serif;
  color: #00866D;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 0;
}
.sol-focus-event-item__text .sol-focus-event-item__preamble {
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
}
.sol-focus-event-item__date {
  margin: 8px 0 0;
}
.sol-focus-event-item__date > div {
  display: inline-block;
  margin-right: 12px;
  font-size: 15px;
  font-weight: 500;
}
.sol-focus-event-item__date > div:nth-of-type(2) {
  margin-right: 0;
}
.sol-focus-event-item__date i {
  margin-right: 2px;
  font-size: 16px;
}
.sol-focus-event-item__date .sol-focus-event-item__location {
  display: inline-block;
  margin-right: 12px;
  width: auto;
  font-size: 15px;
  font-weight: 500;
}
.sol-focus-event-item__date .sol-focus-event-item__free {
  display: inline-block;
  margin-right: 12px;
  width: auto;
  font-size: 15px;
  font-weight: 500;
}
.sol-focus-event-item__date .sol-focus-event-item__categories {
  display: block;
}
.sol-focus-event-item__date .sol-focus-event-item__categories i {
  font-size: 15px;
  font-weight: 500;
}
.sol-focus-event-item__date .sol-focus-event-item__categories ul {
  padding-left: 0;
  list-style: none;
  display: inline;
}
.sol-focus-event-item__date .sol-focus-event-item__categories ul li {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-right: 10px;
}
.sol-focus-event-item__date .sol-focus-event-item__categories ul li span {
  margin-left: -3px;
}
.sol-focus-event-page {
  margin: 60px auto;
}
.sol-focus-event-page .pagecontent {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.sol-focus-event-page .pagecontent .sv-mfpimage-portlet {
  max-height: 540px;
  overflow: hidden;
  margin-bottom: 0;
}
.sol-focus-event-page .pagecontent .sv-mfpimage-portlet img {
  max-width: none !important;
  max-height: none !important;
}
.sol-focus-event-page .pagecontent h1 {
  font-family: 'Fira Sans', sans-serif;
  color: #00866D;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 3px;
  margin-top: 0;
  padding: 18px 18px 0 18px;
}
.sol-focus-event-page .pagecontent p {
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
  padding: 0 18px 24px 18px;
}
.sol-widget-fullwidth .sol-focus-event {
  margin-top: 20px;
}
.sol-tourism-container .sol-focus-event {
  margin: 20px 0;
}
@media (max-width: 767px) {
  .sol-startpage-event .sol-focus-event-item__image-container {
    min-height: 0;
  }
}
.sol-single-event {
  background: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.sol-single-event .sol-focus-event-item__image-container {
  height: 220px;
  background: #efefef;
  text-align: center;
}
.sol-single-event .sol-focus-event-item__image-container img {
  object-fit: cover;
  height: 100%;
}
.sol-startpage-event .sol-single-event {
  background: transparent;
  box-shadow: none;
}
.sol-startpage-event .sol-single-event .sol-focus-event-item__image-container {
  background: transparent;
}
.sv-login-portlet.sol-form-decoration {
  padding-bottom: 0;
}
.sv-login-portlet.sol-form-decoration fieldset {
  margin: 0;
  padding: 0;
}
/* stylelint-disable color-function-notation */
/* stylelint-disable media-feature-range-notation */
/* stylelint-disable alpha-value-notation */
.sol-process__content {
  max-width: 800px;
  padding: 20px 15px;
  margin: 15px auto 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}
.sol-process__content h2:first-of-type {
  margin-top: 0;
}
.sol-process__content > div.sol-process__tab > div > div.sv-layout {
  display: inline-block;
  width: 100%;
}
.sol-process-flow__tip {
  position: relative;
  top: 12px;
  font-size: 21px;
  font-weight: 300;
  color: #767676;
}
@media (max-width: 767px) {
  .sol-process-flow__tip {
    top: 40px;
    font-size: 18px;
  }
}
.sol-process .sol-process-flow-button__name {
  display: block;
  height: 100%;
  overflow: hidden;
}
.sol-process__buttons {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1150px;
  margin: 0 auto;
}
@media (max-width: 1385px) {
  .sol-process__buttons {
    max-width: 825px;
  }
}
@media (max-width: 685px) {
  .sol-process__buttons {
    padding-top: 40px;
    margin: 0 -16px;
  }
}
.sol-process-button {
  position: relative;
  flex: 1 0 33%;
  max-width: 260px;
  height: 120px;
  padding: 20px 30px 10px;
  margin: 42px 60px;
  font-size: 19px;
  font-weight: 600;
  color: black;
  text-overflow: clip;
  letter-spacing: 0.5px;
  vertical-align: middle;
  background-color: #C0E0DD;
  border: 1px solid #00866D;
  border-radius: 4px;
  transition: background-color 300ms, color 300ms ease;
}
@media (max-width: 1380px) {
  .sol-process-button {
    margin: 42px 45px;
  }
}
@media (max-width: 1385px) {
  .sol-process-button {
    max-width: 225px;
    padding: 20px 10px;
    margin: 42px 25px;
  }
}
@media (min-width: 686px) and (max-width: 950px) {
  .sol-process-button {
    min-width: 165px;
    max-width: 184px;
    padding: 20px 6px;
    margin: 42px 12px;
  }
}
@media (max-width: 685px) {
  .sol-process-button {
    min-width: 114px;
    max-width: 114px;
    height: 90px;
    padding: 8px 6px;
    margin: 28px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25;
    word-break: break-word;
    border: 1px solid #00866D;
  }
}
@media (min-width: 560px) and (max-width: 685px) {
  .sol-process-button {
    min-width: 135px;
    max-width: 135px;
    height: 95px;
    padding: 8px 6px;
    margin: 28px 18px;
    font-size: 15px;
  }
}
@media (min-width: 375px) and (max-width: 412px) {
  .sol-process-button {
    min-width: 105px;
    max-width: 105px;
    padding: 6px 4px;
    margin: 20px 10px;
    font-size: 13px;
  }
}
@media (max-width: 374px) {
  .sol-process-button {
    min-width: 92px;
    max-width: 92px;
    padding: 6px 4px;
    margin: 20px 7px;
    font-size: 13px;
  }
}
.sol-process-button--active {
  color: white;
  background-color: #00866D;
}
.sol-process-button--active .sol-process-flow-button-index {
  color: black;
  background-color: #C0E0DD;
}
.sol-process-button::after {
  position: absolute;
  top: 50%;
  right: -105px;
  width: 105px;
  height: 6px;
  content: '';
  background-color: #00866D;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-process-button::after {
    top: 48%;
    height: 8px;
  }
}
@media (max-width: 1400px) and (min-width: 1385px) {
  .sol-process-button::after {
    right: -75px;
    width: 75px;
  }
}
@media (max-width: 1385px) {
  .sol-process-button::after {
    right: -37px;
    width: 37px;
  }
}
@media (max-width: 950px) {
  .sol-process-button::after {
    display: none;
  }
}
.sol-process-button::before {
  position: absolute;
  top: calc(50% - 12px);
  left: -45px;
  z-index: -1;
  font-family: 'Font Awesome 5 Pro';
  font-size: 40px;
  font-weight: 600;
  color: #00866D;
  content: '\f30b';
}
@media (max-width: 1385px) {
  .sol-process-button::before {
    left: -50px;
  }
}
@media (max-width: 1380px) {
  .sol-process-button::before {
    left: -45px;
  }
}
@media (max-width: 685px) {
  .sol-process-button::before {
    top: 20px;
    left: -38px;
  }
}
@media (max-width: 413px) {
  .sol-process-button::before {
    top: 31px;
    left: -23px;
    font-size: 23px;
  }
}
.sol-process-button:focus {
  outline: 2px solid #C0E0DD !important;
  outline-offset: -1px !important;
}
.sol-process-button:nth-child(3n+1)::before {
  display: none;
}
.sol-process-button:nth-child(3n)::after {
  display: none;
}
.sol-process-button:last-of-type::after {
  display: none;
}
.sol-process-button:first-of-type::before {
  display: none;
}
.sol-process-flow-button-index {
  position: absolute;
  top: -20px;
  left: 12px;
  display: block;
  width: 42px;
  height: 42px;
  padding-top: 6px;
  font-size: 21px;
  color: white;
  background-color: #00866D;
  border-radius: 50%;
}
@media (max-width: 1400px) {
  .sol-process-flow-button-index {
    top: -22px;
    left: 10px;
    width: 36px;
    height: 36px;
    padding-top: 3px;
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .sol-process-flow-button-index {
    top: -16px;
    width: 30px;
    height: 30px;
    padding-top: 0;
    font-size: 19px;
  }
}
@media (max-width: 685px) {
  .sol-process-flow-button-index {
    left: 5px;
  }
}
@media (max-width: 685px) {
  .sol-process-flow-button-index {
    width: 26px;
    height: 26px;
    padding-top: 2px;
    font-size: 18px;
  }
}
.sol-process-flow-devider {
  position: absolute;
  top: -50px;
  left: 130px;
  width: 765px;
  height: 6px;
  content: '';
  background-color: #00866D;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-process-flow-devider {
    height: 8px;
  }
}
@media (max-width: 1380px) {
  .sol-process-flow-devider {
    left: 130px;
    width: 700px;
  }
}
@media (max-width: 1385px) {
  .sol-process-flow-devider {
    left: 115px;
    width: 600px;
  }
}
@media (min-width: 686px) and (max-width: 950px) {
  .sol-process-flow-devider {
    left: 90px;
    width: 424px;
  }
}
@media (min-width: 560px) and (max-width: 685px) {
  .sol-process-flow-devider {
    top: -38px;
    left: 68px;
    width: 350px;
  }
}
@media (max-width: 559px) {
  .sol-process-flow-devider {
    top: -36px;
    left: 54px;
    width: 282px;
  }
}
@media (min-width: 375px) and (max-width: 412px) {
  .sol-process-flow-devider {
    top: -26px;
    left: 52px;
    width: 254px;
    height: 4px;
  }
}
@media (max-width: 374px) {
  .sol-process-flow-devider {
    top: -26px;
    left: 48px;
    width: 212px;
    height: 4px;
  }
}
.sol-process-flow-devider::before {
  position: absolute;
  top: 6px;
  left: -8px;
  font-family: 'Font Awesome 5 Pro';
  font-size: 46px;
  font-weight: 600;
  color: #00866D;
  content: '\f309';
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-process-flow-devider::before {
    top: 7px;
  }
}
@media (min-width: 570px) and (max-width: 685px) {
  .sol-process-flow-devider::before {
    top: -6px;
    font-size: 40px;
  }
}
@media (max-width: 569px) {
  .sol-process-flow-devider::before {
    top: -7px;
    left: -8px;
    font-size: 40px;
  }
}
@media (max-width: 413px) {
  .sol-process-flow-devider::before {
    top: -4px;
    left: -8px;
    font-size: 26px;
  }
}
.sol-process-flow-devider::after {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 6px;
  height: 50px;
  content: '';
  background-color: #00866D;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-process-flow-devider::after {
    width: 8px;
  }
}
@media (max-width: 413px) {
  .sol-process-flow-devider::after {
    width: 4px;
  }
}
.sol-process-flow-devider-mobile {
  display: none;
}
.sol-process-flow-side-button {
  display: inline-block;
  width: fit-content;
  max-width: 670px;
  height: 45px;
  padding: 0 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 45px;
  text-decoration: none;
  background-color: rgba(255, 209, 0, 0.6);
  border-radius: 100px;
}
.sol-process-flow-side-button[disabled='disabled'] {
  background-color: #f8f8f8;
}
.sol-process-flow-side-button.sol-process-flow-side-button__next {
  float: right;
}
@media (min-width: 686px) {
  .sol-subpage-wrapper .sol-process__buttons {
    width: 108%;
    margin: 0 -4%;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button {
    max-width: 190px;
    padding: 16px 8px;
    margin: 42px 30px;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button::before {
    left: -63px;
    z-index: -1;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button::after {
    display: none;
  }
  .sol-subpage-wrapper .sol-process-flow-devider {
    left: 95px;
    width: 510px;
  }
}
@media (max-width: 1385px) and (min-width: 686px) {
  .sol-subpage-wrapper .sol-process__buttons {
    width: 100%;
    margin: 0 auto;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button {
    max-width: 145px;
    padding: 15px 5px;
    margin: 42px 18px;
    font-size: 17px;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button::before {
    left: -40px !important;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button::after {
    display: none;
  }
  .sol-subpage-wrapper .sol-process-flow-devider {
    left: 70px;
    width: 370px;
  }
}
@media (min-width: 1400px) {
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button::before {
    left: -42px;
    z-index: -1;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button::after {
    right: -50px;
    display: block;
    width: 50px;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button:nth-child(3n)::after {
    display: none;
  }
  .sol-subpage-wrapper .sol-process__buttons .sol-process-button:last-of-type::after {
    display: none;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 1385px) and (min-width: 686px), (-ms-high-contrast: active) and (max-width: 1385px) and (min-width: 686px) {
  .sol-process__buttons .sol-process-button {
    max-width: 142px !important;
    margin: 42px 17px !important;
  }
}
.sol-news-container {
  margin: 30px 0;
}
.sol-news-container ul li > a.font-normal {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 3px;
}
.sol-news-container ul li > a.font-normal h2,
.sol-news-container ul li > a.font-normal h3 {
  margin: 5px 0 2px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 19px;
  font-weight: 600;
  color: #008169;
}
.sol-news-container ul li > a.font-normal span {
  font-family: 'Fira Sans', sans-serif;
  color: #28282a;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 300;
  margin: 8px 0 0;
}
.sol-news-container ul li > a.font-normal:hover h2,
.sol-news-container ul li > a.font-normal:hover h3 {
  text-decoration: underline !important;
}
.sol-news-container ul li > a.font-normal:hover span {
  text-decoration: underline !important;
}
.sol-news-container ul li > h2,
.sol-news-container ul li > h3 {
  margin: 0 !important;
}
.sol-news-container ul li > h2 a,
.sol-news-container ul li > h3 a {
  text-decoration: none;
}
.sol-news-container ul li > h2 a span,
.sol-news-container ul li > h3 a span {
  font-family: 'Fira Sans', sans-serif;
  font-size: 19px;
  font-weight: 600;
  color: #008169;
}
.sol-news-container ul li > span {
  font-family: 'Fira Sans', sans-serif;
  color: #35383b;
  font-size: 14px;
}
.sol-news-container ul li > span.font-heading-2 {
  display: none;
}
.sol-news-container a {
  font-family: 'Fira Sans', sans-serif;
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
}
.sol-news-container a:hover {
  text-decoration: underline;
}
.sol-news-container li {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 12px;
}
.sol-news-container .sol-links-with-icon a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding-left: 24px;
}
.sol-news-container .sol-links-with-icon a:before {
  font-family: 'Font Awesome 5 Pro';
  content: "\f105";
  font-size: 23px;
  font-weight: 400;
  color: #00866D;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  left: 5px;
  top: -2px;
}
.sol-news-menu__mobile {
  position: relative;
}
.sol-news-menu__mobile .sol-sub-menu-container {
  display: none;
}
.sol-news-menu__mobile i {
  position: absolute;
  right: 20px;
  font-size: 32px;
  top: 5px;
}
@media (max-width: 991px) {
  .sol-menu-container__desktop {
    display: none;
  }
  .sol-news-menu__mobile {
    display: block;
  }
}
/* stylelint-disable media-feature-range-notation */
.sol-page-with-top-image {
  position: relative;
  background: #fff;
}
.sol-page-with-top-image .sol-page__top-image-container {
  position: relative;
}
.sol-page-with-top-image .sol-page__top-image-container .sol-top-banner {
  width: 100%;
  height: 450px;
  background-position: center center;
  background-size: cover;
}
@media (max-width: 991px) {
  .sol-page-with-top-image .sol-page__top-image-container .sol-top-banner {
    width: auto;
    height: 400px;
  }
}
@media (max-width: 767px) {
  .sol-page-with-top-image .sol-page__top-image-container .sol-top-banner {
    width: auto;
    height: 220px;
  }
}
.sol-page-with-top-image .sol-page__top-text {
  max-width: 950px;
  margin: auto;
  margin-top: -25px;
  overflow: hidden;
  font-family: 'Fira Sans', sans-serif;
  font-size: 24px;
  text-align: left;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-page-with-top-image .sol-page__top-text {
    margin-top: -35px;
  }
}
@media (max-width: 991px) {
  .sol-page-with-top-image .sol-page__top-text {
    max-width: 80%;
    font-size: 21px;
  }
}
@media (max-width: 767px) {
  .sol-page-with-top-image .sol-page__top-text {
    max-width: 100%;
    margin: -15px 20px 15px;
  }
}
@media (min-width: 1800px) {
  .sol-page-with-top-image .sol-page__top-text {
    margin-top: -75px;
  }
}
.sol-page-with-top-image .sol-page__top-text-container {
  position: relative;
  width: 100%;
  padding-bottom: 80px;
  margin-bottom: 40px;
  background-color: #fff;
}
@media (min-width: 1200px) {
  .sol-page-with-top-image .sol-page__top-text-container {
    margin-top: -65px;
  }
}
@media (max-width: 1200px) and (min-width: 991px) {
  .sol-page-with-top-image .sol-page__top-text-container {
    padding: 0 60px !important;
  }
}
@media (max-width: 991px) {
  .sol-page-with-top-image .sol-page__top-text-container {
    padding-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .sol-page-with-top-image .sol-page__top-text-container {
    margin-bottom: -25px;
  }
}
.sol-page-with-top-image .sol-bottom-wave {
  position: relative;
  width: 102%;
  margin-top: -45px;
  margin-left: -1%;
}
@media (max-width: 991px) {
  .sol-page-with-top-image .sol-bottom-wave {
    margin-top: -35px;
  }
}
@media (max-width: 767px) {
  .sol-page-with-top-image .sol-bottom-wave {
    margin-top: -20px;
  }
}
@media (min-width: 1200px) {
  .sol-page-with-top-image .sol-bottom-wave {
    max-height: 150px;
    margin-top: -75px;
    margin-bottom: 40px;
  }
}
.sol-page-with-top-image .sol-bottom-wave img {
  z-index: 1;
  width: 100%;
}
@media (max-width: 767px) {
  .sol-page-with-top-image .sol-bottom-wave img {
    margin-top: -10px;
  }
}
.sol-page-with-top-image .sol-bottom-wave svg {
  z-index: 1;
  width: 100%;
}
.sol-page-with-top-image .sol-bottom-wave .sol-top-image__header {
  position: absolute;
  top: -34px;
  width: 100%;
}
.sol-page-with-top-image .sol-bottom-wave .sol-top-image__header--text {
  width: fit-content;
  max-width: 600px;
  height: 85px;
  padding: 10px 20px;
  margin: auto;
  font-family: 'Fira Sans', sans-serif;
  line-height: 60px;
  color: #00866D;
  text-align: center;
  text-overflow: ellipsis;
  background-color: #fff;
  border-radius: 4px;
}
.sol-page-with-top-image .sol-bottom-wave .sol-top-image__header--text h1 {
  font-size: 39px;
  line-height: 55px;
}
@media (max-width: 767px) {
  .sol-page-with-top-image .sol-bottom-wave .sol-top-image__header--text {
    max-width: 440px;
    height: 60px;
    margin-top: 10px;
    line-height: 35px;
  }
  .sol-page-with-top-image .sol-bottom-wave .sol-top-image__header--text h1 {
    font-size: 23px !important;
  }
}
@media (max-width: 479px) {
  .sol-page-with-top-image .sol-bottom-wave .sol-top-image__header--text {
    max-width: 320px;
    padding-top: 0;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 18px;
  }
  .sol-page-with-top-image .sol-bottom-wave .sol-top-image__header--text h1 {
    font-size: 18px !important;
  }
}
@media (min-width: 1200px) {
  .sol-page-with-top-image .sol-bottom-wave .sol-top-image__header {
    top: 0;
  }
}
@media (max-width: 991px) {
  .sol-page-with-top-image .sol-bottom-wave .sol-top-image__header {
    top: -45px;
  }
}
@media (max-width: 767px) {
  .sol-page-with-top-image .sol-bottom-wave .sol-top-image__header {
    top: -50px;
  }
}
.sol-top-image__container {
  position: relative;
}
.sol-top-image__container .sol-top-banner-image {
  width: 100%;
  height: 540px !important;
  height: auto;
  max-height: 540px;
  background-position: center center;
  background-size: cover !important;
}
@media (max-width: 991px) {
  .sol-top-image__container .sol-top-banner-image {
    width: auto;
    height: 450px !important;
  }
}
@media (max-width: 767px) {
  .sol-top-image__container .sol-top-banner-image {
    width: auto;
    height: 220px !important;
  }
}
/* stylelint-disable selector-no-vendor-prefix */
/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
.sol-pinboard {
  padding-left: 30px;
  background-color: rgba(0, 134, 109, 0.1);
  border-radius: 8px;
}
.sol-pinboard-creator {
  padding: 30px 25px 35px;
  border-top: 3px solid #00866D;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.03);
}
.sol-pinboard-creator,
.sol-pinboard-creator input::-webkit-input-placeholder,
.sol-pinboard-creator input::-moz-placeholder,
.sol-pinboard-creator input:-ms-input-placeholder,
.sol-pinboard-creator input:-moz-placeholder {
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  color: #a7a7a7;
  text-align: left;
  letter-spacing: normal;
}
.sol-pinboard-creator__registration-form select {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 17px;
  background-color: #f8f8f8;
  border: 1px solid #cecece;
  border-radius: 2px;
  appearance: menulist;
}
.sol-pinboard-creator__registration-form select:hover {
  cursor: pointer;
}
.sol-pinboard-creator__registration-form input#sol-notice-title,
.sol-pinboard-creator__registration-form input#sol-protocol-storage {
  display: block;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 20px;
  font-size: 18px;
  background-color: white;
  border: 1px solid #cecece;
  border-radius: 2px;
}
.sol-pinboard-creator__registration-form .sol-form-group {
  margin-top: 20px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-form-group {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}
.sol-pinboard-creator__registration-form.input-group--text-field.input-group--dirty.input-group--select label,
.sol-pinboard-creator__registration-form .input-group--text-field.input-group--dirty:not(.input-group--textarea) label {
  left: 40px !important;
  transform: translateY(-40px) translateX(-40px) scale(0.85);
}
.sol-pinboard-creator__registration-form .input-group--text-field label {
  top: -4px;
  left: 50px !important;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
}
.sol-pinboard-creator__registration-form .menu__activator .input-group {
  padding: 6px 12px;
  margin-top: 30px;
  border: 1px solid #cecece;
}
.sol-pinboard-creator__registration-form .menu__activator .input-group input {
  height: 26px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 18px;
}
.sol-pinboard-creator__registration-form .menu__activator--active label {
  top: 18px;
}
.sol-pinboard-creator__registration-form label {
  display: block;
  margin: 16px 0 4px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
}
.sol-pinboard-creator__registration-form .sol-notice__dates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sol-pinboard-creator__registration-form .sol-notice__dates label[for='sol-notice-date'] {
  margin-top: 0;
}
.sol-pinboard-creator__registration-form .sol-notice__dates .application--wrap {
  padding: 0;
  background-color: #fff;
}
.sol-pinboard-creator__registration-form .sol-notice__dates__error-message {
  font-family: 'Fira Sans', sans-serif;
  line-height: 22px;
  color: red;
}
.sol-pinboard-creator__registration-form .sol-notice__dates .menu__content {
  min-width: initial !important;
}
.sol-pinboard-creator__registration-form .sol-notice__dates .notice-date {
  flex: 1 0 50%;
  max-width: 48%;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-notice__dates .notice-date {
    flex: 1 0 100%;
    max-width: none;
  }
}
.sol-pinboard-creator__registration-form .sol-notice__dates .notice-time {
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 48%;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-notice__dates .notice-time {
    flex: 1 0 100%;
    max-width: none;
  }
}
.sol-pinboard-creator__registration-form .sol-authority__error-message {
  color: red;
}
.sol-pinboard-creator__registration-form .sol-title__error-message {
  color: red;
}
.sol-pinboard-creator__registration-form .sol-form-group__error-message {
  color: red;
}
.sol-pinboard-creator__registration-form .sol-files-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.sol-pinboard-creator__registration-form .sol-files-list li {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  background-color: rgba(0, 134, 109, 0.15);
}
.sol-pinboard-creator__registration-form .sol-files-list li:nth-of-type(odd) {
  background-color: rgba(0, 134, 109, 0.05);
}
.sol-pinboard-creator__registration-form .sol-files-list li > i {
  margin-right: 8px;
  font-size: 1.12em;
  color: #00866d;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-files-list li {
    position: relative;
    max-height: 50px;
    padding: 12px;
    padding-right: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.sol-pinboard-creator__registration-form .sol-remove-file-btn {
  float: right;
  padding-left: 20px;
  margin-right: 0;
}
.sol-pinboard-creator__registration-form .sol-remove-file-btn i {
  font-size: 1.12em;
  font-weight: 600;
}
@media (max-width: 767px) {
  .sol-pinboard-creator__registration-form .sol-remove-file-btn i {
    position: absolute;
    right: 12px;
  }
}
.sol-pinboard-creator__registration-form .sol-file-uploader {
  position: relative;
  padding: 0;
  margin: 0;
}
.sol-pinboard-creator__registration-form .sol-file-uploader .sol-file-uploader__file-name {
  display: none;
}
.sol-pinboard-creator__registration-form .fileupload-wrapper {
  background: #fff;
}
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn {
  display: inline-block;
  margin: 4px 0 16px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn:hover {
  color: #006579;
  cursor: pointer;
}
.sol-pinboard-creator__registration-form .sol-file-uploader__upload-btn i {
  margin-right: 10px;
  font-size: 23px;
  color: #00866D;
}
.sol-pinboard-creator__registration-form button[type='submit'] {
  display: inline-block;
  height: 42px;
  padding: 0 30px;
  margin: 10px 0 15px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  line-height: 42px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: rgba(255, 209, 0, 0.6);
  border-radius: 100px;
}
.sol-pinboard-creator__registration-form .sol-file-uploader input {
  position: absolute;
  z-index: -1;
  height: 90px;
  outline: none;
}
.sol-pinboard-creator__registration-form .sol-file-uploader input:focus + label {
  z-index: 10;
  outline: 2px solid #257cc3;
  outline-offset: 1px;
}
.sol-pinboard-creator__registration-form .input-group {
  height: 44px;
  padding: 0;
  margin: 23px 0 18px;
}
.sol-pinboard-creator__registration-form .input-group__details {
  display: none;
}
.sol-pinboard-creator__registration-form .sol-pinboard-datePicker {
  max-height: 100px;
}
.sol-pinboard-creator__registration-form .application--wrap {
  min-height: auto;
}
.sol-pinboard-creator ul {
  padding: 0;
  margin-top: 0;
  list-style-type: none;
}
.sol-pinboard-creator .sol-notice__file-folders {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sol-pinboard-creator .sol-notice__file-folders > div.sol-form-group {
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 48%;
  margin-top: 0;
}
@media (max-width: 767px) {
  .sol-pinboard-creator .sol-notice__file-folders > div.sol-form-group {
    flex: 1 0 100%;
    max-width: none;
    margin: 0;
  }
}
.sol-pinboard .sol-pin {
  padding-top: 16px;
  padding-bottom: 16px;
}
.sol-pinboard .sol-pin p {
  margin-bottom: 0;
}
.sol-collapsible-pinboard {
  margin-bottom: 20px;
}
.sol-form-top-level-error-list {
  margin-bottom: 1rem;
  border-radius: 3px;
}
.sol-form-top-level-error-item {
  padding: 15px;
  margin-bottom: 1rem;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  color: #333;
  letter-spacing: 0.4px;
  background-color: rgba(200, 73, 107, 0.2);
}
.sol-error-message {
  display: none;
}
.sol-thankyou-message {
  display: none;
}
.sol-pinboard-datePicker .material-icons {
  color: #00866D !important;
}
.sol-pinboard-creator label[for='noticeMeeting'],
.sol-pinboard-creator label[for='noticeProtocol'] {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3em;
  color: #35383b;
  cursor: pointer;
}
.sol-pinboard-creator label[for='noticeMeeting'] input,
.sol-pinboard-creator label[for='noticeProtocol'] input {
  position: relative;
  top: 4px;
  display: inline-block;
  width: 21px;
  height: 21px;
  margin-right: 6px;
  background-color: white;
  border: 1px solid #1B927C;
  border-radius: 50%;
  box-shadow: none;
  appearance: none;
}
.sol-pinboard-creator label[for='noticeMeeting'] input:checked,
.sol-pinboard-creator label[for='noticeProtocol'] input:checked {
  background: #1b927c url('https://www.strangnas.se/images/18.38e877f416b3ac0044142498/1560260286947/circle-128.png') no-repeat;
  background-position: center;
  background-size: 11px;
  border: 2px solid #1b927c;
  box-shadow: none;
}
.sol-pinboard-creator input#sol-notice-location,
.sol-pinboard-creator input#sol-notice-storage-location,
.sol-pinboard-creator input#sol-notice-secretary {
  width: 100%;
  height: 45px;
  padding-left: 10px;
  font-size: 15px;
  background-color: #f8f8f8;
  border: 1px solid #cecece;
}
.sol-pinboard-creator input#sol-notice-date,
.sol-pinboard-creator input#sol-notice-time {
  width: 100%;
  height: 45px;
  padding-left: 10px;
  font-size: 15px;
  background-color: #f8f8f8;
  border: 1px solid #cecece;
}
.sv-standardsearch-portlet .sol-did-you-mean,
.sv-standardsearch-portlet .sol-num-hits {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 12px;
}
.sv-standardsearch-portlet .sv-search-hit {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px 50px 20px 40px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}
.sv-standardsearch-portlet .sv-search-hit:hover .font-heading-3 {
  text-decoration: underline;
  color: #00866D;
}
.sv-standardsearch-portlet .sv-search-hit .font-heading-3 {
  display: block;
  margin-top: 0;
  margin-bottom: 3px;
  color: #35383b;
  font-size: 15px;
  font-weight: 800;
  text-decoration: none;
}
.sv-standardsearch-portlet .sv-search-hit .font-normal {
  font-size: 15px;
  line-height: 1.4em;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text {
  font-size: 13px;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fa {
  color: #00866D;
  margin-right: 5px;
  margin-left: 10px;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fa:first-child {
  margin-left: 0;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text > strong {
  margin-left: 10px;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text > strong:first-of-type {
  margin-left: 0;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-path-text {
  font-size: 12px;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-path-text a {
  color: #00866D;
  text-decoration: none;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-path-text a:hover {
  color: #35383b;
  text-decoration: underline;
}
.sv-standardsearch-portlet .sol-pagination {
  text-align: center;
}
.sv-standardsearch-portlet .sol-pagination .pagination {
  display: inline-block;
  margin-top: 50px;
}
.sv-standardsearch-portlet .sol-pagination .pagination:after,
.sv-standardsearch-portlet .sol-pagination .pagination:before {
  content: "";
  display: table;
}
.sv-standardsearch-portlet .sol-pagination .pagination:after {
  clear: both;
}
.sv-standardsearch-portlet .sol-pagination .pagination a,
.sv-standardsearch-portlet .sol-pagination .pagination span.current {
  width: 30px;
  height: 30px;
  margin-top: 0;
  margin-right: 2px;
  margin-left: 2px;
  padding: 4px 0 0;
  border-radius: 100%;
  background-color: transparent;
  box-shadow: none;
  color: #35383b;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  border: none;
  text-decoration: none;
  line-height: 22px;
}
.sv-standardsearch-portlet .sol-pagination .pagination a:hover,
.sv-standardsearch-portlet .sol-pagination .pagination span.current:hover {
  background-color: #e8f3f8;
}
.sv-standardsearch-portlet .sol-pagination .pagination span.current,
.sv-standardsearch-portlet .sol-pagination .pagination span.current:hover {
  font-weight: 700;
  background-color: #00866D;
  color: #fff;
}
.sv-standardsearch-portlet .sol-pagination .pagination span.current strong,
.sv-standardsearch-portlet .sol-pagination .pagination span.current:hover strong {
  font-weight: 300;
}
.sv-standardsearch-portlet .sol-pagination .pagination > *:first-child:not(span),
.sv-standardsearch-portlet .sol-pagination .pagination > *:last-child:not(span) {
  border: none;
  width: auto;
  height: auto;
  border-radius: 0;
  font-size: 14px;
  padding: 4px 0 0;
  text-decoration: underline;
  margin-left: 15px;
  margin-right: 15px;
}
.sv-standardsearch-portlet .sol-pagination .pagination > *:first-child:not(span):hover,
.sv-standardsearch-portlet .sol-pagination .pagination > *:last-child:not(span):hover {
  background-color: transparent;
  color: #00866D;
}
.sv-standardsearch-portlet .sol-pagination .pagination span.current.next,
.sv-standardsearch-portlet .sol-pagination .pagination span.current.prev {
  display: none;
}
.troint-wrapper {
  position: relative;
  border-top-left-radius: 4px;
  padding-bottom: 0;
  margin: 25px 0 5px;
}
.troint-wrapper #troman {
  padding: 0;
  overflow: auto;
}
.troint-wrapper #troman header {
  display: none;
}
.troint-wrapper #troman .breadcrumb {
  display: none;
}
.troint-wrapper #troman .breadcrumb .divider {
  display: inline;
}
.troint-wrapper #troman .breadcrumb > .active {
  color: #000;
}
.troint-wrapper #troman h1:first-of-type {
  display: none;
}
.troint-wrapper #troman h2:first-of-type {
  display: none;
}
.troint-wrapper #troman.preformatted:first-of-type {
  display: none;
}
.troint-wrapper #troman > table {
  font-family: 'Fira Sans', sans-serif;
}
.troint-wrapper #troman > table thead th {
  font-size: 18px;
  padding: 18px 12px;
  font-weight: 600;
}
.troint-wrapper #troman > table tbody td {
  font-size: 18px;
  padding: 18px 12px;
}
.troint-wrapper #troman #organization-engagements #engagementTable {
  font-family: 'Fira Sans', sans-serif;
}
.troint-wrapper #troman #organization-engagements #engagementTable thead th {
  font-size: 18px;
  padding: 18px 8px;
  font-weight: 600;
}
.troint-wrapper #troman #organization-engagements #engagementTable tbody td {
  font-size: 18px;
  padding: 18px 12px;
}
.troint-wrapper br {
  display: none;
}
.troint-wrapper > table {
  width: 100%;
}
.troint-wrapper > table:first-of-type {
  display: none;
}
.troint-wrapper > table:first-of-type h2 {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 0.4px;
}
.troint-wrapper > table:nth-of-type(2) {
  padding: 0 0 30px;
}
.troint-wrapper > table:nth-of-type(2) h3 {
  margin: 0;
}
.troint-wrapper > table:nth-of-type(2) table {
  width: 100%;
  border-spacing: 0;
}
.troint-wrapper > table:nth-of-type(2) table tr[bgcolor="#b0c4de"] {
  background-color: #fff;
}
.troint-wrapper > table:nth-of-type(2) table tr[bgcolor="#b0c4de"] > td {
  padding: 16px 14px;
  padding-left: 0;
}
.troint-wrapper > table:nth-of-type(2) table tr:not([bgcolor="#b0c4de"]) > td form {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.troint-wrapper > table:nth-of-type(2) table tr:not([bgcolor="#b0c4de"]) > td form strong {
  flex: 1 0 100%;
  margin: 6px 0 0;
}
.troint-wrapper > table:nth-of-type(2) table tr:not([bgcolor="#b0c4de"]) > td form input[type="Text"] {
  flex: 1 0 100%;
  border: 1px solid #333;
  padding: 5px 10px;
  margin: 0 0 10px;
}
.troint-wrapper > table:nth-of-type(2) table tr:not([bgcolor="#b0c4de"]) > td form input[type="Submit"] {
  margin: 20px 0 30px;
  background-color: rgba(255, 209, 0, 0.6);
  display: flex;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 670px;
  text-decoration: none;
  border-radius: 100px;
  overflow: hidden;
}
.troint-wrapper > table:nth-of-type(2) table tr:not([bgcolor="#b0c4de"]) > td form select {
  flex: 1 0 100%;
  border: 1px solid #333;
  padding: 5px 10px;
  margin: 0 0 10px;
}
.troint-wrapper > table:nth-of-type(2) table tr:not([bgcolor="#b0c4de"]) > td hr {
  display: none;
}
.troint-wrapper > table:nth-of-type(2) table tr:not([bgcolor="#b0c4de"]) > td > strong {
  margin: 0 10px;
}
.troint-wrapper > table:nth-of-type(2) table td div a {
  display: block;
  margin: 10px 0 0;
  padding: 0;
}
.troint-wrapper > table:nth-of-type(2) table td div a[shape="rect"] {
  text-align: left;
  margin: 20px 15px;
}
.troint-wrapper > table:nth-of-type(2) table td a {
  font-size: 21px;
  display: block;
  padding: 10px 16px;
  margin: 0;
}
.troint-wrapper > table:nth-of-type(2) table td a:nth-of-type(even) {
  background-color: #f8f8f8;
}
.troint-wrapper > table:nth-of-type(2) .MenuPane {
  width: inherit;
  position: absolute;
  padding-top: 20px;
  bottom: 20px;
  left: 0;
  right: 0;
}
.troint-wrapper > table:nth-of-type(2) .MenuPane center {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.troint-wrapper > table:nth-of-type(2) .MenuPane center .MenuItem {
  margin: 0 10px;
}
.troint-wrapper > table:nth-of-type(2) .MenuPane center .MenuItem a {
  background-color: rgba(255, 209, 0, 0.6);
  display: flex;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 670px;
  text-decoration: none;
  border-radius: 100px;
  overflow: hidden;
}
.troint-wrapper > table:nth-of-type(3) {
  display: none;
}
.troint-wrapper > img {
  width: 100%;
  display: none;
}
.troint-wrapper font {
  display: block;
  padding: 10px 20px 0;
}
.troint-wrapper tr[bgcolor="#dcdcdc"] {
  background-color: #f8f8f8;
}
.troint-wrapper tr td div[align="Left"] {
  padding: 0 20px;
}
.troint-wrapper tr td p {
  border-top: 1px solid #f8f8f8;
}
.troint-wrapper tr td p img {
  display: none;
}
.troint-wrapper tr td p a {
  width: 100%;
}
.troint-wrapper tr td b {
  width: 48%;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 10px 16px;
}
.troint-wrapper tr td b a {
  flex: 0 0 80%;
  padding: 0 !important;
}
.troint-wrapper tr td b a:nth-of-type(even) {
  background-color: #f8f8f8;
}
.troint-wrapper form[action="/sitevision/proxy/4.4cd5bc2316db45f598b20762.html/svid12_36841ec916e356d2d753af7b/-2079936182/troint/historik_allanamnder_tidpunkt.asp"] {
  display: none !important;
}
.sol-top-image__container {
  position: relative;
}
.sol-top-image__container img {
  width: 100%;
}
.sol-top-image-breadcrumbs {
  width: 1260px;
  margin: auto;
  height: 0px;
}
.sol-top-image-breadcrumbs .sol-breadcrumbs-wrapper {
  position: relative;
  z-index: 2;
  display: inline-block;
  left: 0;
  top: 10px;
  line-height: 34px;
  border-radius: 3px;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.73);
}
@media (max-width: 767px) {
  .sol-top-image-breadcrumbs .sol-breadcrumbs-wrapper {
    line-height: 20px;
    display: none;
  }
  .sol-top-image-breadcrumbs .sol-breadcrumbs-wrapper li {
    height: 25px;
  }
}
.sol-top-wave {
  margin-top: -90px;
  width: 102%;
  height: 100%;
  max-width: 102%;
  margin-left: -1%;
  position: relative;
}
@media (max-width: 767px) {
  .sol-top-wave {
    margin-top: -60px;
  }
}
.sol-startpage-wrapper .sol-top .sv-mfpimage-portlet,
.sol-page-with-top-image .sv-mfpimage-portlet {
  max-height: 540px;
  overflow: hidden;
  margin-bottom: 0;
}
.sol-startpage-wrapper .sol-top .sv-mfpimage-portlet img,
.sol-page-with-top-image .sv-mfpimage-portlet img {
  max-width: none !important;
  max-height: none !important;
}
