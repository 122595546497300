.sol-top-image__container {
  position: relative;

  & img {
    width: 100%;
  }
}
.sol-top-image-breadcrumbs {
  width: 1260px;
  margin: auto;
  height: 0px;

  & .sol-breadcrumbs-wrapper {
    position: relative;
    z-index: 2;
    display: inline-block;
    left: 0;
    top: 10px;
    line-height: 34px;
    border-radius: 3px;
    padding: 0 10px;
    background-color: fadeout(@white, 27%);

    @media @sm {
      line-height: 20px;
      display: none;

      & li {
        height: 25px;
      }
    }
  }
}

.sol-top-wave {
  margin-top: -90px;
  width: 102%;
  height: 100%;
  max-width: 102%;
  margin-left: -1%;
  position: relative;

  @media @sm {
    margin-top: -60px;
  }
}

.sol-startpage-wrapper .sol-top, .sol-page-with-top-image {
  & .sv-mfpimage-portlet {
    max-height: 540px;
    overflow: hidden;
    margin-bottom: 0;

    & img {
      max-width: none !important;
      max-height: none !important;
    }
  }
}

