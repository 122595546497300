.sol-shortcut-container {
  background-color: @gray-4;
  padding: 40px 0;
  margin-bottom: -2px;

  @media (min-width:768px) and (max-width:1367px) {
    margin-bottom: -50px;
  }

}
.sol-shortcut {
  .sol-clearfix;
  margin-left: -10px;
  margin-right: -10px;
  @media @sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  a {
    text-decoration: none;
    max-width: 150px;
    display: inline-block;
  }
  &-item {
    border-right: 1px solid @gray-2;
    display: inline-block;
    float: left;
    margin-bottom: 20px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    &:last-child{
      border: none;
    }
    @media @md {
      width: 33%!important;
      margin-bottom: 40px;

      &:nth-child(3){
        border: none;
      }
    }

    @media @xs {
      width: 16.6% !important;
      margin-bottom: 0;
      border-right: 1px solid @gray-2;
      padding: 0;

      span {
        display: none;
      }

      &:last-child{
        border-right: none;
      }

      &:nth-child(3) {
        border-right: 1px solid @gray-2;
      }
    }
    & i.fal {
      display: block;
      width: 60px;
      height: 60px;
      margin: 0 auto 0;
      background-color: transparent;
      color: @footer-shortcut-text;
      font-size: 34px;
      text-align: center;
      line-height: 54px;
      .transition(~  'color');

      @media @xs {
        width: 40px;
        height: 40px;
      }
    }
    & a > span {
      text-decoration: none;
      color: @footer-shortcut-text;
      font-family: @main-font;
      font-size: 16px;
      line-height: 16px;
      font-weight: normal;
      .transition(color);
    }
    & a:focus,
    & a:hover {
      i.fal {
        color: @color-4;
      }
      & > span {
        text-decoration: underline;
      }
    }
  }
}
