.sol-event-item {
  display: block;
  margin: 12px;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  .transition(opacity);
  &:hover {
    opacity: 0.9;
  }
  &-content {
    padding: 4px 10px;
    background-color: @event-item-bg-top;
    color: @event-item-text-top;
    text-align: left;
  }
  &-heading {
    display: block;
    font-size: 13px;
    font-weight: 700;
  }
  &-desc {
    display: block;
    margin-bottom: 4px;
    font-size: 13px;
    line-height: 1.4em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &-info {
    .sol-clearfix;
    font-size: 11px;
    background-color: @event-item-bg-bottom;
    color: @event-item-text-bottom;
    line-height: 19px;
    padding: 4px 10px;
    &-item {
      display: inline-block;
      margin-right: 13px;
      float: left;
      & i.fa {
        margin-right: 6px;
        color: @event-item-icon-bottom;
      }
    }
  }
}
.sol-event-container {
  & h1 {
    margin-bottom: 20px;
  }
}
