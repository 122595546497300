.sol-article-wrapper {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  .sv-image-portlet {
    position: relative;
    overflow: hidden;
  }
  & .pagecontent {
    .sol-clearfix;
  }
  &.sol-section {
    padding-top: 10px;
  }
}
.sol-article-top-wrapper {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.sol-news-menu {
  .sol-menu-item[data-expanded="false"] {
    .sol-menu-list {
      display: none;
    }
  }
}
& .sol-article-date {
  font-family: @main-font;
  font-size: 15px;
  font-weight: 500;
  color: @black;
  margin: 15px 0 0 0;
  @media @sm {
  }
}
.sol-blurbs-wrapper {
  & .pagecontent {
    display: flex;
    flex-wrap: wrap;
    & .sv-custom-module {
      flex: 1 0 20%;
      margin: 1%;
    }
  }
}
