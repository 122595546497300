.sol-translated-pages {
  line-height: 50px;

  a {
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    color: @white;
    text-decoration: none;

    @media @sm {
      font-size: 18px;
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      z-index: 10 !important;
      outline: 2px solid #ff0 !important;
      outline-offset: 2px !important;
    }
  }

  span.divider {
    display: inline-block;
    width: 1px;
    height: 25px;
    margin: 0 4px;
    vertical-align: text-bottom;
    background-color: @white;

    @media @sm {
      height: 20px;
    }
  }
}