/* stylelint-disable media-feature-range-notation */
.sol-startpage-events {
  width: 100%;
  max-width: 1260px;

  &__item {
    width: 100%;

    @media (max-width: 1170px) {
      width: 100%;
    }

    @media @sm {
      width: 100%;
    }

    &-content {
      width: auto;
      max-width: 454px;
      height: auto;
      margin: auto;
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 3px;

      @media @md {
        height: auto;
        margin: 0;
      }

      @media @sm {
        height: auto;
        margin: 0;
      }

      & a:focus {
        & h2 {
          z-index: 10;
          outline: 2px solid #257cc3;
        }
      }
    }

    &--text {
      padding: 18px 18px 24px;
    }

    & a {
      text-decoration: none;
    }

    &--image-container {
      text-align: center;
      background: #fff;
    }

    &--image {
      max-width: 100%;
      height: auto;
      max-height: 290px;
    }

    &--no-image {
      width: auto;
      height: 250px;
      background-color: #f8f8f8;
    }

    &--header {
      display: block;
      margin-top: 0;
      margin-bottom: 3px;
      font-family: @main-font;
      font-size: 18px;
      font-weight: 600;
      color: @color-1;
    }

    &--preamble {
      margin-bottom: 6px;
      font-family: @main-font;
      font-size: 19px;
      font-weight: 300;
      line-height: 1.4em;
    }

    &--info {
      & span {
        display: inline-block;
        width: auto;
        margin-right: 15px;
        font-family: @main-font;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.4em;

        @media @sm {
          max-width: none;
        }

        &:first-of-type {
          @media @sm {
            max-width: none;
          }
        }

        &:last-of-type {
          max-width: 100%;
        }
      }

      & i {
        margin-right: 5px;
        font-size: 17px;
        font-weight: 300;
        color: @color-1;

/*         &.fal.fa-th-list {
          font-weight: 600;
        } */
      }

      & ul {
        display: inline-block;
        max-width: 90%;
        vertical-align: top;
        list-style: none;

        & li {
          display: inline-block;

          & span {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
