.sol-info-box,
.sol-simple-box-decoration.sol-info-box {
  position: relative;
  padding: 10px 16px 16px 64px !important;

  &--alt {
    padding: 16px !important;
    background-color: fadeout(@color-4, 95%);

    @media @xs {
      padding: 12px;
    }
  }

  .fa-exclamation-circle {
    position: absolute;
    top: 18px;
    left: 18px;
    font-size: 32px;
    color: @color-4;
  }

  @media @xs {
    padding: 56px 16px 16px 22px;
  }
}
