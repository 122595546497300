.sol-tourism-links {
  padding: 40px 0 0 0;
  & .sol-shortcut-container {
    position: relative;
    width: 100%;
    margin-top: 55px;
    padding: 50px 0 40px 0;

    & > div.sv-grid-main {
      max-width: 950px;
      margin: auto;
    }

    @media @md {
      margin-top: 15px;
    }

    @media (max-width: 1367px) and (min-width: 768px) {
        margin-bottom: 0px;
    }

    @media @xs {
      padding: 20px 0 25px;
    }
  }
  @media (min-width: @lg-min) {
    margin-top: -75px;
  }
  @media @md {
    margin-top: -45px;
  }
}

