.sol-subpage-wrapper {
  .sol-clearfix;
  > .sol-left {
    width: 65%;
    max-width: 700px;
    float: left;
    @media @md {
      float: none;
      width: 100%;
    }
  }
  > .sol-right {
    width: 30%;
    float: right;
    @media @md {
      float: none;
      width: 100%;
    }
  }
  & .pagecontent {
    .sol-clearfix;
  }
  & h1 {
    margin-top: 5px;
    margin-bottom: 15px;
    @media @sm {
      margin-top:5px;
      margin-bottom: 0px;
    }
  }
}
