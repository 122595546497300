.sol-print {
  font-family: @main-font;
  text-decoration: underline;
  color: @black;
  font-size: 16px;
  font-weight: 400;
  float: right;
  & > span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  & i.fal {
    margin-right: 7px;
  }
}
