.sol-tool-nav {
  max-width: 700px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-top: 1px solid @gray-2;
  border-bottom: 1px solid @gray-2;
  position: relative;
  @media @sm {
    display: flex;
    vertical-align: top;
    margin-top: 20px;
    text-align: center;
  }

  &-item {
    display: inline-block;
    & a:hover {
      // color: #16a88d;
      text-decoration: underline;
    }
    & a:focus {
      outline: 2px solid #ff0 !important;
      outline-offset: 2px !important;
      z-index: 10 !important;
    }
    & > a,
    & > button {
      .focus-outline;
      .no-appearance;
      display: block;
      height: 40px;
      margin-left: 12px;
      margin-right: 5px;
      font-weight: 400;
      color: @black;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      font-family: @main-font;
      @media @md{
        margin: 0px 9px 0px;
        height: 100%;
      }
      @media @sm {
        margin: 0 9px 9px;
        height: 100%;
      }

      & i {
        color: @color-1;
        margin-right: 8px;
        font-size: 18px;
      }

      &:hover {
        text-decoration: underline;

        & i {
          color:#16a88d;
        }
      }

      &.tool-translate {
        width: 85px;
        color: @white;
        font-weight: 300;

        @media (max-width: 1260px) and (min-width: 991px) {
          margin-right: 6px;
          margin-left: 3px;
          font-size: 13px;
        }
        @media (max-width: 767px) {
          margin-right: -3px;
          font-size: 11px;
          line-height: 45px;
        }

        & i {
          color: @white;

          @media (max-width: 1260px) and (min-width: 991px) {
            margin-right: 4px;
            font-size: 15px;
          }
          @media (max-width: 767px) {
            transform: translateY(0);
            margin-right: 4px;
            font-size: 11px;
          }
        }
      }
    }

    & .sol-close {
      .focus-outline;
      .no-appearance;
      position: absolute;
      top: 0;
      right: 0;
      color: @header-top-text;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      padding: 0;

      & i.fa {
        font-size: 11px;
      }
    }
    @media @sm {
      flex: 1 0 auto;
    }
  }

  &-box {
    position: absolute;
    width: 189px;
    margin-top: 10px;
    margin-left: -60px;
    padding: 17px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .2);
    text-align: center;
    z-index: 1;
    cursor: default;
    @media @md-strict {
      margin-left: 0;
      right: 45px;
    }
    @media @sm {
      top: 31px;
      left: 50%;
      margin-left: -78px;
      width: 215px;
      padding: 12px;
    }
    &__item {
      text-decoration: none;
      color: @color-1;
      margin: 0 5px;
    }
    & a.sol-btn-round {
      .transition(border);
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      color: @header-top-box-share-icon;
      border: 2px solid @header-top-box-share-border;
      border-radius: 50%;
      font-size: 15px;
      padding-top: 1px;
      text-align: center;
      @media @sm {
        width: 40px;
        height: 40px;
        font-size: 21px;
        padding-top: 0;
        line-height: 37px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border-color: @header-top-box-share-hover;
      }
    }

    &-translate {
      padding: 12px 17px 9px;
    }
  }
  @media @md {
    width: 100%;
  }
}
.sol-wide-content-wrapper {
  & .sol-tool-nav {
    width: 100%;
    max-width: none;
  }
}

.sol-article-top-wrapper {
  & .sol-tool-nav {
    &-item {
      flex: 0 0 31%;

      &:nth-child(2) {
        flex: 1 0 31%;
      }
    }
  }
}

.sol-article-wrapper {
  @media @sm {
    & .sol-tool-nav {
      &-item {
        flex: 0 0 37%;

        & button {
          margin: 0 12px 10px 12px;
        }
      }
    }
  }
}
// READSPEAKER

.sol-article-wrapper {
  & button.sol-js-toggle-readspeaker.sol-listen {
    display: block !important;
  }
}

.sv-readspeaker_expandingplayer-portlet {
  position: relative;

  & > a {
    display: none;
  }

  & div[id^=readspeaker_player].rsexpanded {
    position: absolute;
    left: 0;
    height: auto;
    width: auto !important;
    padding: 17px 17px 22px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0;
    text-align: center;
  }
}
.sol-header-top-item {

  & > .sv-script-portlet {
    @media @md {
      text-align: center;
    }
  }
  @media @sm {
    flex: 0 0 20%;
  }
  

  & .sol-tool-nav {
    width: 100%;
    border: none;
    margin: 0;

    @media @md {
      display: flex;
    }

    @media @sm {
      width: 100%;
    }

    & button {
      padding: 0;
      margin: 0;
      color: @white;
      font-size: 14px;
      line-height: 45px;
      font-weight: 300;
      height: 45px;

      &.sol-listen {
        margin-right: 16px;

        @media (min-width: 991px) and (max-width: 1260px) {
          margin-right: 6px;
          margin-left: 3px;
          font-size: 13px;
        }

        @media @md {
          width: 100%;
        }

        @media @sm {
          margin-right: -3px;
          font-size: 11px;
        }
        &:focus {
          outline: 2px solid yellow;
          outline-offset: 2px;
          z-index: 10;
        }
      }
      &.sol-translate {
        margin-left: 16px;

        @media (min-width: 991px) and (max-width: 1260px) {
          margin-left: 6px;
          margin-right: 8px;
          font-size: 13px;
        }

        @media @md {
          width: 100%;
          margin-right: 10px;
        }

        @media @sm {
          margin-right: 0px;
          margin-left: 0px;
          font-size: 11px;
        }
        &:focus {
          outline: 2px solid yellow;
          outline-offset: 2px;
          z-index: 10;
        }
      }

      & i {
        transform: translateY(2px);
        font-size: 19px;
        margin-right: 8px;
        color: @white;

        @media (min-width: 991px) and (max-width: 1260px) {
          margin-right: 4px;
          font-size: 15px;
        }

        @media @sm {
          transform: translateY(0px);
          margin-right: 4px;
          font-size: 11px;
        }
      }
    }
    &-item {
      margin-right: -30px;
      margin-left: 2px;

      @media @md {
        flex: 1 0 auto;
        margin-right: 0px;
        margin-left: 0px;
      }
      &:first-of-type {
        margin-left: -14px;
        margin-right: 2px;

        @media @md {
          margin-right: 0px;
          margin-left: 0px;
        }
        @media @xs {
          margin-left: -5px;
        }
      }
    }
  }
}
.sol-tools-fullwidth {
  & .sol-tool-nav {
    width: 100%;
  }
}
