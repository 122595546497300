.sol-tourism-boxes {
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  padding: 16px;
  margin: auto;
  margin-bottom: 45px;
}
& .sol-pagelist-box {
  z-index: 2;
  flex: 0 0 32.5%;
  margin: 10px auto;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: @white;
  border: solid 1px rgba(53, 56, 59, 0.1);

  @media @md {
    flex: 0 0 48%;
    margin-left: 0;
    margin-right: auto;
  }

  @media @sm {
    flex: 0 0 100%;
    margin: 10px auto;
  }

  & p, h2 {
    font-family: @main-font !important;
    font-weight: 600 !important;
    font-size: 17px !important; 
    background-color: fadeout(@color-4, 40%);
    border-left: 4px solid @color-4;
    padding: 2px 10px;
    height: auto;
    padding-bottom: 5px;
    line-height: 40px;
    margin-bottom: 5px;
    margin-top: 0px !important;

    & a {
      padding-left: 25px;
      text-decoration: none;
      font-family: @main-font;
      font-size: 17px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.35;
      letter-spacing: normal;
      color: #35383b;
      background: url(/webdav/files/System//resources/graphics/icons/angle-right-light.svg) 5px 0px no-repeat;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__content {
    padding: 10px;
    & p {
      background-color: @white;
      border-left: none;
      font-family: @main-font;
      font-size: 18px !important;
      font-weight: 300 !important;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: #35383b;
      & a{
        border: none;
        background: transparent;
        padding: 5px 0px;
        font-family: @main-font;
        font-size: 18px !important;
        font-weight: 300 !important;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.39;
        letter-spacing: normal;
        color: #35383b;
      }
    }
  }
  &.sol-pagelist-findfast {
    background-color: fadeout(@color-4, 70%);
    & p {
      background-color: @color-4;

      &:before {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 100;
        content: '\f05a';
        display: block;
        float: left;
        margin-right: 10px;
        font-size: 19px;
      }
    }
    & .sol-pagelist-box__content {
      padding: 10px;
      & p {
        margin-bottom: 0;
        background-color: transparent;

        &:before {
          content: '\f105';
        }
      }
    }
    @media @md {
      flex: 0 0 100%;
    }
  }
}
