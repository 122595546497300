.sol-startpage-content-bottom {
    & .sol-left, & .sol-right {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        & .sol-startpage-articles-header {
            display: flex;
            background-color: @color-1;
            padding: 6px 34px; 
            line-height: 24px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            & i.fal {
                color: @white;
                font-weight: lighter;
                font-size: 17px;
                margin-right: 8px;
            }
            & h2 {
                color: @white;
                font-weight: 600 !important;
                font-size: 19px;
                margin: 0 !important;
                line-height: 30px;
            }
        }
        & .sol-news-container {
            margin-top: 0;
            margin-bottom: 0;
            background-color: @gray-4;
            padding: 10px 34px;

            & li {
                &:last-child {
                    border: none;
                }
            }
        }
        & .sol-lightgray-background {
            padding: 30px 30px;
            margin-bottom: 30px;
            border-top: 2px solid fadeout(@gray-1, 90%);
        }
    }
    & .sol-left {
        & .sol-startpage-articles-header {
            border-top-right-radius: 0;
        }
    }
    & .sol-right {
        & .sol-startpage-articles-header {
            border-top-left-radius: 0;
            background-color: @color-1;
            border-left: 4px solid @white;
        }
    }
    & .sol-startpage-event {
        padding: 26px 35px;
        .sv-image-portlet {
            margin-bottom: 20px;
        }
        h2 {
            margin-top: 0;
            a {
                color: @widget-heading-text;
            }
        }
        &:hover {
            h2 {
                a {
                    color: @widget-heading-text;
                    text-decoration: underline;
                }
            }
        }
        &__info {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;

            & p {
                font-family: @main-font;
                font-size: 15px !important;
                font-weight: 500;
                margin: 0 auto 0 6px;
                white-space: nowrap;
                margin-bottom: 20px;

                &:last-child {
                    white-space: normal;
                    margin-bottom: 0;
                }
                & i.fal {
                    color: @color-1;
                    font-size: 15px;
                    font-weight: 300;
                    vertical-align: top;
                    margin-top: 2px;
                    margin-left: 25px;
                    margin-right: 5px;
    
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        & h2 {
            font-family: @main-font;
            color: @color-1;
            font-size: 19px;
            font-weight: 600;
        }
        & p {
            font-family: @main-font;
            color: @black;
            font-size: 18px;
            font-weight: 300;
        }
    }
    & .sol-startpage-available-positions {
        margin-bottom: 0;
        padding: 26px 35px;
        
        & h2 {
            font-family: @main-font;
            color: @color-1;
            font-size: 19px;
            font-weight: 600;
            margin-top: 0;
            a {
                color: @widget-heading-text;
            }
        }
        &:hover {
            h2 {
                a {
                    color: @widget-heading-text;
                    text-decoration: underline;
                }
            }
            & p {
              text-decoration: underline;
              color: #006579;
            }
        }
        & p {
            font-family: @main-font;
            color: @black;
            font-size: 18px;
            font-weight: 300;
        }
    }
}
