
.sol-box-decoration {
  .sol-clearfix;
  border-radius: 4px;
  background-color: @box-decoration-bg;
  //box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  margin-bottom: 20px;
  margin-top: 5px;
  &-header {
    display: block;
    padding: 13px 15px;
    height: 45px;
    background-color: fadeout(@color-4, 40%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: @black;
    text-align: left;
    text-decoration: none;
    line-height: 25px;
    font-weight: 500;
    font-size: 17px;
    margin: 0;

    @media @md {
      font-size: 17px;
    }
    a & {
      .focus-outline;
    }
  }
  .sv-text-portlet.sol-btn-small .font-btn {
    margin-bottom: 5px;
  }
  & .sv-decoration-content {
    background-color: #FFF6CC;
  }
}
.sol-divider-decoration {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid @divider-decoration;
}
.sol-simple-box-decoration {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 8px 20px 2px 15px;
  border: 1px solid @simple-box-decoration-border;
  border-radius: 3px;
  background-color: @simple-box-decoration-bg;
  &.sol-simple-box-decoration-alt {
    background-color: @simple-box-alt-bg;
  }
  & > div {
    display: block;
    margin-bottom: 5px;
  }
}
.sv-editing-mode {
  .sol-error-box,
  .sol-warning-box {
    display: block;
  }
}
.sol-error-box,
.sol-warning-box {
  display: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.sol-warning-box {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.sol-error-box {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.sol-listingpage-container {
  padding-top: 0;
  margin-top: -10px;

  @media @sm {
    padding-top: 0px;
    margin-top: 0px;
  }
  @media @md{
    margin-top: 0px;
  }
  @media @xl {
    padding-top: 10px;
    margin-top: -30px;
  }

  & .pagecontent {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    @media @sm {
      margin-top: -10px;
    }

    & .sol-pagelist-box {
      z-index: 2;
      flex: 0 0 31%;
      margin: 15px 1%;
      border-radius: 4px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
      background-color: @white;
      border: solid 1px rgba(53, 56, 59, 0.1);

      &.sol-clickable-element {
        &:hover {
          & p, & h2 {
            color: #006579;
            text-decoration: underline;

            & a {
              color: #006579;
            }
          }
        }
      }

      @media @md {
        flex: 0 0 48%;
      }

      @media @sm {
        flex: 0 0 100%;
      }

      & p, & h2 {
        font-family: @main-font !important;
        font-weight: 600 !important;
        font-size: 17px !important; 
        background-color: fadeout(@color-4, 40%);
        border-left: 4px solid @color-4;
        padding: 2px 10px;
        height: auto;
        padding-bottom: 5px;
        line-height: 40px;
        margin-bottom: 5px;
        margin-top: 0;

        & a {
          padding-left: 25px;
          text-decoration: none;
          font-family: @main-font;
          font-size: 17px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.35;
          letter-spacing: normal;
          color: #35383b;
          background: url(/webdav/files/System//resources/graphics/icons/angle-right-light.svg) 5px 0px no-repeat;

          &:hover {
            color: #006579;
            text-decoration: underline;
          }
        }
      }
      &__content {
        padding: 10px;
        & p {
          background-color: @white;
          border-left: none;
          font-family: @main-font;
          font-size: 18px !important;
          font-weight: 300 !important;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.39;
          letter-spacing: normal;
          color: #35383b;
          & a{
            border: none;
            background: transparent;
            padding: 5px 0px;
            font-family: @main-font;
            font-size: 18px !important;
            font-weight: 300 !important;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.39;
            letter-spacing: normal;
            color: #35383b;
          }
        }
      }
      &.sol-pagelist-findfast {
        background-color: fadeout(@color-4, 70%);
        & p, h2 {
          background-color: @color-4;

          &:before {
            font-family: 'Font Awesome 5 Pro';
            font-weight: 100;
            content: '\f05a';
            display: block;
            float: left;
            margin-right: 10px;
            font-size: 19px;
          }
        }
        & .sol-pagelist-box__content {
          padding: 10px;
          & p {
            margin-bottom: 0;
            background-color: transparent;

            &:before {
              content: '\f105';
            }
          }
        }
        @media @md {
          flex: 0 0 100%;
        }
      }
    }
  }
}
