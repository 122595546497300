.sol-searchpage-wrapper {
  position: relative;

  & .sol-top-searchpage {
    max-width: 700px;

    & .sol-top-search-field {
      & input[type='search'] {
        font-family: @main-font;
        font-size: 17px;
        font-weight: 400;
        height: 55px;
        border: solid 1px fadeout(@gray-1, 65%);
        box-shadow: none;
        color: @black;

        &:-moz-placeholder {
          color: @black;
          font-weight: 500;
          font-family: @main-font;
          font-size: 17px;
        }

        &::placeholder {
          color: @black;
          font-weight: 500;
          font-family: @main-font;
          font-size: 17px;
        }
      }

      & button[type="submit"] {
        width: 70px;
        height: 70px;
        top: -6px;
        right: -17px;
      }

      &:hover {

        & button[type="submit"] {
          background-color: #09b594;
        }
      }
    }
  }

  & ol.sv-search-result {
    & strong {
      font-weight: 600;
    }

    max-width: 700px;

    & li.sv-search-hit {
      box-shadow: none;
      border-bottom: 2px solid darken(@gray-4, 2%);
      padding-left: 0;

      &:hover {
        & .font-heading-3 {
          text-decoration: underline;
          color: @search-hit-text-hover;
        }
      }

      & a {
        color: @color-1;
        font-family: @main-font;
        font-size: 19px;
        display: block;
        margin-top: 10px;
        margin-bottom: 6px;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }

      & p {
        color: @black;
        font-family: @main-font;
        font-size: 18px;
        font-weight: 300;
        margin-top: 5px;

        & a {
          font-size: 13px;
          color: @black;
          font-weight: normal;

          &:hover {
            color: #006579 !important;
          }
        }
      }

      & img {
        display: none;
      }

      & .sol-search-hit-date {
        display: inline;
        font-size: 14px;
        font-weight: normal;
      }

      & .sol-search-hit-type {
        display: block;
        font-size: 13px;
        font-weight: normal;
      }
    }
  }

  & .sv-search-facets {
    min-width: 300px;
    border: 1px solid fadeout(@gray-1, 60%);
    border-bottom: none;
    padding: 0 !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &>div:nth-child(2),
    &>div:nth-child(3),
    &>div:nth-child(4),
      {
      & ul {
        border-top: none;
      }

      & p {
        display: none;
      }
    }


    @media (max-width: 1200px) {
      border: none;
    }

    & .sv-search-facet-name {
      background-color: fadeout(@color-4, 50%);
      padding: 12px 15px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      font-family: @main-font;
      font-size: 15px;
      font-weight: 500;

      @media (max-width: 1200px) {
        border-left: 4px solid @color-4;

        &.active {
          &::after {
            float: right;
            font-family: 'Font Awesome 5 Pro';
            content: "\f077";
          }
        }

        &::after {
          float: right;
          font-family: 'Font Awesome 5 Pro';
          content: "\f078";
        }
      }
    }

    & ul.sv-search-facet-items {
      @media (max-width: 1200px) {
        display: none;
        border: 1px solid fadeout(@gray-1, 60%);
      }

      & li {
        padding: 4px 4px 9px 9px !important;
        margin-bottom: 0 !important;
        font-family: @main-font;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid fadeout(@gray-1, 60%);

        &.sv-search-facet-item-selected {
          min-height: 45px;
          line-height: 30px;
          padding-top: 5px !important;

          & a {
            width: 247px;
            margin-left: -30px;
            padding-left: 30px;
            position: relative;
            z-index: 2;
            text-decoration: underline;
          }

          &:before {
            font-family: 'Font Awesome 5 Pro';
            content: '\f00d';
            font-size: 18px;
            position: relative;
            left: 3px;
            top: 3px;
          }
        }

        & a {
          display: inline-block;
          width: 210px;
          margin-right: 6px;
          text-decoration: none;
        }
      }
    }

    & .sv-search-facet {
      margin: 0 !important;
    }

    & .sv-search-facet-items {
      margin: 0 !important;
    }

    @media (max-width: 1200px) {
      margin-top: 20px;
      float: none !important;
      width: 300px;
    }

    & p {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  & #search12_1e4f37c616489dbb2245160eresult {
    &>p {
      font-family: @main-font;
      font-size: 12px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;

      @media @sm {
        font-size: 19px;
      }

      & a {
        color: darken(@color-1, 5%);
      }

      & strong {
        font-weight: 700;
        margin: 0 3px;
      }
    }
  }

  & .pagination {
    line-height: 15px;

    & span {
      border: none !important;
      font-family: @main-font;
      font-size: 16px;
      text-decoration: none;
      padding: 6px 10px !important;

      &.current {
        background-color: fadeout(@color-4, 40%);
        border-radius: 50%;

        &.prev,
        &.next {
          background-color: transparent;
          font-size: 16px;
          max-height: unset;
          max-width: unset;
        }
      }
    }

    & a {
      border: none !important;
      font-family: @main-font;
      font-size: 16px;
      text-decoration: none;
      margin-bottom: 0 !important;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .sol-search-facet-btn {
    font-family: @main-font;
    font-weight: 300 !important;
    position: absolute;
    top: 135px;
    width: 300px;
    height: 48px;
    background: transparent;
    border: none;
    z-index: 1;

    @media (min-width: 991px) and (max-width: 1200px) {
      top: 158px;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }

  & .sv-facetedsearch-portlet {
    & ul {
      margin-top: 5px;
      margin-bottom: 20px;
      padding-left: 0px;
      list-style: none !important;

      & li {
        color: @black;
        font-family: @alt-font;
        position: relative;
        margin-bottom: 7px;
        padding-left: 15px;
        font-size: 23px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.22;
        letter-spacing: normal;

        @media @sm {
          font-size: 19px;
        }

        &::before {
          content: "•";
          height: 100%;
          color: @color-4;
          margin-right: 10px;
          font-size: 26px;
        }
      }
    }

    & .sv-search-facet-items {
      list-style: none;

      & li {
        &::before {
          content: '';
        }
      }
    }
  }

  p {
    font-family: @main-font;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
  }

  .sol-hit-path-text {
    font-size: 13px !important;
    color: #35383b !important;
    font-weight: normal !important;
    text-decoration: underline !important;
  }
}
