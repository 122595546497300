.sol-widget-decoration-wrapper {
  margin-top: 20px;
}

.sol-widget-decoration {
  //overflow: hidden;
  border-radius: 3px;
  background-color: @widget-bg;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  max-width: 300px;
  margin-bottom: 20px;

  & &-img {
    position: relative;
    background-position: 50% 50%;
    background-size: contain;
    height: 175px;
    max-width: 300px;
    @media @md {
      height: 124px;
    }
    @media @sm {
      height: 250px;
    }
    &:before {
      content: '';
      background-color: @widget-hover-color;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .transition(opacity);
    }
  }

  & .sv-text-portlet {
    position: relative;
    padding: 5px 20px 30px 20px;
    font-size: 13px;
    line-height: 20px;

    & h2 {
      font-family: 'Fira Sans',sans-serif;
      color: #00866d;
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 3px;
      margin-top: 0;
      
      & a {
        font-family: 'Fira Sans',sans-serif;
        color: #00866d !important;
        font-size: 19px;
        // text-decoration: underline;
      }
    }
    & a {
      &:hover {
        text-decoration: none;
      }
    }
    & p a {
      text-decoration: none;
    }
    & p strong a {
      color: #00866d !important;
    }
  }
  &.sol-clickable-element {
    &:hover {
      & a, & p {
        text-decoration: underline;
        color: #006579;
      }
    }
  }

  & .font-widget-heading {
    color: @widget-heading-text;
    font-family: @main-font;
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 3px;

    &:hover {
      & a {
        color: @widget-heading-text;
        text-decoration: underline;
      }
    }


    & a {
      color: @widget-heading-text;
      font-family: @main-font;
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;

      & .sv-linkicon {
        display: none;
      }
    }
  }

  & .font-normal {
    height: 100%;
    max-height: none;
    font-family: @main-font;
    margin-bottom: 0;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.4em;
    overflow: hidden;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: auto;
      max-height: none;
      overflow: visible;
    }
  }
  @media @md {
    margin: auto;
  }

  &:hover {
    & .font-widget-heading {
        & a {
          color: @widget-heading-text;
          text-decoration: underline;
        }
      }
  }
}
.sol-widget-fullwidth {
  & .sv-column-3 {
/*     &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    } */
    @media @md {
      flex: 1 0 50% !important;
    }
    @media @sm {
      flex: 1 0 100% !important;
      padding: 0 !important;
    }
  }
}
