.sol-tourism-template {
  & .sol-page__top-text-container {
    margin-top: -80px !important;
    padding-bottom: 0px !important;

    @media (max-width: 1200px) and (min-width: 991px) {
      margin-top: -35px !important;
      margin-bottom: 80px !important;
    }
    @media @md {
      margin-top: -20px !important;
      margin-bottom: 25px !important;
    }
    @media @sm {
      margin-top: 15px !important;
      margin-bottom: 30px !important;
    }
  }
  & .sol-top-image__header--text {
    @media @md {
      height: 110px;
      margin-top: -30px;
    }
    @media @sm {
      height: 110px;
      margin-top: -45px;
    }
  }
}

