
  .sol-selfservice-result {
    list-style-type:none;
    margin-left:0; 
    padding-left:0; 
    margin-top:0.7em;
    font-family: @main-font;
    //border-right: 1px solid rgba(0, 134, 109, 0.15);
  }

 .sol-selfservice-searchhit {
    margin-left:0;
    padding-left:0.5em;
    padding-top:0.5em;
    padding-right:0.5em;
    padding-bottom:0.5em;
    border-right: 1px solid rgba(0, 134, 109, 0.15);
    /* background-color: rgba(0, 134, 109, 0.15); */
    border-left: 1px solid rgba(0, 134, 109, 0.15);
    
  }

  .sol-selfservice li.sv-search-facet-item.sv-search-facet-item-selected {
    //color: white;
    
  }

  .sol-selfservice-searchhit-odd {
    border-right: 1px solid rgba(0.15, 134, 109, 0.15);
    background-color: rgba(0, 134, 109, 0.15);
    border-left: 1px solid rgba(0.15, 134, 109, 0.15);

  }

  .sol-service-category {
    //cursor: pointer;
    
  }

  /*.sol-service-category:before {
    content: "\f054";
    font-family: FontAwesome;
    display: inline-block;
    font-size: 11px;
  }*/

  .sol-selfservice-href {
    display: block;
    .fa-file::before{
      padding-right: 5px;
    }
    .fa-desktop::before{
      padding-right: 5px;
    }

  }

  .sol-selfservice-toggler a {
    text-decoration: none;
    .fa-service-collapsible-icon{
      -webkit-transition: -webkit-transform 0.2s ease-in-out;
      transition: -webkit-transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      font-weight: 300;
      font-size: 28px;
    }

  }

  .sol-selfservice-searchfield {
    padding-left : 32px;
    display: inline;
    width: 80%;
    font-family: 'Fira Sans',sans-serif;
    font-size: 16px;
    font-weight: 300;
    height: 55px;
    border: solid 1px rgba(158,158,158,0.35);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #35383b;
    background-color: #fff;
    margin-left: 34px;
  }

  a[aria-expanded=true]:before {
    content: "\f068";
  }
.sv-vamiddle.sol-service-button {
    width: 70px;
    height: 70px;
    top: -6px;
    right: -17px;
    border-radius: 50%;
    background-color: #00866d;
    color: #fff;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    margin-left: -20px;
    @media @sm{
      margin-left: 290px;
      margin-top: -90px;
    }
  }

span.sol-event-checkbox-service{
  position: absolute;
  top: 14px;
  left: 34px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border: 1px solid @color-1;
  border-radius: 50%;
  background-color: @white;

    &:hover{
      background-color: fadeout(@color-1, 80%);
    }

    &:after{
      left: -1px;
      top: -1px;
      width: 20px;
      height: 20px;
      content: "";
      position: absolute;
      background: url('/webdav/files/System//resources/graphics/icons/circle_128x128.png');
      background-size: cover;
      background-position: center;
      visibility: hidden;
    }
}
.sol-service-category-wrapper{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
      @media @sm {
        margin-left: 10px;
      }

  .sol-service-category{
    flex: 0 0 48%;
    height: 50px;
    line-height: 45px;
    list-style: none;
    border-bottom: 1px solid @gray-2;
    position: relative;
    @media @sm {
      flex: 0 0 75%;
    }

    .sol-event-radio-btn{
      opacity: 0;
      cursor: pointer;
      top: 15px;
      left: 20px;
      z-index: 2;
    }
  }
}


.font-heading-service-1{
  padding-top: 32px;
  font-family: @main-font;
  color: @black;
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 23px;
  line-height: 1.32em;
  font-weight: 600;
  margin-left: 34px;
  @media @md {
    font-size: 23px;
    line-height: 25px;
  }
  @media @sm {
    font-size: 23px;
  }
  @media @xs {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 26px;
  }
  
}
.sv-search-facet-item-selected .sol-event-checkbox-service {
    &:after {
      visibility: visible;
    }
 }

.sol-service-cat{
  font-size: 19px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 34px;
  @media @sm {
    padding-left: 45px;
  }
}

.sol-cat-title{
  font-size: 15px;
  padding-left: 48px;

}

.subheading3{
  font-size: 19px;
  font-weight: 600;
  line-height: 1.26;
}
.sol-mer-info-but{
  height: 45px;
  width: 121px;
  font-family: @main-font;
  font-weight: normal;
  background-color: #00866d;
  border-radius: 30px;
  font-size: 16px;
  color: white;
  text-align: center;
  line-height: 45px;
}
.sol-service-border{
    border: solid 1px rgba(53, 56, 59, 0.15);
    margin-top: 40px !important;
    margin-bottom: 15px !important; 
}
.sol-download-file{
padding-left: 2px;
text-decoration: none !important;
font-size: 18px;
&:hover{
  text-decoration: underline !important;
}
}
.sol-to-service{
padding-left: 2px;
flex: 0 0 50%;
text-decoration: none !important;
font-size: 18px;
&:hover{
  text-decoration: underline !important;
}
}

.sol-category-container{
  background-color: @gray-4;
  padding-bottom: 35px;
}
.sol-wrapper-download-service{
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 15px;
}
