.sol-section {
  padding-top: 0px;
  padding-bottom: 50px;
  @media @md {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  @media @sm {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media @xs {
    padding-top: 0px;
    padding-bottom: 20px;
  }
}
.sol-wide-content-wrapper {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
.sol-listingpage-container {
  @media @xl {
    margin-top: -90px;
  }
  @media @md {
    margin-top: -20px;
  }
  &.sol-section {
    @media @sm {
      margin-top: 10px;
      padding-top: 0px;
      padding-bottom: 25px;
    }
  }
}
.sol-article-wrapper,
.sol-subpage-wrapper {
  & .sv-image-portlet,
  & .sv-mfpimage-portlet {
    margin-bottom: 20px;
    position: relative;
    .sv-portlet-image-caption {
      background-color: @img-text-bg;
      bottom: 0;
      color: @img-text;
      font-family: @main-font;
      font-size: 16px !important;
      max-width: 100%;
      padding: 3px 5px;
      position: absolute;
      right: 0;
    }
  }
  & .sv-linkicon {
    display: none;
  }
}
.sol-subpage-wrapper {
  & p.font-description {
    font-size: 24px;
    font-weight: 300;
    @media @md {
      font-size: 23px;
    }
    @media @sm{
      font-size: 19px;
    }
  }

  & p.font-normal {
    @media @md {
      font-size: 19px;
    }
  }
}
