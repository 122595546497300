.sol-article-listing {
  & .sol-article-filter {
    &__categories, &__areas {
      & fieldset > div {
        width: 50%;
        display: inline-block;
        padding: 10px;
      }
    }
  }
}
