.sol-popular-pages {

  .sol-clearfix;
  background-color: @top-content-popular-bg;
  padding: 10px 35px 5px;
  max-height: 50px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  @media @sm {
    background-color: @top-content-popular-bg-phone;
  }
  & > a,
  & > div {
    color: @top-content-popular-text;
    float: left;
    margin-bottom: 5px;
    @media @sm {
      font-size: 13px;
    }
  }
  & > div {
    display: inline-block;
    margin-right: 10px;
    font-weight: 600;
    font-size: 17px;
  }
  & > a {
    font-size: 16px;
    opacity: 0.8;
    margin-right: 15px;
    text-decoration: none;
    .transition(opacity);
    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
    & i.fal {
      opacity: 1;
      font-weight: 500;
      line-height: 15px;
      width: 18px;
      height: 18px;
      vertical-align: middle;
      text-align: center;
    }
  }

  @media @md {
    display: none;
  }
}
