.sol-breadcrumbs-wrapper {
  .sol-hide-sm;
  padding-top: 6px;
  padding-bottom: 6px;
  .sol-breadcrumbs {
    margin-bottom: 0px;
    .sol-clearfix;
    & li {
      float: left;
      & > a,
      & > span {
        font-size: 14px;
        font-weight: 500;
      }
      & > a {
        text-decoration: underline;
        color: @breadcrumbs-text;
        &:hover {
          color: @breadcrumbs-text-hover;
          text-decoration: underline;
        }
      }
      & > span {
        color: @breadcrumbs-text-active;
        &.sol-divider {
          margin-right: 0;
          margin-left: 7px;
        }
      }
    }
    @media @sm{
      display: none;
    }
  }
}
