.sol-action {
  &__link {
      display: inline-flex;
      width: fit-content;
      max-width: 670px;
      height: auto;
      padding: 10px 30px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: normal;
      line-height: 25px;
      text-decoration: none;
      background-color: fadeout(@color-4, 40%);
      border-radius: 100px;

      @media @sm {
          max-width: 480px;
      }

      @media @xs {
          max-width: 320px;
      }

      & .sol-action__icon {
        padding-top: 3px;

        i {
          position: relative;
          width: 23px;
          height: 100%;
          margin-right: 10px;
          font-size: 23px;

          &.fa-angle-left {
            margin-right: 0;
          }

          &::before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &:hover {
          text-decoration: underline;
          background-color: @color-4;
      }
  }
}

.sol-collapsible {
  .sol-action__link {
      margin: 20px 0;
  }
}
