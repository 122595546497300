/* stylelint-disable color-function-notation */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable alpha-value-notation */
.sol-eventlist {
  &__wrapper {
    position: relative;
    display: flex;
    padding-bottom: 60px;
    margin: 20px 0;

    @media @md {
      display: block;
    }

    & .sol-filter-mobile {
      position: relative;
      display: none;
      padding-bottom: 90px;

      @media @md {
        display: block;
      }
    }

    & .sol-filter-desktop {
      display: block;

      @media @md {
        display: none;
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
      height: 100%;

      & .sol-eventlist__list {
        float: left;
        width: 700px;
        height: 100%;
        padding-bottom: 60px;
        margin-right: 20px;

        .clearfix {
          overflow: auto;
        }

        @media @md {
          width: 100%;
          height: auto;
        }

        /* stylelint-disable-next-line media-feature-range-notation */
        @media (min-width: 991px) and (max-width: 1200px) {
          width: 520px;
        }
      }

      & .sol-eventlist-filters {
        float: left;
        width: 320px;
        height: 100%;

        .clearfix {
          overflow: auto;
        }

        &__search--text input {
          flex: 0 0 90% !important;
        }

        @media @md {
          width: 100%;
          height: auto;
        }
      }

      & .sol-eventlist__show-more,
      & .sol-eventlist__show-less {
        top: 20px;
        left: 0;
        text-decoration: none;

        @media @md {
          left: 0;
        }
      }

      & .sol-eventlist__item {
        & a {
          @media @md {
            display: block;
          }
        }
      }
    }
  }

  &__list {
    flex: 0 0 65%;
    max-width: 700px;
    padding-left: 0;
    list-style: none;
  }

  &__item {
    border-bottom: 2px solid @gray-4;

    & > a {
      display: flex;

      & > div {
        &:first-child {
          flex: 0 0 30%;
          padding: 18px 18px 18px 0;

          @media @sm {
            padding: 0;
          }
        }

        &:last-child {
          flex: 1 0 70%;
          padding: 16px 0;
        }
      }

      @media @sm {
        flex-direction: column;
      }
    }

    &--image {
      @media @sm {
        width: 100%;
        height: auto;
        max-height: unset;
      }
    }

    &--header {
      margin-top: 5px !important;
      font-family: @main-font;
      font-size: 19px;
      font-weight: 600;
      color: @color-1;
    }

    &--preamble {
      margin-top: 0 !important;
      margin-bottom: 8px !important;
      font-family: @main-font;
      font-size: 18px;
    }

    &--info {
      & span {
        display: inline-block;
        margin-right: 14px;
        font-family: @main-font;
        font-size: 15px;
        font-weight: 500;

        @media @sm {
          display: block;
        }

        & i {
          margin-right: 7px;
          font-size: 15px;
          white-space: nowrap;
        }

        & ul {
          display: inline-block;
          list-style: none;

          @media @sm {
            display: inline-flex;
          }

          & li {
            display: inline-block;

            @media @sm {
              display: inline-flex;
            }
          }
        }
      }

      &-categories {
        width: 100%;

        & span {
          margin-right: 6px;
        }
      }
    }

    & a {
      text-decoration: none;
    }

    @media @sm {
      padding-bottom: 20px;
      margin-top: 20px;
      border-bottom: 2px solid @gray-3;
    }
  }

  &__show-more,
  &__show-less {
    position: relative;
    top: 20px;
    left: 0;
    padding: 12px 30px;
    margin-top: 40px;
    font-family: @main-font;
    font-size: 17px;
    text-decoration: none;
    background-color: fadeout(@color-4, 40%);
    border: none;
    border-radius: 100px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      background-color: @color-4;
    }
  }

  &-filters {
    flex: 0 0 35%;
    padding: 20px;
    margin-right: auto;
    margin-left: auto;

    &__create {
      position: relative;
      padding: 7px 36px 7px 24px;
      margin-top: 0;
      margin-bottom: 20px;
      margin-left: 64px;
      font-family: @main-font;
      font-size: 17px;
      text-decoration: none;
      background-color: fadeout(@color-4, 40%);
      border: none;
      border-radius: 100px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
        background-color: @color-4;
      }

      & i {
        position: absolute;
        top: 14px;
        right: 14px;
        margin-left: 8px;
      }

      @media @md {
        position: relative;
        left: 50%;
        margin-left: -123px;
      }
    }

    &__search {
      border: 1px solid @gray-2;
      border-radius: 4px;

      &--header {
        padding: 5px 20px;
        background-color: fadeout(@color-4, 40%);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        & i {
          margin-right: 6px;
          font-size: 15px;
          font-weight: 500;
        }

        & span {
          font-family: @main-font;
          font-size: 16px;
          font-weight: 600;
          color: @black;

          @media @md {
            &::after {
              float: right;
              font-family: 'Font Awesome 5 Pro';
              content: '\f078';
            }
          }
        }
      }

      &--body {
        @media @md {
          display: none;
        }
      }

      &--text {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;

        & span {
          display: block;
          flex: 0 0 100%;
          width: 100%;
          font-size: 15px;
          font-weight: 600;
        }

        & input {
          flex: 0 0 100%;
          width: 100%;
          padding: 10px 14px;
          margin-top: 0;
          margin-bottom: 20px;
          font-family: @main-font;
          font-size: 15px;
          font-weight: 300;
          color: @black;
          background-color: @white;
          border: 1px solid @gray-2;
        }

        & .vdatetime {
          & input {
            width: 100%;
            padding: 10px 14px;
            margin-top: 0;
            margin-bottom: 20px;
            font-family: @main-font;
            font-size: 15px;
            font-weight: 300;
            color: @black;
            background-color: @white;
            border: 1px solid @gray-2;
          }
        }

        & label {
          font-size: 15px;
          font-weight: 600;
        }

        .dateContainer {
          flex: 0 0 46%;

          &:first-of-type {
            margin-right: auto;
          }

          &:last-of-type {
            margin-left: auto;
          }
        }
      }

      & button {
        padding: 12px 30px;
        margin: 0 0 20px 20px;
        font-family: @main-font;
        font-size: 17px;
        background-color: fadeout(@color-4, 40%);
        border: none;
        border-radius: 100px;

        &:hover {
          text-decoration: underline;
          background-color: @color-4;
        }
      }
    }

    &__categories,
    &__areas {
      margin-top: 30px;
      border: 1px solid @gray-2;
      border-radius: 4px;

      &--header {
        padding: 5px 20px;
        background-color: fadeout(@color-4, 40%);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        & i {
          margin-right: 6px;
          font-size: 15px;
          font-weight: 500;
        }

        & span {
          font-family: @main-font;
          font-size: 16px;
          font-weight: 600;
          color: @black;

          @media @md {
            &::after {
              float: right;
              font-family: 'Font Awesome 5 Pro';
              content: '\f078';
            }
          }
        }
      }

      &--title {
        padding-left: 50px;
        font-size: 16px;
        font-weight: 400;
      }

      &--categorylist,
      &--arealist {
        & li {
          position: relative;
          height: 50px;
          line-height: 47px;
          list-style: none;
          border-bottom: 1px solid @gray-2;

          & input {
            position: absolute;
            top: 15px;
            left: 20px;
            z-index: 2;
            width: 20px;
            height: 20px;
            cursor: pointer;
            opacity: 0;

            &:hover ~ .sol-checkbox {
              background-color: fadeout(@color-1, 30%);

              &::after {
                display: none;
              }
            }

            &:checked ~ .sol-checkbox {
              background-color: @color-1;

              &::after {
                display: block;
              }
            }

            &::after {
              position: absolute;
              display: none;
              content: '';
            }
          }

          & span.sol-checkbox {
            position: absolute;
            top: 15px;
            left: 20px;
            width: 20px;
            height: 20px;
            background-color: @white;
            border: 1px solid @color-1;
            border-radius: 50%;

            &::after {
              position: absolute;
              top: 3px;
              left: 6px;
              width: 5px;
              height: 10px;
              content: '';
              border: solid @white;
              border-width: 0 3px 3px 0;
              transform: rotate(45deg);
            }
          }
        }

        @media @md {
          display: none;
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-top-image__header--text {
    display: table;

    @media @xl {
      margin-top: -15px;
    }
  }
}

#lediga-jobb-item {
  .gray-background {
    > div {
      @media @sm {
        float: none !important;
        width: auto !important;
      }
    }
  }
}

.sol-footer-wave--svg {
  position: inherit;
  right: 0;
  transition: all 0.2s ease;
  transform: none;
}

.sol-event-registration-success {
  position: relative;
  padding: 12px;
  background-color: #cfc;
  border: 2px solid darken(#cfc, 60%);
  border-radius: 10px;

  & p {
    font-size: 28px;
  }

  &::after {
    position: absolute;
    top: 20px;
    right: 25px;
    font-family: FontAwesome;
    font-size: 40px;
    color: darken(#cfc, 60%);
    content: '\f00c';
  }

  & a {
    background-color: #ffe366;

    &:hover {
      background-color: #ffd100;
    }
  }
}

.sol-event-registration-failure {
  position: relative;
  padding: 12px;
  background-color: #fcc;
  border: 2px solid darken(#fcc, 60%);
  border-radius: 10px;

  & p {
    font-size: 28px;
  }

  &::after {
    position: absolute;
    top: 20px;
    right: 25px;
    font-family: 'Font Awesome 5 Pro';
    font-size: 40px;
    font-weight: 900;
    color: darken(#fcc, 60%);
    content: '\f321';
  }
}


/* NEW UPDATED APP 2020 */

.sol-event-listing {
  margin: 0;

  &-result {
    padding: 15px 20px 10px;
    background-color: #f8f8f8;

    @media @sm {
      padding: 20px 4px;
    }
  }

  & .sol-event-item {
    display: block;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.05);

    @media @sm {
      padding: 10px 12px 16px;
      margin: 12px 0;
    }

    &:hover {
      & h3, & .sol-event-item__desc {
        text-decoration: underline;
      }
    }

    &__content {
      display: flex;
      flex-wrap: wrap;

      @media @sm {
        flex-direction: column;
      }
      
      &--left {
        display: flex;
        justify-content: center;
        max-width: 36%;
        max-height: none;
        margin-right: 4%;
        overflow: hidden;

        @media @sm {
          max-width: none;
          margin-right: 0;
        }

        & .sol-event-item__img {      
          display: block;
          min-width: 190px;
          max-width: 300px;
          max-height: none;
          margin: auto;
        }
      }

      &--right {
        max-width: 60%;
        max-height: 240px;  
        
        @media @sm {
          max-width: none;
          max-height: none;
        }
      }
    }

    &__heading {
      margin-top: 12px;
      margin-bottom: 6px;

      & a {
        font-family: 'Fira Sans',sans-serif;
        font-size: 19px;
        font-weight: 600;
        color: #00866d;
        text-decoration: none;
      }
    }

    &__desc {
      font-family: 'Fira Sans',sans-serif;
      font-size: 18px;

      @media @sm {
        margin-bottom: 12px;
      }
    }

    &__date {
      & > div {
        display: inline-block;
        margin-right: 12px;
        font-size: 15px;
        font-weight: 500;

        @media @sm {
          line-height: 2;
        }
      }

      & i {
        margin-right: 2px;
        font-size: 16px;
      }

      & .sol-event-item__location {
        display: inline-block;
        width: auto;
        margin-right: 12px;
        font-size: 15px;
        font-weight: 500;

        @media @sm {
          line-height: 2;
        }
      }

      & .sol-event-item__free {
        display: block;
        width: auto;
        margin-top: 5px;
        margin-right: 12px;
        font-size: 15px;
        font-weight: 500;
        line-height: normal;

        @media @sm {
          margin-bottom: 10px;
        }
      }

      & .sol-event-item__categories {
        display: flex;
        margin-top: 4px;

        @media @sm {
          line-height: 2;
        }

        & i {
          padding-top: 7px;
          font-size: 15px;
          font-weight: 500;
        }

        & ul {
          display: flex;
          flex-wrap: wrap;
          padding-left: 0;
          list-style: none;

          & li {
            display: inline-block;
            margin-right: 10px;
            font-size: 15px;
            font-weight: 500;

            & span {
              margin-left: -3px;
            }
          }
        }
      }
    }
  }

  & .sol-show-more-btn {
    padding: 8px 25px;
    margin-left: 10px;
    font-size: 18px;
    background-color: rgba(255, 209, 0, 0.6);
    border-radius: 20px;

    &:hover {
      background-color: rgba(255, 209, 0, 0.8);
    }
  }

  & .sol-event-filter {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 30px 18px;

    // border-top: 3px solid rgba(255,209,0,.6);
    margin: 0 !important;
    background-color: #f8f8f8;
    border: none;

    // border-bottom: 10px solid #fff;

    @media @sm {
      padding: 6px 16px 30px;
    }

    &__query, &__date {
      display: flex;
      flex: 1 0 46%;
      flex-wrap: wrap;
      margin: 20px 2%;

      @media @sm {
        margin: 10px 0 5px;
      }

      & label {
        flex: 1 0 100%;
        font-size: 18px;
        font-weight: 500;
      }

      & input {
        flex: 1 0 100%;
        max-width: 100%;
        height: 44px;
        padding: 0 12px;
        font-size: 16px;
        background-color: #fff;
        border: 1px solid #c3c3c3;
      }
    }

    &__categories, &__areas {
      & fieldset {
        display: block;
        margin: 0 !important;

        & > div {
          display: inline-block;
          width: 50%;
          padding: 4px 2px 4px 4px !important;

          @media @sm {
            width: 100%;
          }

          & label {
            font-size: 18px;
          }

          & input {
            position: relative;
            top: 3px;
            display: inline-block;
            width: 22px;
            height: 22px;
            padding: 0;
            background-color: #fff;
            border: 1px solid #1b927c;
            border-radius: 2px;
            box-shadow: none;
            appearance: none;

            &:hover {
              background-color: #b9dbd4;
            }

            &:checked {
              background: #fff url('https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg') no-repeat;
              background-position: center;
              background-size: 16px;
              border: 2px solid #1b927c;
              box-shadow: none;
            }
          }
        }

        & > legend {
          display: none;
        }
      }
    }

    & fieldset {
      margin: 4% 2%;
      border: none;
    }

    & .number-of-hits {
      position: relative;
      left: calc(100% - 282px);
      display: block;
      margin-top: 20px;
      margin-left: 16px;
      font-family: 'Fira Sans',sans-serif;
      font-size: 17px;
      font-weight: 500;

      @media @sm {
        left: 0;
        display: inline-block;
        width: 100%;
        margin-top: 15px;
        margin-left: 0;
      }
    }

    & .sol-clear-filter {
      position: relative;
      top: 40px;
      left: calc(100% - 430px);
      padding: 8px 24px;
      margin-top: 12px;
      margin-left: 16px;
      font-family: 'Fira Sans',sans-serif;
      font-size: 17px;
      font-weight: 500;
      text-decoration: underline;
      border: 0;
      border-radius: 100px;

      @media @sm {
        top: 0;
        left: 0;
        display: inline-block;
        padding: 0;
        margin-left: 0;
      }

      &:hover {
        color: @color-3;
      }

      & i {
        margin-left: 4px;
      }

      & button[type='submit'] {
        visibility: hidden;
      }
    }
  }

  .free-event-container {
    margin: 18px 20px 0;

    @media @sm {
      margin: 15px 0 0;
    }

    & label {
      font-size: 18px;
      font-weight: 500;
    }
  }

  #freeEventCheckbox {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 22px;
    height: 22px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #1b927c;
    border-radius: 2px;
    box-shadow: none;
    appearance: none;

    &:hover {
      background-color: #b9dbd4;
    }

    &:checked {
      background: #fff url('https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg') no-repeat;
      background-position: center;
      background-size: 16px;
      border: 2px solid #1b927c;
      box-shadow: none;
    }
  }

  // Accordions
  .accordion {
    display: block;
    width: 100%;

    @media @sm {
      margin-top: 15px;
    }

    & .card {
      display: block;
      float: left;
      width: inherit;
      max-width: 46%;
      margin: 6px 2%;
      background-color: #fff;
      border: 1px solid #c3c3c3;
      border-radius: 0;
      box-shadow: none;

      & i {
        &::before {
          content: '\f107';
        }
      }

      &:hover {
        cursor: pointer;
      }

      @media @sm {
        max-width: 100%;
        margin: 10px 0 5px;
      }

      &:nth-of-type(2n) {
        float: right;
      }

      &-header {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 12px;

        &-title {
          flex: 1 0;
        }

        &-icon {
          flex: 0 1 auto;
        }
      }

      &-content {
        padding: 12px 20px 20px;
        margin-top: 0;
        border-top: 1px solid #ccc;
      }

      &.card-active {
        & i {
          &::before {
            content: '\f106';
          }
        }

        & .sol-event-filter__categories, .sol-event-filter__areas {
          visibility: visible;
          opacity: 1;
          transition-duration: 0s, 0.3s;
          transition-property: visibility, opacity;
        }
      }

      & .sol-event-filter__categories, .sol-event-filter__areas {
        visibility: hidden;
        opacity: 0;
        transition-delay: 0s, 0.1s;
        transition-duration: 0.3s, 0s;
        transition-property: opacity, visibility;
      }
    }

    & p {
      display: flex;
      margin: 0;
      font-family: 'Fira Sans',sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}


[data-whatinput='keyboard'] .sol-event-item__heading a:focus {
  z-index: 10;
  display: block;
  outline: 2px solid #257cc3;
  outline-offset: -2px;
}

.sol-eventguide-top {
  width: 100%;
  margin-top: 30px;

  & .sv-text-portlet-content {
    & h2 {
      margin-top: 0;
    }
  }

  @media @sm {
    margin-right: auto;
    margin-left: auto;
  }
}

.sol-eventguide-bottom {
  width: 100%;
  margin-top: 0;

  & .sv-text-portlet-content {
    margin: 0 10px;

    & h2 {
      margin-top: 0;
    }
  }

  @media @sm {
    max-width: 100%;
    margin-top: 12px;
  }

  & .sol-tabs {
    margin-top: 15px;

    &__buttons {
      display: inline-flex;
      width: auto;
      margin-bottom: 4px;
      border: none;
    }

    &-button {
      display: flex;
      flex: 0 1;
      flex-direction: column;
      justify-content: center;
      height: 60px;
      border: none;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: 0 1 200px;
        max-width: 200px;
        line-height: 1;
      }

      & > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;
        height: 60px;
        padding: 0 36px;
        margin: 0 10px 0 0;
        font-weight: 300;
        line-height: initial;
        color: black;
        background-color: #fff6cc;
        border: 1px solid #ccc;
        border-bottom: none;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        @media @sm {
          padding: 2px 24px;
        }

        @media @xs {
          height: 55px;
          padding: 0 6px;
          font-size: 0.85em;
        }
      }

      &--active {
        & > span {
          color: black;
          background-color: rgba(255, 209, 0, 0.6);
          border-color: rgba(255, 209, 0, 0.6);
        }
      }
    }

    &__tab {
      padding: 5px 0 15px;
      background-color: #f8f8f8;
      border-top: 3px solid rgba(255, 209, 0, 0.6);

      & > .sv-custom-module {
        & .strex-webapp-form {
          max-width: 800px;
          padding: 30px 40px;
          margin: 30px auto 20px;
          background: #fff;
          border-top: none;
          border-right: 8px solid #f8f8f8;
          border-left: 8px solid #f8f8f8;
          box-shadow: none;

          @media @sm {
            padding: 10px 12px;
            margin: 30px auto 20px;
          }

          & button[type='submit'] {
            margin-top: 15px;
            margin-left: 0;
          }
        }
      }

      & > .sv-text-portlet {
        padding: 25px 20px 15px;
        margin: 25px 20px 0;
        background-color: #fff;

        @media @sm {
          margin: 16px 10px 0;
          border-left: none;
        }
      }

      @media @sm {
        padding: 5px 0 10px;
      }
    }
  }
}

.sol-eventguide {
  & .sol-page-with-top-image {
    & .sol-page__top-text-container {
      @media (min-width: 1200px) {
        padding-bottom: 15px;
        margin-bottom: 120px;
      }

      @media (max-width: 1200px) and (min-width: 991px) {
        margin-bottom: 15px;
      }

      @media (max-width: 991px) {
        padding-bottom: 5px;
      }
    }
  }

  & .sv-mfpimage-portlet, & .sv-image-portlet {
    & img {
      max-width: none !important;
      max-height: 420px !important;
      object-fit: cover;

      @media @sm {
        max-width: none !important;
        max-height: 420px !important;
      }
    }
  }

  & .sol-bottom-wave {
    position: relative;
    top: -165px;

    @media @sm {
      top: -140px;
    }

    & img {
      width: 101%;
      max-width: none;

      @media @sm {
        position: relative;
        top: 10px;
      }
    }

    & .sol-top-image {
      &__header {
        position: relative;
        top: 70px;
        text-align: center;
        
        @media @md {
          top: 50px;
        }

        @media @sm {
          top: 45px;
        }

        &--text {
          display: inline-block;
          padding: 16px 40px 24px;
          background-color: #fff;
          border-radius: 6px;

        }
      }
    }
  }

  .sol-page__top-text-container {
    position: relative;
    bottom: 135px;
    margin-top: -160px;

    @media (max-width: 2100px) {
      bottom: 120px;
      margin-top: -120px;
    }

    @media (max-width: 1500px) {
      bottom: 125px;
      margin-top: -65px;
    }

    @media (max-width: 1200px) {
      bottom: 75px;
      margin-top: -105px;
    }

    @media @md {
      bottom: 45px;
      margin-top: -115px;
    }

    @media @sm {
      bottom: 0;
      margin-top: -110px;
      margin-bottom: 20px;
    }

    & p.sol-page__top-text {
      max-width: 910px;
      margin: 0 auto;

      @media @md {
        max-width: 90%;
      }

      @media @sm {
        max-width: 92%;
      }
    }
  }

  & .sol-listingpage-container {
    @media @xl {
      margin-top: -75px;
    }
  }

  & .sol-eventguide-image {
    & img {
      @media @md {
        width: auto;
        height: 230px;
      }

      @media @sm {
        width: auto;
        height: 180px;
      }
    }
  }

  & .sol-breadcrumbs-wrapper {
    @media @sm {
      left: 10px !important;
      display: inline-block !important;

      & .sol-breadcrumbs {
        display: block;
      }
    }
  }
}
