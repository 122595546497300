.sol-top-search-start {
    padding: 30px 37px 30px 37px !important;

    @media @sm {
        padding: 15px 20px !important;
    }
    &:hover {
        & button[type="submit"] {
            background-color: #f9eaa4;
        }
    }
    & button[type="submit"] {
        top: -7px;
        background-color: #FFE366;
        color: @black;

        & i {
            font-size: 25px;
        }
    }
    & input[type="search"] {
        border: none;
    }
}
