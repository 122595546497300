/* stylelint-disable color-function-notation */
/* stylelint-disable media-feature-range-notation */
/* stylelint-disable alpha-value-notation */
.sol-process {
  &__content {
    max-width: 800px;
    padding: 20px 15px;
    margin: 15px auto 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

    & h2:first-of-type {
      margin-top: 0;
    }

    & > div.sol-process__tab > div > div.sv-layout {
      display: inline-block;
      width: 100%;
    }
  }

  &-flow__tip {
    position: relative;
    top: 12px;
    font-size: 21px;
    font-weight: 300;
    color: #767676;

    @media @sm {
      top: 40px;
      font-size: 18px;
    }
  }

  & .sol-process-flow-button__name {
    display: block;
    height: 100%;
    overflow: hidden;
  }

  &__buttons {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 1150px;
    margin: 0 auto;

    @media (max-width: 1385px) {
      max-width: 825px;
    }

    @media (max-width: 685px) {
      padding-top: 40px;
      margin: 0 -16px;
    }
  }

  &-button {
    position: relative;
    flex: 1 0 33%;
    max-width: 260px;
    height: 120px;
    padding: 20px 30px 10px;
    margin: 42px 60px;
    font-size: 19px;
    font-weight: 600;
    color: black;
    text-overflow: clip;
    letter-spacing: 0.5px;
    vertical-align: middle;
    background-color: #C0E0DD;
    border: 1px solid #00866D;
    border-radius: 4px;
    transition: background-color 300ms, color 300ms ease;

    @media (max-width: 1380px) {
      margin: 42px 45px;
    }

    @media (max-width: 1385px) {
      max-width: 225px;
      padding: 20px 10px;
      margin: 42px 25px;
    }

    @media (min-width: 686px) and (max-width: 950px) {
      min-width: 165px;
      max-width: 184px;
      padding: 20px 6px;
      margin: 42px 12px;
    }

    @media (max-width: 685px) {
      min-width: 114px;
      max-width: 114px;
      height: 90px;
      padding: 8px 6px;
      margin: 28px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.25;
      word-break: break-word;
      border: 1px solid #00866D;
    }

    @media (min-width: 560px) and (max-width: 685px) {
      min-width: 135px;
      max-width: 135px;
      height: 95px;
      padding: 8px 6px;
      margin: 28px 18px;
      font-size: 15px;
    }

    @media (min-width: 375px) and (max-width: 412px) {
      min-width: 105px;
      max-width: 105px;
      padding: 6px 4px;
      margin: 20px 10px;
      font-size: 13px;
    } 

    @media (max-width: 374px) {
      min-width: 92px;
      max-width: 92px;
      padding: 6px 4px;
      margin: 20px 7px;
      font-size: 13px;
    }

    &--active {
      color: white;
      background-color: #00866D;

      & .sol-process-flow-button-index {
        color: black;
        background-color: #C0E0DD;
      }
    }

    &::after {
      position: absolute;
      top: 50%;
      right: -105px;
      width: 105px;
      height: 6px;
      content: '';
      background-color: #00866D;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: 48%;
        height: 8px;
      }

      @media (max-width: 1400px) and (min-width: 1385px) {
        right: -75px;
        width: 75px;
      }

      @media (max-width: 1385px) {
        right: -37px;
        width: 37px;
      }

      @media (max-width: 950px) {
        display: none;
      }
    }

    &::before {
      position: absolute;
      top: calc(50% - 12px);
      left: -45px;
      z-index: -1;
      font-family: 'Font Awesome 5 Pro';
      font-size: 40px;
      font-weight: 600;
      color: #00866D;
      content: '\f30b';

      @media (max-width: 1385px) {
        left: -50px;
      }

      @media (max-width: 1380px) {
        left: -45px;
      }

      @media (max-width: 685px) {
        top: 20px;
        left: -38px;
      }

      @media (max-width: 413px) {
        top: 31px;
        left: -23px;
        font-size: 23px;
      }
    }

    &:focus {
      outline: 2px solid #C0E0DD !important;
      outline-offset: -1px !important;
    }

    &:nth-child(3n+1) {
      &::before {
        display: none;
      }
    }

    &:nth-child(3n) {
      &::after {
        display: none;
      }
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }

    &:first-of-type {
      &::before {
        display: none;
      }
    }
  }

  &-flow-button-index {
    position: absolute;
    top: -20px;
    left: 12px;
    display: block;
    width: 42px;
    height: 42px;
    padding-top: 6px;
    font-size: 21px;
    color: white;
    background-color: #00866D;
    border-radius: 50%;

    @media (max-width: 1400px) {
      top: -22px;
      left: 10px;
      width: 36px;
      height: 36px;
      padding-top: 3px;
      font-size: 20px;
    }

    @media (max-width: 767px) {
      top: -16px;
      width: 30px;
      height: 30px;
      padding-top: 0;
      font-size: 19px;
    }

    @media (max-width: 685px) {
      left: 5px
    }

    @media (max-width: 685px) {
      width: 26px;
      height: 26px;
      padding-top: 2px;
      font-size: 18px;
    }
  }

  &-flow-devider {
    position: absolute;
    top: -50px;
    left: 130px;
    width: 765px;
    height: 6px;
    content: '';
    background-color: #00866D;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 8px;
    }

    @media (max-width: 1380px) {
      left: 130px;
      width: 700px;
    }

    @media (max-width: 1385px) {
      left: 115px;
      width: 600px;
    }

    @media (min-width: 686px) and (max-width: 950px) {
      left: 90px;
      width: 424px;
    }

    @media (min-width: 560px) and (max-width: 685px) {
      top: -38px;
      left: 68px;
      width: 350px;
    }

    @media (max-width: 559px) {
      top: -36px;
      left: 54px;
      width: 282px;
    }

    @media (min-width: 375px) and (max-width: 412px) {
      top: -26px;
      left: 52px;
      width: 254px;
      height: 4px;
    }

    @media (max-width: 374px) {
      top: -26px;
      left: 48px;
      width: 212px;
      height: 4px;
    }

    &::before {
      position: absolute;
      top: 6px;
      left: -8px;
      font-family: 'Font Awesome 5 Pro';
      font-size: 46px;
      font-weight: 600;
      color: #00866D;
      content: '\f309';

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: 7px;
      }

      @media (min-width: 570px) and (max-width: 685px) {
        top: -6px;
        font-size: 40px;
      }

      @media (max-width: 569px) {
        top: -7px;
        left: -8px;
        font-size: 40px;
      }

      @media (max-width: 413px) {
        top: -4px;
        left: -8px;
        font-size: 26px;
      }
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
      width: 6px;
      height: 50px;
      content: '';
      background-color: #00866D;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 8px;
      }

      @media (max-width: 413px) {
        width: 4px;
      }
    }

    &-mobile {
      display: none;
    }
  }

  &-flow-side-button {
    display: inline-block;
    width: fit-content;
    max-width: 670px;
    height: 45px;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 45px;
    text-decoration: none;
    background-color: rgba(255,209,0, 60%);
    border-radius: 100px;

    &[disabled='disabled'] {
      background-color: #f8f8f8;
    }

    &.sol-process-flow-side-button__next {
      float: right;
    }
  }
}

.sol-subpage-wrapper {
  @media (min-width: 686px) {
    & .sol-process__buttons {
      width: 108%;
      margin: 0 -4%;

      & .sol-process-button {
        max-width: 190px;
        padding: 16px 8px;
        margin: 42px 30px;

        &::before {
          left: -63px;
          z-index: -1;
        }

        &::after {
          display: none;
        }
      }
    }

    .sol-process-flow-devider {
      left: 95px;
      width: 510px;
    }
  }

  @media (max-width: 1385px) and (min-width: 686px) {
    & .sol-process__buttons {
      width: 100%;
      margin: 0 auto;

      & .sol-process-button {
        max-width: 145px;
        padding: 15px 5px;
        margin: 42px 18px;
        font-size: 17px;

        &::before {
          left: -40px !important;
        }

        &::after {
          display: none;
        }
      }
    }

    .sol-process-flow-devider {
      left: 70px;
      width: 370px;
    }
  }

  @media (min-width: 1400px) {
    & .sol-process__buttons {
      & .sol-process-button {
        &::before {
          left: -42px;
          z-index: -1;
        }

        &::after {
          right: -50px;
          display: block;
          width: 50px;
        }

        &:nth-child(3n) {
          &::after {
            display: none;
          }
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  @media (max-width: 1385px) and (min-width: 686px) {
    & .sol-process__buttons {
      & .sol-process-button {
        max-width: 142px !important;
        margin: 42px 17px !important;
      }
    }
  }
}
