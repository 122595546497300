.sol-startpage-links {
    display: none;
    background-color: @color-1;
    border-radius: 4px;
    &::before {
        display: none;
    }
    & a {
        flex: 0 0 50%;
        color: @white;
        font-size: 17px;
        text-decoration: none;
        padding: 20px 40px;
        border-bottom: 1px solid fadeout(@white, 40%);
        white-space: nowrap;
        overflow: hidden;

        &:last-child {
            display: none;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            flex: 0 0 100%;
        }

        @media @sm {
            padding: 10px 25px;
            font-size: 15px;
        }
        @media @xs {
            flex: 0 0 100%;
            font-size: 17px;
            padding: 15px 25px;
        }

        &:nth-child(odd) {
            border-right: 1px solid fadeout(@white, 40%);
            @media @xs {
                border-right: unset;
            }
        }

        & i.fal {
            font-size: 29px;
            color: fadeout(@white, 40%);
            margin-right: 15px;
            vertical-align: middle;

            @media @sm {
                margin-right: 8px;
                font-size: 17px;
            }
            @media @xs {
                font-size: 27px;
                margin-right: 15px;
            }
        }
    }
    @media @md {
        display: flex;
        flex-wrap: wrap;
    }
}
