.sol-mobile-header {
  display: none;
  text-align: center;
  background-color: @white;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.05);

  & > div {
    position: relative;
    max-width: 930px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;

    @media @sm {
      padding: unset;
      max-width: 728px;
      height: 55px;
    }
  }

  @media @md {
    display: block;
    padding: 16px 31px;
  }

  @media @sm {
    display: block;
    padding: 12px 5px;
  }

  @media @lg {
    background-color: @header-menu-bg;
  }

  .sol-mobile-logo {
    height: 44px;
    width: auto;
    float: left;

    @media @sm {
      margin-left: 17px;
      margin-top: 10px;
      height: 36px;
    }

    @media (max-width: 767px) and (min-width: 350px) {
      margin-top: 7px;
      height: 40px;
    }

    @media (max-width: 350px) {
      margin-left: 8px;
    }
  }

  .sol-hamburger-wrapper {
    .focus-outline;

    width: 55px;
    height: 55px;
    position: absolute;
    right: 0;
    background-color: @header-menu-bg;
    border: 0;
    text-align: center;
    padding-top: 32px;

    @media (max-width: 767px) and (min-width: 350px) {
      right: 10px;
    }

    @media @xs {
      width: 45px;
    }

    & .sol-hamburger {
      width: 35px;
      position: absolute;
      top: 8px;
      left: 50%;
      margin-left: -18px;

      @media @xs {
        margin-left: 
        -15px;
      }
      & > span {
        display: block;
        width: 35px;
        height: 3px;
        border-radius: 2px;
        background-color: @black;
        transition: transform 500ms;

        @media @xs {
          width: 30px;
        }
      }
      
      & .sol-hamburger-middle {
        margin-top: 6px;
        margin-bottom: 6px;
        transition: width 500ms;
      }

      &.sol-active {
        .sol-hamburger-top {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
          width: 37px;
        }

        .sol-hamburger-middle {
          width: 0;
        }

        .sol-hamburger-bottom {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
          width: 37px;
        }
      }

      @media @sm {
        right: 10px;
      }
    }

    & .sol-hamburger-text {
      color: @black;
      font-size: 14px;
      font-weight: normal;
      font-family: @main-font;

      @media @sm {
        font-size: 11px;
      }
    }
  }

  .sol-header-mobile-search {
    & button {
      font-family: @main-font;
      height: 65px;
      width: 55px;
      overflow: visible;
      background: none;
      border: none;
      font-size: 14px;
      padding-top: 7px;
      position: absolute;
      right: 60px;

      @media @sm {
        font-size: 11px;
      }
      @media @xs {
        width: 45px;
        right: 52px;
      }
      & i {
        margin-bottom: 6px;
        display: block;
        color: @black;
        font-size: 24px;
      }
    }
  }
}

.sol-top-search-mobile {
  display: none;
  &.sol-active {
    position: absolute;
    z-index: 1000;
    width: 100%;

    @media @md {
      top: 165px;
    }

    @media @sm {
      top: 125px;
    }
  }

  .sol-top-search-field {
    & .sol-search-up {
      width: 0;
      height: 0;
      right: 65px;
      top: -19px;
      position: absolute;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 20px solid white;
    }

    & input[type=search] {
      border: none;
      border-radius: 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px @color-3;
      height: 52px;
    }

    & button[type=submit] {
      background-color: transparent;
      color: @color-1;
      margin-right: 30px;
      margin-top: 8px;

      & i {
        font-size: 21px;
        font-weight: 500;
      }
    }
  }
}
