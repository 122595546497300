.sol-link-tablet {
  display: table;
  margin-bottom: 20px;

  &__link {
    display: flex;
    width: fit-content;
    max-width: 670px;
    height: auto;
    padding: 10px 30px;
    line-height: 25px;
    text-decoration: none;
    background-color: fadeout(@color-4, 40%);
    border-radius: 100px;

    @media @sm {
      max-width: 480px;
    }

    @media @xs {
      max-width: 320px;
    }

    & .sol-link-tablet__icon {
      i {
        position: relative;
        width: 23px;
        height: 100%;
        margin-right: 10px;
        font-size: 23px;

        &.fa-angle-left {
          margin-right: 0;
        }

        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    & .sol-link-tablet__text {
      font-size: 16px;
      font-weight: normal;
      text-overflow: ellipsis;
    }

    &:hover {
      text-decoration: underline;
      background-color: @color-4;
    }
  }
}

.sol-collapsible {
  .sol-link-tablet {
    margin: 20px 0;
  }
}
