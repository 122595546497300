@media @md {
  .sv-layout.sv-column-6 {
    margin-bottom: 5px;

    & p.font-normal {
      margin-bottom: 10px;
    }
    & .sv-mfpimage-portlet {
      margin-bottom: 5px;
    }
  }
  .sv-layout .sv-text-portlet-content .font-heading-2 {
    margin-top: 20px;
    margin-bottom: 8px;
  }
}
