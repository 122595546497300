head header {
  position: relative;
  z-index: 50;
  background-color: @header-top-bg;
  margin: 0 !important;
}

header {
  .sol-top-search-desktop {
    @media @md {
      display: none;
    }

    & form div input {
      &:focus {
        outline: 2px solid #257cc3!important;
        outline-offset: -6px!important;
      }
    }
  }

  &.sol-mobile-search-active {
    width: 100%;
  }

  .sol-top-search-mobile,
  .sol-top-search-desktop {
    position: relative;
    background-color: @search-top-bg;
    height: auto;
    overflow: hidden;

    &.sol-active {
      height: 115px;
      background-color: fadeout(@color-1, 10%);

      @media @md {
        height: auto;
      }

      @media @sm {
        height: auto;
      }
    }

    & form {
      position: relative;
      display: block;
      width: 280px;

      @media @md {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        margin-top: 25px;
        margin-bottom: 25px;
      }

      @media @sm {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      @media @xs {
        width: 90%;
      }

      & div {
        & input {
          color: @black;
          padding: 0 20px;
          border: none;
          border-radius: 3px;
          width: 100%;
          height: 40px;
          font-family: @main-font;
          font-size: 14px;
          font-weight: 400;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
          background-color: #ffffff;

          &:focus {
            outline: 2px solid #ff0!important;
            outline-offset: 2px!important;
            z-index: 10!important;
          }

          @media @md {
            height: 66.6px;
            border-radius: 0;
          }

          &::placeholder {
            color: @black;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            color: @black;
            opacity: 1;
          }

          &::-ms-input-placeholder {
            color: @black;
            opacity: 1;
          }
        }

        & button {
          color: @color-1;
          background-color: transparent;
          position: absolute;
          top: 0;
          right: 5px;
          height: 40px;
          width: 40px;
          border: none;

          & i {
            font-size: 19px;
          }
        }
      }
    }

    &-close {
      .no-appearance;
      .focus-outline;
      .transition(color);

      position: absolute;
      right: -74px;
      top: 9px;
      color: @color-1;
      text-decoration: underline;
      cursor: pointer;

      @media @md {
        display: none;
      }

      &:hover {
        color: @search-top-close;
      }
    }
  }

  .sol-top-search-desktop {
    .transition(~  'background-color, height');
  }

  .sol-top {
    padding: 15px 45px;
    background-color: @header-top-bg;

    @media (min-width: 991px) and (max-width: 1260px) {
      padding: 15px 20px;
    }

    @media @md {
      padding: 15px 15px;
    }

    @media @sm {
      max-height: 45px;
      text-align: center;
      background-color: @header-top-mobile-bg;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    @media @sm {
      padding-left: 7px;
      padding-right: 7px;
    }

    @media @xs {
      padding-left: 2px;
      padding-right: 2px;
    }

    .sol-header-flex {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-direction: row;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      justify-content: flex-end;

      @media @lg {
        padding-left: 0px;
        padding-right: 0px;
      }

      @media @md {
        justify-content: space-evenly;
      }

      @media @sm {
        width: 100%;
        padding: 0;
        padding-right: 2%;
      }

      @media @xs {
        padding-right: 0;
      }

      & .sol-header-tools {
        @media @md {
          flex: 1 0 auto;
        }
      }
    }

    .sol-header-top-item {
      line-height: 45px;
      margin: 0 16px;
      font-size: 14px;
      margin: 0 30px;

      @media (min-width: 991px) and (max-width: 1260px) {
        margin: 0 18px;
        font-size: 13px;
      }

      @media @md {
        line-height: 45px;
        margin: 0 12px;
        flex: 1 0 10%;
        margin-right: 20px !important;
      }

      @media @sm {
        font-size: 11px;
        line-height: unset;
        margin: 0;
        margin-right: 12px !important;
      }

      @media @xs {
        margin-right: 8px !important;
      }

      .sol-header-link {
        color: @white;
        text-decoration: none;
        white-space: nowrap;
        line-height: 45px;

        .fa-desktop::before {
          display: none;
        }

        @media @sm {
          vertical-align: middle;
          display: inline-flex;
        }

        & i {
          transform: translateY(2px);
          font-size: 19px;
          margin-right: 10px;
          color: @white;

          @media (min-width: 991px) and (max-width: 1260px) {
            margin-right: 5px;
            font-size: 15px;
          }

          @media @sm {
            line-height: 39px;
            margin-right: 3px;
            font-size: 11px;
          }

          &:nth-child(2) {
            font-size: 14px;
            margin-left: 8px;

            @media @sm {
              line-height: 39px;
              font-size: 11px;
              margin-left: 6px;
            }
          }
        }

        &:focus {
          outline: 2px solid yellow;
          outline-offset: 2px;
          padding: 2px;
          z-index: 10;
        }
      }

      .sol-header-link:hover{
        text-decoration: underline;
      }

      & .sol-header-all-sites {
        display: none;
        position: absolute;
        background: @white;
        border: 2px solid @color-1;
        width: auto;
        padding: 15px;
        z-index: 2;

        @media @xs {
          right: 0;
        }

        & a {
          white-space: nowrap;
          display: block;
          color: @black;
        }
      }

      @media (min-width: 991px) and (max-width: 1260px) {
        &:last-of-type {
          margin-left: 22px;
        }

        &:first-of-type {
          margin-right: 6px;
        }
      }

      &.sol-header-websites {
        @media (max-width: 340px) {
          & .sol-header-link {
            & i {
              display: none;
            }
          }
        }
      }
    }

    .sol-top-logo {
      display: flex;

      & > img {
        height: 48px;
        width: auto;
      }

      & > span {
        font-size: 22px;
        margin-top: -5px;
        margin-left: 12px;
        color: @white;
        text-transform: uppercase;
        text-decoration: underline;
        text-decoration-color: @color-1;
      }

      @media @md {
        display: none;
      }

      &:focus {
        outline: 2px solid yellow;
        outline-offset: 2px;
        z-index: 10;
      }
    }
  }

  .sol-bottom {
    background-color: @header-menu-bg;
    text-align: left;
    -webkit-box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.05);

    @media @md {
      display: none;
    }

    & > div > .sv-script-portlet {
      text-align: center;
    }
  }
}

.sol-to-content {
  width: 1px;
  height: 35px;
  display: block;
  opacity: 0;
  position: relative;
  left: 45px;
  top: 23px;
  padding: 10px;
  line-height: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: @white;

  @media (max-width: 1400px) {
    position: absolute;
    margin-left: 205px;
    top: 40px;
    padding: 1px 10px;
  }

  @media @md {
    position: absolute;
    right: 200px;
    margin-left: 0;
    top: 105px;
  }

  @media @sm {
    top: 92px;
  }

  &:focus {
    opacity: 1;
    width: 200px;
    color: @black;
    background-color: #ffec99e1;
  }
}

.sol-header-display-menu {
  display:  flex;
  flex-direction: column;
  width: auto;
}

.sol-header-logo {
  @media (min-width: 991px) and (max-width: 1260px) {
    position: absolute;
    left: 5%;
    margin-left: -20px;
  }
}

.sol-header-flex {
  &:first-of-type {
    @media (min-width: 991px) and (max-width: 1260px) {
      max-width: 100%;
      padding-left: 200px !important;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  @media @md-strict {
    .sol-header-flex {
      display: block !important;
      width: 100%  !important;

      & > div {
        display: inline-block;
        margin: 0 20px;
      }
    }
  }

  @media @sm {
    .sol-header-flex {
      margin-left: -145px;

      & .sol-header-tools {
        flex: 1 0 auto;
      }
    }

    .sol-header-top-item {
      flex: 1 0 auto;
    }
  }
  @media @xs {
    .sol-header-flex {
      margin-left: -205px;
    }
  }
}
