/* stylelint-disable media-feature-range-notation */
.sol-page-with-top-image {
	position: relative;
	background: @white;

	& .sol-page {
		&__top-image-container {
			position: relative;

			.sol-top-banner {
				width: 100%;
				height: 450px;
				background-position: center center;
				background-size: cover;

				@media @md {
					width: auto;
					height: 400px;
				}

				@media @sm {
					width: auto;
					height: 220px;
				}
			}
		}

		&__top-text {
			max-width: 950px;
			margin: auto;
			margin-top: -25px;
			overflow: hidden;
			font-family: @main-font;
			font-size: 24px;
			text-align: left;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				margin-top: -35px;
			}

			@media @md {
				max-width: 80%;
				font-size: 21px;
			}

			@media @sm {
				max-width: 100%;
				margin: -15px 20px 15px;
			}

			@media (min-width: 1800px) {
				margin-top: -75px;
			}
		}

		&__top-text-container {
			position: relative;
			width: 100%;
			padding-bottom: 80px;
			margin-bottom: 40px;
			background-color: @white;
			
			@media @xl {
				margin-top: -65px;
			}

			@media (max-width: 1200px) and (min-width: 991px) {
				padding: 0 60px !important;
			}

			@media @md {
				padding-bottom: 20px;
				margin-top: 10px;
				margin-bottom: 0;
			}

			@media @sm {
				margin-bottom: -25px;
			}
		}
	}

	.sol-bottom-wave {
		position: relative;
		width: 102%;
		margin-top: -45px;
		margin-left: -1%;

		@media @md {
			margin-top: -35px;
		}

		@media @sm {
			margin-top: -20px;

		}

		@media @xl {
			max-height: 150px;
			margin-top: -75px;
			margin-bottom: 40px;
		}
	
		& img {
			z-index: 1;
			width: 100%;

			@media @sm {
				margin-top: -10px;
			}
		}

		& svg {
			z-index: 1;
			width: 100%;
		}

		& .sol-top-image__header {
			position: absolute;
			top: -34px;
			width: 100%;

			&--text {
				width: fit-content;
				max-width: 600px;
				height: 85px;
				padding: 10px 20px;
				margin: auto;
				font-family: @main-font;
				line-height: 60px;
				color: @color-1;
				text-align: center;
				text-overflow: ellipsis;
				background-color: @white;
				border-radius: 4px;

				& h1 {
					font-size: 39px;
					line-height: 55px;
				}

				@media @sm {
					max-width: 440px;
					height: 60px;
					margin-top: 10px;
					line-height: 35px;

					& h1 {
						font-size: 23px !important;
					}
				}

				@media @xs {
					max-width: 320px;
					padding-top: 0;
					padding-right: 10px;
					padding-left: 10px;
					font-size: 18px;

					& h1 {
						font-size: 18px !important;
					}
				}
			}

			@media @xl {
				top: 0;
			}

			@media @md {
				top: -45px;
			}

			@media @sm {
				top: -50px;
			}
		}
	}
}

.sol-top-image__container {
	position: relative;

	.sol-top-banner-image{
		width: 100%;
		height: 540px !important;
		height: auto;
		max-height: 540px;
		background-position: center center;
		background-size: cover !important;
		
		@media @md {
			width: auto;
			height: 450px !important;
		}

		@media @sm {
			width: auto;
			height: 220px !important;
		}
	}
}
