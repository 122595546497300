.sol-action-link {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 44px;
  a {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.1;
    text-decoration: none;
    color: @white;
    padding: 13px 50px 14px 15px;
    border-radius: 6px;
    background-color: @color-1;
    position: absolute;
    right: 0;
    left: 0;
    &:hover {
      text-decoration: underline;
    }
    &:after {
      content: '\f054';
      font-size: 20px;
      font-family: FontAwesome;
      position: absolute;
      right: 16px;
      margin-top: -1px;
    }
    i.fa-external-link:before {
      position: relative;
      margin-left: 6px;
      top: 1px;
    }
  }
}
