/* subpage/page-listing.less */
.sol-page-listing-old {
  background-color: @page-listing-bg;
  padding: 20px 20px 15px 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  ul {
    .no-list-style;
  }
  &-item {
    margin-bottom: 20px;
    &__link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &__name {
      color: @page-listing-subheader-color;
      margin-bottom: 12px;
      margin-top: 0;
      font-weight: 700;
    }
    &__icon {
      font-size: 17px;
      float: left;
      margin-right: 7px;
      height: 20px;
      width: 20px;
      color: @page-listing-item-icon-color;
      background-color: @page-listing-item-icon-bg;
      border-radius: 50%;
      text-align: center;
      position: relative;
      top: 2px;
      padding-left: 1px;
      padding-top: 0;
      margin-left: -12px;
    }
  }
  &-child {
    &__link {
      display: block;
      margin-bottom: 10px;
      font-size: 17px;
      font-weight: 400;
      color: @page-listing-child-color;
      text-decoration: none;
      .transition(color);
      &:hover {
        text-decoration: underline;
      }
      & i.fa-external-link {
        font-size: 11px;
        position: relative;
        top: -1px;
        margin-left: 5px;
      }
    }
    &__icon {
      font-size: 23px;
      float: left;
      margin-right: 8px;
      color: @page-listing-child-icon-color;
      position: relative;
      top: 3px;
      padding-left: 2px;
    }
  }
  & i.fa-external-link {
    position: relative;
    top: -5px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 2px;
  }
}
