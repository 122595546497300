.sol_matsedel_RSS {
  & h3 {
    margin-bottom: 0 !important;
  }
  & time {
    display: block;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 0.9em;
    color: #333;
  }
}
