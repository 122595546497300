* {
  box-sizing: border-box;
}
a,
button {
  .focus-outline;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.sol-ul,
.sol-ul-container ul {
  .no-list-style;
}
.sol-float-right {
  float: right;
}
.sol-float-left {
  float: left;
}
.sol-hidden {
  display: none;
}
.sol-visibility-hidden {
  visibility: hidden;
}
.sol-clearfix {
  .clearfix;
}
.sol-relative {
  position: relative;
}
.sol-no-anim * {
  transition: none !important;
}
.sol-external-link-icon {
  margin-left: 6px;
  font-size: 10px;
  display : none;
}
.sol-lightgray-background {
  background-color: @gray-4;
}
.sv-portlet-image-caption {
  text-align: left !important;
}
.sv-youtube-portlet {
  .sv-video-container {
    padding-bottom: 0;
    margin-bottom: 10px;
    min-height: 315px;
  }
}
