.sol-form,
.sol-form-decoration {
  padding: 20px 20px 40px;
  border-radius: 3px;
  background-color: @gray-4 !important;
  margin-bottom: 20px;
  @media @md {
    padding: 20px;
  }
  @media @sm {
    padding-right: 10px;
    padding-left: 10px;
  }
  & h2 {
    margin-top: 0;
  }
  & form {
    & input[type='email'],
    & input[type='number'],
    & input[type='password'],
    & input[type='tel'],
    & input[type='text'],
    & input[type='url'],
    select,
    textarea {
      .input-text;
      &:invalid {
        .input-text-invalid;
      }
      &:required:valid {
        .input-text-valid;
      }
      & ~ span.font-normal {
        margin-top: 10px;
      }
    }
    & textarea {
      height: auto;
    }
    select {
      .input-select;
    }
    & .sol-form-field {
      margin-bottom: 10px;
    }
    & .sol-label-container,
    & .sv-field-title-container,
    & .sv-login-input-group {
      & > label {
        .input-label;
      }
      & > span {
        display: block;
        margin-bottom: 5px;
        font-size: 23px;
        font-weight: 600;
        font-family: @main-font;
      }
    }
    & .sv-form-datefield {
      & > div {
        width: 50%;
        margin: 0 !important;
        &:nth-of-type(1) {
          padding-right: 5px;
        }
        &:nth-of-type(2) {
          padding-left: 5px;
        }
        & select {
          min-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    & br {
      display: none;
    }
    .font-normal { 
      display: block;
      font-size: 19px;
      font-weight: 600;
    }
    & .sv-formField {
      margin-bottom: 0;
      @media @sm {
        width: 100%;
        padding: 0;
      }
    }
    & input[type='checkbox'] {
      .input-checkbox;
    }
    & input[type='radio'] {
      .input-radio;
    }
    & button[type='submit'],
    & input[type='submit'] {
      .sol-btn;
      margin-top: 20px;
      padding-left: 25px;
      padding-right: 25px;
      & > i.fa {
        margin-right: 11px;
        margin-left: -15px;
      }
    }
    & .sol-form-divider {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed @form-divider;
    }
  }
  & input[type="tel"]{
    & ~ span.font-normal {
      font-size: 11px !important;
      background-color: #ac1a2f !important;
      border-radius: 2px !important;
      color: @white !important;
      height: 21px;
      width: auto;
      line-height: 20px;
      padding-left: 5px;
    }
  }
}
.sv-dbform-portlet {
  & form {
    margin-top: 20px;
    & .sv-form-consent {
      position: relative;
      padding-bottom: 30px;
      border-bottom: 2px solid fadeout(lightgray, 20%);
      & input {
        top: 27px;
        height: 22px;
        width: 22px;
        -webkit-appearance: none;
        background-color: white;
        border: 1px solid #1B927C;
        box-shadow: none;
        padding: 10px;
        border-radius: 3px;
        display: block;

        &:active, &:checked:active {
          box-shadow: none;
        }
        &:checked {
          background: #fff url(https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg) no-repeat;
          background-size: 18px;
          background-position: center;
          border: 2px solid #1B927C;
          box-shadow: none; 
        }
        &:focus {
          outline: 2px solid #257cc3;
          z-index: 10;
          outline-offset: 0px;

          & + label {
            text-decoration: underline;
          }
        }
        &:hover {
          background-color: #B9DBD4;
        }
        & + label {
          padding-left: 36px;
          display: inline;
        }
      }
    }
  }
}
.sv-formField {
  & input[type="submit"] {
    border: 0;
    background-color: fadeout(@color-4, 40%);
    border-radius: 40px;
    padding: 8px 20px;
    margin-top: 45px;

    &:focus {
      background-color: @color-4;
      border-color: transparent;
    }
  }
  & input[type="text"], & input[type="email"], & input[type="tel"] {
    &:focus {
      outline: 2px solid #257cc3 !important;
      z-index: 10 !important;
      outline-offset: -1px !important;
    }
  }
}
.sv-dbform-portlet {
  & form {
    & input[type="radio"] {
      position: relative;
      top: 27px;
      width: 22px;
      height: 22px;
      -webkit-appearance: none;
      background-color: white;
      border: 1px solid #1B927C;
      box-shadow: none;
      padding: 10px;
      border-radius: 50%;
      display: block;

      &:active, &:checked:active {
        box-shadow: none;
      }
      &:checked {
        background: #1b927c url('https://www.strangnas.se/images/18.38e877f416b3ac0044142498/1560260286947/circle-128.png') no-repeat;
        border: 2px solid #1b927c;
        box-shadow: none;
        background-size: 8px;
        background-position: center;
      }
      &:focus {
        outline: 2px solid #257cc3;
        z-index: 10;
        outline-offset: 0px;

        & + label {
          text-decoration: underline;
        }
      }
      &:hover {
        background-color: #B9DBD4;
      }
      & + label {
        padding-left: 30px;
        display: inline-block;
      }
    }
  }
}
input[type=radio]::-ms-check {
  border: 2px solid #1b927c;
  box-shadow: none;
  background-size: 8px;
  background-position: center;
}

input[type=checkbox]::-ms-check {
  //background: #fff url(https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg) no-repeat;
  background-size: 18px;
  background-position: center;
  border: 2px solid #1B927C;
  box-shadow: none;
  border-radius: 3px;
}
.sv-defaultFormTheme input[type="checkbox"] {
  position: relative;
  top: 27px;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 3px;
  display: block;

  &:active, &:checked:active {
    box-shadow: none;
  }
  &:checked {
    background: #fff url(https://www.strangnas.se/images/18.9808b9916e35ca14f56851d/1573206012248/check-solid.svg) no-repeat;
    background-size: 18px;
    background-position: center;
    border: 2px solid #1B927C;
    box-shadow: none; 
  }
  &:focus {
    outline: 2px solid #257cc3;
    z-index: 10;
    outline-offset: 0px;

    & + label {
      text-decoration: underline;
    }
  }
  &:hover {
    background-color: #B9DBD4;
  }
  & + label {
    padding-left: 30px;
    display: inline-block;
  }
}
.sv-defaultFormTheme input[type="radio"] {
  position: relative;
  top: 27px;
  width: 22px;
  height: 22px;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #1B927C;
  box-shadow: none;
  padding: 10px;
  border-radius: 50%;
  display: block;

  &:active, &:checked:active {
    box-shadow: none;
  }
  &:checked {
    background: #1b927c url('https://www.strangnas.se/images/18.38e877f416b3ac0044142498/1560260286947/circle-128.png') no-repeat;
    border: 2px solid #1b927c;
    box-shadow: none;
    background-size: 8px;
    background-position: center;
  }
  &:focus {
    outline: 2px solid #257cc3;
    z-index: 10;
    outline-offset: 0px;

    & + label {
      text-decoration: underline;
    }
  }
  &:hover {
    background-color: #B9DBD4;
  }
  & + label {
    color: #35383b;
    font-family: @main-font;
    padding-left: 30px;
    font-size: 17px;
    font-size: 17px;
    line-height: 1.3em;
    font-weight: 400;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.sv-form-singleselectionfield {
  margin-bottom: 20px !important;
}
.sv-formField .sv-form-consent {
  padding-left: 0 !important;
  &  input {
    position: relative !important;
  }
}
