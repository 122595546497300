/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */

.sol-error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 764px;
  text-align: center;
  background-color: transparent;

  @media @lg {
    height: 764px;
  }

  @media @md {
    height: 420px;
    background-color:rgba(0, 0, 0, 0.6);
  }

  @media @sm {
    height: 330px;
  }

  @media @xs {
    height: 270px;
  }

  &-message{
    max-width: 50%;
    padding: 3em;
    background-color: rgba(255,255,255,.9);
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);

    @media @md{
      max-width: 80%;
      padding:0;
      background-color: transparent;
      box-shadow: none;   
    }

    & h1 {
      font-family: @main-font;
      font-size: 42px;
      font-weight: 600;
      line-height: 40px;

      @media @md {
        font-weight: bold;
        color: white;
      }

      @media @sm {
        font-size: 30px;
        font-weight: bold;
      }

      @media @xs {
        font-size: 24px;
      }
    }
  }

  &-text-big {
    font-family: @main-font;
    font-size: 255px;
    font-weight: 700;
    line-height: 220px;
    color: @white;
  }

  &-text-small {
    @media @md {
      span{
        font-weight: 500;
        color: white;
      }
    }

    @media @sm {
      span{
        display: none;
      }
    }
  }

  &-btns{
    margin-top:30px;

    .sol-btn {
      min-width: 180px;
      font-weight: 400;
      background-color: #FFE366;
  
      &:last-of-type {
        margin-left: 25px;
  
        @media @md {
          margin-left: 18px;
        }

        @media @sm {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
  }
}
