.eventItem {
  position: relative;
}
.eventDateWrp {
  top: 24px;
  padding: 0 !important;
  background-color: @color-1 !important;
}
.eventName h3 {
  margin: 16px 0 0 0;
}
.eventLocation {
  margin-bottom: 16px;
}
.eventForm {
  & .btn {
    background-color: @color-1;
  }
}
.eventDetails a.calDownload {
  color: @color-1 !important;
}
