/* stylelint-disable media-feature-range-notation */
.sol-top-contact {
  display: flex;
  padding: 62px 15px;
  background-color: @color-1;
  border-radius: 4px;

  @media (min-width: 1150px) and (max-width: 1200px) {
    padding: 54px 15px;
  }

  @media @md {
    margin-bottom: 10px;
  }

  @media (max-width: 820px) {
    padding: 15px 10px;

    &__inner-left, &__inner-right {
      width: 50%;
      padding: 0 10px;
    }
  }

  @media @sm {
    flex-wrap: wrap;

    &__inner-left, &__inner-right {
      flex: 0 0 100%;
    }

    &__inner-right {
      padding: 0 !important;

      & a {
        font-size: 14px;
      }
    }
  }

  &__inner-left {
    max-width: 49%;
    padding: 0 1em;
    border-right: 1px dotted fadeout(@white, 75%);

    @media @md {
      width: 100%;
      max-width: none;
      margin-bottom: 40px;
      border: none;
    }

    @media @sm {
      margin-bottom: 10px;
    }

    &--header {
      margin-bottom: 0.5em;
      font-family: @main-font;
      font-size: 23px;
      font-weight: 600 !important;
      color: @white;

      @media @sm {
        font-size: 18px !important;
      }
    }

    &--info {
      display: flex;
      flex-direction: column;

      & a{
        color: @white;
        text-decoration: none;

        &:hover{
          text-decoration: underline;
        }
      }

      & p {
        margin: 0.5em 0;
        font-family: @main-font;
        font-size: 17px;
        font-weight: 200;
        color: @white;
        text-overflow: ellipsis;

        @media @sm {
          font-size: 17px !important;
        }

        & i {
          margin-right: 0.8em;
          font-size: 18px;
          color: @white;
        }

        & a {
          font-family: @main-font;
          font-size: 17px;
          font-weight: 200;
          color: @white;
          text-decoration: none;

          &:hover{
            text-decoration: underline;
          }

          &:focus {
            z-index: 10;
            outline: 2px solid yellow;
            outline-offset: 2px;
          }

          @media @sm {
            font-size: 17px !important;
          }
        }
      }
    }
  }

  &__inner-right {
    max-width: 49%;
    padding: 0 1em;

    @media @lg { 
      max-width: none;
    }

    @media @md {
      width: 100%;
      max-width: none;
    }

    &--wrapper {
      display: flex;
      flex-direction: column;
    }

    &--item {
      position: relative;
      width: 100%;
      height: 50px;
      padding: 0 15px 0 40px;
      margin: 0.8em;
      font-family: @main-font;
      font-size: 17px;
      font-weight: 400;
      line-height: 50px;
      color: @black;
      text-decoration: none;
      white-space: nowrap;
      background-color: @white;
      border-radius: 300px;

      @media @md {
        margin-left: 0;
      }

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        z-index: 10;
        outline: 2px solid yellow !important;
        outline-offset: 2px;
      }

      & i {
        position: absolute;
        top: 48%;
        left: 17px;
        font-size: 17px;
        line-height: 50px;
        transform: translateY(-50%);

        &:last-child {
          right: 17px;
          left: unset;
          font-size: 27px;
        }
      }
    }
  }
}
