.sol-mobile-menu-wrapper {
  position: relative;
  display: none;
  @media @md {
    display: block;
  }
}

.sol-mobile-menu-container {
  display: none;
  background-color: @gray-4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 49;
  height: 0;
  min-height: 0;
  .transition(~'height, min-height', 250ms);
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.43);
  @media @md {
    &.sol-active {
      display: block;
    }
  }

  & > ul {
    border-top: 1px solid @mobile-menu-border;
  }

  & .sol-menu-list {
    width: 100%;
    &-expanded-init {
      display: block;
    }
  }

  & .sol-menu-item {
    position: relative;
    &-link {
      .focus-outline;
      display: block;
      position: relative;
      padding: 10px 49px 10px 19px;
      border-bottom: 1px solid @mobile-menu-border;
      text-decoration: none;
      margin-left: 1px;
      margin-right: 1px;
      color: @black;

      & .sol-menu-item-display-name {
        font-size: 17px;
        //font-weight: 400;
      }
    }
    & .sol-menu-button {
      .no-appearance;
      .focus-outline;
      position: absolute;
      top: 0;
      height: 43px;
      right: 0;
      width: 50px;
      //border-left: 1px solid @mobile-menu-border;

      & i {
        position: absolute;
        right: 16px;
        top: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        text-align: center;
        padding-top: 3px;
        padding-left: 3px;
        .transition(all);
      }
    }

    &[data-menu-level='1'] .sol-menu-item-link {
      padding-left: 30px;
    }

    &[data-menu-level='2'] .sol-menu-item-link {
      padding-left: 40px;
    }

    &[data-menu-level='3'] .sol-menu-item-link {
      padding-left: 50px;
      border: none;
    }

    &[data-menu-level='4'] .sol-menu-item-link {
      padding-left: 60px;
      background-color: @white;
    }

    &[data-menu-level='5'] .sol-menu-item-link {
      padding-left: 70px;
      background-color: @white;
      //border-bottom: 1px solid @mobile-menu-border;
      //border-left: 1px solid @mobile-menu-border;
      //border-top: 1px solid @mobile-menu-border;
    }

    &[data-expanded='true'] > .sol-menu-button {
      & i.fal {
        .rotate(90deg);
      }
    }

    &[data-expanded='false'],
    &[data-is-current='true'] {
      &[data-menu-level='2'] > .sol-menu-item-link { 
        //color: @mobile-menu-text-active;
        //background-color: @mobile-menu-bg-active;
        background-color: @white;
      }
    }

    &[data-expanded='true'][data-has-children='true'],
    &[data-is-current='true'] {
      > .sol-menu-item-link { 
        //color: @mobile-menu-text-active;
        //background-color: @mobile-menu-bg-active;
        background-color: @gray-4;
      }
    } 

    &[data-expanded='false'],
    &[data-is-current='true'] {
      &[data-menu-level='3'] > .sol-menu-item-link { 
        //color: @mobile-menu-text-active;
        //background-color: @mobile-menu-bg-active;
        background-color: @white;
      }
    }

    &[data-expanded='true'][data-has-children='true'],
    &[data-is-current='false'] {
      &[data-menu-level='2'] > .sol-menu-list{
        ul{
          border-bottom: 1px solid  rgba(255, 209, 0, 0.4);
          border-left: 2px solid   rgba(255, 209, 0, 0.4);
          border-top: 1px solid  rgba(255, 209, 0, 0.4);
        }
      }
    }

    &[data-expanded='true'][data-has-children='false'][data-is-current='true'] {
        a{
           background-color: rgba(255, 209, 0, 0.2) !important;
        }
        & > .sol-menu-item-link span{
          font-size: 17px;
          font-weight: normal !important;
        }
    }

    &[data-expanded='true'][data-has-children='true'],
    &[data-is-current='true'] {
      & > .sol-menu-item-link span{
        font-size: 17px;
        font-weight: 600;
      }
    }

    &[data-node-type='sv:folder'] {
      & > .sol-menu-button {
        width: 100%;
        border-left: none;
      }
    }
  }
}
