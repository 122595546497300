.sol-crisis-container {
  position: relative;
  background-color: @crisis-bg;
  color: @crisis-text;
  .transition(margin-top, 150ms, linear);
  

  &.interference {
    background-color: fadeout(@color-4, 80%);
    border: 2px solid @color-4;

    & .sv-row {
      padding: 5px 10px 0;
    }

    & .sol-crisis-message-title, & .sol-crisis-message-desc,
    & .sol-close-crisis {
      color: @black;
    }
    & .sol-crisis-message .fa-info-circle {
      color: @color-4;
    }
    & .sol-crisis-message-title {
      padding-left: 75px;

      @media @md {
        padding-left: 45px;
      }
    }
    & .sol-crisis-message-desc {
      padding-left: 35px;
      max-width: 880px;

      @media @md {
        padding-left: 20px;
      }
    }
    & .sol-close-crisis {
      @media @md {
        top: 10px;
      }
    }
  }

  & .sv-row {
    position: relative;
    padding: 20px;
    display: inline-block;
    font-family: @main-font;
    @media @md {
      padding: 10px;
    }
    @media @sm {
      padding: 10px;
    }
  }

  & i.fa-warning {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -55px;
    font-size: 100px;
    line-height: 110px;
    @media @md {
      left: 30px;
      top: 20px;
      margin-top: 0;
      font-size: 45px;
      line-height: 50px;
    }
    @media @sm {
      left: 20px;
      font-size: 25px;
      line-height: 30px;
    }
  }

  .sol-crisis-message {
    margin-top: 30px;
    margin-left: -30px;
    padding-bottom: 10px;
    .sol-clearfix;
    
  .fa-exclamation, .fa-info-circle {
    font-size: 51px;
    display: flex;
    margin-bottom: -50px;
    color: white;
    @media @md {
      height: 51px;
      font-size: 40px;
      margin-left: -15px;
      line-height: 1.2;
      }
      @media @sm {
        margin-left: 0px;
        height: 27px;
        font-size: 27px;
        line-height: 0px;
        width: 11px;
        }
    }

    &-title {
      font-size: 23px;
      font-weight: 600;
      font-family: @main-font;
      line-height: 1.35;
      display: block;
      padding-left: 40px;
      color: @crisis-text;
      text-decoration: underline;
      @media @md {
        padding-left: 26px;
        font-weight: 600;
        line-height: 1.11;
        font-size: 18px;
        }
      @media @sm {
        font-weight: 600;
        line-height: 1.11;
        font-size: 16px;
        }
    }

    &-desc {
      color: @crisis-text;
      line-height: 1.44;
      font-family: @main-font;
      font-size: 16px !important;
      font-weight: 300;
      margin-left: 40px;

      @media @md {
        font-size: 15px !important;;
        margin-bottom: 10px;
        line-height: 1.24;
        margin-left: 26px;
      }
      @media @sm {
        font-size: 13px !important;;
      }
    }

    &-more {
      display: block;
      text-align: right;
      font-size: 15px;
      & a {
        border-radius: 2px;
        color: @crisis-text;
        font-weight: 600;
        text-decoration: none;
        .transition(background-color);
        @media @sm {
          display: block;
          text-align: right;
          bottom: 6px;
          right: 20px;
          position: absolute;
          font-size: 15px;
        }

        &:hover {
          background-color: @crisis-btn-bg-hover;
          text-decoration: underline;
        }
      }
    }
  }

  .sol-close-crisis {
    position: absolute;
    top: 30px;
    right: -40px;
    cursor: pointer;
    width: 160px;
    font-size: 13px;
    font-weight: 600;
    margin-right: 50px;
    color: @crisis-text;
    cursor: pointer;

    @media @md {
      top: 18px;
      right: -60px;
    }

    & i.fal, & i.fas {
      margin-left:  10px;
      margin-top: -5px;
      font-size: 19px;
      @media @md {
        font-size: 16px;
      }
    }
  }
  & a {
    padding: 10px 25px;
    width: 100%;
    height: 100%;
    text-decoration: none;

    @media @sm {
      padding: 35px 25px 10px;
    }
  }
}

.sol-no-script {
  i.fa-warning {
    font-size: 50px;
    margin-top: -57px;
  }

  .sv-row {
    padding: 15px 20px 0 90px;
  }

  .sol-crisis-message-title {
    margin-bottom: 5px;
  }
}
