 /* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
 
 table.sol-table-decoration,
 table.sv-table-type-stylad-tabell {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 3px;
  box-shadow: 0 0 0 1px @table-border;

  & caption {
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 800;
    line-height: 1.2em;
  }

  & thead {
    //background-color: @thead-bg;
   // background-color:  @color-1;
    //opacity: 0.8;
    & th {
      padding: 11px 18px;
      font-family: @main-font;
      font-size: 19px;
      font-weight: 600;
      border-right: 1px solid @thead-border;

      @media @sm{
        font-size: 13px;
        font-weight: 700;
      }

      & p {
        font-family: @main-font !important;
        font-size: 19px !important;

       // line-height: 22px;
       // color: @thead-color;
        font-weight: 600 !important;

        @media @sm{
          font-size: 13px !important;
          font-weight: 700 !important;
        }
      }

      &:last-child {
        border: none;
        border-top-right-radius: 3px;
      }

      &:first-child {
        border-top-left-radius: 3px;
      }
    }
  }

  & tbody {
    background-color: @tbody-bg;

    & th {
      padding: 8px 12px;
      background-color: rgba(255, 209, 0, 0.3) !important;
      border-right: 1px solid @thead-border;

      @media @sm{
        font-size: 13px;
        font-weight: 700;
      }

      & p {
        font-family: @main-font !important;
        font-size: 18px !important;

       // line-height: 22px;
       // color: @thead-color;
        font-weight: 600 !important;

        @media @sm{
          font-size: 13px !important;
          font-weight: 700 !important;
        }
      }

      &:last-child {
        border: none;
        border-top-right-radius: 3px;
      }

      &:first-child {
        border-top-left-radius: 3px;
      }
    }

    & tr {
      td {
        padding: 11px 18px;
        font-family: @main-font;
        font-size: 17px;
        font-weight: 300;
        border-right: 1px solid @tbody-border;

        @media @sm{
          font-size: 13px;
          font-weight: 300;
        }

        & p {
          font-family: @main-font !important;
          font-size: 17px !important;
          font-weight: 300 !important;

          @media @sm{
            font-size: 13px ! important;
            font-weight: 300 !important;
          }
        }

        &:last-child {
          border: none;
        }
      }

      &:nth-child(odd) {
        background-color: @tbody-odd;
      }
    }
  }
}

table.sv-table-type-stylad-tabell {
  & thead {
    p{
      color: @white !important;
    }

    background-color: @color-1 !important;
  }
}

table {
  caption {
    display: none;
  }
}

.sv-editable-layout {
  table {
    caption {
      display: table-caption;
    }
  }
}
