/* subpage/page-listing.less */
.sol-page-listing {
  margin: 30px 0;
  position: relative;
  ul {
    .no-list-style;
  }
  &-item {
    padding: 15px 25px !important;
    background-color: @page-listing-bg;
    margin-bottom: 10px;
    &__link {
      text-decoration: none;
      display: block;

      &:focus {
        & h2 {
          .focus-outline;
        }
      }
      &:hover {
        text-decoration: underline;
        text-decoration-color: #006579;
      }
/*       .fa-external-link{
        display: none;
      } */
    }
    &__name {
      color: @page-listing-subheader-color;
      margin-bottom: 8px;
      margin-top: 0;
      font-size: 19px;
      font-weight: 600;
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__icon {
      font-size: 23px;
      font-weight: 400;
      margin-right: 8px;
      color: @color-1;
      position: relative;
    }
    &__external-icon {
      font-size: 19px;
      color: @black;
      margin-left: 12px;
    }
  }
  &-child {
    &__link {
      display: block;
      margin-bottom: 10px;
      font-size: 17px;
      font-weight: 400;
      color: @page-listing-child-color;
      text-decoration: none;
      .transition(color);
      &:hover {
        text-decoration: underline;
      }
      &:first-of-type {
        margin-top: 8px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__icon {
      font-size: 21px;
      margin-right: 6px;
      color: @black;
      position: relative;
    }
    &__external-icon {
      font-size: 16px;
      color: @black;
      margin-left: 12px;
    }
    &__name {
      font-size: 17px;
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

