.sol-tourism-content {
  & .sol-widget-decoration {
    min-height: 400px;

    @media (max-width: 1170px) {
      margin: auto;
    }
    @media @sm {
      min-height: 0;
    }
    & .sv-text-portlet-content {
      & p {
        overflow: hidden;
        display: block;
      }
    }
    & .sol-widget-decoration-img {
      background-size: cover;
    }
    .font-widget-heading {
    }
  }
  & > div.sv-layout {
    flex: 0 0 32%;

    @media (max-width: 1170px) {
      flex: 0 0 49%;
      margin: 0;
    }
    @media @sm {
      flex: 0 0 100%;
      margin: 0;
    }

    &:last-of-type {
      margin-left: 0;
      margin-right: auto;
    }
  }
  & > .sv-custom-module {
    width: 100%;
  }
  & .sv-mfpimage-portlet {
    margin-bottom: 0;
  }
}
.sol-tourism-container {
  padding: 30px 20px;
  & .sol-tourism-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1260px;
    margin: auto;

    & > div.sv-layout {
      margin: 0 auto;

      @media (max-width: 1170px) {
        margin-left: 0;
        margin-right: auto;
      }
      @media @sm {
        margin: 0 auto;
      }
    }
    & .sol-widget-decoration-img {
      max-width: 406px;
      height: 230px;
    }
  }
  & .sol-widget-decoration {
    max-width: 406px;

    & .sv-text-portlet {
      padding: 18px 18px 40px 18px;

      @media @sm {
        padding: 18px 18px 24px 18px;
      }
    }
  }
}
