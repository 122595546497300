.sol-top-nav {
  .sol-clearfix;
  display: inline-block;
  vertical-align: top;

  &-item {
    .focus-outline;
    white-space: nowrap;
    float: left;
    padding: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    color: @header-menu-text;
    font-size: 16px;
    line-height: 1.2em;
    font-weight: 400;
    cursor: pointer;
    margin-left: auto;
    margin-right: 25px;
    vertical-align: top;
    text-decoration: none;
    max-width: 215px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .transition(background-color);
    &:first-child {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      padding-left: 0;
    }
    &:hover {
      border-bottom: 3px solid @color-1;
      height: 54px !important;
      top: 2px;
      position: relative;
    }
    &.sol-active {
      border-bottom: 3px solid @color-1;
    }
  }
}
